import { FC, useMemo } from "react";
import { MdError, MdWarning } from "react-icons/md";
import { Table, Head, Body, Span } from "./bulk-upload.styled";

import { FlexContainer, ButtonAction } from "../../ui/layout";

import {
  IValidateData,
  ValidateDataList,
} from "../../../interfaces/bulk-upload/IValidateData";
import { headerItems } from "../../../constants/bulk-upload/bulk-upload";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface IBulkUploadTableProps {
  errors?: ValidateDataList;
  onlyWarnings?: boolean;
  onConfirm?: () => void;
}

const BulkUploadTable: FC<IBulkUploadTableProps> = ({
  errors,
  onlyWarnings,
  onConfirm,
}) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const groupErrors = useMemo(() => {
    const criticalErrors: IValidateData[] = [];
    const warnings: IValidateData[] = [];

    errors?.forEach((error) => {
      if (error.is_critical) criticalErrors.push(error);
      else warnings.push(error);
    });

    return {
      criticalErrors,
      warnings,
    };
  }, [errors]);

  return (
    <Table className='bulk-upload-table'>
      {!isMobile && (
        <Head>
          <tr>
            {headerItems.map(({ label, width }) => (
              <td style={{ width }} key={label}>
                {label}
              </td>
            ))}
          </tr>
        </Head>
      )}

      <Body className='scroll-upload-container'>
        {!isMobile &&
          groupErrors.criticalErrors.map(({ row, message, field, id }) => (
            <tr key={id}>
              <td width='10%'>{row}</td>
              <td width='10%'>
                <MdError color='red' size={30} />
              </td>
              <td width='15%'>{field}</td>
              <td>{message}</td>
            </tr>
          ))}
        {!isMobile &&
          groupErrors.warnings.map(({ row, message, field, id }) => (
            <tr key={id}>
              <td width='10%'>{row}</td>
              <td width='10%'>
                <MdWarning color='orange' size={30} />
              </td>
              <td width='15%'>{field}</td>
              <td>{message}</td>
            </tr>
          ))}
        {isMobile &&
          groupErrors.criticalErrors.map(({ row, message, field, id }) => (
            <FlexContainer
              direction='column'
              align='start'
              key={id}
              style={{ borderTop: "1px solid #dddfd2", padding: "10px " }}
            >
              <FlexContainer>
                <Span>Row</Span>
                {row}
                <MdError color='red' size={20} />
              </FlexContainer>
              <span>
                <Span>Field</Span>
                {field}
              </span>
              <span>
                <Span>Issue</Span>
                {message}
              </span>
            </FlexContainer>
          ))}
        {isMobile &&
          groupErrors.warnings.map(({ row, message, field, id }) => (
            <FlexContainer
              direction='column'
              align='start'
              key={id}
              style={{ borderTop: "1px solid #dddfd2", padding: "10px " }}
            >
              <FlexContainer>
                <Span>Row</Span>
                {row}
                <MdWarning color='orange' size={20} />
              </FlexContainer>
              <span>
                <Span>Field</Span>
                {field}
              </span>
              <span>
                <Span>Issue</Span>
                {message}
              </span>
            </FlexContainer>
          ))}
      </Body>
      {onlyWarnings ? (
        <div style={{ marginTop: "1.77777rem", width: "100%" }}>
          <ButtonAction onClick={onConfirm}>Proceed Anyway</ButtonAction>
        </div>
      ) : null}
    </Table>
  );
};

export default BulkUploadTable;
