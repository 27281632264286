import {
  ICustomCardImage,
  ImageType,
} from "../../interfaces/custom-cards/ICustomCardImage";

interface ICustomImageState {
  listLogo: ICustomCardImage[];
  listCover: ICustomCardImage[];
  listIcon: ICustomCardImage[];
  listQrCode: IQrCode[];
  customizedCard: any;
  isLoading: boolean;
  frameTemplates: IFrameTemplate[];
}

export interface IDeleteCustomImageAction {
  id: number;
  type: ImageType;
}

export interface IUploadCustomImage {
  id: number;
  image_url: string;
}

export interface IFrameTemplate {
  id: number;
  url: string;
  aspect_ratio: string;
  position_x: string;
  position_y: string;
  scale: string;
  qr_code_size: string;
  type: "mixed" | "footer" | "header";
}

export interface IQrCode {
  id: number;
  name: string;
  url: string;
}

export const initialState: ICustomImageState = {
  listLogo: [],
  listCover: [],
  listIcon: [],
  listQrCode: [],
  customizedCard: null,
  isLoading: false,
  frameTemplates: [],
};
