// import { useEffect } from "react";
// import { isIOS } from "react-device-detect";

import loadAsyncScript from "../../helpers/load-script";
import { IAuthProvider } from "../../interfaces/auth/IAuthProvider";
import useHubSpotUser from "../hubSpot/useHubSpotUser";

const useFacebookSignIn = () => {
  const { setAuthProvider } = useHubSpotUser();

  const src = "https://connect.facebook.net/en_US/sdk.js";
  const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

  const _callAuthProvider = async (response: fb.StatusResponse) => {
    if (!response.authResponse) return;

    const { accessToken } = response.authResponse;

    const signInCandidate: IAuthProvider = {
      client: "web2",
      access_token: accessToken!,
      provider: "facebook",
    };

    setAuthProvider(signInCandidate);
  };

  const loadFacebookScript = () => {
    if (window.FB) return Promise.resolve();
    return loadAsyncScript(src);
  };

  const loadFacebookSdk = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FACEBOOK_APP_ID,
        version: "v15.0",
        autoLogAppEvents: false,
        xfbml: true,
      });
    };

    window.FB.getLoginStatus((res) => {
      const { status } = res;
      if (status === "connected") {
        return _callAuthProvider(res);
      }
    });
  };

  const load = async () => {
    await loadFacebookScript();
    loadFacebookSdk();
  };

  // useEffect(() => {
  //   if (isIOS) {
  //     load();
  //   }
  // }, []);

  const signIn = async () => {
    await load();
    window.FB.login(
      (res) => {
        return _callAuthProvider(res);
      },
      { scope: "email,public_profile", auth_type: "reauthenticate" }
    );
  };

  const signOut = () => window.FB?.logout();

  return {
    signIn,
    signOut,
  };
};

export default useFacebookSignIn;
