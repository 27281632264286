import { useCallback, useEffect, useState, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import {
  selectUpdateRecipients,
  newRecipientAddressLength,
} from "../../redux/recipients/selectors";
import {
  addRecipient,
  deleteRecipient,
  updateRecipient,
  getRecipients,
  IUpdateInfo,
  getRecipientById,
} from "../../redux/recipients/thunks";
import { setPortalPopup } from "../../redux/portal-popup/slice";
import { setEditId } from "../../redux/recipients/slice";

import { IRecipient } from "../../interfaces/address/IRecipient";
import typePopups from "../../constants/popup/type-popup";
import { IAutomationsEdit } from "../../constants/automation-compain/automation-types";
import { getTagList } from "../../redux/recipient-tags/thunks";

const { RECIPIENT_EDIT } = typePopups;

type Recipients = Array<IRecipient>;

const useRecipients = (recipients?: Recipients, inputValue?: string) => {
  const dispatch = useAppDispatch();

  const [recipientsData, setRecipientsData] = useState<Recipients>([]);
  const [confirmPopUpText, setConfirmPopUpText] = useState([]);
  const updateRecipients = useAppSelector(selectUpdateRecipients);
  const newRecipientsAmount = useAppSelector(newRecipientAddressLength);
  const [initialRecipientsLength, setInitialRecepientsLength] = useState<
    number | null
  >(null);

  const create = useCallback(async (recipient: IRecipient) => {
    await dispatch(addRecipient(recipient));
    await dispatch(getTagList(true));
  }, []);

  const correctDateSent = (dateBirthday?: Date) => {
    if (dateBirthday) {
      return `${`0${dateBirthday.getMonth() + 1}`.slice(
        -2
      )}/${`0${dateBirthday.getDate()}`.slice(
        -2
      )}/${dateBirthday.getFullYear()}`;
    }
    return "";
  };

  const update = useCallback(
    async (event, recipients: IRecipient) => {
      if (event) event.preventDefault();

      const { meta, payload } = await dispatch(updateRecipient(recipients));
      await dispatch(getTagList(true));

      if (
        meta.requestStatus === "fulfilled" &&
        !!(payload as IUpdateInfo).value &&
        (payload as IUpdateInfo).type === "info"
      ) {
        setConfirmPopUpText(
          // @ts-ignore
          (payload as IUpdateInfo).value as Array<IAutomationsEdit>
        );
      }
    },
    [recipients]
  );

  const remove = useCallback(
    async (ids: Array<number>) => {
      const { meta } = await dispatch(deleteRecipient(ids));
      if (meta.requestStatus === "fulfilled") {
        await dispatch(getRecipients(true));
      }
      return meta;
    },
    [dispatch, deleteRecipient]
  );

  const openRecipientEditPopup = useCallback(
    async (id: number, isAddressBook?: boolean) => {
      dispatch(setEditId({ id }));
      if (isAddressBook) await dispatch(getRecipientById(id));
      dispatch(
        setPortalPopup({
          form: RECIPIENT_EDIT,
          title: "Edit Recipient",
          typePopup: "form",
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isInternational = (checked: Array<number>) => {
    const intersection = (value: IRecipient) => checked.includes(value.id!);
    const international = (value: IRecipient) => value.country_id > 1;

    if (recipients) {
      return recipients.filter(intersection).some(international);
    }
  };

  const mapRecipient = (recipient: IRecipient) => {
    const { first_name, last_name, address1, address2, state, zip, city, id } =
      recipient;

    const label = `${first_name} ${last_name}`;
    const address = `${address1}, ${
      address2 ? `${address2},` : ""
    } ${city}, ${state} ${zip}`;
    return {
      label,
      value: id,
      address,
      last_name,
      id,
    };
  };

  const recipientToList = useMemo(() => {
    if (recipientsData) {
      return recipientsData.map(mapRecipient);
    }
    return recipientsData;
  }, [recipientsData]);

  useEffect(() => {
    if (inputValue && recipients) {
      const searchRecipients = recipients.filter(
        ({ name, business_name, email }) => {
          const searchFields = [name!, business_name, email];

          return searchFields.some((field) => {
            return field?.toLowerCase().includes(inputValue?.toLowerCase());
          });
        }
      );
      return setRecipientsData(searchRecipients);
    }
    if (recipients) {
      setInitialRecepientsLength(recipients!.length);
    }

    setRecipientsData(recipients!);
  }, [inputValue, recipients]);

  return {
    create,
    update,
    remove,
    openRecipientEditPopup,
    isInternational,
    recipientsData,
    correctDateSent,
    recipients,
    recipientToList,
    mapRecipient,
    updateRecipients,
    initialRecipientsLength,
    confirmPopUpText,
    newRecipientsAmount,
    setConfirmPopUpText,
  };
};

export default useRecipients;
