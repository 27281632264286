import { useCallback } from "react";

import { closePopup, setPortalPopup } from "../../redux/portal-popup/slice";
import { addAddress, updateAddress } from "../../redux/address/thunks";
import { setEditId } from "../../redux/address/slice";
import { useAppDispatch } from "../redux/useRedux";

import { IAddress } from "../../interfaces/address/IAddress";
import typePopups from "../../constants/popup/type-popup";

const { ADDRESS_EDIT } = typePopups;

const useAddress = () => {
  const dispatch = useAppDispatch();

  const togglePopup = useCallback((id: null | number, title: string) => {
    dispatch(setEditId(id));
    dispatch(setPortalPopup({ form: ADDRESS_EDIT, title, typePopup: "form" }));
  }, []);

  const create = useCallback(async (address: IAddress) => {
    dispatch(closePopup());
    const result = await dispatch(addAddress(address));

    return result.payload as IAddress;
  }, []);

  const update = useCallback(async (address: IAddress) => {
    dispatch(closePopup());
    await dispatch(updateAddress(address));
  }, []);

  return {
    create,
    togglePopup,
    update,
  };
};

export default useAddress;
