import { IReports } from "../interfaces/reports/IReports";
import { userInstance } from "../hooks/instance/useInstance";
import downloader from "../helpers/file-download";

const reportsApi = {
  dashboard: async () => {
    return userInstance.get<IReports>("/qrCode/dashboard");
  },
  exportActivity: async () => {
    const result = await userInstance.get("/qrCode/export", {
      responseType: "blob",
    });
    downloader(result.data, result.headers["file-name"]);
  },
};

export default reportsApi;
