import { useState } from "react";

const useValidateForm = () => {
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const removeError = (name: string) =>
    setErrorFields(errorFields.filter((f) => f !== name));

  const checkEmptyFields = (data: object) => {
    const errors: string[] = [];
    Object.entries(data).forEach((field) => {
      if (!field[1].trim()) {
        errors.push(field[0]);
      }
    });
    setErrorFields(errors);
    return !errors.length;
  };

  return {
    errorFields,
    removeError,
    checkEmptyFields,
  };
};

export default useValidateForm;
