import { userInstance } from "../hooks/instance/useInstance";
import { setAiMessage } from "../redux/ai/slice";
import { setSpinner } from "../redux/spinner/slice";

const aiApi = {
  generateMessage: async (wishesForAi: string, dispatch: any) => {
    try {
      await userInstance.post<any>(
        "/ai/generateMessage",
        {
          input: wishesForAi,
        },
        {
          onDownloadProgress: (progressEvent) => {
            dispatch(setSpinner(false));

            const dataChunk = progressEvent.event.target.response;

            if (progressEvent.event.target.status === 200) {
              dispatch(setAiMessage(dataChunk));
            }
          },
        }
      );
    } catch (err) {
      throw err;
    }
  },
};

export default aiApi;
