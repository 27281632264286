import { RootState } from "../store";

export const getActiveStep = (state: RootState) => state.outboundLeads.step;

export const getActiveOutboundLeads = (state: RootState) =>
  state.outboundLeads.activeOutboundLeads;

export const getLocation = (state: RootState) => state.outboundLeads.location;

export const getMask = (state: RootState) => state.outboundLeads.mask;

export const getOneBusiness = (state: RootState) =>
  state.outboundLeads.oneBusiness;

export const getLocationRadius = (state: RootState) =>
  state.outboundLeads.radiusAddress;

export const getTypeOptions = (state: RootState) =>
  state.outboundLeads.typeOptions;

export const getSearchType = (state: RootState) =>
  state.outboundLeads.searchType;

export const getPolygon = (state: RootState) => state.outboundLeads.poligon;

export const getIndustries = (state: RootState) =>
  state.outboundLeads.industries;

export const getCount = (state: RootState) => state.outboundLeads.count;

export const getOutboundProcces = (state: RootState) =>
  state.outboundLeads.procces;

export const getOutboundPrices = (state: RootState) =>
  state.outboundLeads.prices;

export const getOutboundCountry = (state: RootState) =>
  state.outboundLeads.searchCountry;

export const getOutboundCity = (state: RootState) =>
  state.outboundLeads.searchCity;

export const getOutboundCardId = (state: RootState) =>
  state.outboundLeads.card_id;
