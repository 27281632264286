import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  CSSProperties,
} from "react";

import { ButtonAction } from "../../layout";
import { useAppDispatch } from "../../../../hooks/redux/useRedux";
import CancelSelectValueButton from "../input-cancel-btn/input-cancel.common";

import "./style.scss";

interface IUploadFile {
  onUploadFile: (file: FormData) => void;
  placeholder?: string;
  cardId?: number;
  clear?: () => void;
  hideButton?: boolean;
  cancelButtonStyle?: CSSProperties;
}

const UploadFile: FC<IUploadFile> = ({
  onUploadFile,
  cardId,
  clear,
  placeholder = "",
  hideButton,
  cancelButtonStyle,
}) => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | null>(null);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  const uploadFile = useCallback(() => {
    if (!file) return;

    const formData = new FormData();

    formData.append("file", file);

    if (cardId) {
      formData.append("cardId", cardId.toString());
    }
    onUploadFile(formData);
  }, [onUploadFile, file, dispatch]);

  useEffect(() => {
    uploadFile();
  }, [file]);

  const cancelUpload = async (e: React.MouseEvent) => {
    e.preventDefault();
    await setFile(null);
    if (clear) await clear();
  };

  return (
    <label className='input-file' htmlFor='upload'>
      <span aria-hidden='true'>{file?.name || placeholder}</span>
      <input
        ref={inputRef}
        id='upload'
        type='file'
        name='file'
        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        onChange={handleFile}
        onClick={(e) => {
          // @ts-ignore
          e.target.value = null;
        }}
      />
      <CancelSelectValueButton
        active={!!file}
        onClick={cancelUpload}
        style={{ right: "9rem", ...cancelButtonStyle }}
      />
      {!hideButton ? (
        <ButtonAction
          type='button'
          style={{ maxWidth: "8.18611rem" }}
          onClick={() => (inputRef.current as HTMLInputElement).click()}
          disabled={!!file}
        >
          UPLOAD A FILE
        </ButtonAction>
      ) : null}
    </label>
  );
};

export default UploadFile;
