import React, { FC, Suspense, useEffect, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../hooks/redux/useRedux";
import { login, uidStorage } from "../redux/auth/thunks";
import { isAuth, isTokenChecking } from "../redux/auth/selectors";

import Loader from "../components/loader/Loader";
import Header from "../components/navigation/header/header.nav";
import Footer from "../components/navigation/footer/footer.nav";
import CustomLoader from "../components/ui/common/loaders/custom.loader";
import LoaderContainer from "../components/ui/common/loaders/loader.styled";
import PortalPopup from "../components/ui/common/portal-popup/portal-popup.common";
import NotificationsContainer from "../components/notifications/notifications.container";

import DataDeletion from "./data-deletion/DataDeletion";
import HubSpot from "./hubspot/HubSpot";

import useNavigation from "../hooks/navigation/useNavigation";

import ScrollToTop from "../helpers/ScrollToTop";
import BeaconLoader from "../components/beacon/beaconLoader";
import ChooseCampaignMobile from "../components/popups/outbounds-prospecting/choose-campaign-mobile";
import ConfirmationPopup from "../components/popups/confirmation/confirmation.popup";

import { Main } from "../components/ui/layout";

import NotFound from "./not-found/not-found";
import navigation from "../constants/navigation";
import lazyWithRetry from "../HOC/lazyWithRetry";

interface IPrivateRoute {
  token: boolean;
  component: any;
  path: string;
}

const {
  auth,
  home,
  error,
  category,
  privacyPolicy,
  dataDeletion,
  hubspot,
  cards,
  oauth,
  chooseCampaign,
  completeOrder,
  bulkSend,
  myBasket,
  paymentOptions,
  subscriptions,
  prepaidPlans,
  thanksForSubscribing,
  thanksForPrepaid,
  addressBook,
  messageTemplates,
  customizable,
  pastOrders,
  onItsWay,
  contactUs,
  outboundProspecting,
  automationCards,
  addNewAutomationCards,
  editAutomations,
  automationId,
  cardId,
  integrations,
  multiStepCampaign,
  addNewMultiStep,
  editMultiStep,
  requestMailingAddress,
  thanksForProvidedAddress,
  reporting,
} = navigation;

// congrats page
const OnItsWay = lazyWithRetry(() => import("./on-its-way/OnItsWay"));

// automations
const MultiStep = lazyWithRetry(() => import("./campaigns/multi-step/index"));
const Automations = lazyWithRetry(
  () => import("./campaigns/automations/index")
);

// clients settings
const Integrations = lazyWithRetry(() => import("./Integrations/Integrations"));

const MessageTemplates = lazyWithRetry(
  () => import("./message-templates/MessageTemplates")
);
const PaymentOptions = lazyWithRetry(
  () => import("./payment-options/PaymentOptions")
);
const AddressBook = lazyWithRetry(() => import("./address-book/AddressBook"));
const Customizable = lazyWithRetry(() => import("./customizable/Customizable"));
const ClientCredits = lazyWithRetry(
  () => import("./client-credits/client-credits")
);

const Reporting = lazyWithRetry(() => import("./reporting/Reporting"));
const MailingAddress = lazyWithRetry(
  () => import("./request-mailing-address/request-mailing-address")
);

// clients orders
const CompleteOrder = lazyWithRetry(
  () => import("./add-message/complete-order/CompleteOrder")
);
const PastOrders = lazyWithRetry(() => import("./past-orders/PastOrders"));
const BulkOrder = lazyWithRetry(() => import("./bulk-order/BulkOrder"));
const MyBasket = lazyWithRetry(() => import("./my-basket/my-basket"));

const Contact = lazyWithRetry(() => import("./contact-us/Contact"));

const ErrorPage = lazyWithRetry(() => import("./error/Error"));
const Home = lazyWithRetry(() => import("./home/Home"));
const Auth = lazyWithRetry(() => import("./auth/auth"));
const CategoryRoute = lazyWithRetry(() => import("./categoryRoute"));
const PrivacyPolicy = lazyWithRetry(
  () => import("./privacy-policy/PrivacyPolicy")
);

const PrivateRoute: FC<IPrivateRoute> = ({
  token,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          // eslint-disable-next-line react/prop-types
          to={{ pathname: auth.signin, state: { from: props.location } }}
        />
      )
    }
  />
);

const AppLayout: FC = () => {
  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <CustomLoader />
        </LoaderContainer>
      }
    >
      <Main>
        <div className='main-template'>
          <Switch>
            <Route path={completeOrder} component={CompleteOrder} />
            <Route path={`${bulkSend}${cardId}`} component={BulkOrder} />
            <Route path={myBasket} component={MyBasket} />
            <Route path={paymentOptions} component={PaymentOptions} />
            <Route
              path={[
                subscriptions,
                prepaidPlans,
                thanksForSubscribing,
                thanksForPrepaid,
              ]}
              component={ClientCredits}
            />
            <Route path={integrations} component={Integrations} />
            <Route path={reporting} component={Reporting} />
            <Route
              path={`${addressBook}/:campaign_id?/:edit?`}
              component={AddressBook}
            />

            <Route path={messageTemplates} component={MessageTemplates} />
            <Route path={`${customizable}${cardId}`} component={Customizable} />
            <Route path={pastOrders} component={PastOrders} />
            <Route path={onItsWay} component={OnItsWay} />
            <Route path={contactUs} component={Contact} />
            <Route
              path={[
                outboundProspecting,
                automationCards,
                `${editAutomations}${automationId}`,
                addNewAutomationCards,
              ]}
              component={Automations}
            />

            <Route
              path={[
                multiStepCampaign,
                addNewMultiStep,
                `${editMultiStep}/:campaign_id`,
              ]}
              component={MultiStep}
            />
            <Route path='*' exact>
              <NotFound />
            </Route>
          </Switch>
        </div>
      </Main>
    </Suspense>
  );
};

const Routes: FC = () => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    // @ts-ignore
    ErrorPage.preload();
    // @ts-ignore
    Home.preload();
    // @ts-ignore
    Auth.preload();
    // @ts-ignore
    CategoryRoute.preload();
    // @ts-ignore
    PrivacyPolicy.preload();
    // @ts-ignore
    Contact.preload();
    // @ts-ignore
    OnItsWay.preload();
    // @ts-ignore
    Automations.preload();
    // @ts-ignore
    MultiStep.preload();
    // @ts-ignore
    Integrations.preload();
    // @ts-ignore
    MessageTemplates.preload();
    // @ts-ignore
    PaymentOptions.preload();
    // @ts-ignore
    MyBasket.preload();
    // @ts-ignore
    BulkOrder.preload();
    // @ts-ignore
    PastOrders.preload();
    // @ts-ignore
    CompleteOrder.preload();
    // @ts-ignore
    ClientCredits.preload();
    // @ts-ignore
    Customizable.preload();
    // @ts-ignore
    AddressBook.preload();
    // @ts-ignore
    MailingAddress.preload();
  }, []);

  useNavigation();

  const uid = localStorage.getItem(uidStorage);

  const isAuthenticated = useAppSelector(isAuth);
  const isChecking = useAppSelector(isTokenChecking);

  const hideNotifications = useMemo(
    () =>
      pathname.includes(requestMailingAddress) ||
      pathname.includes(thanksForProvidedAddress),
    [pathname]
  );

  if (isChecking) {
    dispatch(login());
    return (
      <LoaderContainer bgcolor='unset'>
        <CustomLoader />
      </LoaderContainer>
    );
  }

  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <CustomLoader />
        </LoaderContainer>
      }
    >
      <Header />

      {!hideNotifications ? <NotificationsContainer /> : null}

      <Switch>
        <Route exact path={home} component={Home} />
        <Route path={error} component={ErrorPage} />
        <Route path={Object.values(auth)}>
          {isAuthenticated ? <Redirect to={home} /> : <Auth />}
        </Route>
        <Route path={category} component={CategoryRoute} />
        <Route exact path={privacyPolicy} component={PrivacyPolicy} />
        <Route exact path={dataDeletion} component={DataDeletion} />
        <Route exact path={hubspot} component={HubSpot} />
        <Route path={cards} component={Home} />
        <Route
          path={[requestMailingAddress, thanksForProvidedAddress]}
          component={MailingAddress}
        />
        <Route exact path={chooseCampaign} component={ChooseCampaignMobile} />
        <Route exact path={oauth} />
        <PrivateRoute
          token={!!uid}
          path={`${match.url}`}
          component={AppLayout}
        />
      </Switch>
      <ScrollToTop />
      <PortalPopup />
      <ConfirmationPopup />
      <Loader />
      <Footer />
      <BeaconLoader />
    </Suspense>
  );
};

export default Routes;
