export interface IHubStopUser {
  first_name: string;
  last_name: string;
  country_id: string;
  country: string;
  tag: string[];
  delivery_cost: string;
  ext_id: string;
  token: string;
  hs_token: string;
  city: string;
  address_id: string;
  address1: string;
  address2: string;
  zip: string;
  state: string;
  business_name: string;
  email?: string;
  phone?: string;
}

export interface IHubStopUserRedirect {
  first_name?: string;
  last_name?: string;
  country_id?: string;
  country?: string;
  tag: string[];
  delivery_cost?: string;
  ext_id?: string;
  token?: string;
  hs_token?: string;
  city?: string;
  address_id?: string;
  address1?: string;
  address2?: string;
  zip?: string;
  state?: string;
  business_name?: string;
  redirect_uri?: string;
  email?: string;
  phone?: string;
}

interface IHubStop {
  user: null | IHubStopUser;
  use: boolean;
  hsToken: string | null;
}

export const initialState: IHubStop = {
  user: null,
  use: false,
  hsToken: null,
};
