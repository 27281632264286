import { useCallback, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  IValidateData,
  ValidateDataList,
} from "../../interfaces/bulk-upload/IValidateData";
import { IBulkOrder } from "../../interfaces/orders/IBulkOrder";

import { parseRecipientXlsFile } from "../../redux/recipients/thunks";
import { setPortalPopup } from "../../redux/portal-popup/slice";
import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import {
  parseOrdersXlsFile,
  placeBasket,
  IResponse,
} from "../../redux/orders/thunks";
import { selectAddresses, selectLoader } from "../../redux/orders/selectors";

import messagePopup from "../../constants/popup/constants";
import { setErrorQuantityInsert } from "../../redux/basket/slice";
import navigation from "../../constants/navigation";
import { getTagList } from "../../redux/recipient-tags/thunks";

interface IAddressBasket {
  card_id: number;
  font: string;
  date_send: string;
  asap_send: boolean;
  delivery_confirmation: number | boolean;
  check_quantity: boolean;
  addresses: IBulkOrder[];
  insert_id: number;
  denomination_id?: number;
  save_recipients: boolean;
  shipping_rate_id: number;
  shipping_method_id: number;
  shipping_address_id: number;
  must_deliver_by: string;
  auto_font_size: boolean;
  no_check_warning?: boolean;
  check_quantity_inserts?: boolean;
  tags?: number[];
}

const useBulkUpload = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [errors, setErrors] = useState<ValidateDataList>([]);
  const [onlyWarnings, setOnlyWarnings] = useState<boolean>(false);
  const [onlyWarningsAddress, setOnlyWarningsAddress] =
    useState<boolean>(false);
  const [recipientXlsFile, setRecipientXlsFile] = useState<FormData | null>(
    null
  );
  const [success, setSuccess] = useState(false);
  const [confirmPopUp, setConfirmPopUp] = useState(false);

  const addresses = useAppSelector(selectAddresses);
  const isLoader = useAppSelector(selectLoader);

  const cleanErrors = () => setErrors([]);
  const rejectUpload = (errors: ValidateDataList) => setErrors(errors);

  const validAddressesGift = useMemo(() => {
    const booleanAddress = addresses.map((el) => el.to_country_id !== 1);
    return booleanAddress.includes(true);
  }, [addresses]);

  const successUpload = () => {
    setSuccess(true);
    return dispatch(
      setPortalPopup({
        text: messagePopup.importSuccess,
        typePopup: "alert",
        title: "Success",
      })
    );
  };

  const addToBasketBulkSent = useCallback(
    async (
      card_id,
      tags,
      font,
      date_send,
      asap_send,
      delivery_confirmation,
      insert_id?,
      denomination_id?,
      save_recipients?,
      shippingMethod?,
      shippingRate?,
      shippingAddressID?,
      dateSendAddress?,
      isMaximizeFontSize = false,
      ignoreWarnings?,
      checkQuantityInserts?
    ) => {
      const addressBasket: IAddressBasket = {
        card_id,
        tags,
        font,
        date_send,
        asap_send,
        delivery_confirmation,
        check_quantity: true,
        addresses,
        insert_id: +insert_id,
        denomination_id: denomination_id && +denomination_id,
        save_recipients,
        shipping_rate_id: shippingRate,
        shipping_method_id: shippingMethod,
        shipping_address_id: shippingAddressID,
        must_deliver_by: dateSendAddress,
        auto_font_size: isMaximizeFontSize,
        no_check_warning: !!ignoreWarnings,
        check_quantity_inserts: !!checkQuantityInserts,
      };

      const { payload } = await dispatch(placeBasket(addressBasket));
      let fileCorrect: boolean = true;
      let messages: IValidateData[] = [];

      if (payload) {
        if (typeof payload?.fileCorrect === "boolean") {
          fileCorrect = payload.fileCorrect;
        }
        messages = payload?.messages || messages;
      }

      if (!fileCorrect) {
        const hasCritical = !!payload?.errors_count?.critical;
        const hasWarnings = !!payload?.errors_count?.warnings;

        setOnlyWarnings(!hasCritical && hasWarnings);
        const messagesWithId = messages.map((message, index) => ({
          ...message,
          id: index,
        }));
        return rejectUpload(messagesWithId as ValidateDataList);
      }

      if (payload?.status !== "error" && fileCorrect) {
        history.push(navigation.myBasket);
        dispatch(setErrorQuantityInsert(false));
      }
    },
    [addresses]
  );

  const uploadRecipientsXlsFile = useCallback(async (data: FormData) => {
    const { meta, payload } = await dispatch(parseRecipientXlsFile(data));
    await dispatch(getTagList(true));
    if (meta.requestStatus === "fulfilled" && !payload.correct_file) {
      if (!payload.correct_file) {
        const hasCritical = !!payload?.errors_count?.critical;
        const hasWarnings = !!payload?.errors_count?.warnings;

        setRecipientXlsFile(data);
        setOnlyWarningsAddress(!hasCritical && hasWarnings);
        return rejectUpload(payload?.validate_data as ValidateDataList);
      }
      if (payload && payload.correct_file) {
        dispatch(setErrorQuantityInsert(false));
      }
    }
  }, []);

  const confirmRecipientForm = useCallback(async () => {
    if (recipientXlsFile) {
      recipientXlsFile.append("no_check_warning", "true");
      await dispatch(parseRecipientXlsFile(recipientXlsFile));
    }
  }, [recipientXlsFile]);

  const uploadOrdersXlsFile = useCallback(async (data: FormData) => {
    const { meta, payload } = await dispatch(parseOrdersXlsFile(data));

    if (meta.requestStatus === "fulfilled") {
      if (!(payload as IResponse)?.correct_postal_code as boolean) {
        return setConfirmPopUp(true);
      }
      return successUpload();
    }
  }, []);

  return {
    cleanErrors,
    uploadRecipientsXlsFile,
    uploadOrdersXlsFile,
    errors,
    setConfirmPopUp,
    success,
    addresses,
    successUpload,
    addToBasketBulkSent,
    isLoader,
    confirmPopUp,
    validAddressesGift,
    onlyWarnings,
    onlyWarningsAddress,
    confirmRecipientForm,
  };
};

export default useBulkUpload;
