import React, { FC } from "react";

import { BorderedBox } from "../ui/layout";
import { Typography } from "../ui/typography";

interface IBox {
  text?: string | JSX.Element;
  style?: React.CSSProperties;
  childStyle?: React.CSSProperties;
  onAction?: () => void;
}

const BorderBox: FC<IBox> = ({
  text,
  style,
  children,
  childStyle,
  onAction = () => {},
}) => {
  return (
    <BorderedBox
      align='center'
      justify='center'
      style={style}
      onClick={onAction}
    >
      {children || (
        <Typography fontSize='.66666rem' style={childStyle}>
          {text}
        </Typography>
      )}
    </BorderedBox>
  );
};

export default BorderBox;
