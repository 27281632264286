import { FormEvent, useCallback } from "react";

import { changeEmail } from "../../redux/profile/thunks";
import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import checkAuth from "../../redux/checkauth/selectors";
import { convertAccount } from "../../redux/auth/thunks";

import useUser from "../auth/useUser";
import useForm from "../forms/useForm";

interface FormElementsEmailChange extends HTMLCollection {
  email: HTMLInputElement;
  email_confirm: HTMLInputElement;
  password: HTMLInputElement;
}

const useEmail = () => {
  const dispatch = useAppDispatch();

  const { last: lastTypeLogin } = useAppSelector(checkAuth);

  const { email: login } = useUser();
  const { isInputsEqual } = useForm();

  const changeEmailSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>, newPassword?: string) => {
      const { email, email_confirm, password } = event.currentTarget
        .elements as FormElementsEmailChange;
      const isEmailsEqual = isInputsEqual([email.value, email_confirm.value]);
      if (!isEmailsEqual) return;

      if (lastTypeLogin !== "email" && newPassword) {
        await dispatch(
          convertAccount({
            email: email.value,
            new_email: email_confirm.value,
            password: newPassword,
          })
        );
      }
      if (lastTypeLogin === "email" && login) {
        await dispatch(
          changeEmail({
            login,
            new_email: email.value,
            password: password.value,
          })
        );
      }
    },
    [login]
  );

  return {
    changeEmailSubmit,
  };
};

export default useEmail;
