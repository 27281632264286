import { createAsyncThunk } from "@reduxjs/toolkit";

import insertsApi from "../../api/inserts.api";

const fetchInserts = createAsyncThunk("inserts/list", async () => {
  const res = await insertsApi.list();

  const { inserts } = res.data;

  return inserts;
});

export default fetchInserts;
