import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import { fetchCountries, getDisabledStates } from "./thunks";

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      state.countries = payload;
    });
    builder.addCase(getDisabledStates.fulfilled, (state, { payload }) => {
      state.disabledStates = payload;
    });
  },
});

export default countriesSlice.reducer;
