import { FC } from "react";

import { FlexContainer, TextLink } from "../../../ui/layout";
import { setPortalPopup } from "../../../../redux/portal-popup/slice";
import typePopups from "../../../../constants/popup/type-popup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/redux/useRedux";
import checkAuth from "../../../../redux/checkauth/selectors";

const { PASSWORD_EDIT, EMAIL_CHANGE } = typePopups;

interface IAccountChangeCredentials {
  hideSetting: () => void;
}

const AccountChangeCredentials: FC<IAccountChangeCredentials> = ({
  hideSetting,
}) => {
  const dispatch = useAppDispatch();

  const { last: lastTypeLogin } = useAppSelector(checkAuth);

  const openPasswordEdit = () => {
    dispatch(
      setPortalPopup({
        title: "Password Edit",
        typePopup: "form",
        form: PASSWORD_EDIT,
      })
    );
  };

  const openEmailChange = () => {
    dispatch(
      setPortalPopup({
        title: "Email Change",
        typePopup: "form",
        form: EMAIL_CHANGE,
      })
    );
  };

  const emailLoginButtons = [
    {
      onClick: openEmailChange,
      value: "Change Email",
    },
    {
      onClick: openPasswordEdit,
      value: "Change Password",
    },
  ];

  if (lastTypeLogin !== "email") return null;

  return (
    <>
      {emailLoginButtons.map(({ value, onClick }) => (
        <FlexContainer
          key={value}
          className='nav__links'
          justify='space-between'
        >
          <div onClick={onClick}>
            <TextLink color='#fff' onClick={hideSetting}>
              {value}
            </TextLink>
          </div>
        </FlexContainer>
      ))}
    </>
  );
};

export default AccountChangeCredentials;
