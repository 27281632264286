import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import {
  getHubSpotUser,
  getHubSpotUse,
  getHSToken,
} from "../../redux/hubspot/selector";
import { clearHubSpotUser, setHubSpotInfo } from "../../redux/hubspot/slice";
import fetchHubSpot from "../../redux/hubspot/thunks";
import { authProvider, uidStorage } from "../../redux/auth/thunks";
import { IAuthProvider } from "../../interfaces/auth/IAuthProvider";
import navigation from "../../constants/navigation";

import usePopup from "../popup/usePopup";
import useUrlSearchParams from "../utils/useUrlSearchParams";
import { IHubStopUser, IHubStopUserRedirect } from "../../redux/hubspot/types";

const useHubSpotUser = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const hubSpotUser = useAppSelector(getHubSpotUser);
  const hubSpotUse = useAppSelector(getHubSpotUse);
  const hSToken = useAppSelector(getHSToken);
  const { openSuccessPopup } = usePopup();

  const token = useUrlSearchParams("token");
  const objectId = useUrlSearchParams("objectId");

  const uid = localStorage.getItem(uidStorage);

  const redirectToken = useMemo(() => {
    if (!token && hSToken && objectId) {
      return { token: hSToken, uid, objectId };
    }
    return { token, uid };
  }, [token, uid, hSToken, objectId]);

  const setHubSpotUser = useCallback(async () => {
    const { meta, payload } = await dispatch(fetchHubSpot(redirectToken));

    if (
      meta.requestStatus === "fulfilled" &&
      !!(payload as IHubStopUserRedirect)?.ext_id
    ) {
      await openSuccessPopup("You will be redirected shortly.");

      const {
        ext_id,
        state,
        delivery_cost,
        country,
        country_id,
        city,
        business_name,
        email,
        last_name,
        first_name,
        address_id,
        address1,
        address2,
        zip,
        tag,
        phone,
      } = payload as IHubStopUserRedirect;

      const autoFill = {
        first_name: first_name || "",
        last_name: last_name || "",
        country: country || "",
        country_id: country_id || "",
        ext_id: ext_id || "",
        delivery_cost: delivery_cost || "",
        hs_token: "",
        city: city || "",
        token: token || "",
        address_id: address_id || "",
        address1: address1 || "",
        address2: address2 || "",
        zip: zip || "",
        state: state || "",
        business_name: business_name || "",
        tag: tag || "",
        email: email || "",
        phone: phone || "",
      } as IHubStopUser;

      await dispatch(setHubSpotInfo(autoFill));
      history.push("/");
    }
    if (
      meta.requestStatus === "fulfilled" &&
      !!(payload as IHubStopUserRedirect)?.redirect_uri
    ) {
      await openSuccessPopup("You will be redirected shortly.");

      setTimeout(() => {
        const win = window.open(
          (payload as IHubStopUserRedirect)?.redirect_uri,
          "_self"
        );
        if (win !== null) {
          win.focus();
        }
      }, 1500);
    }
  }, [token, redirectToken]);

  const removeToken = useCallback(() => {
    const url = new URLSearchParams(location.search);
    return url.delete("token");
  }, [location]);

  const backToHubSpot = useCallback(async () => {
    await window.parent.postMessage(JSON.stringify({ action: "DONE" }), "*");
  }, []);

  const clearUser = useCallback(() => {
    return dispatch(clearHubSpotUser());
  }, [dispatch, clearHubSpotUser]);

  const redirectTokenHubSpot = useMemo(() => {
    return token === "hubspot_integration";
  }, [token]);

  //   const hsTokenUse =useMemo(()=>{
  // return !!hubSpotUse ||
  //   },[])

  const setAuthProvider = async (authCandidate: IAuthProvider) => {
    const result = await dispatch(authProvider(authCandidate));

    if (
      !redirectTokenHubSpot &&
      token &&
      authProvider.fulfilled.match(result)
    ) {
      removeToken();
      return history.push(navigation.home);
    }

    if (redirectTokenHubSpot && token && authProvider.fulfilled.match(result)) {
      return history.push({
        pathname: navigation.hubspot,
        search: `?token=${token}`,
      });
    }

    if (authProvider.rejected.match(result)) {
      if (token) removeToken();
    }
    if (
      location.pathname === navigation.home &&
      !redirectTokenHubSpot &&
      !token &&
      authProvider.fulfilled.match(result)
    ) {
      return history.go(0);
    }
    if (
      !redirectTokenHubSpot &&
      !token &&
      authProvider.fulfilled.match(result)
    ) {
      return history.push(navigation.home);
    }
  };

  return {
    setHubSpotUser,
    hubSpotUser,
    hubSpotUse,
    clearUser,
    hSToken,
    token,
    backToHubSpot,
    redirectToken,
    redirectTokenHubSpot,
    removeToken,
    setAuthProvider,
  };
};

export default useHubSpotUser;
