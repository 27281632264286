import { userInstance } from "../hooks/instance/useInstance";
import { ISignatureResponse } from "../interfaces/signatures/ISignature";

const signaturesApi = {
  list: async () => {
    return userInstance.get<ISignatureResponse>("/profile/signatures");
  },
};

export default signaturesApi;
