import { FC, useMemo } from "react";

import { FlexContainer } from "../../../ui/layout";
import useUser from "../../../../hooks/auth/useUser";

const AccountInfo: FC = () => {
  const { user, email, lname, fname } = useUser();

  const userInfo = useMemo(() => {
    if (fname && lname) {
      return {
        initials: (fname.charAt(0) + lname.charAt(0)).toUpperCase(),
        fullName: `${fname.toUpperCase()} ${lname.toUpperCase()} `,
      };
    }
  }, [lname, fname]);

  return (
    <FlexContainer align='stretch' justify='flex-start' width='auto'>
      {user && userInfo && (
        <FlexContainer
          align='center'
          justify='center'
          className='account-settings__initials'
        >
          {userInfo.initials}
        </FlexContainer>
      )}
      <FlexContainer
        justify='center'
        align='flex-start'
        direction='column'
        className='account-settings__info'
      >
        {user && userInfo && <div>{userInfo.fullName}</div>}
        <div style={{ fontSize: "0.61111rem" }}>{email}</div>
      </FlexContainer>
    </FlexContainer>
  );
};

export default AccountInfo;
