import { FC } from "react";

import { Input } from "../../ui/common/inputs/input.common";
import Select from "../../ui/common/select/select.common";
import { ButtonAction, FlexContainer, Form } from "../../ui/layout";

import useCountries from "../../../hooks/countries/useCountries";
import usePhisycalAddress from "../../../hooks/address/usePhisycalAddress";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const AddressPhysical: FC = ({ children }) => {
  const { countriesToList } = useCountries();
  const { newAddress, newZip, newCountry, updateBilling, address } =
    usePhisycalAddress();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  return (
    <div style={{ paddingTop: isMobile ? "1rem" : 0 }}>
      <p style={{ fontSize: "0.88888rem", lineHeight: "1.66666rem" }}>
        We are required to collect your physical location for sales tax purposes
      </p>
      <p>{children}</p>

      <Form>
        <FlexContainer
          align='center'
          justify='space-between'
          direction='column'
        >
          <FlexContainer
            align='center'
            justify='space-between'
            direction={isMobile ? "column" : "row"}
          >
            <Input
              placeholder='Address'
              type='text'
              style={{
                border: `${
                  !newAddress.value && address ? "1px solid red" : "none"
                }`,
              }}
              {...newAddress}
            />
            <Input
              placeholder='Zip Code '
              type='text'
              {...newZip}
              style={{
                marginLeft: isMobile ? "0" : "1rem",
                marginTop: isMobile ? "0.61111rem" : "0",
                border: `${
                  !newZip.value && address ? "1px solid red" : "none"
                }`,
              }}
            />
          </FlexContainer>
          <Select
            options={countriesToList}
            placeholder='Country'
            {...newCountry}
            style={{
              border: `${
                !newCountry.value && address ? "1px solid red" : "none"
              }`,
            }}
          />
          <ButtonAction onClick={(event) => updateBilling(event)}>
            save address
          </ButtonAction>
        </FlexContainer>
      </Form>
    </div>
  );
};

export default AddressPhysical;
