// @ts-nocheck
import { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { TitleBar } from "../ui/common/title-bar";
import { FlexContainer } from "../ui/layout";

import {
  addEventListeners,
  getCursorPoint,
  getRelativePoint,
  removeEventListeners,
} from "../../utils";
import CardItem from "../cards/card/card.item";
import CarouselPagination from "./carousel.pagination";
import { ICard } from "../../interfaces/cards/ICard";
import { getCardsListByCategory } from "../../redux/cards/thunks";
import { getSearchCards } from "../../redux/cards/selectors";

import { useAppSelector, useAppDispatch } from "../../hooks/redux/useRedux";
import {
  getIsMobileWindowDimensions,
  getWidthWindowDimensions,
} from "../../redux/window-dimensions/selectors";

import "./style.scss";

const getMinWidth = (
  fontSize: number,
  windowWidth: number,
  isMobile: boolean
): number => {
  let fontSizeValue: number = 15;
  if (isMobile) fontSizeValue = 8;

  return fontSize * (windowWidth < 1024 ? fontSizeValue : 16);
};

interface ISectionCards {
  title: string;
  cards: ICard[];
  categoryId: number;
  totalItems: number;
  isAllCategories?: boolean;
  onSelectCard?: (id: number, card: ICard) => void;
  isSelectCardPopup?: boolean;
}

const SliderCards: FC<ISectionCards> = ({
  title,
  cards,
  categoryId,
  totalItems,
  isAllCategories,
  onSelectCard,
  isSelectCardPopup,
}) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const width = useAppSelector(getWidthWindowDimensions);

  const containerRootRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);
  const [cardWidth, setCardWidth] = useState<number | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [cardLength, setFetchCard] = useState<number>();
  const [totalCardLength, setTotalLength] = useState<number>(1);
  const [preDragging, setPredragging] = useState<boolean>(false);
  const [gap, setGap] = useState<number>(1);
  const [xAxis, setXAxis] = useState<number>(0);
  const search = useAppSelector(getSearchCards);

  useEffect(() => {
    setTotalLength(totalItems);
  }, [totalItems]);

  useEffect(() => {
    let startCursorPoint;
    let startPoint;
    let deltaX: number;
    let newX: number = 0;
    let newPage = 0;

    const containerClickHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.removeEventListener("click", containerClickHandler);
    };

    const mouseMoveHandler = (event) => {
      const cursorPoint = getCursorPoint(event, containerRootRef);

      deltaX = cursorPoint.x - startCursorPoint.x;
      newX = startPoint.x + deltaX;

      if (Math.abs(deltaX) > 3 && !dragging) {
        containerRootRef.current.addEventListener(
          ["click", "touchmove"],
          containerClickHandler
        );
        setDragging(true);
      }
      setXAxis(newX);
    };

    const mouseUpHandler = () => {
      setDragging(false);
      setPredragging(false);

      const containerRootWidth =
        containerRootRef.current.getBoundingClientRect().width;
      const result: number = Math.abs(newX / (containerRootWidth + gap));
      const pct: number = result - Math.floor(result);

      if (deltaX > 0 && pct < 0.85) newPage = Math.floor(result);
      else if (deltaX < 0 && pct > 0.15) newPage = Math.ceil(result);

      newPage = Math.min(Math.max(0, newPage), pages - 1);

      if (newPage !== 0) {
        newPage += 1;
        setPage(newPage);
      } else if (deltaX > 0 && newPage !== page && page !== 1) {
        newPage = page - 1;
        setPage(newPage);
      }

      removeEventListeners(
        containerRootRef.current,
        "mousemove",
        mouseMoveHandler
      );
      removeEventListeners(window, ["mouseup", "touchmove"], mouseUpHandler);
    };

    const mouseDownHandler = (event) => {
      startCursorPoint = getCursorPoint(event, containerRootRef);

      if (
        (event.button === 0 || event.button === undefined) &&
        startCursorPoint.x > 0 &&
        startCursorPoint.x < containerRootRef.current?.offsetWidth
      ) {
        startPoint = getRelativePoint(containerRef);

        setXAxis(startPoint.x);
        setPredragging(true);

        containerRootRef.current.removeEventListener(
          "click",
          containerClickHandler
        );
        addEventListeners(
          containerRootRef.current,
          ["mouseup", "touchmove"],
          mouseMoveHandler
        );
        addEventListeners(window, ["mouseup", "touchmove"], mouseUpHandler);
      }
    };

    addEventListeners(
      containerRootRef.current,
      ["mouseup", "touchmove"],
      mouseDownHandler
    );
    return () => {
      if (containerRootRef.current) {
        containerRootRef.current.removeEventListener(
          "click",
          containerClickHandler
        );
        removeEventListeners(
          containerRootRef.current,
          ["mouseup", "touchmove"],
          mouseDownHandler
        );
        removeEventListeners(
          containerRootRef.current,
          "mousemove",
          mouseMoveHandler
        );
        removeEventListeners(window, ["mouseup", "touchmove"], mouseUpHandler);
      }
    };
  }, [gap, pages, page]);

  useEffect(() => {
    const fontSize = parseFloat(
      window.getComputedStyle(document.body, null).getPropertyValue("font-size")
    );
    const gap: number = fontSize * 1.16666;
    setGap(gap);

    const containerWidth: number =
      containerRootRef.current.getBoundingClientRect().width;
    const minWidth: number = getMinWidth(fontSize, width, isMobile);
    const count = Math.floor((containerWidth + gap) / minWidth);
    setFetchCard(Math.ceil((cards?.length ?? 0) / count));
    if (containerRootRef.current) {
      const dif = containerWidth - (count - 1) * gap - count * minWidth;
      const width = (minWidth + dif / count) / fontSize;
      setCardWidth(width);
    }
    const pagesAll = Math.ceil(totalCardLength / count);

    if (!!pagesAll && page > pagesAll) {
      const newPage = pagesAll;
      setPage(newPage);
    }

    setPages(pagesAll);
  }, [width, cards, totalCardLength, isMobile]);

  const changePage = (page: number) => {
    setPage(page);
  };

  const getCard = useCallback(async () => {
    const limit = 25;
    const nextPageCategory = Math.round(cards.length / limit);
    await dispatch(
      getCardsListByCategory({
        categoryId,
        nextPageCategory: nextPageCategory + 1,
        limit,
        search: search || "",
      })
    );
  }, [cards, dispatch, getCardsListByCategory]);

  useEffect(() => {
    if (
      page + 1 === cardLength &&
      pages !== cardLength - 1 &&
      !!categoryId &&
      page + 1 !== pages
    ) {
      getCard();
    }
  }, [page, pages, cardLength]);

  const renderCards = useMemo(() => {
    return cards?.map((card) => {
      const {
        allow_edit,
        price,
        discount_price,
        name,
        isFavorite,
        cover,
        id,
        detailed_images,
        orientation,
        category_taxonomy,
        isCustomize,
        isCustom,
      } = card;
      const backImage = detailed_images?.back?.image || "";
      const insideImage = detailed_images?.inside?.image || "";
      return (
        <CardItem
          key={id}
          id={id}
          // dont change type of categoryId
          categoryId={categoryId.toString()}
          name={name}
          price={price}
          discount_price={discount_price}
          cover={cover}
          cardLength={cards.length - 1}
          backImage={backImage}
          isFavorite={isFavorite}
          disable={window.innerWidth > 600}
          insideImage={insideImage}
          isPreview={false}
          category={title}
          setTotalLength={setTotalLength}
          orientation={orientation}
          isCustomize={
            isCustomize !== undefined
              ? isCustomize
              : category_taxonomy === "CUSTOMIZED"
          }
          isCustom={
            isCustom !== undefined ? isCustom : category_taxonomy === "CUSTOM"
          }
          allowEdit={!!allow_edit}
          isAllCategories={isAllCategories}
          onSelectCard={onSelectCard ? () => onSelectCard(id, card) : null}
        />
      );
    });
  }, [cards, title, setTotalLength, isAllCategories, onSelectCard]);

  return (
    <FlexContainer align='center' direction='column' margin='20px 0'>
      <TitleBar title={title} style={isSelectCardPopup ? { padding: 0 } : {}}>
        {!isMobile && (
          <CarouselPagination
            pages={pages}
            page={page}
            onHandlePage={changePage}
          />
        )}
      </TitleBar>
      <div
        ref={containerRootRef}
        className={`cards__category ${!isMobile ? "no-scrollbar" : ""}`}
      >
        <FlexContainer
          align='flex-start'
          ref={containerRef}
          className={`cards__list-card ${
            isSelectCardPopup ? "cards__list-card-popup" : ""
          }`}
          style={{
            "--width": `${cardWidth ? `${cardWidth}rem` : "unset"}`,
            "--x": preDragging
              ? `${xAxis}px`
              : `calc(-${100 * (page - 1)}% - ${(page - 1) * 1.16666}rem)`,
          }}
        >
          {renderCards}
        </FlexContainer>
      </div>
    </FlexContainer>
  );
};

export default SliderCards;
