import { RootState } from "../store";

export const selectBasketCount = (state: RootState) => state.basket.count;
export const selectBasketLoading = (state: RootState) => state.basket.isLoading;
export const selectBasketSendOrderId = (state: RootState) =>
  state.basket.basket_id;
export const selectBasketSendPriceStructure = (state: RootState) =>
  state.basket.priceStructure;
export const selectBasketSendCouponName = (state: RootState) =>
  state.basket.couponName;
export const selectBasketPage = (state: RootState) => state.basket.page;

export const selectBasketEndPage = (state: RootState) =>
  state.basket.endPageBasket;
export const selectBasketTotalPage = (state: RootState) =>
  state.basket.totalPage;
export const selectBasketSendOrder = (state: RootState) =>
  state.basket.basketSendOrder;
export const selectSendSuccess = (state: RootState) => state.basket.sendSuccess;
export const selectSpecialRequest = (state: RootState) =>
  state.basket.specialRequestNotes;
export const selectbusinessIncludesAtBasket = (state: RootState) =>
  state.basket.businessIncludes;
export const selectAllBasketItems = (state: RootState) => state.basket.items;
export const selectRecipientAddress = (state: RootState) =>
  state.basket.recipientAddress;
export const getErrorQuantityInserts = (state: RootState) =>
  state.basket.errorQuantityInserts;
