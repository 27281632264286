import { IAddress } from "../../interfaces/address/IAddress";
import { automationTypesConstant } from "../../constants/automation-compain/automation-types";
import {
  IAutomationId,
  IAutomationList,
} from "../../interfaces/automation/automation";

export interface IAutomationState {
  step: number;
  automationType: IAutomationType | null;
  cards: IAutomationList;
  nextPage: number;
  pages: number | null;
  allPage: number | null;
  isDisableStepSwitch: boolean;
  disableStepSwitchByError: string;
  isLoading: boolean;
  isListEnd: boolean;
  isDefaultAutomation: boolean;
  notCorrectBirthdayCardList: boolean;
  editCardId: number | null;
  newCardInfo: INewAutomation;
  internationalRecipient: boolean;
}

export const initialState: IAutomationState = {
  internationalRecipient: false,
  cards: [],
  nextPage: 1,
  pages: null,
  allPage: null,
  step: 1,
  automationType: null,
  isDisableStepSwitch: false,
  disableStepSwitchByError: "",
  isLoading: false,
  isListEnd: false,
  isDefaultAutomation: true,
  editCardId: null,
  notCorrectBirthdayCardList: false,
  newCardInfo: {
    start_date: null,
    card: null,
    end_date: null,
    noEndDate: false,
    card_id: null,
    address_ids: [],
    is_default: false,
    insert_id: "",
    giftCard: null,
    denomination: null,
    denomination_id: "",
    giftCard_id: "",
    address_from_id: null,
    address_from: null,
    font: "",
    message: "",
    name: "",
    wishes: "",
    signature_id: "",
    signature2_id: "",
    signature2_preview: "",
    signature_preview: "",
    delivery_confirmation: "",
  },
};

export interface IAutomationSelectCardData {
  id: number;
  cover: string;
  price: number;
  discount_price?: number;
  name: string;
}

export interface IAutomationSelectCard extends IAutomationSelectCardData {
  card_id: number;
}

export interface INewAutomation {
  start_date: number | null;
  card: IAutomationSelectCardData | null;
  end_date: number | null;
  noEndDate: boolean;
  address_from?: IAddress | null;
  address_from_id?: number | null;
  card_id: null | number;
  address_ids: number[];
  is_default: boolean;
  insert_id: string;
  giftCard: IBirthdayCardGiftCard | null;
  denomination: IBirthdayDenominations | null;
  giftCard_id?: string;
  denomination_id: string;
  auto_font_size?: boolean;
  font: string;
  message: string;
  name: string;
  wishes: string;
  delivery_confirmation: number | string;
  signature_id?: string;
  signature2_id?: string;
  signature2_preview?: string;
  signature_preview?: string;
}

export interface IActionStep {
  activeStep: number;
}

export interface IAutomationType {
  activeAutomationType: string;
  id: automationTypesConstant;
}

export interface IActionChangeDate {
  start_date?: number;
  end_date?: number;
}

export interface IActionSetRecipients {
  address_ids: number[];
}

export interface IActionSetCardInfo {
  insert_id?: string;
  giftCard?: string;
  denomination_id?: string;
  card_id?: IAutomationId;
  giftCard_id?: string;
}

export interface IBirthdayDenominations {
  id: number;
  price: string;
  nominal: string;
}

export interface IBirthdayCardGiftCard {
  id: number | string;
  name: string;
  image: string;
}

export interface IAddAutomation {
  id?: IAutomationId;
  name: string;
  card_id: number;
  address_from?: null;
  font_id: string;
  font_size: number;
  message: string;
  end_date?: string;
  start_date: string;
  no_end_date: boolean;
  address_ids: number[];
  is_default: boolean;
  denomination_id: number | null;
  insert_id?: number | null;
  wishes?: string;
  auto_font_size?: boolean;
  return_address_id?: number | string;
  signature_id?: number | string;
  signature2_id?: number | string;
  signature2_preview?: string;
  signature_preview?: string;
  type: string;
  delivery_confirmation: number | boolean;
}

export interface IPageAllAutomation {
  nextPage: number;
  limit: number;
}

export interface IBirthdayCardsData {
  cards: IAutomationList;
  pages: number;
  page: number;
}
