import "./style.scss";

const NewLabel = () => {
  return (
    <div className='label-pointer'>
      <span>New</span>
      <div className='label-arrow' />
    </div>
  );
};

export default NewLabel;
