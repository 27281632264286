import { useLocation } from "react-router";

const useUrlSearchParams = (query: string) => {
  const location = useLocation();

  const url = new URLSearchParams(location.search);

  return url.get(query);
};

export default useUrlSearchParams;
