import { FC, useMemo, useRef, useEffect, FormEvent, useState } from "react";

import useForm from "../../../hooks/forms/useForm";
import useInput from "../../../hooks/input/useInput";
import useSelect from "../../../hooks/input/useSelect";
import useCountries from "../../../hooks/countries/useCountries";
import useRecipients from "../../../hooks/address/useRecipients";
import useHubSpotUser from "../../../hooks/hubSpot/useHubSpotUser";
import useCalendarInput from "../../../hooks/input/useCalendarInput";
import useAutoCompletePlaces from "../../../hooks/autocomplete-places/useAutocompletePlaces";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import useSetDataFromGoogleApi from "../../../hooks/autocomplete-places/useSetDataFromGoogleApi";

import RecipientForm from "./recipient.form";
import { ValidateCollection } from "./recipient.edit";
import useWarningAtRecipientForm from "../../../hooks/address/useWorningAtRecipientForm";
import trimString from "../../../helpers/trimString";
import { ITagValueWithLabel } from "../../../interfaces/recipient-tags/ITags";
import useAddressLongInputErrors from "../../../hooks/address/useAddressLongInputErrors";
import { IRecipientCustomField } from "../../../interfaces/custom-fields/IRecipientCustomFields";
import getCustomFields from "../../../redux/custom-fields/selectors";
import { getCustomFieldsList } from "../../../redux/custom-fields/thunks";
import { IRecipient } from "../../../interfaces/address/IRecipient";

const RecipientCreate: FC = () => {
  const dispatch = useAppDispatch();

  const customFields = useAppSelector(getCustomFields);

  const addressInputRef = useRef<HTMLInputElement>(null);

  const { hubSpotUser, clearUser } = useHubSpotUser();
  const date = useCalendarInput();
  const anniversaryDate = useCalendarInput();
  const { checkCorrectText } = useWarningAtRecipientForm();
  const first_name = useInput("");
  const last_name = useInput("");
  const business_name = useInput("");
  const address1 = useInput("");
  const address2 = useInput("");
  const city = useInput("");
  const zip = useInput("");
  const stateCountry = useSelect("");
  const extId = useInput("");
  const country = useSelect("1");
  const tag = useSelect("");
  const email = useInput("");
  const phone = useInput("");

  useEffect(() => {
    if (hubSpotUser && !hubSpotUser.address_id) {
      first_name.setValue(hubSpotUser.first_name);
      last_name.setValue(hubSpotUser.last_name);
      country.setValue(hubSpotUser.country_id);
      // tag.setValue(hubSpotUser.tag);
      extId.setValue(hubSpotUser.ext_id);
      city.setValue(hubSpotUser.city);
      address1.setValue(hubSpotUser.address1);
      address2.setValue(hubSpotUser.address2 || "");
      zip.setValue(hubSpotUser.zip);
      stateCountry.setValue(hubSpotUser.state);
      business_name.setValue(hubSpotUser.business_name);
      email.setValue(hubSpotUser?.email || "");
      phone.setValue(hubSpotUser?.phone || "");
    }
  }, []);

  useEffect(() => {
    dispatch(getCustomFieldsList());
  }, []);

  const { lengthInputErrors } = useAddressLongInputErrors({
    firstName: first_name.value,
    lastName: last_name.value,
    businessName: business_name.value,
    address1: address1.value,
    address2: address2.value,
  });

  const [tagsValueWithLabel, setTagsValueWithLabel] = useState<
    ITagValueWithLabel[]
  >([]);

  const [recipientCustomFields, setRecipientCustomFields] = useState<
    IRecipientCustomField[]
  >([]);

  const { create, correctDateSent } = useRecipients();
  const { countriesToList, statesToList, countryState } = useCountries(
    country.value as string
  );
  const address = useAutoCompletePlaces(addressInputRef);
  const {
    findEmptyElements,
    toggleValidateClass,
    checkValidEmail,
    phoneError,
    emailError,
  } = useForm();

  const countryLabel = useMemo(() => {
    const countrySelect = countriesToList.find(
      ({ value }) => value === country.value
    );
    if (countrySelect) return countrySelect.label;
    return countriesToList[0]?.label;
  }, [country, countriesToList]);

  useSetDataFromGoogleApi(address, city, zip, stateCountry, address1, country);

  const states = statesToList(country?.value as string);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const htmlFormControlsCollection = event.currentTarget.elements;

    const {
      first_name,
      last_name,
      address1,
      zip,
      city,
      state,
      country,
      phone,
      email,
    } = htmlFormControlsCollection as ValidateCollection;
    const correctState = states!.map((el) => el.label).includes(state?.value);

    if (!correctState && countryState) {
      (
        document.querySelector('select[name="state"]') as HTMLInputElement
      ).value = "";
      stateCountry.setValue("");
    }

    const inputs = [first_name, address1, zip, city, country];
    const inputsWithState = [first_name, address1, zip, city, state, country];
    toggleValidateClass(countryState ? inputsWithState : inputs);

    const emptyElements = findEmptyElements(
      countryState ? inputsWithState : inputs
    );

    // const isPhoneValid = checkValidPhone(phone);
    const isEmailValid = checkValidEmail(email);

    if (emptyElements.length || !isEmailValid) return;

    const birthday = correctDateSent(date.date);
    const anniversary = correctDateSent(anniversaryDate.date);

    const tags = tagsValueWithLabel.map((tag) => +tag.id) || [];

    const isAddedAnyCustomField = recipientCustomFields.some(
      (field) => field.value?.length
    );

    const recipientStructure: IRecipient = {
      first_name: trimString(first_name.value),
      last_name: trimString(last_name.value),
      business_name: business_name.value,
      address1: address1.value,
      address2: address2.value,
      city: city.value,
      tags,
      state: countryState ? (state.value as string) : "",
      country: countryLabel,
      country_id: country.value as string,
      ext_id: hubSpotUser ? hubSpotUser.ext_id : null,
      zip: zip.value,
      birthday: birthday || null,
      anniversary: anniversary || null,
      phone: phone.value,
      email: email.value,
    };

    if (isAddedAnyCustomField)
      recipientStructure.custom_fields = recipientCustomFields.map((field) => {
        // on create recipient we should send data about label to save full object to redux to avoid error on complete order when we add new recipient with custom field
        return {
          id: field.id,
          value: typeof field.value === "string" ? field.value.trim() : null,
          label: field.label,
        };
      });

    clearUser();
    checkCorrectText(
      () => create({ ...recipientStructure, allow_poor: true }),
      first_name.value,
      last_name.value,
      address1.value
    );
  };

  return (
    <RecipientForm
      addressInputRef={addressInputRef}
      isEditRecipient={false}
      date={date}
      anniversaryDate={anniversaryDate}
      first_name={first_name}
      last_name={last_name}
      recipientCustomFields={recipientCustomFields}
      setRecipientCustomFields={setRecipientCustomFields}
      initialCustomFields={customFields}
      business_name={business_name}
      email={email}
      phone={phone}
      address1={address1}
      address2={address2}
      tagsValueWithLabel={tagsValueWithLabel}
      setTagsValueWithLabel={setTagsValueWithLabel}
      tag={tag}
      countriesToList={countriesToList}
      city={city}
      zip={zip}
      state={stateCountry}
      country={country}
      countryState={countryState}
      btnText='Add Address'
      onSubmit={onSubmit}
      states={states}
      isRecipientForm
      lengthInputErrors={lengthInputErrors}
      phoneError={phoneError}
      emailError={emailError}
    />
  );
};

export default RecipientCreate;
