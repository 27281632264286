import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import getCheckAuth from "./thunks";

const checkAuthSlice = createSlice({
  name: "checkAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCheckAuth.fulfilled, (state, { payload }) => {
      state.checkAuth = payload;
    });
  },
});

export default checkAuthSlice.reducer;
