import { FC, RefObject } from "react";
import classNames from "classnames";

import "./style.scss";

interface IHamburger {
  toggleMenu: () => void;
  isSettingAccountOpen: boolean;
  hamburgerRef: RefObject<HTMLDivElement>;
}

const Hamburger: FC<IHamburger> = ({
  toggleMenu,
  isSettingAccountOpen,
  hamburgerRef,
}) => {
  const hamburgerStyle = classNames({
    hamburger: true,
    hamburger_active: isSettingAccountOpen,
  });

  return (
    <div className={hamburgerStyle} onClick={toggleMenu} ref={hamburgerRef}>
      <div className='hamburger__line' />
      <div className='hamburger__line' />
      <div className='hamburger__line' />
    </div>
  );
};
export default Hamburger;
