import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState, templatesAdapter } from "./entity";
import {
  createTemplate,
  deleteTemplate,
  fetchListTemplates,
  updateTemplate,
} from "./thunks";

import { TemplateId } from "../../interfaces/templates/ITemplate";
import { TemplateCreatePayload, IActiveTemplate } from "./types";

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    addTemplate: (state, { payload }: PayloadAction<TemplateCreatePayload>) => {
      const { template } = payload;

      templatesAdapter.addOne(state, template);
    },
    addTemplateToEdit: (state, { payload }: PayloadAction<TemplateId>) => {
      templatesAdapter.updateOne(state, {
        id: payload,
        changes: {
          status: "edit",
          editing: true,
        },
      });
      state.editId = payload;
    },
    removeTemplateCandidate: (
      state,
      { payload }: PayloadAction<TemplateId>
    ) => {
      templatesAdapter.removeOne(state, payload);
    },
    removeTemplateFromEdit: (state, { payload }: PayloadAction<TemplateId>) => {
      templatesAdapter.updateOne(state, {
        id: payload,
        changes: {
          editing: false,
          status: null,
        },
      });
      state.editId = null;
    },
    chooseTemplate: (state, { payload }: PayloadAction<TemplateId>) => {
      state.activeTemplateId = payload;
    },
    saveActiveTemplate: (
      state,
      { payload }: PayloadAction<IActiveTemplate>
    ) => {
      state.activeTemplate = payload;
    },
    saveActiveEditTemplate: (
      state,
      { payload }: PayloadAction<IActiveTemplate>
    ) => {
      state.activeEditTemplate = payload;
    },
    resetActiveTemplateId: (state) => {
      state.activeTemplateId = null;
    },
    resetActiveTemplate: (state) => {
      state.activeTemplate = null;
    },
    resetActiveEditTemplate: (state) => {
      state.activeEditTemplate = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListTemplates.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      templatesAdapter.setAll(state, payload);
    });
    builder.addCase(fetchListTemplates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchListTemplates.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      if (payload) templatesAdapter.removeOne(state, payload);
    });
    builder.addCase(updateTemplate.fulfilled, (state, { payload }) => {
      if (!payload) return;

      const { id } = payload;
      state.isLoading = false;
      templatesAdapter.updateOne(state, {
        id,
        changes: {
          ...payload,
        },
      });
    });
    builder.addCase(updateTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTemplate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTemplate.fulfilled, (state, { payload }) => {
      if (!payload) return;
      const { new_id, old_id, template } = payload;
      const { signature } = template;
      state.isLoading = false;

      templatesAdapter.updateOne(state, {
        id: old_id!,
        changes: {
          id: new_id!,
          editing: false,
          signature,
          status: null,
          ...template,
        },
      });
    });
  },
});

export const {
  addTemplate,
  addTemplateToEdit,
  removeTemplateFromEdit,
  removeTemplateCandidate,
  chooseTemplate,
  saveActiveTemplate,
  resetActiveTemplate,
  saveActiveEditTemplate,
  resetActiveEditTemplate,
  resetActiveTemplateId,
} = templatesSlice.actions;

export default templatesSlice.reducer;
