import { userInstance } from "../hooks/instance/useInstance";
import { ICheckAuth } from "../interfaces/checkAuth/checkAuth";

const checkAuthApi = {
  checkAuth: async () => {
    return userInstance.get<ICheckAuth>("/checkauth/auth");
  },
};

export default checkAuthApi;
