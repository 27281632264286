import { FC } from "react";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Typography } from "../../ui/typography";
import SectionBox from "../../ui/common/section-box/box";
import { ICampaignList } from "../../../constants/campaigns/campaigns";

const OutboundLeadsItemMobile: FC<ICampaignList> = ({
  name,
  description,
  isActive,
  icon,
  action,
}) => {
  return (
    <SectionBox style={{ width: "90%", opacity: isActive ? "1" : "0.66" }}>
      <FlexContainer className='campaign-item-mobile' direction='column'>
        <FlexContainer className='campaign-item__title-block' direction='row'>
          <img
            className='campaign-item__icon-mobile'
            src={icon}
            alt='icon'
            style={{ width: "1.5rem", marginRight: "0.666rem" }}
          />
          <Typography className='campaign-item__title'>{name}</Typography>
        </FlexContainer>
        <Typography
          className='campaign-item__text-mobile'
          style={{ padding: "1rem 0" }}
        >
          {description}
        </Typography>
        {isActive ? (
          <ButtonAction onClick={action!}>Select</ButtonAction>
        ) : (
          <ButtonAction
            className='disabled-button'
            style={{ opacity: 1 }}
            disabled
          >
            COMING IN 2023!
          </ButtonAction>
        )}
      </FlexContainer>
    </SectionBox>
  );
};

export default OutboundLeadsItemMobile;
