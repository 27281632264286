import { ChangeEvent, useMemo, FC } from "react";

import ZoomSection from "./zoom-section";

import "./style.scss";

interface IRange {
  zoom: number;
  onZoom?: (zoom: number) => void;
  uploadNewImage: (
    event: ChangeEvent<HTMLInputElement>,
    isCover: boolean,
    setNewImage: (id: number, image_url: string) => void,
    isIcon: boolean
  ) => void;
  imageUrl?: string;
  isCover: boolean;
  isIcon?: boolean;
  textResolution: string;
  heightResolution?: number;
  widthResolution?: number;
  handleImage: (id: number, image_url: string) => void;
  onRemoveImage?: () => void;
  hideZoom?: boolean;
  minZoom: string;
  maxZoom: string;
}

const UploadImg: FC<IRange> = ({
  zoom,
  onZoom = () => {},
  uploadNewImage,
  onRemoveImage = () => {},
  imageUrl,
  isCover,
  isIcon = false,
  textResolution,
  heightResolution,
  widthResolution,
  handleImage,
  hideZoom,
  minZoom,
  maxZoom,
}) => {
  const resolution: string = useMemo(() => {
    if (heightResolution && widthResolution)
      return `${widthResolution} x ${heightResolution}px`;
    if (heightResolution) return `${heightResolution}px`;
    if (widthResolution) return `${widthResolution}px`;
    return "";
  }, [heightResolution, widthResolution]);

  return (
    <div className='upload-image'>
      <label>
        <span>{textResolution} &nbsp;</span>
        <span>{resolution}</span>
        <div className='upload-image__plus' />
        <input
          type='file'
          name='file'
          // accept='image/*'
          accept='.png, .jpg, .jpeg'
          onChange={(event) =>
            uploadNewImage(event, isCover, handleImage, isIcon)
          }
        />
      </label>
      {!hideZoom && (
        <ZoomSection
          zoom={zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onZoom={onZoom}
          imageUrl={imageUrl}
          onRemoveImage={onRemoveImage}
        />
      )}
    </div>
  );
};

export default UploadImg;
