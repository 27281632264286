import { useEffect, useState } from "react";

export interface IUseAddressLongInputErrors {
  firstName: string;
  lastName: string;
  businessName: string;
  address1: string;
  address2: string;
}

const useAddressLongInputErrors = ({
  firstName,
  lastName,
  businessName,
  address1,
  address2,
}: IUseAddressLongInputErrors) => {
  const [lengthInputErrors, setLengthInputErrors] = useState<string[]>([]);

  const removeLengthErrorByName = (error: string) => {
    setLengthInputErrors((prev) => prev.filter((value) => value !== error));
  };

  const addLengthErrorByName = (error: string) => {
    setLengthInputErrors((prev) => prev.concat(error));
  };

  const createOrDeleteError = (valueLength: number, errorName: string) => {
    const isLongValue = valueLength > 30;

    if (isLongValue && lengthInputErrors.includes(errorName)) return;

    if (!isLongValue && lengthInputErrors.includes(errorName))
      removeLengthErrorByName(errorName);

    if (isLongValue) addLengthErrorByName(errorName);
  };

  useEffect(() => {
    const valueLength = firstName.length + lastName.length;

    createOrDeleteError(valueLength, "long Recipient Name");
  }, [firstName, lastName]);

  useEffect(() => {
    const valueLength = businessName.length;

    createOrDeleteError(valueLength, "long Business Name");
  }, [businessName]);

  useEffect(() => {
    const valueLength = address1.length;

    createOrDeleteError(valueLength, "long Address1");
  }, [address1]);

  useEffect(() => {
    const valueLength = address2.length;

    createOrDeleteError(valueLength, "long Address2");
  }, [address2]);

  return { lengthInputErrors };
};

export default useAddressLongInputErrors;
