import { FC, memo, useMemo } from "react";
import classnames from "classnames";

import { View } from "../../../hooks/cards/useCard";
import controlView from "../../../constants/card/card-control";

const { controlWithoutInside, control } = controlView;

interface ICardControl {
  onClick: (view: View, noFlatBack: boolean) => void;
  view: string;
  orientation: string;
}

const CardControl: FC<ICardControl> = ({ onClick, view, orientation }) => {
  const cardPreview = useMemo(() => {
    const _noFlat = orientation === "F";
    const controls = _noFlat ? controlWithoutInside : control;

    return controls.map((type) => {
      const { name, value } = type;

      const li = classnames({
        active: value === view,
      });

      return (
        <li
          key={value}
          className={li}
          onClick={() => onClick(value as View, _noFlat)}
        >
          {name}
        </li>
      );
    });
  }, [view, onClick, orientation]);

  return <ul className='card__preview'>{cardPreview}</ul>;
};

export default memo(CardControl);
