import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import creditCardsApi from "../../api/credit-cards.api";
import { INewCreditCartInfo } from "../../interfaces/credit-cards/ICreditCard";
import { login } from "../auth/thunks";

export const fetchCreditCards = createAsyncThunk(
  "credit-cards/list",
  async () => {
    const res = await creditCardsApi.list();

    const { credit_cards, discount_credit, discount_credit_type2 } = res.data;

    return {
      credit_cards,
      discount_credit,
      discount_credit_type2,
    };
  }
);
export const fetchCreditCardKeys = createAsyncThunk(
  "profile/getAuthorizeNetInfo",
  async () => {
    const res = await creditCardsApi.getKeyForNewCard();
    const { login_id, client_key, transaction_key } =
      res.data.authorizenet_info;
    return {
      client_key,
      login_id,
      transaction_key,
    };
  }
);

export const fetchCreditCardsOnly = createAsyncThunk(
  "credit-cards/list/only",
  async () => {
    await creditCardsApi.listOnly();
  }
);

export const deleteCreditCard = createAsyncThunk(
  "credit-cards/delete",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await creditCardsApi.delete(id);
      dispatch(fetchCreditCards());
      dispatch(login());
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

      throw err;
    }
  }
);

export const addNewCreditCard = createAsyncThunk(
  "creditCards/addNew",
  async (newCreditCardTokenInfo: any, { rejectWithValue }) => {
    try {
      const res = await creditCardsApi.addCreditCard(newCreditCardTokenInfo);
      return res.data.credit_card;
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

      throw err;
    }
  }
);

export const addCreditCard = createAsyncThunk(
  "xml/v1/request.api",
  async (newCreditCardInfo: INewCreditCartInfo) => {
    const res = await creditCardsApi.add(newCreditCardInfo);
    const { dataDescriptor, dataValue } = res.data.opaqueData;

    return {
      dataDescriptor,
      dataValue,
    };
  }
);

export const setDefaultCreditCard = createAsyncThunk(
  "credit-cards/default",
  async (id: number, { dispatch }) => {
    await creditCardsApi.setDefault(id);
    dispatch(fetchCreditCards());
  }
);
