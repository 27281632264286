import { userInstance } from "../hooks/instance/useInstance";
import { IInsertsResponse } from "../interfaces/inserts/IInserts";
import { IStatesResponse } from "../interfaces/inserts/IState";

const insertsApi = {
  list: async () => {
    return userInstance.get<IInsertsResponse>("/inserts/list");
  },
  states: async (insertsId: number) => {
    return userInstance.get<IStatesResponse>(
      `/inserts/listStates?id=${insertsId}`
    );
  },
};

export default insertsApi;
