import { createAsyncThunk } from "@reduxjs/toolkit";
import outboundLeadsApi from "../../api/outbound-prospecting";
import {
  IBoundaryZip,
  IDemographicInfo,
  IOutboundProcess,
  IOutboundProcessPrice,
  IOutboundAddToBasket,
} from "./types";

export const calculateTargets = createAsyncThunk(
  "outbound/calcTargets",
  async (payload: IDemographicInfo) => {
    const res = await outboundLeadsApi.count(payload);
    const { total_records, mask } = res.data;
    return { total_records, mask };
  }
);

export const outboundProcess = createAsyncThunk(
  "outbound/procces",
  async (payload: IOutboundProcess) => {
    const res = await outboundLeadsApi.process(payload);
    const { prices, addresses, address_from, quantity } =
      res.data as IOutboundProcessPrice;
    return { prices, addresses, address_from, quantity };
  }
);

export const outboundGetIndustry = createAsyncThunk(
  "outbound/industries",
  async () => {
    const res = await outboundLeadsApi.industries();

    return res.data;
  }
);

export const outboundZipBoundary = createAsyncThunk(
  "outbound/zip_boundary",
  async (payload: string) => {
    const res = await outboundLeadsApi.boundaryZip(payload);
    const { boundary, zip } = res.data.data as IBoundaryZip;

    return { boundary, zip };
  }
);

export const outboundMessageAddToBasket = createAsyncThunk(
  "outbound/addToBasket",
  async (payload: IOutboundAddToBasket) => {
    const res = await outboundLeadsApi.outboundAddToBasket(payload);
    return res.data;
  }
);
