import { Dispatch, FC, SetStateAction, CSSProperties } from "react";
import Select, { IOption, IValue } from "../ui/common/select/select.common";

export interface ISelect {
  value?: string | IValue;
  onChange?: (
    value: string | IValue,
    setShow?: Dispatch<SetStateAction<boolean>>
  ) => void;
}

interface ICustomSelect extends ISelect {
  options: IOption[];
  style?: CSSProperties;
  select: ISelect;
  placeholder?: string;
  fixedPlaceholder?: string;
  className?: string;
  containerClass?: string;
  disabled?: boolean;
  clearSelector?: boolean;
  isFontsForCustomCard?: boolean;
  styleForCancelButton?: CSSProperties;
}

const CustomSelect: FC<ICustomSelect> = ({
  options,
  style,
  select,
  placeholder,
  fixedPlaceholder,
  className,
  containerClass,
  onChange,
  disabled,
  clearSelector,
  isFontsForCustomCard,
  styleForCancelButton,
}) => {
  return (
    <Select
      {...select}
      disabled={disabled}
      className={className}
      options={options}
      style={style}
      placeholder={placeholder}
      fixedPlaceholder={fixedPlaceholder}
      onChange={onChange!}
      clearSelector={clearSelector}
      containerClass={containerClass}
      isFontsForCustomCard={!!isFontsForCustomCard}
      styleForCancelButton={styleForCancelButton}
    />
  );
};

export default CustomSelect;
