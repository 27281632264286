import { useMemo } from "react";
import {
  getUser,
  getCredit1User,
  getCredit2User,
} from "../../redux/auth/selectors";
import { useAppSelector } from "../redux/useRedux";

const useUser = () => {
  const user = useAppSelector(getUser);
  const credit1 = useAppSelector(getCredit1User);
  const credit2 = useAppSelector(getCredit2User);

  const {
    email,
    lname,
    fname,
    test_mode,
    user_subscription,
    max_discount_subscriptions,
    expiring_credit2,
    google_id,
    facebook_id,
    apple_id,
    total_credit_card,
    name,
    detected_subscription_issue,
    problematic_subscription,
    lost_credit,
  } = useMemo(() => {
    if (user) {
      const {
        email,
        lname,
        fname,
        test_mode,
        user_subscription,
        expiring_credit2,
        max_discount_subscriptions,
        google_id,
        facebook_id,
        apple_id,
        total_credit_card,
        detected_subscription_issue,
        problematic_subscription,
        lost_credit,
      } = user;
      return {
        email,
        lname,
        fname,
        expiring_credit2,
        test_mode,
        user_subscription,
        max_discount_subscriptions,
        google_id,
        facebook_id,
        apple_id,
        total_credit_card,
        detected_subscription_issue,
        problematic_subscription,
        lost_credit,
        name: `${`${fname} ${lname}`}`,
      };
    }
    return {
      login: null,
      lname: null,
      fname: null,
      credit1: null,
      credit2: null,
      test_mode: null,
      expiring_credit2: null,
      user_subscription: null,
      max_discount_subscriptions: null,
      remaining_credit: null,
      google_id: null,
      facebook_id: null,
      apple_id: null,
      total_credit_card: null,
      name: null,
      detected_subscription_issue: false,
      problematic_subscription: null,
      lost_credit: 0,
    };
  }, [user]);

  return {
    email,
    lname,
    fname,
    credit1,
    credit2,
    test_mode,
    expiring_credit2,
    user_subscription,
    max_discount_subscriptions,
    google_id,
    facebook_id,
    apple_id,
    user,
    total_credit_card,
    name,
    detected_subscription_issue,
    problematic_subscription,
    lost_credit,
  };
};

export default useUser;
