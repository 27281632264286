import { useRef, Ref } from "react";

interface IFocus {
  htmlElRef: Ref<HTMLInputElement>;
  setFocus: () => void;
}

const useFocus = () => {
  const htmlElRef = useRef<HTMLInputElement>(null);
  const setFocus = () => {
    setTimeout(() => {
      if (htmlElRef.current) {
        htmlElRef.current.focus();
      }
    });
  };

  return { htmlElRef, setFocus } as IFocus;
};

export default useFocus;
