import { Detail } from "react-calendar";

interface ITTileDisabled {
  date: Date;
  view: Detail;
  currentDateTime?: number | null;
  isAutomation?: boolean;
  birthday?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  disabled?: string[];
  holidays?: string[];
  isShippingMethods?: boolean;
  anniversary?: boolean;
}

interface IValidDateForHolidays {
  dateValue: string;
  currentDateTime?: number | null;
  disableFuture?: boolean;
  disablePast?: boolean;
  holidays?: string[];
  disabled?: string[];
  birthday?: boolean;
  isAutomation?: boolean;
  correctEditDate?: number | null;
  correctDateEditSent: string;
  optional?: boolean;
  isShippingMethods?: boolean;
  anniversary?: boolean;
}

export const oneDay = 86400000;

export const holidayDays = ({
  date,
  currentDateTime,
  view,
  isAutomation,
  birthday,
  disablePast,
  disableFuture,
  holidays,
  isShippingMethods,
  anniversary,
}: ITTileDisabled) => {
  let isHoliday: boolean = false;
  const correctDate = currentDateTime
    ? currentDateTime - oneDay
    : new Date().getTime() - oneDay;
  const correctDay = new Date().getTime();
  const currentDateTimeStamp =
    !!currentDateTime && correctDate ? correctDate : correctDay;
  const dateTimeStamp: number = new Date(date).getTime();
  const correctMonth: number = new Date(date).getMonth();
  const year = new Date(date).getFullYear();
  const currentYear = new Date(correctDate as number).getFullYear();
  const correctCurentMonth = new Date(correctDate!).getMonth();
  if (view === "year" && year === currentYear) {
    if (isAutomation) {
      return correctMonth < correctCurentMonth;
    }
    if (birthday) {
      return correctMonth > correctCurentMonth;
    }
    if (disablePast) {
      return correctMonth < correctCurentMonth;
    }
    if (disableFuture) {
      return correctMonth > correctCurentMonth;
    }
    return false;
  }

  if (view === "decade") {
    const correctDecade = new Date().getFullYear();
    if (isAutomation) {
      return year < currentYear;
    }
    if (birthday) {
      return year > correctDecade;
    }
    if (disablePast) {
      return year < correctDecade;
    }
    if (disableFuture) {
      return year > correctDecade;
    }
    return false;
  }

  const correctCentury = +year.toString()[0];
  const correctCenturyNow = +new Date().getFullYear().toString()[0];

  if (view === "century" && correctCentury === correctCenturyNow) {
    const correctCentury = +year.toString()[2];
    const correctCenturyNow = +new Date().getFullYear().toString()[2];
    if (isAutomation) {
      return correctCentury < correctCenturyNow;
    }
    if (birthday) {
      return correctCentury > correctCenturyNow;
    }
    if (disablePast) {
      return correctCentury < correctCenturyNow;
    }
    if (disableFuture) {
      return correctCentury > correctCenturyNow;
    }
    return false;
  }

  if (isAutomation) {
    return dateTimeStamp < correctDate! + oneDay;
  }
  if (birthday) {
    return dateTimeStamp > currentDateTimeStamp || isHoliday;
  }
  if (holidays?.length) {
    const disabledTimeStamp = holidays.map((date) => new Date(date).getTime());
    isHoliday = disabledTimeStamp.includes(dateTimeStamp);
  }
  if (isShippingMethods) {
    return dateTimeStamp < correctDate || isHoliday;
  }
  if (disablePast) {
    return dateTimeStamp < correctDay || isHoliday;
  }
  if (disableFuture) {
    return dateTimeStamp > correctDay || isHoliday;
  }
  if (anniversary) {
    return false;
  }
  return false;
};

export const isValidDateForHolidays = ({
  dateValue,
  currentDateTime,
  holidays,
  disableFuture,
  disablePast,
  birthday,
  isAutomation,
  correctDateEditSent,
  optional,
  isShippingMethods,
  anniversary,
}: IValidDateForHolidays) => {
  let isHoliday: boolean = false;
  const correctDate = currentDateTime ? currentDateTime - oneDay : null;
  const dateTimeStamp: number = new Date(dateValue as string).getTime();
  const currentDateTimeStamp =
    !!currentDateTime && correctDate ? correctDate : new Date().getTime();
  const holiday: boolean =
    new Date(dateValue as string).getDay() === 0 ||
    new Date(dateValue as string).getDay() === 6;
  if (isAutomation) {
    return correctDateEditSent !== dateValue
      ? dateTimeStamp < correctDate! + oneDay
      : false;
  }
  if (birthday) {
    return dateTimeStamp > currentDateTimeStamp || isHoliday;
  }
  if (holidays?.length) {
    const disabledTimeStamp = holidays.map((date) => new Date(date).getTime());
    isHoliday = disabledTimeStamp.includes(dateTimeStamp);
  }
  if (isShippingMethods) {
    return dateTimeStamp < currentDateTimeStamp || holiday || isHoliday;
  }
  if (disablePast) {
    return dateTimeStamp < currentDateTimeStamp || holiday || isHoliday;
  }
  if (disableFuture) {
    return dateTimeStamp > currentDateTimeStamp || isHoliday;
  }
  if (anniversary) {
    return false;
  }
  if (!optional) {
    return holiday;
  }
};
