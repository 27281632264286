import { userInstance, userInstanceV2 } from "../hooks/instance/useInstance";

import {
  ISubscriptionsResponse,
  INewSubscription,
  ITaxListResponse,
  ISubscriptionCancel,
} from "../interfaces/subscriptions/ISubscriptions";
import { ISubscriptionsUpdate } from "../redux/subscriptions/types";

const subscriptionsApi = {
  list: async () => {
    return userInstance.get<ISubscriptionsResponse>("/subscriptions/list");
  },
  setNewSubscriptions: async (subscription: INewSubscription) => {
    const response = await userInstanceV2.post(
      "/subscriptions/new",
      subscription
    );
    return response;
  },
  cancelSubscriptions: async (payload: ISubscriptionCancel) => {
    return userInstanceV2.post("/subscriptions/cancel", payload);
  },
  updatePaymentMethod: async (subscription: ISubscriptionsUpdate) => {
    return userInstance.post(
      "/subscriptions/updatePaymentMethod",
      subscription
    );
  },
  getTaxList: async () => {
    return userInstance.get<ITaxListResponse>("/subscriptions/taxList");
  },
};

export default subscriptionsApi;
