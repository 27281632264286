import { FC } from "react";
import { IControlledLogo } from "../../interfaces/logo/ILogo";

const BirthdayLogo: FC<IControlledLogo> = ({ isActive = false }) => {
  const backgroundColor = isActive ? "#F85E49" : "#E9E9E0";
  const color = isActive ? "#FFFFFF" : "#a7a794";
  return (
    <svg
      width='31px'
      height='31px'
      viewBox='0 0 31 31'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ width: "100%", height: "100%" }}
    >
      <defs>
        <polygon
          id='path-1'
          points='0 0 12.6688675 0 12.6688675 11.5388059 0 11.5388059'
        />
        <polygon
          id='path-3'
          points='0 0 1.56346075 0 1.56346075 2.64252625 0 2.64252625'
        />
        <polygon
          id='path-5'
          points='0 0 1.56346075 0 1.56346075 2.64252625 0 2.64252625'
        />
        <polygon
          id='path-7'
          points='0 0 1.56346075 0 1.56346075 2.64252625 0 2.64252625'
        />
      </defs>
      <g
        id='Design'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Address-Book' transform='translate(-1593.75, -470.55)'>
          <g id='Group-8-Copy-17' transform='translate(1593.75, 470.55)'>
            <circle
              id='Oval-Copy-7'
              fill={backgroundColor || "#F85E49"}
              cx='15.5'
              cy='15.5'
              r='15.5'
            />
            <g id='Group-13-Copy-2' transform='translate(9.1656, 8)'>
              <g id='Group-3' transform='translate(0, 3.4612)'>
                <mask id='mask-2' fill='white'>
                  <use xlinkHref='#path-1' />
                </mask>
                <g id='Clip-2' />
                <path
                  d='M12.6664498,9.64044582 L12.6664498,3.87149057 C12.6664498,3.86611785 12.6691361,3.86119286 12.6691361,3.85626787 C12.6691361,3.85089515 12.6664498,3.84597016 12.6664498,3.84104517 L12.6664498,3.83835881 L12.666002,3.83835881 C12.653018,3.42376413 12.186487,3.04230119 11.4105771,2.73292222 L11.4105771,0 L10.0051638,0 L10.0051638,2.32325252 C9.14418582,2.14058013 8.12560816,2.01969399 7.01838066,1.9843236 L7.01838066,0 L5.61296735,0 L5.61296735,1.98611451 C4.52096255,2.02282808 3.51492123,2.14281877 2.66379326,2.32325252 L2.66379326,0 L1.25837995,0 L1.25837995,2.73292222 C0.482470017,3.04230119 0.0159390614,3.42376413 0.00295499454,3.83835881 L0.0016118152,3.83835881 L0.0016118152,3.84552244 C0.0016118152,3.84910425 -0.000179090578,3.85268606 -0.000179090578,3.85626787 C-0.000179090578,3.85984968 0.0016118152,3.86343149 0.0016118152,3.86701331 L0.0016118152,9.64492308 C0.0016118152,9.64850489 -0.000179090578,9.6520867 -0.000179090578,9.65566852 C-0.000179090578,9.65925033 0.0016118152,9.66283214 0.0016118152,9.66641395 L0.0016118152,9.7129775 L0.00967089121,9.7129775 C0.112200247,10.7261824 2.90064055,11.5388059 6.33470238,11.5388059 C9.76831649,11.5388059 12.5567568,10.7261824 12.6592861,9.7129775 L12.6664498,9.7129775 L12.6664498,9.67089121 C12.6664498,9.66596622 12.6691361,9.66104123 12.6691361,9.65566852 C12.6691361,9.65074352 12.6664498,9.64537081 12.6664498,9.64044582'
                  id='Fill-1'
                  fill={color || "#FFFFFF"}
                  mask='url(#mask-2)'
                />
              </g>
              <g id='Group-6' transform='translate(1.2165, 0)'>
                <mask id='mask-4' fill='white'>
                  <use xlinkHref='#path-3' />
                </mask>
                <g id='Clip-5' />
                <path
                  d='M0.781730373,2.64252625 C1.21333867,2.64252625 1.56346075,2.05107962 1.56346075,1.32128551 C1.56346075,0.591491407 1.21333867,-0.0004029538 0.781730373,-0.0004029538 C0.35012208,-0.0004029538 0,0.591491407 0,1.32128551 C0,2.05107962 0.35012208,2.64252625 0.781730373,2.64252625'
                  id='Fill-4'
                  fill={color || "#FFFFFF"}
                  mask='url(#mask-4)'
                />
              </g>
              <g id='Group-9' transform='translate(5.5342, 0)'>
                <mask id='mask-6' fill='white'>
                  <use xlinkHref='#path-5' />
                </mask>
                <g id='Clip-8' />
                <path
                  d='M0.781730373,2.64252625 C1.21333867,2.64252625 1.56346075,2.05107962 1.56346075,1.32128551 C1.56346075,0.591491407 1.21333867,-0.000402953801 0.781730373,-0.000402953801 C0.35012208,-0.000402953801 0,0.591491407 0,1.32128551 C0,2.05107962 0.35012208,2.64252625 0.781730373,2.64252625'
                  id='Fill-7'
                  fill={color || "#FFFFFF"}
                  mask='url(#mask-6)'
                />
              </g>
              <g id='Group-12' transform='translate(9.8892, 0)'>
                <mask id='mask-8' fill='white'>
                  <use xlinkHref='#path-7' />
                </mask>
                <g id='Clip-11' />
                <path
                  d='M0.781730373,2.64252625 C1.21333867,2.64252625 1.56346075,2.05107962 1.56346075,1.32128551 C1.56346075,0.591491407 1.21333867,-0.000402953801 0.781730373,-0.000402953801 C0.35012208,-0.000402953801 0,0.591491407 0,1.32128551 C0,2.05107962 0.35012208,2.64252625 0.781730373,2.64252625'
                  id='Fill-10'
                  fill={color || "#FFFFFF"}
                  mask='url(#mask-8)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BirthdayLogo;
