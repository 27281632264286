import { ICard } from "../../interfaces/cards/ICard";
import { PastOrdersList } from "../../interfaces/orders/IPastOrder";
import { IOrderEditDetail } from "../../components/orders/order/order.item";
import { IBulkOrder } from "../../interfaces/orders/IBulkOrder";
import { ITotalCheckout } from "../../interfaces/checkout/ITotalCheckout";

interface IPastOrders {
  page: number;
  allow_send?: boolean;
  orders: PastOrdersList;
  isLoading: boolean;
  error: string | null | boolean;
  endPagePastOrders: boolean;
  parentId: string | null;
}
export interface IIndustryListAtOrder {
  value: string;
  label: string;
}

interface OrdersState {
  pastOrders: IPastOrders;
  allTax: IAllTaxes;
  addresses: Array<IBulkOrder>;
  isLoader: boolean;
  editOrder: IOrderEditDetail | null;
  editId: number | null;
  searchPastOrder: string | null;
  loginAtSend: boolean;
  loaderCalcTax: boolean;
  calcTaxCanceled: boolean;
  selectedReturnAddress: number | null;
  groundShippingDisabled: boolean;
  businessIncludes: boolean;
}

export interface IChildsThunkPayload {
  childs: Array<ICard>;
  id: number;
}

export interface IAllTaxes {
  price_structure: ITotalCheckout;
  outbound_exist?: boolean;
  billing_info: {
    address: string;
    zip: number;
    country?: string;
    country_id: number;
  };
}

export const initialState: OrdersState = {
  pastOrders: {
    page: 1,
    orders: [],
    isLoading: false,
    error: null,
    endPagePastOrders: false,
    parentId: null,
  },
  editOrder: null,
  editId: null,
  selectedReturnAddress: null,
  searchPastOrder: null,
  loginAtSend: false,
  allTax: {
    outbound_exist: false,
    price_structure: {
      grand_total: 0,
      tax: 0,
      bonus_credit_total: 0,
      bonus_credit: 0,
      account_credit_total: 0,
      account_credit: 0,
      coupon_credit_total: "",
      coupon_credit: 0,
      prepaid_credit: 0,
      applied_credit: 0,
      total: 0,
    },
    billing_info: {
      address: "",
      zip: 0,
      country: "",
      country_id: 0,
    },
  },
  isLoader: false,
  addresses: [],
  groundShippingDisabled: false,
  loaderCalcTax: false,
  calcTaxCanceled: false,
  businessIncludes: false,
};
