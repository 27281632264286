import { FC, useEffect } from "react";
import useRecipientTags from "../../../hooks/recipient-tags/useRecipientTags";
import { ButtonAction, FlexContainer } from "../../ui/layout";
import CustomCheckbox from "../../custom-checkbox/custom.checkbox";
import useCheckbox from "../../../hooks/input/useCheckbox";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import { selectActiveOrder } from "../../../redux/order/selectors";
import { closePopup } from "../../../redux/portal-popup/slice";
import { setSelectedTags } from "../../../redux/recipient-tags/slice";
import { getSelectedTags } from "../../../redux/recipient-tags/selectors";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const SelectTags: FC = () => {
  const dispatch = useAppDispatch();

  const activeOrder = useAppSelector(selectActiveOrder);
  const selectedTags = useAppSelector(getSelectedTags);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const { checked, onChange: onCheckboxChange, setChecked } = useCheckbox([]);

  const { filteredTagsToList } = useRecipientTags(true, true);

  useEffect(() => {
    const checkedAddressesIds = activeOrder?.address_ids || [];

    // check tags which addresses is actually selected
    const activeTags = selectedTags.filter((tag) => {
      if (!tag?.addresses) return false;
      return tag.addresses.every((address) =>
        checkedAddressesIds.includes(address)
      );
    });
    const selectedTagsIds = activeTags.map(({ id }) => +id);
    setChecked(selectedTagsIds);
  }, [selectedTags]);

  const getTagsWithSameAddresses = (id: number): number[] => {
    const tagsWithSameAddresses: number[] = [];

    const selectedTagAddresses = filteredTagsToList.find(
      (tag) => +tag.id === id
    )?.addresses;

    if (selectedTagAddresses) {
      filteredTagsToList.forEach((tag) => {
        if (
          tag.addresses?.every((address) =>
            selectedTagAddresses.includes(address)
          )
        ) {
          tagsWithSameAddresses.push(+tag.id);
        }
      });
    }
    return tagsWithSameAddresses;
  };

  const checkTagHandler = (id: number) => {
    const tagsWithSameAddresses = getTagsWithSameAddresses(id);

    const isIdAlreadyChecked = checked.includes(id);

    if (isIdAlreadyChecked) {
      setChecked(
        checked.filter(
          (checkedId) => !tagsWithSameAddresses.some((id) => id === checkedId)
        )
      );
    } else {
      onCheckboxChange([...checked, ...tagsWithSameAddresses]);
    }
  };

  const onSubmit = () => {
    const newSelectedTags = filteredTagsToList.filter((tag) =>
      checked.some((id) => id === +tag.id)
    );

    dispatch(setSelectedTags(newSelectedTags));

    dispatch(closePopup());
  };

  return (
    <FlexContainer
      direction='column'
      style={{
        minWidth: isMobile ? "85%" : "20rem",
        height: isMobile ? "85%" : "55vh",
        paddingTop: isMobile ? "0.5rem" : 0,
      }}
    >
      <div
        style={{
          margin: "0 .8rem",
        }}
        className='scroll-container'
      >
        {filteredTagsToList.map(({ label, id }) => {
          return (
            <CustomCheckbox
              key={id}
              data={{ label, id: +id }}
              checked={checked}
              onChange={checkTagHandler}
              className='name-column'
              style={{
                marginTop: "1rem",
                borderBottom: "1px solid #dddfd2",
                paddingBottom: "1rem",
              }}
            />
          );
        })}
      </div>
      <ButtonAction
        className={isMobile ? "updateBtn-mobile" : "updateBtn"}
        onClick={onSubmit}
      >
        Select
      </ButtonAction>
    </FlexContainer>
  );
};

export default SelectTags;
