import { createAsyncThunk } from "@reduxjs/toolkit";

import countriesApi from "../../api/countires.api";

export const fetchCountries = createAsyncThunk("countries/list", async () => {
  const res = await countriesApi.list();

  const { countries } = res.data;

  return countries;
});

export const fetchStates = createAsyncThunk<void, number>(
  "countries/states/list",
  async (countryId) => {
    await countriesApi.states(countryId);
  }
);

export const getDisabledStates = createAsyncThunk(
  "/states/?ground_shipping=0",
  async () => {
    const res = await countriesApi.disabledStates();

    const { states } = res.data;

    return states;
  }
);
