import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "../redux/useRedux";
import { togglePreview } from "../../redux/cards/slice";

export type View = "front" | "inside" | "back";
type Disable = boolean | undefined;
export type Orientation = "L" | "P" | "F";

const useCard = () => {
  const dispatch = useAppDispatch();

  const [view, setView] = useState<View>("front");
  const [writing, setWriting] = useState(false);

  useEffect(() => {
    if (view === "inside") setWriting(true);
  }, [view]);

  const changeView = useCallback((view: View, isFlat: boolean) => {
    if (view === "inside" && isFlat) return;
    setView(view);
  }, []);

  const resetView = useCallback(() => {
    if (view === "front") return;

    setView("front");
  }, [view]);

  const closePreview = useCallback((disable: Disable) => {
    if (disable) return;

    dispatch(togglePreview());
  }, []);

  return {
    changeView,
    view,
    resetView,
    closePreview,
    writing,
  };
};

export default useCard;
