import { createEntityAdapter } from "@reduxjs/toolkit";
import { IBasketItem } from "../../interfaces/basket/IBasketItem";
import { IBasketState } from "./types";

export const basketAdapter = createEntityAdapter<IBasketItem>();

export const initialState = basketAdapter.getInitialState<IBasketState>({
  count: null,
  isLoading: false,
  recipientAddress: null,
  sendSuccess: false,
  specialRequestNotes: {},
  page: 0,
  endPageBasket: false,
  totalPage: 1,
  items: [],
  errorQuantityInserts: false,
  basketSendOrder: null,
  businessIncludes: false,
  basket_id: null,
  priceStructure: null,
  couponName: null,
});
