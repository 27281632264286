import { createAsyncThunk } from "@reduxjs/toolkit";

import reportsApi from "../../api/reports.api";

export const fetchReports = createAsyncThunk("qrCode/dashboard", async () => {
  const res = await reportsApi.dashboard();
  return res.data;
});

export const exportScansActivity = createAsyncThunk(
  "qrCode/export",
  async () => {
    await reportsApi.exportActivity();
  }
);
