import { createAsyncThunk } from "@reduxjs/toolkit";

import giftCardsApi from "../../api/gcards.api";

const fetchGiftCards = createAsyncThunk("giftCards/list", async () => {
  const res = await giftCardsApi.list();

  const { gcards } = res.data;

  return gcards;
});

export default fetchGiftCards;
