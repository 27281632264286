import { ICheckAuth } from "../../interfaces/checkAuth/checkAuth";

interface AuthState {
  checkAuth: ICheckAuth;
}

const initialState: AuthState = {
  checkAuth: {
    has_no_password: "",
    last: "",
    login: "",
    test_mode: 0,
  },
};

export default initialState;
