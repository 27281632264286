import { useCallback } from "react";

import {
  setInitCardsSection,
  setIsListEnd,
  setPage,
} from "../../redux/cards/slice";
import { useAppDispatch } from "../redux/useRedux";

const useCards = () => {
  const dispatch = useAppDispatch();

  const resetList = useCallback(() => {
    dispatch(setPage(1));
    dispatch(setInitCardsSection());
    dispatch(setIsListEnd(false));
  }, []);

  return {
    resetList,
  };
};

export default useCards;
