import { userInstance } from "../hooks/instance/useInstance";
import {
  IPrepaidPlanPayload,
  IPrepaidPlansResponse,
  IUpdarePrepaidPlanPayload,
  IPrepaidPlanChanges,
} from "../interfaces/subscriptions/IPrepaidPlans";

const prepaidPlansApi = {
  list: async () => {
    return userInstance.get<IPrepaidPlansResponse>("/prepaidPlans/list");
  },
  buy: async (prepaidPlan: IPrepaidPlanPayload) => {
    const response = await userInstance.post("/prepaidPlans/buy", prepaidPlan);
    return response;
  },
  update: async (prepaidPlan: IUpdarePrepaidPlanPayload) => {
    const changes: IPrepaidPlanChanges = {
      threshold: prepaidPlan.threshold,
      extendability: prepaidPlan.extendability,
    };
    if (prepaidPlan.credit_card) {
      changes.credit_card_id = prepaidPlan.credit_card;
    }
    const response = userInstance.put(
      `/prepaidPlans/${prepaidPlan.prepaid_plan_id}`,
      changes
    );
    return response;
  },
};

export default prepaidPlansApi;
