import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import { useAppDispatch } from "../redux/useRedux";
import { setHsToken, setHubSpotInfo } from "../../redux/hubspot/slice";
import { signIn } from "../../redux/auth/thunks";
import { basketCount } from "../../redux/basket/thunks";

import usePopup from "../popup/usePopup";
import useUrlSearchParams from "../utils/useUrlSearchParams";
import useHubSpotUser from "../hubSpot/useHubSpotUser";
import navigation from "../../constants/navigation";

const useHubSpot = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { backToHubSpot, removeToken, redirectTokenHubSpot } = useHubSpotUser();

  const token = useUrlSearchParams("token");
  const error = useUrlSearchParams("error");
  const extId = useUrlSearchParams("ext_id");
  const deliveryCost = useUrlSearchParams("delivery_cost");
  const country = useUrlSearchParams("country");
  const countryId = useUrlSearchParams("country_id");
  const fname = useUrlSearchParams("first_name");
  const lname = useUrlSearchParams("last_name");
  const hs_token = useUrlSearchParams("hs_token");
  const city = useUrlSearchParams("city");
  const address_id = useUrlSearchParams("address_id");
  const email = useUrlSearchParams("email");
  const phone = useUrlSearchParams("phone");

  const address1 = useUrlSearchParams("address1");
  const address2 = useUrlSearchParams("address2");
  const zip = useUrlSearchParams("zip");
  const state = useUrlSearchParams("state");
  const business_name = useUrlSearchParams("business_name");

  const autoFillRecipientForm = useMemo(() => {
    if (
      fname ||
      lname ||
      country ||
      countryId ||
      extId ||
      deliveryCost ||
      address_id ||
      address1 ||
      address2 ||
      zip ||
      state ||
      email ||
      business_name ||
      phone
    ) {
      return {
        first_name: fname || "",
        last_name: lname || "",
        country: country || "",
        country_id: countryId || "",
        ext_id: extId || "",
        delivery_cost: deliveryCost || "",
        hs_token: hs_token || "",
        city: city || "",
        token: token || "",
        address_id: address_id || "",
        address1: address1 || "",
        address2: address2 || "",
        zip: zip || "",
        state: state || "",
        business_name: business_name || "",
        email: email || "",
        phone: phone || "",
        tag: [],
      };
    }
    return null;
  }, []);

  useEffect(() => {
    if (hs_token) {
      localStorage.setItem("hs_token", hs_token);
    }
  }, [hs_token]);

  const { openErrorPopup } = usePopup();

  const errorMessage = useCallback(async () => {
    await openErrorPopup(error || "Something went wrong");
    return backToHubSpot();
  }, [backToHubSpot, openErrorPopup, error]);

  useEffect(() => {
    if (autoFillRecipientForm) {
      (async () => dispatch(setHubSpotInfo(autoFillRecipientForm)))();
      history.push(navigation.home);
    }
  }, [autoFillRecipientForm]);

  const loginUserAtHubSpot = useCallback(
    async (token: string) => {
      const { meta, payload } = await dispatch(signIn({ token }));
      if (meta.requestStatus === "fulfilled") {
        await dispatch(basketCount());

        if (!redirectTokenHubSpot && hs_token) {
          return history.push({
            pathname: "/",
          });
        }
        if (redirectTokenHubSpot) {
          return history.push({
            pathname: navigation.hubspot,
            search: `?token=${token}`,
          });
        }
        if (!redirectTokenHubSpot) {
          return history.push({
            pathname: navigation.hubspot,
          });
        }
      }

      await openErrorPopup(
        (meta.requestStatus === "rejected" && payload?.message!) ||
          "Something went wrong"
      );
      removeToken();
      return history.push(navigation.auth.signin);
    },
    [history, dispatch, basketCount, openErrorPopup, signIn, token, hs_token]
  );
  useEffect(() => {
    if (hs_token) {
      (async () => dispatch(setHsToken(hs_token)))();
    }
    if ((token && hs_token) || (token && !redirectTokenHubSpot)) {
      loginUserAtHubSpot(token);
    }
  }, [token, hs_token]);

  useEffect(() => {
    if (error) {
      errorMessage();
    }
  }, [error]);
};

export default useHubSpot;
