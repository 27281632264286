import { RootState } from "../store";
import { addressAdapter } from "./entity";

export const { selectAll: selectAllAddresses, selectById: selectAddressById } =
  addressAdapter.getSelectors((state: RootState) => state.address);
export const getAddressLoader = (state: RootState) =>
  state.address.isLoadingAddressList;

export const getEditAddressId = (state: RootState) => state.address.editId;
export const getDefaultAddress = (state: RootState) =>
  state.address.defaultAddress?.address || {};
export const getDefaultPhysicalAddress = (state: RootState) =>
  state.address.billing_info;
