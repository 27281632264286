import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import getCategories from "../../redux/categories/selectors";
import fetchCategories from "../../redux/categories/thunks";

import { ICategory } from "../../interfaces/categories/ICategories";
import navigation from "../../constants/navigation";

const useCategories = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const categories = useAppSelector(getCategories);

  useEffect(() => {
    if (
      (location.pathname.includes(navigation.category) ||
        location.pathname.includes(navigation.outboundProspecting) ||
        location.pathname.includes(navigation.automationCards) ||
        location.pathname.includes(navigation.addNewAutomationCards)) &&
      !categories.length
    ) {
      dispatch(fetchCategories());
    }

    if (location.pathname === navigation.home) {
      dispatch(fetchCategories());
    }
  }, [location.pathname]);

  const categoriesToList = useMemo(() => {
    const commonCategoriesList: ICategory[] = [];
    const customCategoriesList: ICategory[] = [];

    categories.forEach((category) => {
      const cat: ICategory = {
        ...category,
        id: category.id,
        to:
          category.slug === " "
            ? navigation.home
            : `/${category.slug.toLowerCase().replace(" ", "-")}`,
        name: category.name,
      };
      if (category.taxonomy.toLowerCase() === "customized") {
        customCategoriesList.push(cat);
      } else {
        commonCategoriesList.push(cat);
      }
    });

    return {
      commonCategoriesList,
      customCategoriesList,
    };
  }, [categories]);

  return {
    categories: categoriesToList,
  };
};

export default useCategories;
