export type EditId = null | number;

export interface IShippingMethods {
  ratesList: IShippingRate[];
  methodsList: IShippingMethod[];
  ratesError: string | null;
}

export interface IShippingMethod {
  add_postage_fee: number;
  check_recipient_address: number;
  check_return_address: number;
  discount: string;
  id: number;
  method_label: string;
  method_name: string;
  show_options: number;
  status: number;
}

export interface IShippingRate {
  id: number;
  name: string;
  sort_order: number;
  fee: number;
  earliest_business_days: number;
}

export interface INotes {
  shippingDate: Date | null;
  shippingAddress: number;
}

export const initialState: IShippingMethods = {
  ratesList: [],
  methodsList: [],
  ratesError: null,
};
