import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import shippingApi from "../../api/shipping.api";
import { IShippingRateRequest } from "../../interfaces/shipping/IShipping";

export const getShippingRatesList = createAsyncThunk<any, IShippingRateRequest>(
  "shipping/rates",
  async (data, { rejectWithValue }) => {
    try {
      const res = await shippingApi.list(data);
      if (res) return res.data.list;
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    }
  }
);

export const getShippingMethodsList = createAsyncThunk<any, boolean>(
  "shipping/methods",
  async (isOutbound) => {
    const res = await shippingApi.methodsList(isOutbound);

    return res.data.methods;
  }
);
