import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./entity";
import couponGet from "./thunks";

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    getCouponValue: (state, action) => {
      state.couponCode = action.payload as string;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(couponGet.fulfilled, (state, { payload }) => {
      state.coupon = payload;
    });
    builder.addCase(couponGet.rejected, (state, { payload }) => {
      state.error = payload?.message;
    });
  },
});

export const { reset, getCouponValue } = couponSlice.actions;

export default couponSlice.reducer;
