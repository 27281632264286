import { ICustomCheckbox } from "../../components/custom-checkbox/custom.checkbox";

interface IImageResolutions {
  cover_min_width: number;
  cover_min_height: number;
  back_min_height: number;
}

interface ICustomCard {
  coverPercent: number;
  headerFontSizeMin: number;
  headerFontSizeMax: number;
  footerFontSizeMin: number;
  footerFontSizeMax: number;
  maxSize: number;
}

interface ITab {
  title: string;
  id: string;
}

const imageResolutions: IImageResolutions = {
  cover_min_width: 2025,
  cover_min_height: 1425,
  back_min_height: 225,
};

const customCard: ICustomCard = {
  coverPercent: 100,
  headerFontSizeMin: 5,
  headerFontSizeMax: 50,
  footerFontSizeMin: 5,
  footerFontSizeMax: 30,
  maxSize: 540,
};

const tabs: ITab[] = [
  { title: "header", id: "HEADER" },
  { title: "footer", id: "FOOTER" },
  { title: "qr code", id: "QR CODE" },
];

const tabsInsideFoldedCard: ITab[] = [
  { title: "top", id: "TOP" },
  { title: "bottom", id: "BOTTOM" },
  { title: "qr code", id: "QR CODE" },
];

const tabsBackFoldedCard: ITab[] = [
  { title: "cover", id: "COVER" },
  { title: "footer/logo", id: "FOOTER/LOGO" },
];
const maxIMGSize = 20000000;

const lockSize: ICustomCheckbox = { id: 1, label: "Lock Font Size" };

const fontSizeHeader = {
  min: 5,
  max: 50,
};
const fontSizeFooter = {
  min: 5,
  max: 30,
};

const initialHeaderQrCodeSize = 57;

export default {
  imageResolutions,
  customCard,
  tabs,
  lockSize,
  maxIMGSize,
  tabsInsideFoldedCard,
  tabsBackFoldedCard,
  fontSizeHeader,
  fontSizeFooter,
  initialHeaderQrCodeSize,
};
