const typePopups = {
  USE_TEMPLATE: "use-template",
  SAVE_TEMPLATE: "save-template",
  RECIPIENTS_DEFAULT_ADDRESS: "recipient.defaultAddress",
  RECIPIENT_EDIT: "recipient.edit",
  RECIPIENT_CREATE: "recipient.create",
  PASSWORD_EDIT: "password.edit",
  PASSWORD_RESET_REQUEST_FORM: "password-reset-request.form",
  PASSWORD_RESET_CONFIRM_FORM: "password-reset-confirm.form",
  INVALID_COUPON: "invalid-coupon.popup",
  EMAIL_CHANGE: "email.change",
  CUSTOM_CREATE_SUCCESS: "custom-cards.success.create.popup",
  CONVERT_EMAIL: "convert.email",
  ADDRESS_PHYSICAL: "address.physical",
  ADDRESS_EDIT: "address.edit",
  LOG_IN: "log-in",
  ADD_CUSTOMIZATION_CARD: "add-customization-card.save.popup",
  TEMPLATE_LIST: "templates/template-use/template-use.list",
  TEMPLATE_ITEM: "templates/template-use/template-use.item",
  RETURN_ADDRESS_LIST: "return-address.list",
  RETURN_ADDRESS_SELECT: "return-address.select",
  CHOOSE_CAMPAIGN: "chooseCampaign.popup",
  AI_ASSIST: "ai-assist.popup",
  PROCEED_ANYWAY: "proceed-anyway.popup",
  CREATE_QR_CODE: "create-QR.popup",
  SELECT_CARD: "select-card.popup",
  ADD_TAG: "addTag",
  SELECT_TAGS: "select-tags.popup",
  REQUEST_MAILING_ADDRESS: "request-mailing-address.popup",
  CUSTOM_FIELDS: "customFields",
};

export default typePopups;
