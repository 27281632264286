import { IDefaultResponse } from "../interfaces/axios/IDefaultResponse";
import { IBillingInfo } from "../interfaces/credit-cards/IBillingInfo";
import { IChangeEmail } from "../interfaces/profile/IEmailChange";
import { IChangePassword } from "../interfaces/profile/IProfile";
import { IChangePasswordResponse } from "../interfaces/profile/responses/IChangePasswordResponse";
import { IGetCreditsResponse } from "../interfaces/profile/responses/IGetCreditsResponse";
import { IHandleAttachInvoice } from "../interfaces/profile/responses/IHandleAttachInvoice";
import {
  IAddTagResponse,
  IGetTagsResponse,
} from "../interfaces/profile/responses/ITagsResponse";
import { IAddTag } from "../redux/recipient-tags/types";
import { IUpdateRecipientsTags } from "../interfaces/profile/IUpdateRecipientsTags";
import { userInstance } from "../hooks/instance/useInstance";
import {
  IRequestMailingAddressPayload,
  ISendAddressPayload,
} from "../interfaces/profile/IRequestMailingAddress";
import downloader from "../helpers/file-download";

const profileApi = {
  updateBillingInfo: async (payload: IBillingInfo) => {
    return userInstance.post("/profile/updateBillingInfo", { ...payload });
  },
  changePassword: async (payload: IChangePassword) => {
    return userInstance.post<IChangePasswordResponse>(
      "/profile/changePassword",
      { ...payload }
    );
  },
  changeEmail: async (payload: IChangeEmail) => {
    return userInstance.post<IDefaultResponse>("/profile/actionChangeEmail", {
      ...payload,
    });
  },
  getCredits: async () => {
    return userInstance.get<IGetCreditsResponse>("/profile/credits");
  },
  handleAttachInvoice: async () => {
    return userInstance.post<IHandleAttachInvoice>(
      "/profile/changeAttachInvoice"
    );
  },
  getTags: async (include_addresses?: boolean) => {
    return userInstance.get<IGetTagsResponse>(
      `/profile/tags?include_addresses=${include_addresses}`
    );
  },
  updateRecipientsTags: async (payload: IUpdateRecipientsTags) => {
    return userInstance.post("/profile/handleTags", {
      ...payload,
    });
  },
  createTag: async (payload: IAddTag) => {
    return userInstance.post<IAddTagResponse>("/profile/tags", payload);
  },
  deleteTag: async (id: number) => {
    return userInstance.delete(`/profile/tags/${id}`);
  },
  requestMailingAddress: async (
    payload: IRequestMailingAddressPayload | FormData
  ) => {
    return userInstance.post(`/profile/mailingAddress/request`, payload);
  },
  sendAddress: async (payload: ISendAddressPayload) => {
    return userInstance.post(`/profile/mailingAddress`, payload);
  },
  checkToken: async (token: string) => {
    return userInstance.get(`/profile/mailingAddress/${token}`);
  },
  exportRecipientsList: async () => {
    const result = await userInstance.get("/profile/exportRecipients", {
      responseType: "blob",
    });
    downloader(result.data, result.headers["file-name"]);
  },
};

export default profileApi;
