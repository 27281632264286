import styled from "styled-components";

const SelectStyled = styled.select`
  background-color: ${(props) => props.theme.medium};
  border: none;
  outline: none;
  width: 100%;
  font-size: 100%;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export default SelectStyled;
