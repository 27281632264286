import { FC, memo } from "react";
import { useLocation } from "react-router";
import classNames from "classnames";

import useHubSpotUser from "../../../hooks/hubSpot/useHubSpotUser";

import { FlexContainer } from "../../ui/layout";
import CardButtonsAction from "./card.buttons-action";
import navigation from "../../../constants/navigation";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface ICardButtons {
  onSendClick: () => void;
  onSendBulkClick: () => void;
  onCustomize: () => void;
  isPreview: boolean;
  isCustomize: boolean;
  isCustom: boolean;
  openDeletePopup: () => void;
  allowEdit?: boolean;
  onSelectCard?: () => void;
}

const CardButtons: FC<ICardButtons> = ({
  onSendClick,
  onSendBulkClick,
  isPreview,
  isCustomize,
  onCustomize,
  isCustom,
  openDeletePopup,
  allowEdit,
  onSelectCard,
}) => {
  const location = useLocation();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const { hubSpotUse } = useHubSpotUser();

  const cardButtons = classNames({
    card__buttons: !(
      isMobile && location.pathname.includes(navigation.category)
    ),
    category__buttons:
      isMobile && location.pathname.includes(navigation.category),
    "card__buttons-detail": isPreview && isMobile,
  });

  return (
    <FlexContainer
      direction={isCustom ? "column" : "row"}
      justify='space-between'
      className={cardButtons}
    >
      <CardButtonsAction
        isCustomize={isCustomize}
        isCustom={isCustom}
        allowEdit={!!allowEdit}
        hubSpotUse={hubSpotUse}
        openDeletePopup={openDeletePopup}
        isPreview={isPreview}
        onSelectCard={onSelectCard}
        onSendBulkClick={onSendBulkClick}
        onSendClick={onSendClick}
        onCustomize={onCustomize}
      />
    </FlexContainer>
  );
};

export default memo(CardButtons);
