import { createEntityAdapter } from "@reduxjs/toolkit";
import { ITemplate } from "../../interfaces/templates/ITemplate";
import { ITemplatesState } from "./types";

export const templatesAdapter = createEntityAdapter<ITemplate>({
  selectId: (template) => template.id,
});

export const initialState = templatesAdapter.getInitialState<ITemplatesState>({
  editId: null,
  createIds: [],
  isLoading: false,
  activeTemplateId: null,
  activeTemplate: null,
  activeEditTemplate: null,
});
