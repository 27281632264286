import { memo, useMemo, FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import cart from "../../assets/icons/icons/cart-ic.svg";
import navigation from "../../constants/navigation";
import { useAppSelector } from "../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

import "./style.scss";

interface ICart {
  count?: number;
}

const Cart: FC<ICart> = ({ count }) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const bigCount = useMemo(() => {
    const index = isMobile ? 0.8 : 1.1;
    const number = (count?.toString().length ?? 0) / 10;

    const step = index + number;
    const topCircle = isMobile ? 1.1 : 1;
    const mobileFontSize = 0.61111 - number / 2;
    const fontSize = !!count && count?.toString().length >= 5 ? 0.45 : 0.61111;

    return {
      width: step,
      height: step,
      top: topCircle,
      mobileFontSize,
      fontSize,
    };
  }, [count, isMobile]);

  const cartStyle = classNames({
    cart: true,
    cart__circle: !!count,
  });

  return (
    <Link to={navigation.myBasket}>
      <div
        className={cartStyle}
        style={{
          // @ts-ignore
          "--count": `${count}`,
          "--width": `${bigCount.width}rem`,
          "--height": `${bigCount.height}rem`,
          "--top": `${bigCount.top}rem`,
          "--mobileFontSize": `${bigCount.mobileFontSize}rem`,
          "--fontSize": `${bigCount.fontSize}rem`,
        }}
      >
        <img className='cart__image' src={cart} alt='cart' />
        <span className='cart__counter' />
      </div>
    </Link>
  );
};

export default memo(Cart);
