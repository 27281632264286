import { RootState } from "../store";

export const getCardImage = (state: RootState) =>
  state.order.card?.cover || null;
export const getCardName = (state: RootState) => state.order.card?.name;
export const getDiscount = (state: RootState) =>
  state.order.card?.discounts_table || [];
export const getPrice = (state: RootState) => state.order.card?.price;

export const getDiscountPrice = (state: RootState) =>
  state.order.card?.discount_price;

export const selectOrder = (state: RootState) => state.order;
export const selectLoaderCard = (state: RootState) => state.order.isLoader;
export const selectActiveOrder = (state: RootState) => state.order.activeOrder;
export const selectCardById = (state: RootState) => state.order.card;
export const getSizeOffsetSignature = (state: RootState) =>
  state.order.activeOrder?.size_offset || 0;
