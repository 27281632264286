import styled from "styled-components";

import { ITypography } from "../../interfaces/typography/ITypography";

export const TypographyH1 = styled.h1<ITypography>`
  font-family: aktiv-grotesk, sans-serif;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  font-style: ${(props) => props.fontStyle};
  line-height: ${(props) => props.lineHeight};
  text-decoration: ${(props) => props.decoration};
  margin: ${(props) => props.margin || ""};
  color: ${(props) => props.color || "#020f14"};
`;

export const Typography = styled.span<ITypography>`
  font-family: aktiv-grotesk, sans-serif;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  font-style: ${(props) => props.fontStyle};
  line-height: ${(props) => props.lineHeight};
  text-decoration: ${(props) => props.decoration};
  margin: ${(props) => props.margin || ""};
  color: ${(props) => props.color || "#020f14"};
`;

export const Title = styled(Typography)`
  font-weight: 300;
  font-size: 1.3333rem;

  @media (max-width: 599px) {
    font-size: 1.11111rem;
  }
`;

export const Overline = styled(Typography)`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.2px;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color || "#221d1d"};
`;

export const NavLink = styled(Typography)`
  display: inline-block;

  &:after {
    display: block;
    content: "";
    border-bottom: 1px solid ${(props) => props.theme.primary};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};

  color: ${(props) => props.color || props.theme.darkLight};
`;

export const TextWithLines = styled(Typography)`
  width: 100%;
  margin: 10px 0 20px;

  border-bottom: 1px solid ${(props) => props.theme.dark};
  line-height: 0.1em;

  text-align: center;
  text-transform: uppercase;

  span {
    background: #fff;
    padding: 0 10px;
  }
`;

NavLink.defaultProps = {
  fontSize: "12.5px",
};

Overline.defaultProps = {
  fontSize: "13px",
};
