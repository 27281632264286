import { IDataTab } from "../../pages/customizable/tabs/tabs";

export enum automationTypes {
  BIRTHDAY = "BIRTHDAY",
  ANNIVERSARY = "ANNIVERSARY",
}

export interface IAutomationsEdit {
  name: string;
  type: string;
}

export type automationTypesConstant = "BIRTHDAY" | "ANNIVERSARY";

export const automationTabs: IDataTab[] = [
  {
    title: "Birthday Card Automation",
    tabListTitle: "Birthday Automation List",
    id: automationTypes.BIRTHDAY,
  },
  {
    title: "Anniversary Card Automation",
    tabListTitle: "Anniversary Automation List",
    id: automationTypes.ANNIVERSARY,
  },
];

export default {
  automationTabs,
  automationTypes,
};
