import {
  FC,
  useState,
  FormEvent,
  useCallback,
  useEffect,
  CSSProperties,
} from "react";

import { useAppDispatch, useAppSelector } from "../../hooks/redux/useRedux";
import useInput from "../../hooks/input/useInput";
import searchIcon from "../../assets/icons/icons/search-icon.svg";

import {
  setSearchCards,
  setInitCardsSection,
  setDefaultSearchCards,
  setInitCardsCategory,
} from "../../redux/cards/slice";
import {
  getCardsSections,
  getCardsListByCategory,
} from "../../redux/cards/thunks";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

import { Input } from "../ui/common/inputs/input.common";
import { Form } from "../ui/layout";
import usePrevious from "../../hooks/usePrevious";

import "./style.scss";

interface IHomeSearchProps {
  style?: CSSProperties;
  hideMenu?: () => void;
  isOutbound?: boolean;
  isAutomation?: boolean;
  category?: number;
  classes?: string;
}

const HomeSearch: FC<IHomeSearchProps> = ({
  style,
  hideMenu,
  isOutbound = false,
  category,
  isAutomation = false,
  classes,
}) => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const [search, setSearch] = useState(false);

  const searchInput = useInput("");

  const previousSearchValue = usePrevious(searchInput.value);

  const setDefaultCategorySearch = useCallback(async () => {
    await dispatch(setInitCardsCategory());
    await dispatch(setSearchCards(""));
  }, [category]);

  useEffect(() => {
    if (isOutbound) {
      searchInput.setValue("");
      setDefaultCategorySearch();
    }
  }, [category]);

  const setDefaultParameters = useCallback(
    async (resetCards: boolean = true) => {
      setSearch(false);
      searchInput.onChange("");
      await dispatch(setDefaultSearchCards());

      if (resetCards) await dispatch(setInitCardsSection());
    },
    [
      dispatch,
      searchInput,
      setSearch,
      setInitCardsSection,
      setDefaultSearchCards,
    ]
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (isMobile && hideMenu) {
        hideMenu();
      }
      if (!searchInput.value && !previousSearchValue) return;

      if (!searchInput.value && !isOutbound) await setDefaultParameters();

      if (!searchInput.value && isOutbound) await setDefaultCategorySearch();
      const nextRenderLimit = 6;

      if (!searchInput.value.trim().length && !search) return;
      if (!searchInput.value.trim().length && !isOutbound) {
        await dispatch(setSearchCards(searchInput.value));
        return dispatch(
          getCardsSections({
            nextPage: 1,
            limit: nextRenderLimit,
            search: searchInput.value.trim(),
          })
        );
      }

      setSearch(true);

      if (isOutbound) {
        await dispatch(setInitCardsCategory());
        await dispatch(setSearchCards(searchInput.value));
        await dispatch(
          getCardsListByCategory({
            categoryId: category!,
            nextPageCategory: 1,
            limit: 10,
            search: searchInput.value.trim(),
          })
        );
      } else {
        await dispatch(setInitCardsSection());
        await dispatch(setSearchCards(searchInput.value));
        return dispatch(
          getCardsSections({
            nextPage: 1,
            limit: nextRenderLimit,
            search: searchInput.value.trim(),
          })
        );
      }
    },
    [searchInput, dispatch, setSearchCards, isOutbound, previousSearchValue]
  );

  useEffect(() => {
    return () => {
      setDefaultParameters(false);
    };
  }, []);

  const clearSearch = useCallback(async () => {
    if (isOutbound) {
      await dispatch(setInitCardsCategory());
      searchInput.onChange("");
      await dispatch(setSearchCards(""));
      return dispatch(
        getCardsListByCategory({
          categoryId: category!,
          nextPageCategory: 1,
          limit: 3,
        })
      );
    }
    if (isMobile && hideMenu) {
      hideMenu();
    }
    await setDefaultParameters();
    dispatch(
      getCardsSections({
        nextPage: 1,
        limit: 6,
      })
    );
  }, [
    setDefaultParameters,
    dispatch,
    getCardsSections,
    isOutbound,
    searchInput,
    setSearchCards,
    setInitCardsCategory,
    getCardsListByCategory,
    category,
  ]);

  return (
    <Form
      className={classes}
      onSubmit={onSubmit}
      style={
        !isOutbound
          ? { width: "100%", ...style }
          : {
              ...style,
              marginLeft: isMobile ? "0" : "2rem",
              width: isMobile ? "100%" : "30%",
            }
      }
    >
      <Input
        className={
          !isOutbound ? "home-search-container" : "outbound-search-container"
        }
        inputClass={!isOutbound ? "home-search-input" : ""}
        placeholder='Search'
        type='text'
        {...searchInput}
        clear={clearSearch}
        search={search}
        style={{ height: "inherit" }}
      >
        <button
          type='submit'
          className='search-button'
          style={
            isOutbound && !isAutomation && !isMobile ? { right: "1rem" } : {}
          }
          onClick={onSubmit}
        >
          <img src={searchIcon} alt='cart' />
        </button>
      </Input>
    </Form>
  );
};

export default HomeSearch;
