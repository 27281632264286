import { createAsyncThunk } from "@reduxjs/toolkit";

import cardsApi from "../../api/cards.api";
import { RootState } from "../store";
import { ISectionData, ICategoryCard } from "../../interfaces/cards/ICard";
import {
  IToggleFavoriteAction,
  IToggleFavoriteResponse,
  IPageCategory,
  IPageAllCategory,
} from "./types";

export const getCards = createAsyncThunk("cards/list", async () => {
  await cardsApi.list();
});

export const getCardsSections = createAsyncThunk<
  ISectionData,
  IPageAllCategory,
  { rejectValue: boolean; state: RootState }
>("cards/sections", async (category) => {
  const res = await cardsApi.sections(category);

  const { cards, total_pages, page } = res.data;

  return { cards, pages: total_pages, page };
});

export const getRandomCards = createAsyncThunk("cards/random", async () => {
  await cardsApi.random();
});

export const getCardsListByCategory = createAsyncThunk<
  ICategoryCard,
  IPageCategory,
  { rejectValue: boolean; state: RootState }
>("cards/list", async (categoryId, { getState }) => {
  const state = getState();

  // in all categories page we need to get cursor from section
  const currentCard = state.cards.cards.find(
    (card) => card.id === categoryId.categoryId
  );

  // if it is card list by category we get cursor from response of previous request to cards list
  const cursorForList =
    state.cards.cursor?.categoryId === categoryId.categoryId
      ? state.cards.cursor?.value
      : null;

  const currentCursor = state.cards.cards.length
    ? currentCard?.cursor || null
    : cursorForList;

  const { isDelete, categoryId: category } = categoryId;

  const res = await cardsApi.cardsByCategory(categoryId, currentCursor);
  const { cards, total_pages, page, total_items } = res.data;

  return {
    cards,
    total_pages,
    page,
    total_items,
    isDelete,
    category,
    search: !!categoryId.search,
    cursor: res.data?.cursor || null,
  };
});

export const toggleFavoriteCard = createAsyncThunk<
  IToggleFavoriteResponse,
  IToggleFavoriteAction
>("cards/favorite", async ({ cardId, is_home_page, categoryId }) => {
  const res = await cardsApi.favoriteCardToggle(cardId, is_home_page);

  const { favorite_cards, favorite_card, card_id, is_like } = res.data;

  return {
    favorite_cards,
    favorite_card,
    card_id,
    is_like,
    category_id: categoryId,
  };
});
