import { ChangeEvent, useCallback, useState } from "react";

export type InputEvent =
  | ChangeEvent<HTMLInputElement>
  | ChangeEvent<HTMLTextAreaElement>;

export interface IInputInstance {
  value: string;
  onChange: (event: InputEvent | string) => void;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const useInput = (
  initialValue: string,
  maxCharacters?: number,
  pattern?: RegExp,
  password?: string
) => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(
    (event: InputEvent | string) => {
      if (typeof event === "string") {
        setValue(event);
        return;
      }

      if (pattern && !password) {
        const regExpValue = event.target.value.replace(pattern, "");
        return setValue(regExpValue);
      }

      const inputValue = event?.target?.value || event?.target?.innerHTML || "";
      setValue(inputValue);
    },
    [pattern]
  );

  return {
    value,
    onChange,
    setValue,
  };
};

export default useInput;
