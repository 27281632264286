import {
  FC,
  memo,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { useHistory, generatePath } from "react-router-dom";
import { useLocation } from "react-router";

import { isSafari } from "react-device-detect";
import Lottie from "react-lottie";
import classNames from "classnames";

import CardControl from "./card.control";
import { CardHeader } from "./card.header";
import CardButtons from "./card.buttons";

import useCard from "../../../hooks/cards/useCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import * as WriteMessageAim from "../../../assets/json/Write_your_message_here.json";
import { FlexContainer, BoxContainer } from "../../ui/layout";
import CustomLoader from "../../ui/common/loaders/custom.loader";
import { ICardItem } from "../../../interfaces/cards/ICard";

import { addToPreview } from "../../../redux/preview/slice";
import deleteCustomCard from "../../../redux/design/thunks";
import {
  selectIsCardsListEndCategory,
  getCardsByCategory,
} from "../../../redux/cards/selectors";
import { setRenderWithoutCustom } from "../../../redux/cards/slice";
import {
  getCardsListByCategory,
  toggleFavoriteCard,
} from "../../../redux/cards/thunks";
import { isAuth } from "../../../redux/auth/selectors";
import navigation from "../../../constants/navigation";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";
import correctPathName from "../../../helpers/correctPathName";
import {
  getIsDesktopWindowDimensions,
  getIsMobileWindowDimensions,
  getIsTabletWindowDimensions,
} from "../../../redux/window-dimensions/selectors";

import "./style.scss";

const CardItem: FC<ICardItem> = ({
  masonry,
  name,
  widthCard,
  price,
  discount_price,
  isFavorite,
  cover,
  backImage,
  id,
  insideImage,
  isPreview,
  category,
  orientation,
  cardLength,
  setRender,
  categoryId,
  isCustomize = false,
  isCustom = false,
  allowEdit = false,
  isAllCategories,
  isOutboundLeads,
  onSelectCard,
  setTotalLength,
  isVisibleByDefault = false,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const imageRef = useRef<HTMLImageElement>(null);
  const listCardsRef = useRef<HTMLImageElement>(null);

  const { openConfirmationPopup } = useContext(ConfirmationPopupContext);

  const cards = useAppSelector(getCardsByCategory);
  const isAuthUser = useAppSelector(isAuth);
  const isListEndCategory = useAppSelector(selectIsCardsListEndCategory);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const isTablet = useAppSelector(getIsTabletWindowDimensions);
  const isDesktop = useAppSelector(getIsDesktopWindowDimensions);

  // visible, when the client scroll to this card, then we get fetch for images
  // need to show all images on the mobile, because we have an issue with render cards
  const [isVisible, setIsVisible] = useState<boolean>(
    isMobile || isVisibleByDefault
  );
  const [favorite, setFavorite] = useState<boolean>(!!isFavorite);
  const [direction, setDirection] = useState<boolean>();
  const [showAnimation, setShowAnimation] = useState<boolean>(false);

  const { view, writing, resetView, changeView } = useCard();

  const homeLocations = useMemo(() => {
    return location.pathname === "/";
  }, [location]);

  const options = {
    root: listCardsRef.current,
    threshold: 0,
    rootMargin: "0px",
  };

  const observer = new IntersectionObserver(([target]) => {
    if (target.isIntersecting) {
      let needToRefresh = false;
      setIsVisible((prev) => {
        needToRefresh = prev;
        return !prev;
      });

      if (needToRefresh) {
        setIsVisible(true);
      }

      if (window.navigator.onLine) {
        observer.unobserve(target.target);
      }
    }
  }, options);

  useEffect(() => {
    if (listCardsRef.current) {
      observer.observe(listCardsRef.current);
    }
  }, [listCardsRef]);

  useEffect(() => {
    if (orientation === "P") {
      setDirection(true);
    }
    if (orientation === "L") {
      setDirection(false);
    }
  }, [orientation]);

  const previewCard = classNames({
    postcard__side: true,
    postcard__side_vertical: direction,
    postcard__side_horizontal: !direction,
    back: view === "back",
    inside: view === "inside",
  });

  const card = classNames({
    "outbound-prospecting-card": isOutboundLeads,
    card: !(isMobile && location.pathname.includes(navigation.category)),
    category: !!(isMobile && location.pathname.includes(navigation.category)),
    "card__detail-preview": isMobile && isPreview,
    "select-popup-card": onSelectCard && !isAllCategories,
  });

  const cardActions = classNames({
    card__actions: masonry
      ? isMobile && location.pathname.includes(navigation.category)
      : !(isMobile && location.pathname.includes(navigation.category)),
    category__actions:
      isMobile && location.pathname.includes(navigation.category),
    card__custom: isCustom,
  });

  const _addToPreview = () => {
    if (isMobile && !location.pathname.includes(navigation.category)) {
      dispatch(
        addToPreview({
          name,
          price,
          discount_price,
          id,
          isFavorite,
          cover,
          isPreview: true,
          category,
          categoryId,
          backImage,
          insideImage,
          orientation,
          isCustomize,
          isCustom,
          allowEdit,
        })
      );
    }
  };
  const onSendBulkClick = () => {
    history.push(`${navigation.bulkSend}/${id}`);
  };

  const onSendClick = () => {
    const correctCategory = correctPathName(category);
    const correctCardName = correctPathName(name);

    history.push(
      `${generatePath(navigation.send, {
        cardId: id,
        category: correctCategory,
        cardName: correctCardName,
      })}`
    );
  };

  const onCustomize = () => {
    if (isAuthUser) history.push(`${navigation.customizable}/${id}`);
    else history.push(navigation.auth.signin);
  };

  const backPreview = useMemo(() => {
    return orientation === "F";
  }, [orientation]);

  const previewInMobil = useMemo(() => {
    return isPreview && isMobile;
  }, [isPreview, isMobile]);

  const limit = useMemo(() => {
    return 1;
  }, [isMobile, isTablet]);

  const toggleFavorite = useCallback(async () => {
    if (!isAuthUser) {
      history.push(navigation.auth.signin);
      return;
    }

    await dispatch(
      toggleFavoriteCard({
        cardId: id,
        categoryId: categoryId!,
        is_home_page: homeLocations,
      })
    );
    if (categoryId === 0) {
      await dispatch(
        getCardsListByCategory({
          categoryId,
          nextPageCategory: cards.length + 1,
          limit,
        })
      );
    }

    if (previewInMobil) {
      setFavorite(!favorite);
    }

    const isMultiStepPage =
      location.pathname.includes(navigation.addNewMultiStep) ||
      location.pathname.includes(navigation.editMultiStep);

    if (categoryId === 0 && !isMultiStepPage) {
      if (isListEndCategory && !cardLength) {
        return history.push(navigation.home);
      }
    }
  }, [
    isListEndCategory,
    categoryId,
    cardLength,
    history,
    backImage,
    isAuthUser,
    id,
    categoryId,
    _addToPreview,
    setFavorite,
    homeLocations,
    favorite,
    previewInMobil,
  ]);

  const previewInImage = useMemo(() => {
    return backPreview ? insideImage! : backImage!;
  }, [backPreview, insideImage, backImage]);

  const deleteCard = async () => {
    const { meta } = await dispatch(deleteCustomCard(id));

    if (meta.requestStatus === "fulfilled" && setTotalLength) {
      if (id && isFavorite) {
        await toggleFavorite();
      }
      setTotalLength((prev: number) => prev - 1);
    }

    if (location.pathname === "/" && !cardLength) {
      return dispatch(setRenderWithoutCustom());
    }

    if (categoryId === 0 || categoryId === 27) {
      if (isListEndCategory && !cardLength) {
        return history.push(navigation.home);
      }
      await dispatch(
        getCardsListByCategory({
          categoryId,
          nextPageCategory: cards.length + 1,
          limit,
          isDelete: true,
        })
      );
    }
  };

  const openDeletePopup = () => {
    openConfirmationPopup({
      subtitle: "Are you sure you want to delete?",
      onConfirm: deleteCard,
    });
  };

  const allCategoryTrue = useMemo(() => {
    return isAllCategories || location.pathname === "/";
  }, [location, isAllCategories]);

  const getBackImage = useMemo(() => {
    if (orientation === "F") {
      const style = isVisible
        ? {
            backgroundImage: `url(${previewInImage})`,
            backgroundPosition: "center center",
            backgroundSize: `${isCustom ? "contain" : "cover"}`,
            backgroundRepeat: "no-repeat",
            height: "100%",
          }
        : {};
      return (
        <FlexContainer
          className='back-face'
          style={style}
          justify='center'
          width='100%'
        >
          {showAnimation ? (
            <Lottie
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              options={{
                loop: false,
                autoplay: true,
                // @ts-ignore
                animationData: WriteMessageAim.default,
              }}
              isStopped={!writing}
              height='50%'
              width='50%'
            />
          ) : (
            <></>
          )}
        </FlexContainer>
      );
    }
    return (
      <img
        className='back-face'
        alt='Back'
        src={isVisible ? previewInImage : ""}
      />
    );
  }, [
    orientation,
    previewInImage,
    isMobile,
    isTablet,
    isDesktop,
    showAnimation,
  ]);

  const isFlat = useMemo(() => orientation === "F", [orientation]);

  const changeOrientationHandler = () => {
    const newView = isFlat ? "back" : "inside";
    changeView(newView, isFlat);
  };

  const resetOrientation = () => changeView("front", isFlat);

  // const changeOrientationHandler = () => {
  //   changeView(newView);
  // };
  //
  // const resetOrientation = () => changeView("front");

  const cardClickHandler = () => {
    if (isMobile) {
      if (onSelectCard) onSelectCard();
      else _addToPreview();
    }
  };

  useEffect(() => {
    // it is need to run animation, only when the client hover on the card
    if (isFlat && view === "back") setShowAnimation(true);
    else if (isFlat && view !== "back") setShowAnimation(false);
    else if (!isFlat && view === "inside") setShowAnimation(true);
    else if (!isFlat && view !== "inside") setShowAnimation(false);
  }, [isFlat, view]);

  return (
    <div style={{ width: widthCard! }}>
      <BoxContainer
        onClick={cardClickHandler}
        className={card}
        onMouseLeave={resetView}
        ref={listCardsRef}
        width={allCategoryTrue ? "" : "93%"}
      >
        <CardHeader
          name={name}
          price={price}
          discount_price={discount_price}
          isFavorite={previewInMobil ? favorite : !!isFavorite}
          toggleFavorite={toggleFavorite}
          isPreview={isPreview}
          isBirthday={isOutboundLeads}
        />
        <FlexContainer
          align='center'
          justify='center'
          className='postcard scene-3d'
          onMouseEnter={changeOrientationHandler}
          onMouseLeave={resetOrientation}
        >
          <FlexContainer
            align='center'
            justify='center'
            className={previewCard}
            style={isOutboundLeads ? { width: "90%", marginTop: "1rem" } : {}}
          >
            {isVisible ? (
              <img
                className='front-face'
                alt='Front'
                src={cover}
                ref={imageRef}
                onLoad={() => {
                  if (setRender) {
                    setRender(id);
                  }
                }}
                style={{ zIndex: 2 }}
              />
            ) : (
              <div style={{ zIndex: 100 }}>
                <CustomLoader />
              </div>
            )}

            <div
              className={isSafari ? "front-face" : "front-face inner-mask"}
            />
            <FlexContainer
              className='inside-face'
              style={
                isVisible
                  ? {
                      backgroundImage: `url(${insideImage || ""})`,
                      backgroundPosition: "bottom right",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      height: "100%",
                    }
                  : {}
              }
              justify='center'
              align='center'
              width='100%'
            >
              {showAnimation && (
                <Lottie
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  options={{
                    loop: false,
                    autoplay: true,
                    // @ts-ignore
                    animationData: WriteMessageAim.default,
                  }}
                  isStopped={!writing}
                  height='50%'
                  width='50%'
                />
              )}
            </FlexContainer>
            {getBackImage}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer className={cardActions} direction='column'>
          <CardControl
            orientation={orientation!}
            onClick={changeView}
            view={view}
          />
          <CardButtons
            onSendBulkClick={onSendBulkClick}
            onCustomize={onCustomize}
            onSendClick={onSendClick}
            isPreview={isPreview}
            isCustomize={isCustomize}
            isCustom={isCustom}
            openDeletePopup={openDeletePopup}
            allowEdit={allowEdit}
            onSelectCard={onSelectCard}
          />
        </FlexContainer>
      </BoxContainer>
    </div>
  );
};

export default memo(CardItem);
