export interface IApiKey {
  id: number;
  name: string;
  uid: string;
  user_id: number;
}

interface ApiKeysState {
  apiKeysList: Array<IApiKey>;
}

export interface IAddApiKeys {
  name: string;
}

export const initialState: ApiKeysState = {
  apiKeysList: [],
};
