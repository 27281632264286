import { RootState } from "../store";

export const getWidthWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.width;

export const getHeightWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.height;

export const getIsMobileWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.isMobile;

export const getIsTabletWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.isTablet;

export const getIsDesktopWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.isDesktop;

export const getIsMediumTabletWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.isMediumTablet;

export const getIsLargeTabletWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.isLargeTablet;

export const getIsSmallDesktopWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.isSmallDesktop;

export const getIsMediumDesktopWindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.isMediumDesktop;

export const getX1100WindowDimensions = (state: RootState) =>
  state.windowDimensions.window_dimensions.x1100;
