const bulkOrderTemplate =
  "https://cdn.handwrytten.com/assets/templates/handwryttenbulkimport.xlsx";

const bulkRecipientTemplate =
  "https://cdn.handwrytten.com/assets/templates/handwryttenaddressesimport.xlsx";

const bulkRequestMailingTemplate =
  "https://cdn.handwrytten.com/assets/templates/handwryttenbulkaddressrequest.xlsx";

const correctUrl = "cdn.handwrytten.com";

export {
  bulkOrderTemplate,
  correctUrl,
  bulkRecipientTemplate,
  bulkRequestMailingTemplate,
};
