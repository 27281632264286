import React, { FC, memo } from "react";

interface ISubscriptionMessageIssue {
  lostCredits?: number;
}

const SubscriptionMessageIssue: FC<ISubscriptionMessageIssue> = ({
  lostCredits,
}) => {
  return (
    <>
      <p style={{ lineHeight: "2" }}>
        There was an issue charging your credit card for your subscription.
      </p>
      <p style={{ lineHeight: "2" }}>
        We have put your subscription on pause and your{" "}
        <strong>${lostCredits}</strong> in subscription credits will be lost
        unless you update soon.
      </p>
    </>
  );
};

export default memo(SubscriptionMessageIssue);
