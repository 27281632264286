import { userInstance } from "../hooks/instance/useInstance";
import {
  IConfirmDeletingRecipientsData,
  IConfirmDeletingRecipientsResponse,
  IRecipient,
  IRecipientCreateResponse,
  IRecipientsListResponse,
  IRecipientListQuery,
} from "../interfaces/address/IRecipient";
import { IGetRecipients } from "../redux/recipients/thunks";

const recipientsApi = {
  list: async (params?: IGetRecipients) => {
    const queryParams: IRecipientListQuery = {
      include_campaign_info: params?.getCampaignInfo ? 1 : 0,
    };

    if (params?.sort) {
      const { sort } = params;

      if (sort.field === "name") {
        queryParams["order[first_name]"] = sort.order;
        queryParams["order[last_name]"] = sort.order;
      } else {
        queryParams[`order[${sort.field}]`] = sort.order;
      }
    }

    return userInstance.get<IRecipientsListResponse>(
      `/profile/recipientsList`,
      {
        params: queryParams,
      }
    );
  },

  getById: async (id: number) => {
    const response = await userInstance.get<{ recipient: IRecipient }>(
      `/profile/recipients/${id}`
    );
    return response.data;
  },

  delete: async (ids: Array<number>) => {
    const response = await userInstance.post("/profile/deleteRecipient", {
      address_ids: ids,
    });
    return response.data;
  },
  add: async (recipient: IRecipient) => {
    return userInstance.post<IRecipientCreateResponse>(
      "/profile/addRecipient",
      recipient
    );
  },
  update: async (recipient: IRecipient) => {
    return userInstance.post("/profile/updateRecipient", {
      ...recipient,
      address_id: recipient.id,
    });
  },
  parseXls: async (payload: FormData) => {
    return userInstance.post("/profile/parseXls", payload);
  },
  confirmationDeletingRecipients: async (
    data: IConfirmDeletingRecipientsData
  ) => {
    return userInstance.post<IConfirmDeletingRecipientsResponse>(
      "/profile/confirmationDeletingAddress",
      data
    );
  },
};

export default recipientsApi;
