import { FC, ReactNode, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

const parent = document.getElementById("root_modal");
interface IPortal {
  children: ReactNode;
}

const Portal: FC<IPortal> = ({ children }) => {
  const el = useMemo(() => document.createElement("div"), []);
  useEffect(() => {
    if (parent) {
      parent.appendChild(el);

      return () => {
        parent.removeChild(el);
      };
    }
  }, []);
  return ReactDOM.createPortal(children, el);
};

export default Portal;
