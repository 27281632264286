import { RootState } from "../store";
import { templatesAdapter } from "./entity";

export const selectTemplatesState = (state: RootState) => state.templates;

export const getActiveTemplate = (state: RootState) =>
  state.templates.activeTemplate;

export const getActiveEditTemplate = (state: RootState) =>
  state.templates.activeEditTemplate;

export const getActiveEditId = (state: RootState) => state.templates.editId;

export const selectTemplatesWithOutDefault = (state: RootState) =>
  Object.values(state.templates.entities).filter(
    (template) => !template?.category_id
  );

export const selectCreateCandidateTemplate = (state: RootState) =>
  Object.values(state.templates.entities).find(
    (template) => template?.status === "create"
  );

export const selectTemplatesLoading = (state: RootState) =>
  state.templates.isLoading;

export const { selectAll: selectAllTemplates, selectById: selectTemplateById } =
  templatesAdapter.getSelectors((state: RootState) => state.templates);
