import { createEntityAdapter } from "@reduxjs/toolkit";
import { ICoupon } from "../../interfaces/coupon/ICoupon";
import { ICouponState } from "./types";

export const couponAdapter = createEntityAdapter<ICoupon>();

export const initialState = couponAdapter.getInitialState<ICouponState>({
  coupon: null,
  error: "",
  couponCode: null,
});
