import { FC } from "react";

import { Typography } from "../../typography";
import ClosePopup from "../../../popups/utils/close.popup";
import { FlexContainer } from "../../layout";

import { useAppSelector } from "../../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../../redux/window-dimensions/selectors";

interface IPortalPopupAlert {
  text: string;
  onClick: () => void;
}

const PortalPopupAlert: FC<IPortalPopupAlert> = ({ text, onClick }) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  return (
    <>
      <FlexContainer justify='center'>
        <div style={isMobile ? { width: "80vw" } : { minWidth: "100%+20wv" }}>
          <Typography fontSize='0.88888rem'>{text}</Typography>
        </div>
      </FlexContainer>
      <ClosePopup onClick={onClick} />
    </>
  );
};

export default PortalPopupAlert;
