import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { ordersApi } from "../../api/orders.api";
import { RootState } from "../store";

import { IPlaceBasket } from "../../interfaces/orders/IPlaceBasket";
import { OrderId } from "../../interfaces/orders/IOrder";
import { IAllTaxes, IChildsThunkPayload } from "./types";
import {
  ValidateDataList,
  ValidBulkOrder,
} from "../../interfaces/bulk-upload/IValidateData";

import { IThunkError } from "../../interfaces/redux/IThunkError";
import { IOrderEditDetail } from "../../components/orders/order/order.item";
import { IBulkOrder } from "../../interfaces/orders/IBulkOrder";
import { ICoupons } from "../../interfaces/orders/responses/bulk-orders/IParseXlsResponse";

export interface IResponse {
  correct_postal_code?: boolean;
  correct: boolean;
  data: IBulkOrder[];
}

export const cancelOrder = createAsyncThunk<
  number | null,
  OrderId,
  { rejectValue: IThunkError }
>("orders/cancel", async (payload, { rejectWithValue }) => {
  try {
    const res = await ordersApi.cancelOrder(payload);
    return res.status === 200 ? payload : null;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const calcTaxes = createAsyncThunk<
  IAllTaxes,
  ICoupons,
  { rejectValue: IThunkError }
>("orders/calcTaxes", async (payload, { rejectWithValue }) => {
  try {
    const res = await ordersApi.calcTaxes(payload);
    return res.data;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const placeBasket = createAsyncThunk<
  void,
  IPlaceBasket,
  { rejectValue: ValidBulkOrder }
>("orders/placeBasket", async (payload, { rejectWithValue }) => {
  try {
    const res = await ordersApi.placeBasket(payload);

    return res.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err.response?.data);
    }
  }
});

export const getOrderDetails = createAsyncThunk<IOrderEditDetail, number>(
  "/orders/details",
  async (payload) => {
    const res = await ordersApi.getOrderDetails(payload);
    return res.data.order;
  }
);

export const getInvoicePdf = createAsyncThunk<void, number>(
  "/orders/invoice",
  async (payload) => {
    try {
      await ordersApi.getInvoicePdf(payload);
    } catch (error) {
      throw error;
    }
  }
);

export const listOrders = createAsyncThunk("orders/list", async () => {});

export const listChilds = createAsyncThunk<IChildsThunkPayload, OrderId>(
  "orders/list/childs",
  async (payload) => {
    const res = await ordersApi.listChilds(payload, "");

    const { childs } = res.data;

    return {
      childs,
      id: payload,
    };
  }
);

export const getPastOrders = createAsyncThunk("orders/list/past", async () => {
  const res = await ordersApi.listPast();

  const { orders, total_pages, total_items } = res.data;

  return { orders, total_pages, total_items };
});

export const payOrder = createAsyncThunk("orders/pay", async () => {});

export const placeOrder = createAsyncThunk("orders/place", async () => {});

export const singleStepOrder = createAsyncThunk(
  "orders/step/single",
  async () => {}
);

export const listGroupedOrders = createAsyncThunk<
  any,
  {
    page?: number;
    searchQuery?: string | null;
    limit?: number;
    parent?: string;
  },
  { rejectValue: boolean | string; state: RootState }
>("orders/grouped/list", async (payload, { rejectWithValue, getState }) => {
  const {
    orders: { pastOrders },
  } = getState();

  try {
    const res = await ordersApi.listGrouped(
      payload.page ? payload.page : pastOrders.page,
      payload.searchQuery,
      payload.limit,
      payload.parent
    );

    const { orders, total_pages, page } = res.data;

    return { orders, total_pages, page };
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const listGroupedOrdersSearch = createAsyncThunk(
  "orders/grouped/search",
  async (payload: string) => {
    try {
      const res = await ordersApi.listGrouped(undefined, payload);

      const { orders, total_pages, page } = res.data;

      return { orders, total_pages, page };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const parseOrdersXlsFile = createAsyncThunk<
  IResponse,
  FormData,
  { rejectValue: ValidateDataList | IThunkError }
>("bulkOrders/parseXls", async (formData) => {
  const res = await ordersApi.parseXls(formData);

  const { correct, data, correct_postal_code } = res.data;

  return {
    correct,
    data,
    correct_postal_code,
  };
});
