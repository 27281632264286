import { FC, memo } from "react";
import { useLocation } from "react-router";
import classNames from "classnames";

import CardPrice from "./card.price";
import CardInfo from "./card.info";

import { FlexContainer } from "../../ui/layout";
import navigation from "../../../constants/navigation";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface ICardHeader {
  isFavorite: boolean;
  price: string;
  discount_price: string | null;
  name: string;
  toggleFavorite: () => void;
  isPreview: boolean;
  isBirthday?: boolean;
}

export const CardHeader: FC<ICardHeader> = ({
  isFavorite,
  price,
  discount_price,
  name,
  toggleFavorite,
  isPreview,
  isBirthday,
}) => {
  const location = useLocation();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const cardDetail = classNames({
    card__detail: !(
      (isMobile && location.pathname.includes(navigation.category)) ||
      (isMobile && isBirthday)
    ),
    category__detail: isMobile && !location.pathname.includes(navigation.home),
    "card__detail-mobile":
      !!(isPreview || location.pathname.includes(navigation.category)) &&
      isMobile,
  });

  return (
    <FlexContainer className={cardDetail}>
      <FlexContainer justify='space-between' width='100%'>
        <CardInfo
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
          name={name}
        />
        <CardPrice discount_price={discount_price} price={price} />
      </FlexContainer>
    </FlexContainer>
  );
};

export default memo(CardHeader);
