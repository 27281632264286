import styled from "styled-components";

const BoxStyled = styled.div`
  flex: 1 1;

  width: 100%;
  padding: 2rem;
  margin-top: 1rem;

  box-sizing: border-box;

  background-color: #fff;

  box-shadow: 0.4444rem 0.4444rem 1.1111rem #f4f4f4;

  @media only screen and (max-width: 599px) {
    padding: 1rem;
    margin-top: 1.33333rem;
  }
`;

export default BoxStyled;
