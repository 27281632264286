import { useEffect, useMemo, useState } from "react";

import {
  selectNotifications,
  selectNotificationsHeight,
} from "../../redux/notifications/selector";
import { markNotificationAsRead } from "../../redux/notifications/thunks";
import { isAuth } from "../../redux/auth/selectors";
import { removeNotification } from "../../redux/notifications/slice";
import { useAppDispatch, useAppSelector } from "../redux/useRedux";

import {
  NotificationColor,
  NotificationId,
} from "../../interfaces/notifications/INotification";

const useNotifications = () => {
  const dispatch = useAppDispatch();

  const notifications = useAppSelector(selectNotifications);
  const isAuthenticated = useAppSelector(isAuth);
  const heightNotification = useAppSelector(selectNotificationsHeight);

  const [hidden, setHidden] = useState(false);
  const [top, setTop] = useState(
    window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop
  );
  const [bottomEdge, setBottomEdge] = useState(heightNotification - top);

  const unreadNotifications = useMemo(() => {
    if (isAuthenticated) {
      return notifications;
    }

    const readNotifications = localStorage.getItem("readNotifications") || "";
    const readNotificationsIds = readNotifications.split(",");

    return notifications.filter(
      (notification) =>
        !readNotificationsIds.some((readId) => +readId === notification.id)
    );
  }, [isAuthenticated, notifications]);

  const handleScroll = () =>
    setTop(
      window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
    );

  useEffect(() => {
    setHidden(top > heightNotification);
    setBottomEdge(top > heightNotification ? 0 : heightNotification - top);
  }, [top, heightNotification]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setNotificationColor = (color: NotificationColor) => {
    if (color === "GREEN") return "#81c784";

    if (color === "RED") return "#ef5350";

    return "";
  };

  const removeNotificationLocally = (id: number) => {
    dispatch(removeNotification(id));
    const readNotifications = localStorage.getItem("readNotifications");
    let updatedReadNotifications = String(id);

    if (readNotifications) {
      updatedReadNotifications = String([...readNotifications.split(","), id]);
    }

    localStorage.setItem("readNotifications", updatedReadNotifications);
  };

  const markNotificationRead = (id: NotificationId) => {
    if (!isAuthenticated) {
      removeNotificationLocally(id);
    } else {
      dispatch(markNotificationAsRead(id));
    }
  };

  return {
    notifications: unreadNotifications,
    setNotificationColor,
    markNotificationRead,
    hidden,
    heightNotification,
    bottomEdge,
  };
};

export default useNotifications;
