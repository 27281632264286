import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { IValue } from "../../components/ui/common/select/select.common";

export interface ISelectInstance {
  value: string | IValue;
  onChange: (
    value: string | IValue,
    setShow?: Dispatch<SetStateAction<boolean>>
  ) => void;
  setValue: Dispatch<SetStateAction<string | IValue>>;
}

const useSelect = (initialValue?: string | IValue) => {
  const [value, setValue] = useState(initialValue || "");

  const onChange = useCallback(
    (value: string | IValue, setShow?: Dispatch<SetStateAction<boolean>>) => {
      if (setShow) setShow(false);
      setValue(value);
    },
    [value]
  );

  return {
    value,
    onChange,
    setValue,
  };
};

export default useSelect;
