import { FC, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

import Routes from "./pages/Routes";
import { useAppSelector } from "./hooks/redux/useRedux";
import useLogInAsUser from "./hooks/auth/useLogInAsUser";
import useGetHubSpotToken from "./hooks/hubSpot/useGetHubSpotToken";
import useRedirectCardsFromApp from "./hooks/useRedirectCardsFromApp";

import { getUser, isAuth } from "./redux/auth/selectors";
import { getCredits } from "./redux/profile/thunks";
import { getNotifications } from "./redux/notifications/thunks";

import navigation from "./constants/navigation";
import ConfirmationPopupProvider from "./context/confirmation-popup.provider";
import PreventNavigationProvider from "./context/preventNavigation.context";
import useWindowDimensions from "./hooks/utils/useWindowDimensions";
import instance from "./hooks/instance/useInstance";

const App: FC = () => {
  const dispatch = useDispatch();
  const uid = localStorage.getItem("uid");
  const user = useAppSelector(getUser);

  const store = useStore();
  instance(store);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-MC3G8BG" });
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-K8NW7Z4Y5N");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window.location.pathname]);

  const isAuthenticated = useAppSelector(isAuth);

  const loadNotifications = () => {
    dispatch(getNotifications());
  };

  useEffect(() => {
    loadNotifications();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated || !uid) return;

    dispatch(getCredits());
  }, [isAuthenticated]);

  // hash use for forgot password
  useRedirectCardsFromApp(navigation.auth.signin, "hash");
  // activate use for activate acc
  useRedirectCardsFromApp(navigation.home, "activate");
  useGetHubSpotToken();
  useLogInAsUser();

  useWindowDimensions();

  // eslint-disable-next-line  @typescript-eslint/no-unused-expressions
  process.env.NODE_ENV === "production" &&
    Sentry.setUser({
      id: user?.id.toString(),
      email: user?.email,
    });

  return (
    <PreventNavigationProvider>
      <ConfirmationPopupProvider>
        <Routes />
      </ConfirmationPopupProvider>
    </PreventNavigationProvider>
  );
};

export default Sentry.withProfiler(App);
