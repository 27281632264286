import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { multiStepsAdapter } from "./entity";
import { IMultiCampaignPayload } from "./types";

export const getMultiCampaignsList = (state: RootState) =>
  state.multiSteps.campaignsList;

export const getMultiCampaignsPage = (state: RootState) =>
  state.multiSteps.page;

export const getMultiCampaignsEndList = (state: RootState) =>
  state.multiSteps.endMultiStepCampaignsList;

export const isKeepStateActive = (state: RootState) =>
  state.multiSteps.keepState;

export const getActiveStepId = (state: RootState) =>
  state.multiSteps.activeStepId;

export const getNewCampaignData = (state: RootState) =>
  state.multiSteps.newCampaignData;

export const getEditMultiStepCampaign = (state: RootState) =>
  state.multiSteps.editMultiStep;

const getId = (_: any, id: number) => id;

export const getStepById = createSelector(
  getEditMultiStepCampaign,
  getId,
  (stateAll: IMultiCampaignPayload | null, id: number) =>
    stateAll?.steps.find((v) => v.id === id)
);

export const { selectAll: selectAllSteps, selectById: selectStepById } =
  multiStepsAdapter.getSelectors((state: RootState) => state.multiSteps);
