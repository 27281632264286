import { RootState } from "../store";

export const getCreditCards = (state: RootState) =>
  state.creditCards.creditCards;

export const selectCreditInfo = (state: RootState) => state.creditCards;

export const selectClientInfo = (state: RootState) =>
  state.creditCards.creditCardsInfo;

export const selectCreitCardTokenInfo = (state: RootState) =>
  state.creditCards.creditCardsTokenInfo;

export const selectLoaderNewCreditCard = (state: RootState) =>
  state.creditCards.isLoader;
export const getNewCreditCard = (state: RootState) =>
  state.creditCards.newCreditCard;
