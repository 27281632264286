import {
  ICreditCard,
  ICreditCardInfo,
  ICreditCardTokenValueInfo,
  ICreditCardError,
} from "../../interfaces/credit-cards/ICreditCard";

interface ICreditCardState {
  creditCards: Array<ICreditCard>;
  discountCredit: number;
  discountCreditType2: number;
  creditCardsInfo: ICreditCardInfo | null;
  creditCardsTokenInfo: ICreditCardTokenValueInfo | null;
  errorCard: ICreditCardError | null;
  isLoader: boolean;
  newCreditCard: null | ICreditCard;
}

const initialState: ICreditCardState = {
  creditCards: [],
  discountCredit: 0,
  discountCreditType2: 0,
  creditCardsInfo: null,
  creditCardsTokenInfo: null,
  errorCard: null,
  isLoader: false,
  newCreditCard: null,
};

export default initialState;
