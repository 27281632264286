import React, { memo, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { selectAllAddresses } from "../../../redux/address/selectors";
import { closePopup } from "../../../redux/portal-popup/slice";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import maxAddressInnContainer from "../../../constants/address-book/address-book";
import AddressBookCard from "../address-book/address-book.card";
import SectionBox from "../../ui/common/section-box/box";
import { selectedReturnAddress } from "../../../redux/orders/selectors";
import { selectReturnAddress } from "../../../redux/orders/slice";
import navigation from "../../../constants/navigation";
import { setNewCampaignContacts } from "../../../redux/multi-step/slice";

const { count } = maxAddressInnContainer;

const ReturnAddressesSelectList = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const addresses = useAppSelector(selectAllAddresses);
  const selectedNewReturnAddress = useAppSelector(selectedReturnAddress);

  const handleReturnAddress = useCallback(
    (id) => {
      if (pathname.includes(navigation.addNewMultiStep)) {
        dispatch(
          setNewCampaignContacts({
            return_address_id: id,
          })
        );
      } else {
        dispatch(selectReturnAddress(id));
      }
      dispatch(closePopup());
    },
    [dispatch, selectReturnAddress]
  );

  const heightContainers = useMemo(
    () => (addresses.length > count ? "30vh" : "max-content"),
    [addresses, count]
  );

  return (
    <div
      className='scroll-container'
      style={{
        maxHeight: heightContainers,
      }}
    >
      <SectionBox className='addresses-list' style={{ padding: "0rem" }}>
        {addresses.map(
          ({
            address1,
            id,
            city,
            state,
            first_name,
            last_name,
            business_name,
            zip,
            default: defaultAddress,
          }) => {
            const _address = `${address1}, ${city}, ${state} ${zip}`;
            let checkedAddress = null;
            if (defaultAddress) {
              checkedAddress = id;
            }
            if (selectedNewReturnAddress) {
              checkedAddress = selectedNewReturnAddress;
            }
            return (
              <AddressBookCard
                key={id}
                id={id!}
                type='radio'
                radioValue={id === checkedAddress}
                label={_address}
                onChange={() => handleReturnAddress(id!)}
                square
                name={`${first_name} ${last_name}`}
                businessName={business_name}
                defaultAddress={defaultAddress}
              />
            );
          }
        )}
      </SectionBox>
    </div>
  );
};

export default memo(ReturnAddressesSelectList);
