import { IInserts } from "../../interfaces/inserts/IInserts";

interface IInsertsState {
  inserts: Array<IInserts>;
}

const initialState: IInsertsState = {
  inserts: [],
};

export default initialState;
