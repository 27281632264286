import { useState } from "react";

const useCalendarInput = (initialValue?: Date) => {
  const [date, setDate] = useState<Date | undefined>(initialValue);
  const [notValid, setValid] = useState<boolean>(false);
  const onChange = (time: Date | undefined) => {
    setDate(time);
  };

  return {
    date,
    onChange,
    notValid,
    setValid,
  };
};

export default useCalendarInput;
