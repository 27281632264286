import styled from "styled-components";

import { IDashedContainer, IFlex } from "../../interfaces/layout/ILayout";
import {
  IPosition,
  ITypography,
  IButtonAction,
  INavigation,
  ISection,
} from "../../interfaces/typography/ITypography";

const displayFlex = (props: IFlex) => `
  display: flex;
  display: -webkit-flex;
  flex-wrap: ${props.wrap || "nowrap"};
  flex-direction: ${props.direction || "row"};
  align-items: ${props.align || "flex-start"};
  -webkit-align-items: ${props.align || "flex-start"};
  justify-content: ${props.justify || "start"};
 -webkit-justify-content: ${props.justify || "start"};
`;

export const Main = styled.main`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  padding-top: 1rem;
  margin: 0 auto;
  min-height: calc(100vh - 4rem);
  background-color: ${(props) => props.theme.lightest};

  @media only screen and (max-width: 599px) {
    padding-top: 2.94444rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const FlexContainer = styled.div`
  ${displayFlex};

  background-color: ${(props) => props.bgColor};

  width: ${(props) => props.width};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;

export const DashedContainer = styled.div<IDashedContainer>`
  display: inline-block;
  position: relative;
  border: ${(props) =>
    `${props.borderSize} ${props.borderType} ${props.borderColor}`};
  line-height: 1.77777rem;
`;

export const TextLink = styled.span<ITypography>`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin};
  line-height: ${(props) => props.lineHeight};
  font-style: ${(props) => props.fontStyle};

  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  white-space: ${(props) => props.whiteSpace};
  cursor: pointer;
  position: relative;
  width: fit-content;
  &:hover:after {
    width: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0%;
    border-bottom: 1px solid #ff5037;
    bottom: -0.1666rem;
    transition-duration: 0.3s;
    transition-property: width;
  }

  @media screen and (max-width: 599px) {
    &:after {
      transition: none;
    }

    &:hover:after {
      width: 0;
      transition: none;
    }
  }

  &.my-basket__preview:after {
    width: 0%;
    display: none;
  }
`;

export const FooterLink = styled.p<ITypography>`
  display: block;
  font-size: 0.6rem;
  color: white;
  margin: 0 0 0 0.4rem;
  cursor: pointer;
  position: relative;
  width: fit-content;

  &:hover:after {
    width: calc(100% - 0.4rem);
  }
  &:after {
    content: "";
    display: block;
    align-self: flex-end;
    position: absolute;
    left: 0.4rem;
    width: 0%;
    border-bottom: 1px solid #ff5037;
    bottom: -0.1666rem;
    transition-duration: 0.3s;
    transition-property: width;
  }

  @media screen and (max-width: 599px) {
    &:after {
      transition: none;
    }

    &:hover:after {
      width: 0;
      transition: none;
    }
  }

  &.my-basket__preview:after {
    width: 0%;
    display: none;
  }
`;

export const ButtonAction = styled.button<IButtonAction>`
  width: ${(props) =>
    props.plusIcon ? `calc(${props.width} - 1.3rem) !important` : props.width};
  margin-left: ${(props) => (props.plusIcon ? "1rem" : 0)};
  position: ${(props) => (props.plusIcon ? "relaative" : "static")};
  height: 2.77777rem;
  background-color: ${(props) => props.bgColor};
  font-weight: bold;
  font-size: 0.72222rem;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  color: ${(props) => props.color};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  border: ${(props) => props.border};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition-duration: 0.3s;
  transition-property: background, color;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  title: ${(props) => props.title};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  margin-right: ${(props) => (props.plusIcon ? "2.2rem" : 0)};
  &:hover {
    background: ${(props) => !props.disabled && props.hoverBgColor};
    color: ${(props) => !props.disabled && props.hoverColor};
    &:after {
      color: white;
    }
  }
  &:after {
    content: "＋";
    color: white;
    display: ${(props) => (props.plusIcon ? "flex" : "none")};
    justify-content: center;
    position: absolute;
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 50%;
    background-color: black;
    right: 0;
    font-size: 2.3rem;
    font-weight: 100;
  }
`;

export const BorderedBox = styled.div`
  width: 100%;
  height: 2.11111rem;
  border: 1px solid #ddddd4;
  border-radius: 1.05555rem;
  ${displayFlex}
`;

export const CharactersCounter = styled.div<IPosition>`
  position: absolute;
  font-size: 0.61111rem;
  line-height: 0.61111rem;
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
`;

export const Navigation = styled.nav<INavigation>`
  ${displayFlex};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
`;

export const Form = styled.form`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow-y: auto;
  z-index: 999;

  background-color: rgba(249, 249, 248, 0.9);
`;

export const BoxContainer = styled.div<ISection>`
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  background-color: #fff;
  box-shadow: 0.4444rem 0.4444rem 1.1111rem #f4f4f4;

  grid-row-end: span 2;

  @media only screen and (max-width: 599px) {
    padding: 1rem;
    margin-top: 1.33333rem;
  }
`;
export const SectionGrid = styled.section`
  display: grid;
  grid-gap: 1rem;
  min-height: 220px;
  grid-row: 2;
  grid-template-rows: 1px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(14rem, 1fr));
    grid-gap: 1rem;
  }
  @media screen and (min-width: 1000px) and (max-width: 1400px) {
    grid-template-columns: repeat(3, minmax(14rem, 1fr));
    grid-gap: 1rem;
  }
  @media screen and (min-width: 2100px) {
    grid-gap: 2.5rem;
  }

  grid-template-columns: repeat(4, minmax(14rem, 1fr));
`;

FlexContainer.defaultProps = {
  align: "center",
  width: "100%",
};

DashedContainer.defaultProps = {
  borderSize: "1px",
  borderType: "solid",
  borderColor: "#00000",
};

ButtonAction.defaultProps = {
  width: "100%",
  bgColor: "#FF5037",
  color: "white",
  border: "2px solid #FF5037",
  hoverBgColor: "white",
  hoverColor: "#221d1d",
};

Navigation.defaultProps = {
  width: "100%",
  margin: "0",
};
