import {
  IResetPasswordPayload,
  ISignInData,
  ISignInResponse,
  ISignUpData,
  ITestModeResponse,
  IUserResponse,
} from "../interfaces/auth/IAuth";
import { IActivationResponse } from "../interfaces/auth/responses/IActivationResponse";
import { IConvertEmail } from "../interfaces/profile/IEmailChange";
import { IAuthProvider } from "../interfaces/auth/IAuthProvider";
import { userInstance } from "../hooks/instance/useInstance";

const authApi = {
  signin: async (payload: ISignInData) => {
    const { email, token } = payload;

    const data = token ? { token } : { login: email, ...payload };

    return userInstance.post<ISignInResponse>("/auth/authorization", data);
  },
  signup: async (payload: ISignUpData) => {
    const { email } = payload;

    return userInstance.post("/auth/registration", {
      login: email,
      silent: false,
      ...payload,
    });
  },
  user: async () => {
    return userInstance.get<IUserResponse>("/auth/getUser");
  },
  logout: async () => {
    return userInstance.post("/auth/logout");
  },
  resetPasswordRequest: async (login: string) => {
    return userInstance.post("/auth/resetPasswordRequest", { login });
  },
  resetPasswordConfirm: async (payload: IResetPasswordPayload) => {
    const { hash, pass, pass_confirm } = payload;

    return userInstance.post(`/auth/resetPasswordConfirm/${hash}`, {
      pass,
      pass_confirm,
    });
  },
  setPassword: async () => {
    return userInstance.post("/auth/setPassword");
  },
  convertAccount: async (payload: IConvertEmail) => {
    return userInstance.post("/auth/convertAccount", payload);
  },
  testMode: async (test_mode: number) => {
    return userInstance.post<ITestModeResponse>("/auth/setTestMode", {
      test_mode,
    });
  },
  activation: async (hash: string) => {
    return userInstance.post<IActivationResponse>(`/auth/activation/${hash}`);
  },
  authProvider: async (data: IAuthProvider) => {
    return userInstance.post<ISignInResponse>(
      "/auth/socialNetworkRegistration",
      data
    );
  },
};

export default authApi;
