import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { initialState, multiStepsAdapter } from "./entity";
import { IStep } from "./types";
import { getNextSteps } from "../../helpers/multi-step";
import {
  getMultiCampaignById,
  getMultiCampaigns,
  removeMultiStepCampaigns,
} from "./thunks";

const multiStepSlice = createSlice({
  name: "multi-step",
  initialState,
  reducers: {
    addStep: (state, { payload }: PayloadAction<IStep>) => {
      multiStepsAdapter.addOne(state, payload);
    },
    addEditStep: (state, { payload }: PayloadAction<IStep[]>) => {
      multiStepsAdapter.addMany(state, payload);
    },
    resetEditCampaign: (state) => {
      // if keepState is true tah means that user in process of the enrolling
      // addresses, so we need to keep campaign state
      if (!state.keepState) {
        state.editMultiStep = null;
        multiStepsAdapter.removeAll(state);
      }
    },
    resetCampaignsList: (state) => {
      state.campaignsList = null;
      state.page = 1;
      state.endMultiStepCampaignsList = false;
    },
    setActiveStepId: (state, { payload }) => {
      state.activeStepId = payload;
    },
    resetActiveStepId: (state) => {
      state.activeStepId = null;
    },
    setKeepState: (state, { payload }) => {
      state.keepState = payload;
    },
    setActiveStep: (state, { payload }) => {
      const { id } = payload;

      if (id) {
        multiStepsAdapter.updateOne(state, {
          id,
          changes: {
            ...payload,
          },
        });
      }
    },
    setActiveCampaign: (state, { payload }) => {
      const { id } = payload;

      if (id) {
        state.editMultiStep = {
          ...state.editMultiStep,
          ...payload,
        };
      }
    },
    resetNewCampaignData: (state) => {
      if (!state.keepState) {
        state.newCampaignData = {
          campaign_name: "",
          return_address_id: null,
          address_ids: [],
        };
      }
    },
    setNewCampaignContacts: (state, { payload }) => {
      const { return_address_id, address_ids } = payload;

      const updatedPayload = { return_address_id, address_ids };

      if (!return_address_id) delete updatedPayload.return_address_id;
      if (!address_ids) delete updatedPayload.address_ids;

      state.newCampaignData = {
        ...state.newCampaignData,
        ...updatedPayload,
      };
    },
    setNewCampaignName: (state, { payload }) => {
      state.newCampaignData = {
        ...state.newCampaignData,
        campaign_name: payload,
      };
    },
    deleteStepById: (state, { payload: id }) => {
      if (id) {
        multiStepsAdapter.removeOne(state, id);
      }
    },
    resetAiMessageByStepId: (state, { payload }) => {
      const { id } = payload;

      if (id) {
        multiStepsAdapter.updateOne(state, {
          id,
          changes: {
            aiMessage: null,
          },
        });
      }
    },
    updateNextStepsOrder: (state, { payload: { startWith, decrease } }) => {
      const steps = current(state).entities;
      const stepsToUpdate = getNextSteps(steps, startWith);

      const changesObject = stepsToUpdate.map((step) => ({
        id: step.id,
        changes: {
          sortOrder: decrease ? step.sortOrder - 1 : step.sortOrder + 1,
        },
      }));

      multiStepsAdapter.updateMany(state, changesObject);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMultiCampaigns.fulfilled, (state, { payload }) => {
      if (state.campaignsList) {
        state.campaignsList = state.campaignsList.concat(
          payload.multiStepCampaigns
        );
      } else {
        state.campaignsList = payload.multiStepCampaigns;
      }
      state.page = payload.page;
      state.endMultiStepCampaignsList = payload.total_pages <= payload.page;
    });
    builder.addCase(
      removeMultiStepCampaigns.fulfilled,
      (state, { payload }) => {
        if (state.campaignsList)
          state.campaignsList = state.campaignsList.filter(
            (el) => el.id !== payload
          );
      }
    );
    builder.addCase(getMultiCampaignById.fulfilled, (state, { payload }) => {
      if (payload) {
        state.editMultiStep = payload;
        const steps = payload.steps.map((step) => {
          return {
            ...step,
            // @ts-ignore
            sortOrder: step.step,
          };
        });
        multiStepsAdapter.addMany(state, steps);
      }
    });
  },
});

export const {
  addStep,
  setActiveStepId,
  resetActiveStepId,
  setActiveStep,
  setActiveCampaign,
  setNewCampaignContacts,
  setNewCampaignName,
  resetNewCampaignData,
  resetAiMessageByStepId,
  deleteStepById,
  updateNextStepsOrder,
  resetEditCampaign,
  resetCampaignsList,
  setKeepState,
} = multiStepSlice.actions;

export default multiStepSlice.reducer;
