import styled from "styled-components";

interface INotificationWrapperProps {
  bgcolor: string;
}

const NotificationWrapper = styled.div<INotificationWrapperProps>`
  display: flex;
  justify-content: space-between;

  padding: 20px;
  margin-bottom: 10px;

  background-color: ${(props) => props.bgcolor};
`;

export default NotificationWrapper;
