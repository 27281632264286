import { FC } from "react";
import { Typography } from "../../ui/typography";

const OutboundsLeadsHeader: FC = () => (
  <Typography className='choose-campaign-container__title'>
    Choose a Campaign
  </Typography>
);

export default OutboundsLeadsHeader;
