import { createAsyncThunk } from "@reduxjs/toolkit";
import tagsApi from "../../api/tags.api";

export default createAsyncThunk("config", async () => {
  const res = await tagsApi.list();

  if (!res) return [];

  return res.data.tags.map((tag, index) => {
    return {
      id: index,
      value: tag.placeholder,
      label: tag.label,
    };
  });
});
