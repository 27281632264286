import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import customCardsApi from "../../api/custom-images.api";

import { IThunkError } from "../../interfaces/redux/IThunkError";
import {
  CustomCardId,
  ICustomCardCreate,
} from "../../interfaces/custom-cards/ICustomCard";
import {
  ICustomCardImage,
  ImageType,
} from "../../interfaces/custom-cards/ICustomCardImage";

import { IDeleteCustomImageAction } from "./types";

import { closePopup, setPortalPopup } from "../portal-popup/slice";
import fetchCategories from "../categories/thunks";

import typePopups from "../../constants/popup/type-popup";
import { IQrCode } from "../../interfaces/custom-cards/IQrCode";

const { CUSTOM_CREATE_SUCCESS } = typePopups;

export const createCustomCard = createAsyncThunk<
  void,
  ICustomCardCreate,
  { rejectValue: IThunkError }
>("custom-cards/create", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await customCardsApi.createCard(payload);
    if (!res) return;

    await dispatch(fetchCategories());
    await dispatch(
      setPortalPopup({
        form: CUSTOM_CREATE_SUCCESS,
        title: "",
        typePopup: "form",
      })
    );
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export const listImages = createAsyncThunk(
  "custom-cards/images/list",
  async (type: ImageType | string | null) => {
    const res = await customCardsApi.listImages(type);

    const { images } = res.data;

    return { images, type };
  }
);

export const deleteCustomImage = createAsyncThunk<
  void,
  IDeleteCustomImageAction
>("custom-cards/image/delete", async ({ id, type }, { dispatch }) => {
  await customCardsApi.deleteImage(id);
  dispatch(listImages(type));
});

export const getCustomizedCard = createAsyncThunk(
  "custom-cards/customized/get",
  async (id: CustomCardId) => {
    const res = await customCardsApi.getCustomizedCard(id);

    const { card } = res.data;

    return { card };
  }
);

export const uploadCustomImage = createAsyncThunk<
  ICustomCardImage,
  FormData,
  { state: RootState }
>("custom-cards/image/upload", async (file: FormData, { getState }) => {
  const res = await customCardsApi.uploadImage(file);
  const state = getState();
  const thumbnail_url = "";
  const type = file.get("type");

  const { image_url, id } = res.data;
  const { user_id, image } = state.customCards.customizedCard;

  return {
    image_url,
    type: type as ImageType,
    id: id.toString(),
    user_id,
    thumbnail_url,
    image,
  };
});

export const getQrCodeList = createAsyncThunk("qrCode", async () => {
  const res = await customCardsApi.getQrCodeList();

  const { list } = res.data;

  return list;
});

export const deleteQrCodeById = createAsyncThunk<void, number>(
  "deleteQrCode",
  async (id, { dispatch }) => {
    const res = await customCardsApi.deleteQrCodeById(id);
    if (res) await dispatch(getQrCodeList());
  }
);

export const createQrCode = createAsyncThunk<
  void,
  IQrCode,
  { rejectValue: IThunkError }
>("custom-cards/qrCode", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await customCardsApi.createQrCode(payload);

    if (res) {
      await dispatch(closePopup());
      await dispatch(getQrCodeList());
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export const getFrames = createAsyncThunk("qrCode/frames", async () => {
  const res = await customCardsApi.getFrames();

  const { frames } = res.data;

  return frames;
});
