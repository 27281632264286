import { createAsyncThunk } from "@reduxjs/toolkit";

import profileApi from "../../api/profile.api";
import { IAddTag } from "./types";
import { getRecipients } from "../recipients/thunks";

export const getTagList = createAsyncThunk(
  "profile/tags",
  async (include_addresses?: boolean) => {
    try {
      const res = await profileApi.getTags(include_addresses);
      const { list } = res.data;
      return list;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteTag = createAsyncThunk<any, number>(
  "profile/tags/delete",
  async (id, { dispatch }) => {
    try {
      const result = await profileApi.deleteTag(id);

      if (result) {
        await dispatch(getRecipients(true));
        return { id };
      }
    } catch (error) {
      throw error;
    }
  }
);

export const addTag = createAsyncThunk<any, IAddTag>(
  "profile/tags/createTag",
  async (payload) => {
    try {
      const res = await profileApi.createTag(payload);
      const { tag } = res.data;
      return tag;
    } catch (error) {
      throw error;
    }
  }
);
