import { FC, FormEvent } from "react";

import { Input } from "../../ui/common/inputs/input.common";
import { Typography } from "../../ui/typography";
import { ButtonAction, FlexContainer, TextLink, Form } from "../../ui/layout";

import usePassword from "../../../hooks/auth/usePassword";
import { useAppDispatch } from "../../../hooks/redux/useRedux";
import useInput from "../../../hooks/input/useInput";
import { closePopup } from "../../../redux/portal-popup/slice";
import useValidateForm from "../../../hooks/forms/useValidateForm";

const PasswordResetFormPopup: FC = () => {
  const dispatch = useAppDispatch();
  const { requestPasswordResetSubmit } = usePassword();
  const email = useInput("");

  const { errorFields, removeError, checkEmptyFields } = useValidateForm();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const valid = checkEmptyFields({
      email: email.value,
    });
    if (valid) requestPasswordResetSubmit(e);
  };

  return (
    <>
      <Typography fontSize='0.88888rem'>
        Enter your email to reset your password
      </Typography>
      <Form onSubmit={onSubmit}>
        <Input
          {...email}
          placeholder='Email Address'
          type='email'
          name='email'
          className={errorFields.includes("email") ? "error-field" : ""}
          style={{ margin: "1rem 0" }}
          onFocus={() => removeError("email")}
        />
        <ButtonAction type='submit'>Send</ButtonAction>
      </Form>
      <FlexContainer justify='center' margin='2rem 0 0'>
        <Typography fontSize='0.88888rem'>Have a password?</Typography>
        <TextLink
          onClick={() => dispatch(closePopup())}
          style={{ marginLeft: ".2rem", fontWeight: "bold" }}
          fontSize='0.88888rem'
        >
          Sign In
        </TextLink>
      </FlexContainer>
    </>
  );
};

export default PasswordResetFormPopup;
