import { useCallback, useState, useEffect, FormEvent } from "react";
import { useHistory, useLocation } from "react-router";

import { logout, signIn, signUp, uidStorage } from "../../redux/auth/thunks";
import { useAppDispatch } from "../redux/useRedux";

import useForm from "../forms/useForm";
import usePopup from "../popup/usePopup";
import { ISignInData, ISignUpData, Uid } from "../../interfaces/auth/IAuth";
import { closePopup } from "../../redux/portal-popup/slice";
import messagePopup from "../../constants/popup/constants";
import useFacebookSignIn from "./useFacebookSignIn";
import { setLoginAtSend } from "../../redux/orders/slice";
import useHubSpotUser from "../hubSpot/useHubSpotUser";
import navigation from "../../constants/navigation";
import useGoogleSignIn from "./useGoogleSignIn";
import { changeDetectedSubscriptionIssue } from "../../redux/subscriptions/slice";
import clearLocalStorage from "../../helpers/local-storage";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const [uid, setUid] = useState<Uid | null>("");

  const { checkEmptyInputs } = useForm();
  const { openErrorPopup } = usePopup();

  const { signOut: signOutGoogle } = useGoogleSignIn();
  const { signOut: signOutFacebook } = useFacebookSignIn();
  const { token, redirectTokenHubSpot, removeToken } = useHubSpotUser();

  const signin = useCallback(
    async (event: FormEvent<HTMLFormElement>, signInFormData: ISignInData) => {
      event.preventDefault();

      const isEmpty = checkEmptyInputs(Object.values(signInFormData));

      if (isEmpty) {
        return openErrorPopup(messagePopup.emptyFields);
      }

      const result = await dispatch(signIn({ ...signInFormData }));

      if (
        location.pathname.includes("/send") &&
        signIn.fulfilled.match(result)
      ) {
        dispatch(setLoginAtSend(true));
        dispatch(closePopup());
      }

      if (!redirectTokenHubSpot && token && signIn.fulfilled.match(result)) {
        removeToken();
        return history.push(navigation.home);
      }

      if (redirectTokenHubSpot && token && signIn.fulfilled.match(result)) {
        return history.push({
          pathname: navigation.hubspot,
          search: `?token=${token}`,
        });
      }

      if (signIn.rejected.match(result)) {
        if (token) removeToken();
        // return openErrorPopup(messagePopup.wrongLogin);
      }
    },
    []
  );

  const signup = useCallback(async (signUpFormData: ISignUpData) => {
    const copyPayload = JSON.parse(JSON.stringify(signUpFormData));

    const inputs = [signUpFormData].map((value) => {
      delete value.phone;
      delete value.discount_code;
      return Object.values(value);
    })[0];

    const isEmpty = checkEmptyInputs(inputs);

    if (isEmpty) {
      return openErrorPopup(messagePopup.emptyFields);
    }

    await dispatch(signUp({ ...copyPayload }));
  }, []);

  const signout = useCallback(async () => {
    const navigateToSignIn = () => {
      history.push(navigation.auth.signin);
    };

    dispatch(changeDetectedSubscriptionIssue(false));

    const result = await dispatch(logout());
    navigateToSignIn();

    clearLocalStorage();
    await sessionStorage.clear();

    if (logout.fulfilled.match(result)) {
      // if (user?.logged_with !== 'email') {
      await signOutGoogle();
      await signOutFacebook();
      // }
    }
  }, []);

  useEffect(() => {
    (async () => {
      const uid = localStorage.getItem(uidStorage);
      try {
        setUid(uid);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return {
    signin,
    signup,
    signout,
    uid,
  };
};

export default useAuth;
