import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import profileApi from "../../api/profile.api";
import { IChangeEmail } from "../../interfaces/profile/IEmailChange";
import { IChangePassword } from "../../interfaces/profile/IProfile";
import { IThunkError } from "../../interfaces/redux/IThunkError";
import { setNewEmail } from "../auth/slice";
import getCheckAuth from "../checkauth/thunks";
import { setPortalPopup } from "../portal-popup/slice";
import { logout } from "../auth/thunks";
import { IUpdateRecipientsTags } from "../../interfaces/profile/IUpdateRecipientsTags";
import { getRecipients } from "../recipients/thunks";
import { InfoByToken } from "./types";
import {
  IBulkRequestMailingAddressResponse,
  IRequestMailingAddressPayload,
  ISendAddressPayload,
  ISendAddressResponse,
} from "../../interfaces/profile/IRequestMailingAddress";

export const changePassword = createAsyncThunk<
  void,
  IChangePassword,
  { rejectValue: IThunkError }
>("profile/password/change", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await profileApi.changePassword(payload);

    if (res) {
      const { message } = res.data;
      await dispatch(logout());

      dispatch(
        setPortalPopup({
          text: message,
          typePopup: "alert",
          title: "Success",
        })
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

    throw err;
  }
});

export const changeEmail = createAsyncThunk<
  string | undefined,
  IChangeEmail,
  { rejectValue: IThunkError }
>("/profile/email/change", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const res = await profileApi.changeEmail(payload);

    if (res) {
      dispatch(
        setPortalPopup({
          text: res.data.message,
          typePopup: "alert",
          title: "Success",
        })
      );
      dispatch(setNewEmail(payload.new_email));
      dispatch(getCheckAuth());
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const getCredits = createAsyncThunk("/profile/credits", async () => {
  try {
    const res = await profileApi.getCredits();

    const { credits } = res.data;

    return credits;
  } catch (err) {
    throw err;
  }
});

export const handleAttachInvoice = createAsyncThunk(
  "/profile/changeAttachInvoice",
  async () => {
    try {
      const res = await profileApi.handleAttachInvoice();

      const { attach_invoice } = res.data;

      return attach_invoice;
    } catch (err) {
      throw err;
    }
  }
);

export const updateRecipientsTags = createAsyncThunk<
  void,
  IUpdateRecipientsTags,
  { rejectValue: IThunkError }
>("/profile/setTags", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await profileApi.updateRecipientsTags(payload);
    if (res) {
      dispatch(getRecipients(true));
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const sendAddress = createAsyncThunk<
  ISendAddressResponse,
  ISendAddressPayload
>("profile/sendAddress", async (payload) => {
  const res = await profileApi.sendAddress(payload);
  return res.data;
});

export const requestMailingAddress = createAsyncThunk<
  IBulkRequestMailingAddressResponse,
  IRequestMailingAddressPayload | FormData
>("profile/requestMailingAddress", async (payload) => {
  const res = await profileApi.requestMailingAddress(payload);

  return res.data;
});

export const checkToken = createAsyncThunk<InfoByToken, string>(
  "profile/checkToken",
  async (token) => {
    const res = await profileApi.checkToken(token);
    const { user, initial_values } = res.data;

    return { user, initial_values };
  }
);

export const exportRecipientsList = createAsyncThunk(
  "profile/exportRecipients",
  async () => {
    await profileApi.exportRecipientsList();
  }
);
