import { FC, CSSProperties } from "react";
import { MdCancel } from "react-icons/md";
import "./style.scss";

interface ICancelBtn {
  onClick: (e: React.MouseEvent) => void | Promise<void>;
  active: boolean;
  style?: CSSProperties;
}

const CancelSelectValueButton: FC<ICancelBtn> = ({
  onClick,
  style,
  active,
}) => {
  if (!active) return null;

  return (
    <div className='cancel-input-value' style={style} onClick={onClick}>
      <MdCancel size={15} />
    </div>
  );
};

export default CancelSelectValueButton;
