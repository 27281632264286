import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState, previewAdapter } from "./entity";
import { ICardItem } from "../../interfaces/cards/ICard";

export const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    addToPreview: (state, { payload }: PayloadAction<ICardItem>) => {
      if (state.ids.length) return;
      previewAdapter.addOne(state, payload);
    },
    removeFromPreview: (state, { payload }: PayloadAction<number>) => {
      previewAdapter.removeOne(state, payload);
    },
  },
});

export const { addToPreview, removeFromPreview } = previewSlice.actions;

export default previewSlice.reducer;
