import { createSlice } from "@reduxjs/toolkit";
import generateMessage from "./thunks";

interface IAiMessage {
  aiMessage: null | string;
  pending: boolean;
}

const initialState: IAiMessage = {
  aiMessage: null,
  pending: false,
};

const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    resetAiMessage: (state) => {
      state.aiMessage = null;
      state.pending = false;
    },
    setAiMessage: (state, action) => {
      state.aiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateMessage.fulfilled, (state) => {
      state.pending = false;
    });
    builder.addCase(generateMessage.pending, (state) => {
      state.pending = true;
    });
  },
});

export const { resetAiMessage, setAiMessage } = aiSlice.actions;

export default aiSlice.reducer;
