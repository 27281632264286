import { ICategory } from "../../interfaces/categories/ICategories";

interface ICategoryState {
  categories: Array<ICategory>;
}

const initialState: ICategoryState = {
  categories: [],
};

export default initialState;
