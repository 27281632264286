import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  clean9: "rgba(249,249,248, .9)",
  clean: "rgba(244, 244, 244, 1)",
  dark: "#221D1D",
  darkMid: "#444444",
  darkMid2: "#433A44",
  darkMid3: "#5B5B5B",
  darkLight: "#857878",
  darkLight2: "#858585",
  mid: "#989A9B",
  medium: "#F5F5F2",
  medium2: "#F4F4F4",
  primary: "#FF5037",
  light: "#DDDFD2",
  lightest: "#F9F9F8",
  writingColor: "#0625D3",
  fbColor: "rgb(80, 109, 170)",
  fbIColor: "#2171B8",
  gColor: "rgb(221, 39, 39)",
  gIColor: "#0933A0",
  aColor: "rgb(22, 22, 22)",
  bgColor: "#E9E9E0",
  btnIcon: "#A7A794",
  bgColorMid: "#f9f9f8",
  white: "white",
  btnApiKey: "#ECECE2",
  red: "#ef6241",
};

export default theme;
