import { FC, useRef, RefObject, CSSProperties } from "react";
import {
  CloseButtonWrapper,
  MobileModalContent,
  PopupBackground,
  PortalPopupContent,
} from "../popups/common/popup.styled";

import ButtonClose from "../ui/buttons/btn-close/btn-close.common";
import { FlexContainer } from "../ui/layout";

import Portal from "../portal/portal";

interface IModal {
  open: boolean;
  onClose: () => void;
  style?: CSSProperties;
  isMobileModal?: boolean;
}

export const ModalConfirm: FC<IModal> = ({
  open,
  onClose,
  children,
  style,
  isMobileModal,
}) => {
  const popupRef: RefObject<HTMLDivElement> = useRef(null);
  const backdrop = useRef(null);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <PopupBackground
        ref={backdrop}
        style={isMobileModal ? { alignItems: "flex-end" } : {}}
      >
        <FlexContainer justify='center' align='center' style={style}>
          {!isMobileModal ? (
            <div>
              <CloseButtonWrapper>
                <ButtonClose onClick={() => onClose()} />
              </CloseButtonWrapper>
              <PortalPopupContent className='popup-content' ref={popupRef}>
                {children}
              </PortalPopupContent>
            </div>
          ) : (
            <MobileModalContent ref={popupRef}>{children}</MobileModalContent>
          )}
        </FlexContainer>
      </PopupBackground>
    </Portal>
  );
};

export default ModalConfirm;
