import { memo, useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CSSObject } from "styled-components";

import { FlexContainer } from "../../ui/layout";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import useUser from "../../../hooks/auth/useUser";

import { selectBasketCount } from "../../../redux/basket/selectors";
import { basketCount } from "../../../redux/basket/thunks";

import Cart from "../../cart/cart.item";
import UserMenu from "../menu-user/user.menu";
import Hamburger from "../../hamburger";
import HeaderSubscription from "./header.subscription";
import HeaderUserInfo from "./header.user-info";
import HeaderTestMode from "./header.test-mode";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const HeaderAuthenticated = memo(() => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const accountRef = useRef<HTMLDivElement>(null);
  const hamburgerRef = useRef<HTMLDivElement>(null);

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const count = useAppSelector(selectBasketCount);

  const {
    fname,
    lname,
    user_subscription,
    test_mode,
    detected_subscription_issue,
    problematic_subscription,
    lost_credit,
  } = useUser();

  const [isSettingAccountOpen, setSettingAccountOpen] =
    useState<boolean>(false);

  const styles: CSSObject = useMemo(() => {
    if (user_subscription)
      return {
        "--color-logo": `#${user_subscription?.color}`,
      };
    return {
      "--color-logo": `#${problematic_subscription?.color}`,
    };
  }, [problematic_subscription, user_subscription]);

  const hideSetting = () => {
    if (isSettingAccountOpen) setSettingAccountOpen(false);
  };

  useEffect(() => {
    hideSetting();
  }, [pathname]);

  useEffect(() => {
    dispatch(basketCount());
  }, []);

  const showSubscriptionIcon = useMemo(() => {
    return !!user_subscription || detected_subscription_issue;
  }, [user_subscription, detected_subscription_issue]);

  const userSubscriptionIcon = useMemo(() => {
    if (user_subscription) return user_subscription;
    if (problematic_subscription) return problematic_subscription;
    return null;
  }, [user_subscription, problematic_subscription]);

  return (
    <FlexContainer align='center' width='auto' className='authentificated-nav'>
      <FlexContainer
        style={{
          marginRight:
            user_subscription || problematic_subscription ? "1.1111rem" : "0",
        }}
        className='account'
        width='auto'
      >
        {!!test_mode && <HeaderTestMode isMobile={isMobile} />}
        <HeaderUserInfo
          fname={fname}
          lname={lname}
          setSettingAccountOpen={setSettingAccountOpen}
          isHasSubscription={!!user_subscription}
          isHasCart={!!count}
          accountRef={accountRef}
        />
        <UserMenu
          isSettingOpen={isSettingAccountOpen}
          hideSetting={hideSetting}
          additionalRefs={[hamburgerRef, accountRef]}
        />

        {showSubscriptionIcon && userSubscriptionIcon && (
          <HeaderSubscription
            style={styles}
            subscriptionTitle={userSubscriptionIcon.name}
            isDetectedSubscriptionIssue={detected_subscription_issue}
            lostCredit={lost_credit}
          />
        )}
      </FlexContainer>
      {!!count && <Cart count={count} />}
      <Hamburger
        hamburgerRef={hamburgerRef}
        toggleMenu={() => setSettingAccountOpen((prev) => !prev)}
        isSettingAccountOpen={isSettingAccountOpen}
      />
    </FlexContainer>
  );
});

export default HeaderAuthenticated;
