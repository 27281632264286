export interface ICard {
  card: Array<ICard>;
  cardId: number | null;
  cover: string | null;
  name: string | null;
}

export const initialState: ICard = {
  card: [],
  cardId: null,
  cover: null,
  name: null,
};
