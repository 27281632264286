import { FC, useMemo } from "react";
import { useHistory } from "react-router";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Title, Typography } from "../../ui/typography";

import { closePopup } from "../../../redux/portal-popup/slice";
import getCategories from "../../../redux/categories/selectors";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import { ICategory } from "../../../interfaces/categories/ICategories";
import { linkCategory } from "../../../constants/category/category";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const CustomCardsSuccessPopup: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const categories = useAppSelector(getCategories);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const customIdCategories = useMemo(() => {
    const custom = categories.find(({ taxonomy }) => taxonomy === "CUSTOM");
    const customize = categories.find(
      ({ taxonomy }) => taxonomy === "CUSTOMIZED"
    );

    return {
      custom,
      customize,
    };
  }, [categories]);

  const toCategory = async (customCategory: ICategory) => {
    if (customCategory.taxonomy === "CUSTOM") {
      history.push({
        pathname: `/${linkCategory.pathname}/my-custom-cards`,
        state: {
          categoryId: customCategory.id as number,
        },
      });
    }
    if (customCategory.taxonomy === "CUSTOMIZED") {
      history.push({
        pathname: `/${linkCategory.pathname}/customizable-cards`,
        state: {
          categoryId: customCategory.id as number,
        },
      });
    }

    await dispatch(closePopup());
  };

  return (
    <div style={{ padding: ".35rem .84rem" }}>
      {isMobile ? null : (
        <>
          <Title style={{ fontSize: "1.5rem" }}>Success</Title>
          <FlexContainer justify='start'>
            <Typography
              fontSize='1rem'
              lineHeight='2rem'
              style={{ maxWidth: "90%", marginTop: "1rem" }}
            >
              Your custom card has been created and is saved in the “My Custom
              Cards” category!
            </Typography>
          </FlexContainer>
        </>
      )}

      <FlexContainer
        justify='space-between'
        direction={isMobile ? "column" : "row"}
        style={{ marginTop: isMobile ? ".5rem" : "3rem" }}
      >
        <FlexContainer justify='center'>
          <ButtonAction
            style={
              isMobile
                ? {
                    marginBottom: "1rem",
                  }
                : {
                    marginRight: "1rem",
                  }
            }
            onClick={() => toCategory(customIdCategories.customize!)}
          >
            Customize more cards
          </ButtonAction>
        </FlexContainer>
        <FlexContainer justify='center'>
          <ButtonAction onClick={() => toCategory(customIdCategories.custom!)}>
            Take me there
          </ButtonAction>
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

export default CustomCardsSuccessPopup;
