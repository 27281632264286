import { FC } from "react";
import { IControlledLogo } from "../../interfaces/logo/ILogo";

const MultiStepLogo: FC<IControlledLogo> = ({ isActive = false }) => {
  const backgroundColor = isActive ? "#F85E49" : "#E9E9E0";
  const color = isActive ? "#FFFFFF" : "#a7a794";
  return (
    <svg
      width='31px'
      height='31px'
      viewBox='0 0 31 31'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ width: "100%", height: "100%" }}
    >
      <g
        id='Design'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Address-Book' transform='translate(-1271.1274, -519.9417)'>
          <g id='Group-10-Copy' transform='translate(1271.1274, 519.9417)'>
            <circle
              id='Oval-Copy-8'
              fill={backgroundColor || "#F85E49"}
              cx='15.5'
              cy='15.5'
              r='15.5'
            />
            <g
              id='Group-4-Copy-2'
              transform='translate(15.5, 15.5) scale(-1, 1) translate(-15.5, -15.5)translate(8.3489, 8.5)'
            >
              <line
                x1='3.14285714'
                y1='2.15539307'
                x2='11.1428571'
                y2='2.15539307'
                id='Line'
                stroke={color || "#FFFFFF"}
                strokeWidth='1.2173913'
                strokeLinecap='square'
              />
              <line
                x1='3.14285714'
                y1='11.7741829'
                x2='11.1428571'
                y2='11.7741829'
                id='Line-Copy-3'
                stroke={color || "#FFFFFF"}
                strokeWidth='1.2173913'
                strokeLinecap='square'
              />
              <line
                x1='3.66308786'
                y1='10.6035851'
                x2='11.1825209'
                y2='3.08415203'
                id='Line-Copy'
                stroke={color || "#FFFFFF"}
                strokeWidth='1.2173913'
                strokeLinecap='square'
              />
              <circle
                id='Oval'
                fill={color || "#FFFFFF"}
                cx='2.2258171'
                cy='2.2258171'
                r='2.2258171'
              />
              <circle
                id='Oval-Copy-2'
                fill={color || "#FFFFFF"}
                cx='2.2258171'
                cy='11.7741829'
                r='2.2258171'
              />
              <circle
                id='Oval-Copy'
                fill={color || "#FFFFFF"}
                cx='12.0764495'
                cy='2.2258171'
                r='2.2258171'
              />
              <circle
                id='Oval-Copy-3'
                fill={color || "#FFFFFF"}
                cx='12.0764495'
                cy='11.7741829'
                r='2.2258171'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MultiStepLogo;
