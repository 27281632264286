import { NotificationsList } from "../../interfaces/notifications/INotification";

interface NotificationsInitialState {
  notifications: NotificationsList;
  heightNotification: number;
}

const initialState: NotificationsInitialState = {
  notifications: [],
  heightNotification: 0,
};

export default initialState;
