import { userInstance } from "../hooks/instance/useInstance";
import { ILinkAuth } from "../interfaces/auth/IAuth";

const hubSpotApi = {
  getHubSpotUsr: async (hubStopToken: ILinkAuth) => {
    return userInstance.post("hubspot/linkOAuth", hubStopToken);
  },
};

export default hubSpotApi;
