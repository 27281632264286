import { userInstance } from "../hooks/instance/useInstance";
import {
  IFontsCustomizerResponse,
  IFontsListResponse,
} from "../interfaces/fonts/IFont";

const fontsApi = {
  list: async () => {
    return userInstance.get<IFontsListResponse>("/fonts/list");
  },
  customizer: async () => {
    return userInstance.get<IFontsCustomizerResponse>(
      "/fonts/listForCustomizer"
    );
  },
};

export default fontsApi;
