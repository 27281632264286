interface IControl {
  name: string;
  value: string;
}

const controlWithoutInside: IControl[] = [
  {
    name: "Front",
    value: "front",
  },
  {
    name: "Back",
    value: "back",
  },
];

const control: IControl[] = [
  {
    name: "Front",
    value: "front",
  },
  {
    name: "Inside",
    value: "inside",
  },
  {
    name: "Back",
    value: "back",
  },
];

export default {
  controlWithoutInside,
  control,
};
