import React, { FC } from "react";

import BoxStyled from "./box.styled";

interface IProps {
  children?: React.ReactNode;
  style?: Object;
  className?: string;
  containerRef?: any;
}

const SectionBox: FC<IProps> = ({
  children,
  style,
  className,
  containerRef,
}) => {
  return (
    <BoxStyled ref={containerRef} className={className} style={style}>
      {children}
    </BoxStyled>
  );
};

export default SectionBox;
