import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IHubStopUser, initialState } from "./types";
import fetchHubSpot from "./thunks";

const hubSpotSlice = createSlice({
  name: "hubspot",
  initialState,
  reducers: {
    setHubSpotInfo: (state, action: PayloadAction<IHubStopUser>) => {
      const { payload } = action;
      state.user = payload;
      state.use = true;
    },
    clearHubSpotUser: (state) => {
      state.user = null;
    },
    setHsToken: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.hsToken = payload;
      state.use = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHubSpot.fulfilled, (state) => {
      state.use = true;
    });
    builder.addCase(fetchHubSpot.rejected, (state) => {
      state.use = false;
    });
    builder.addCase(fetchHubSpot.pending, (state) => {
      state.use = false;
    });
  },
});

export const { setHubSpotInfo, clearHubSpotUser, setHsToken } =
  hubSpotSlice.actions;

export default hubSpotSlice.reducer;
