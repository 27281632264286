import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { useGoogleOneTapLogin } from "@react-oauth/google";

import { useAppDispatch } from "../../../hooks/redux/useRedux";
import { IAuthProvider } from "../../../interfaces/auth/IAuthProvider";
import usePopup from "../../../hooks/popup/usePopup";
import { TextLink } from "../../ui/layout";

import "./style.scss";
import navigation from "../../../constants/navigation";
import useHubSpotUser from "../../../hooks/hubSpot/useHubSpotUser";

const HeaderLink: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setAuthProvider } = useHubSpotUser();
  const isSignInPathname = location.pathname === navigation.auth.signin;

  const redirect = isSignInPathname
    ? navigation.auth.signup
    : navigation.auth.signin;
  const link = isSignInPathname ? "Sign Up" : "Sign In";

  const { openErrorPopup } = usePopup();

  useGoogleOneTapLogin({
    promptMomentNotification: () => history.action === "POP",
    onSuccess: (credentialResponse) => {
      if (!credentialResponse.credential) return;
      const authCandidate: IAuthProvider = {
        access_token: credentialResponse?.credential,
        client: "web2",
        provider: "google",
      };
      setAuthProvider(authCandidate);
    },
    onError: () => {
      dispatch(openErrorPopup("Login Failed"));
    },
  });

  return (
    <Link to={redirect}>
      <TextLink>{link}</TextLink>
    </Link>
  );
};

export default HeaderLink;
