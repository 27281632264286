import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  initialState,
  IActionStep,
  IActionChangeDate,
  IActionSetRecipients,
  IActionSetCardInfo,
  IAutomationType,
  IAutomationSelectCard,
} from "./types";
import {
  checkDefaultAutomation,
  deleteAutomation,
  getAutomation,
  getAutomationList,
} from "./thunks";

const birthdayCardsSlice = createSlice({
  name: "automations",
  initialState,
  reducers: {
    nextAutomationStep: (state) => {
      state.step = Math.min(state.step + 1, 4);
    },
    setActiveAutomationStep: (state, action: PayloadAction<IActionStep>) => {
      const { activeStep } = action.payload;
      if ((state.step || 1) > activeStep) {
        state.step = Math.max(activeStep, 1);
      }
    },
    setActiveAutomationStepOnEdit: (
      state,
      action: PayloadAction<IActionStep>
    ) => {
      const { activeStep } = action.payload;
      if (activeStep <= 4) {
        state.step = Math.max(activeStep, 1);
      }
    },
    setDisableStepSwitch: (state, action: PayloadAction<boolean>) => {
      state.isDisableStepSwitch = action.payload;
    },
    setDisableStepSwitchByError: (state, action: PayloadAction<string>) => {
      state.disableStepSwitchByError = action.payload;
    },
    setActiveAutomationType: (
      state,
      action: PayloadAction<IAutomationType>
    ) => {
      if (action.payload) {
        state.automationType = action.payload;
      }
    },
    defaultAutomationStep: (state) => {
      state.step = 1;
    },
    setCorrectBirthdayCardList: (state, action: PayloadAction<boolean>) => {
      state.notCorrectBirthdayCardList = action.payload;
    },
    changeDate: (state, action: PayloadAction<IActionChangeDate>) => {
      const { start_date, end_date } = action.payload;
      if (start_date) {
        state.newCardInfo.start_date = start_date;
      }
      if (end_date) {
        state.newCardInfo.end_date = end_date;
      }
    },
    toggleNoEndDate: (state) => {
      state.newCardInfo.noEndDate = !state.newCardInfo.noEndDate;
    },
    setAutomationRecipients: (
      state,
      action: PayloadAction<IActionSetRecipients>
    ) => {
      const { address_ids } = action.payload as IActionSetRecipients;
      state.newCardInfo.address_ids = address_ids;
    },
    toggleDefaultAutomation: (state) => {
      state.newCardInfo.is_default = !state.newCardInfo.is_default;
    },
    setAutomationSelectCard: (
      state,
      action: PayloadAction<IAutomationSelectCard>
    ) => {
      const { card_id, ...rest } = action.payload;
      state.newCardInfo.card = rest;
      state.newCardInfo.card_id = card_id;
    },
    setAutomationInfo: (state, action: PayloadAction<IActionSetCardInfo>) => {
      const { insert_id, giftCard, denomination_id, card_id } = action.payload;
      if (insert_id) {
        state.newCardInfo.insert_id = insert_id;
      }
      if (giftCard) {
        state.newCardInfo.giftCard_id = giftCard;
      }
      if (denomination_id) {
        state.newCardInfo.denomination_id = denomination_id;
      }
      if (card_id) {
        state.newCardInfo.card_id = card_id;
      }
    },
    setAutomationInit: (state) => {
      state.editCardId = initialState.editCardId;
      state.isDefaultAutomation = initialState.isDefaultAutomation;
      state.newCardInfo = initialState.newCardInfo;
      state.automationType = initialState.automationType;
    },
    setAutomationEditId: (
      state,
      { payload: { value } }: PayloadAction<{ value: number | null }>
    ) => {
      state.editCardId = value;
    },
    setInitAutomationList: (state) => {
      state.cards = [];
      state.nextPage = 1;
      state.newCardInfo.address_ids = [];
    },
    setInternationalRecipient: (state, action: PayloadAction<boolean>) => {
      state.internationalRecipient = action.payload;
    },
    setDefaultAutomationInit: (state) => {
      state.newCardInfo.is_default = false;
    },
    setAutomationDirectly: (state, action: PayloadAction<object>) => {
      state.newCardInfo = { ...state.newCardInfo, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkDefaultAutomation.fulfilled, (state, { payload }) => {
      const { exist } = payload;
      state.isDefaultAutomation = exist;
    });
    builder.addCase(getAutomationList.fulfilled, (state, { payload }) => {
      const { cards } = payload;
      state.cards = state.cards.concat(cards);
      state.nextPage += 1;
      state.isLoading = false;
      state.allPage = payload.pages;
      state.isListEnd =
        +payload.page === payload.pages || +payload.page > payload.pages;
    });
    builder.addCase(getAutomationList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAutomationList.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAutomation.fulfilled, (state, { payload }) => {
      const { id } = payload;
      state.cards = state.cards.filter((card) => card.id !== id);
    });
    builder.addCase(getAutomation.fulfilled, (state, { payload }) => {
      if (payload?.details) {
        const { details } = payload;

        state.newCardInfo = {
          ...initialState.newCardInfo,
          // card_id: details.id,
          card_id: details?.card.id,
          card: details.card,
          start_date: new Date(details.start_date).getTime(),
          end_date: details.end_date
            ? new Date(details.end_date).getTime()
            : null,
          noEndDate: details.no_end_date,
          address_from_id: details.address_from_id,
          address_from: details.address_from,
          address_ids: details.address_ids || [],
          is_default: details.is_default || false,
          insert_id: details.insert_id ? details.insert_id.toString() : "",
          giftCard: details.giftCard ? details.giftCard : null,
          denomination: details.denomination ? details.denomination : null,
          denomination_id: details.denomination?.id
            ? details.denomination?.id.toString()
            : "",
          giftCard_id: details.giftCard?.id
            ? details.giftCard?.id.toString()
            : "",
          font: details.font ? details.font : "",
          auto_font_size: !!details.auto_font_size,
          message: details.message ? details.message : "",
          name: details.name ? details.name : "",
          wishes: details.wishes ? details.wishes : "",
          signature_id: details?.signature_id ? details?.signature_id : "",
          signature2_id: details?.signature2_id ? details?.signature2_id : "",
          signature2_preview: details?.signature2_preview
            ? details?.signature2_preview
            : "",
          signature_preview: details?.signature_preview
            ? details?.signature_preview
            : "",
          delivery_confirmation: details.delivery_confirmation || 0,
        };
        state.editCardId = details.id;
      }
    });
  },
});

export const {
  nextAutomationStep,
  setActiveAutomationStep,
  setActiveAutomationStepOnEdit,
  setActiveAutomationType,
  defaultAutomationStep,
  changeDate,
  toggleNoEndDate,
  setAutomationRecipients,
  toggleDefaultAutomation,
  setAutomationInfo,
  setAutomationInit,
  setCorrectBirthdayCardList,
  setAutomationEditId,
  setInitAutomationList,
  setInternationalRecipient,
  setDefaultAutomationInit,
  setAutomationSelectCard,
  setAutomationDirectly,
  setDisableStepSwitch,
  setDisableStepSwitchByError,
} = birthdayCardsSlice.actions;

export default birthdayCardsSlice.reducer;
