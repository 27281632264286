import React, { ChangeEvent, FC, memo } from "react";
import { IRadioInput } from "../../../radio-group/customRadioGroup";
import { FlexContainer } from "../../layout";

import {
  RadioStyled,
  LabelStyled,
  InputStyled,
  CheckmarkStyled,
} from "./radio.styled";

interface IRadio {
  radioGroup: Array<IRadioInput>;
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  defaultTitle?: boolean;
  square?: boolean;
  name?: string;
  businessName?: string;
  margin?: string;
}

const Radio: FC<IRadio> = ({
  radioGroup,
  value,
  onChange,
  style,
  defaultTitle,
  square,
  name = "",
  businessName = "",
  margin,
}) => {
  const showName = !!businessName || !!name;
  const addressName = name + (businessName ? `, ${businessName}` : "");

  return (
    <RadioStyled>
      {radioGroup.map((radio) => (
        <LabelStyled key={radio.id} style={style}>
          {showName ? (
            <FlexContainer
              direction='column'
              align='flex-start'
              justify='start'
              style={{ marginLeft: margin || "0.5rem" }}
            >
              <span className='address-info__detail_company'>
                {addressName}
              </span>
              {radio.label}
              {defaultTitle && " (Default)"}
            </FlexContainer>
          ) : (
            <>
              {radio.label}
              {defaultTitle && " (Default)"}
            </>
          )}

          <InputStyled
            onChange={onChange}
            type='radio'
            name='radio'
            checked={value}
          />
          <CheckmarkStyled
            className={`${value ? "active" : ""}`}
            square={square}
          />
        </LabelStyled>
      ))}
    </RadioStyled>
  );
};

export default memo(Radio);
