import { FC } from "react";
import { Link } from "react-router-dom";
import AirplaneLottie from "../../components/lottie/airplane.lottie";
import { FlexContainer } from "../../components/ui/layout";
import { Typography } from "../../components/ui/typography";

import navigation from "../../constants/navigation";

import notFoundImage from "../../assets/images/404-robot.svg";
import { useAppSelector } from "../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

import "./style.scss";

const NotFound: FC = () => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  return (
    <FlexContainer direction='column' align='center' className='not-found'>
      <FlexContainer direction='column' className='not-found__text-section'>
        <Typography className='not-found-title'>404 - Uh Oh!</Typography>
        <Typography>
          The page you have been looking for can&apos;t be found.
        </Typography>
        <Link to={navigation.redirect} className='not-found-link'>
          Take me Home
        </Link>
      </FlexContainer>
      <div className='not-found__image'>
        <img src={notFoundImage} alt='404' />
      </div>
      {!isMobile ? <AirplaneLottie className='not-found__airplane' /> : null}
    </FlexContainer>
  );
};

export default NotFound;
