import { useEffect } from "react";

import loadAsyncScript from "../../helpers/load-script";
import { IAuthProvider } from "../../interfaces/auth/IAuthProvider";
import useHubSpotUser from "../hubSpot/useHubSpotUser";

const src =
  "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";

const useAppleSignIn = () => {
  const { setAuthProvider } = useHubSpotUser();
  const CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;

  const loadAppleJsFramework = () => {
    if (window.AppleID) return Promise.resolve();

    return loadAsyncScript(src);
  };

  const _callAuthProvider = async (response: any) => {
    const {
      authorization: { id_token },
    } = response;

    if (!id_token) return;

    const signInCandidate: IAuthProvider = {
      client: "web2",
      access_token: id_token,
      provider: "apple",
    };

    setAuthProvider(signInCandidate);
  };

  const signIn = async () => {
    try {
      const res = await window.AppleID.auth.signIn();
      _callAuthProvider(res);
    } catch (err) {
      console.log(err);
    }
  };

  const initSignIn = () => {
    window.AppleID.auth.init({
      clientId: CLIENT_ID,
      scope: "name email",
      state: "origin:web",
      usePopup: true,
      redirectURI: REDIRECT_URI,
    });
  };

  useEffect(() => {
    const load = async () => {
      await loadAppleJsFramework();
      initSignIn();
    };

    load();
  }, []);

  return {
    signIn,
  };
};

export default useAppleSignIn;
