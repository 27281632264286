import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import multiStepApi from "../../api/multi-step.api";
import { IThunkError } from "../../interfaces/redux/IThunkError";
import {
  IEnrollPayload,
  IMultiCampaign,
  IMultiCampaignPayload,
  IPaginationPayload,
} from "./types";

export const getMultiCampaigns = createAsyncThunk<any, IPaginationPayload>(
  "get/multiCampaign",
  async (payload) => {
    const res = await multiStepApi.getAllCampaigns(payload);
    const { rows: multiStepCampaigns, page, total_pages } = res.data;
    return { multiStepCampaigns, page, total_pages };
  }
);

export const removeMultiStepCampaigns = createAsyncThunk<
  number | null,
  number,
  { rejectValue: IThunkError }
>("delete/multiCampaign/:id", async (payload, { rejectWithValue }) => {
  try {
    const res = await multiStepApi.removeCampaign(payload);
    if (!res) return null;
    return payload;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const getMultiCampaignById = createAsyncThunk<
  IMultiCampaign,
  number,
  { rejectValue: IThunkError }
>("get/multiCampaign/:id", async (payload, { rejectWithValue }) => {
  try {
    const res = await multiStepApi.getCampaignById(payload);
    const { details } = res.data;
    return details;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const updateMultiStepCampaigns = createAsyncThunk<
  number | null,
  IMultiCampaignPayload,
  { rejectValue: IThunkError }
>("put/multiCampaign/:id", async (payload, { rejectWithValue }) => {
  try {
    if (!payload?.id) return null;
    await multiStepApi.updateCampaign(payload);
    return payload.id;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const createMultiStepCampaigns = createAsyncThunk<
  number | null,
  IMultiCampaignPayload,
  { rejectValue: IThunkError }
>("create/multiCampaign", async (payload, { rejectWithValue }) => {
  try {
    const res = await multiStepApi.createCampaign(payload);
    if (!res.data) return null;
    const { campaign_id } = res.data;
    return campaign_id;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const updateCampaignAddressesById = createAsyncThunk<
  number | null,
  IEnrollPayload,
  { rejectValue: IThunkError }
>("multiCampaign/:id/enroll", async (payload, { rejectWithValue }) => {
  try {
    const res = await multiStepApi.updateCampaignAddresses(payload);
    if (!res.data) return null;
    const { campaign_id } = res.data;
    return campaign_id;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});
