import { FC, useMemo } from "react";

import { FlexContainer } from "../../../ui/layout";
import { setPortalPopup } from "../../../../redux/portal-popup/slice";
import typePopups from "../../../../constants/popup/type-popup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/redux/useRedux";
import checkAuth from "../../../../redux/checkauth/selectors";

const { EMAIL_CHANGE } = typePopups;

const AccountConvert: FC = () => {
  const dispatch = useAppDispatch();

  const { last: lastTypeLogin } = useAppSelector(checkAuth);

  const lastTypeEnterLogin = useMemo(() => {
    if (lastTypeLogin) {
      return lastTypeLogin[0].toUpperCase() + lastTypeLogin.slice(1);
    }
    return "";
  }, [lastTypeLogin]);

  const openCheckAuthChange = () => {
    dispatch(
      setPortalPopup({
        title: `Convert ${lastTypeEnterLogin} to Email `,
        typePopup: "form",
        form: EMAIL_CHANGE,
      })
    );
  };

  if (lastTypeLogin === "email") return null;

  return (
    <FlexContainer className='nav__links' justify='space-between'>
      <div>Logged with {lastTypeEnterLogin}</div>
      <div className='nav__links_action'>
        <div onClick={openCheckAuthChange}>Convert</div>
      </div>
    </FlexContainer>
  );
};

export default AccountConvert;
