import { useCallback, useEffect, MouseEvent, useState } from "react";
import { useLocation } from "react-router";

import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import { closePopup } from "../../redux/portal-popup/slice";
import { getAddress, updateBillingInfo } from "../../redux/address/thunks";
import { getDefaultPhysicalAddress } from "../../redux/address/selectors";
import { calcTaxes } from "../../redux/orders/thunks";
import { getCouponCode } from "../../redux/coupon/selectors";
import { fetchSubscriptions } from "../../redux/subscriptions/thunks";

import useInput from "../input/useInput";
import useSelect from "../input/useSelect";
import navigation from "../../constants/navigation";

type Event = MouseEvent<HTMLButtonElement>;

const usePhysicalAddress = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [address, setAddress] = useState(false);

  const coupon = useAppSelector(getCouponCode);
  const defaultPhysicalAddress = useAppSelector(getDefaultPhysicalAddress);

  const newAddress = useInput("");
  const newZip = useInput("");
  const newCountry = useSelect("");

  useEffect(() => {
    dispatch(getAddress());
  }, []);

  useEffect(() => {
    if (defaultPhysicalAddress) {
      const { zip, address, country_id } = defaultPhysicalAddress;
      newZip.onChange(zip);
      newCountry.onChange(country_id);
      newAddress.onChange(address);
    }
  }, []);

  const updateBilling = useCallback(
    async (event: Event) => {
      event.preventDefault();
      if (!newAddress.value || !newZip.value || !newCountry.value) {
        setAddress(true);
        return;
      }
      setAddress(false);
      const newPhysicalAddress = {
        country_id: +newCountry.value,
        zip: newZip.value,
        address: newAddress.value,
      };
      if (!!newAddress && !!newZip) {
        await dispatch(updateBillingInfo(newPhysicalAddress));
        if (location.pathname.includes(navigation.myBasket)) {
          dispatch(calcTaxes({ couponCode: coupon }));
        }
        if (location.pathname === navigation.subscriptions) {
          dispatch(fetchSubscriptions());
        }
        dispatch(closePopup());
      }
    },
    [newAddress, newZip, newCountry]
  );

  return {
    newAddress,
    address,
    newZip,
    newCountry,
    updateBilling,
    defaultPhysicalAddress,
  };
};

export default usePhysicalAddress;
