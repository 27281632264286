import { ITemplateCategory } from "../../interfaces/templates/ITemplateCategory";

const all = {
  label: "All",
  value: "all",
  id: NaN,
};

const custom = {
  label: "Custom",
  value: "0",
  id: 0,
};

const useTemplatesCategories = () => {
  const categoriesSelect = (categories: Array<ITemplateCategory>) => {
    const _categories = categories.map(({ name, id }) => ({
      label: name,
      value: id.toString(),
      id,
    }));

    _categories.unshift(all);
    _categories.push(custom);

    return _categories;
  };

  return {
    categoriesSelect,
  };
};

export default useTemplatesCategories;
