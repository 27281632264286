import { FC } from "react";
import { Link } from "react-router-dom";

import { FlexContainer, TextLink } from "../../ui/layout";
import navigation from "../../../constants/navigation";

interface IAccountIntegrations {
  hideSetting: () => void;
}

const Integrations: FC<IAccountIntegrations> = ({ hideSetting }) => {
  return (
    <FlexContainer className='nav__links' justify='space-between'>
      <FlexContainer width='auto'>
        <Link to={navigation.integrations}>
          <TextLink color='#fff' onClick={hideSetting}>
            Integrations
          </TextLink>
        </Link>

        {/* <Tooltip message='Test usage of the system without writing cards. Credit cards are authorized but not charged. Turn on Test Mode during testing of the API.' /> */}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Integrations;
