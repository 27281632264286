import { FC } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import { userInstance } from "../../../hooks/instance/useInstance";

import navigation from "../../../constants/navigation";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Typography } from "../../ui/typography";

import { getTextPortalPopup } from "../../../redux/portal-popup/selectors";
import { closePopup } from "../../../redux/portal-popup/slice";
import { setLastFontFamily } from "../../../redux/auth/slice";
import { setActiveOrderEmpty } from "../../../redux/order/slice";
import { setAutomationInit } from "../../../redux/automation/slice";
import { removeTemplateFromEdit } from "../../../redux/templates/slice";
import { fetchListTemplates } from "../../../redux/templates/thunks";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const ProceedAnyway: FC = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const popupData: any = useAppSelector(getTextPortalPopup);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const { method, url, payload, message } = popupData;

  const multiCampaign = () => history.push(navigation.multiStepCampaign);

  const placeBasket = (parsedData: any) => {
    history.push(navigation.myBasket);
    dispatch(setLastFontFamily(parsedData?.font));
    dispatch(setActiveOrderEmpty());
  };

  const basketUpdate = (parsedData: any) => {
    dispatch(setLastFontFamily(parsedData?.font));
    dispatch(setActiveOrderEmpty());
    history.push(navigation.myBasket);
  };
  const automation = () => {
    dispatch(setActiveOrderEmpty());
    history.push(navigation.automationCards);
    dispatch(setAutomationInit());
  };

  const templates = async (parsedData: any) => {
    dispatch(removeTemplateFromEdit(parsedData.id));
    await dispatch(fetchListTemplates(null));
  };

  const onRedirect = (parsedData: any, url: string) => {
    let endpoint = "";
    if (url.includes("multiCampaign")) {
      endpoint = "multiCampaign";
    } else if (url.includes("placeBasket")) {
      endpoint = "placeBasket";
    } else if (url.includes("automation")) {
      endpoint = "automation";
    } else if (url.includes("templates")) {
      endpoint = "templates";
    } else if (url.includes("/basket/update")) {
      endpoint = "updateBasket";
    }

    switch (endpoint) {
      case "multiCampaign":
        return multiCampaign();
      case "placeBasket":
        return placeBasket(parsedData);
      case "automation":
        return automation();
      case "templates":
        return templates(parsedData);
      case "updateBasket":
        return basketUpdate(parsedData);
      default:
        return () => {};
    }
  };

  const onClosePopUp = () => dispatch(closePopup());

  const onProceed = async () => {
    const parsedData = JSON.parse(payload);

    const data = {
      ...parsedData,
      supressWarnings: true,
    };
    onClosePopUp();
    try {
      // @ts-ignore
      const res = await userInstance[method](url, data);
      if (res.status === 200) {
        history.block(() => undefined);
        onRedirect(parsedData, url);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <FlexContainer justify='center'>
        <div style={isMobile ? { width: "80vw" } : { minWidth: "100%+20wv" }}>
          <Typography fontSize='1rem' lineHeight='1.5rem'>
            {message}
          </Typography>
        </div>
      </FlexContainer>
      <FlexContainer
        style={{
          gap: "1rem",
          marginTop: "2rem",
        }}
      >
        <ButtonAction width='100%' onClick={onProceed}>
          proceed anyway
        </ButtonAction>
        <ButtonAction width='100%' onClick={onClosePopUp}>
          go back
        </ButtonAction>
      </FlexContainer>
    </div>
  );
};

export default ProceedAnyway;
