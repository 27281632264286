import React, { FC, memo, useContext, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { CSSObject } from "styled-components";

import navigation from "../../../constants/navigation";
import PlanLogo from "../../logo/PlanLogo";

import { CloseButtonWrapper } from "../../popups/common/popup.styled";

import ButtonClose from "../../ui/buttons/btn-close/btn-close.common";
import useCreditCards from "../../../hooks/credit-cards/useCreditCards";
import CreditCardForm from "../../credit-card/add-card/new-card";
import { IValue } from "../../ui/common/select/select.common";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import SubscriptionMessageIssue from "../../../pages/subscription/Subscription.message-issue";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";
import { changeDetectedSubscriptionIssue } from "../../../redux/subscriptions/slice";
import { getDetectedIssueLater } from "../../../redux/subscriptions/selector";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface IHeaderSubscription {
  style: CSSObject;
  subscriptionTitle: string;
  isDetectedSubscriptionIssue: boolean;
  lostCredit?: number;
}

const HeaderSubscription: FC<IHeaderSubscription> = ({
  style,
  subscriptionTitle,
  isDetectedSubscriptionIssue,
  lostCredit = 0,
}) => {
  const dispatch = useAppDispatch();

  const { openConfirmationPopup, closeConfirmationPopup } = useContext(
    ConfirmationPopupContext
  );

  const location = useLocation();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const isDetectedIssueLater = useAppSelector(getDetectedIssueLater);

  const { cardInfo, createNewCreditCard, creditCardIcon, setCreditCardNumber } =
    useCreditCards(!isDetectedSubscriptionIssue); // fetch credit cards list only if user have a subscription issue

  const createNewCreditCardIssue = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    nameOfCard: string,
    cardNumber: string,
    cvvCode: string,
    address: string,
    zipCode: string,
    monthSelectValue: string | IValue,
    yearSelectValue: string | IValue,
    countrySelectValue: string | IValue,
    isDetectedSubscriptionIssue: boolean
  ) => {
    await createNewCreditCard(
      event,
      nameOfCard,
      cardNumber,
      cvvCode,
      address,
      zipCode,
      monthSelectValue,
      yearSelectValue,
      countrySelectValue,
      isDetectedSubscriptionIssue
    );
    closeConfirmationPopup();
  };

  const openAddCardPopup = () => {
    openConfirmationPopup({
      title: "Update Your Credit Card to Continue your Subscription",
      subtitle: (
        <CreditCardForm
          onCancel={closeConfirmationPopup}
          buttonsPosition='column'
          widthButton={16.33333}
          widthForm={100}
          cardInfo={cardInfo}
          createNewCreditCard={createNewCreditCardIssue}
          creditCardIcon={creditCardIcon}
          setCreditCardNumber={setCreditCardNumber}
          isDetectedSubscriptionIssue={isDetectedSubscriptionIssue}
        />
      ),
      onConfirm: () => {},
      textButtonConfirm: "add card",
      textButtonCancel: "cancel",
      positionButtons: "flex-start",
      styleForTextSub: { display: "block" },
      showButtons: false,
    });
  };

  const openIssueDetectedPopup = () => {
    openConfirmationPopup({
      title: "Issue Detected",
      subtitle: <SubscriptionMessageIssue lostCredits={lostCredit} />,
      onConfirm: openAddCardPopup,
      closeAfterConfirm: false,
      textButtonConfirm: "update now",
      textButtonCancel: "do it later",
      onCancel: () => dispatch(changeDetectedSubscriptionIssue(true)),
      positionButtons: "flex-start",
    });
  };

  useEffect(() => {
    if (isDetectedSubscriptionIssue) {
      if (location.pathname === navigation.home && !isDetectedIssueLater) {
        openIssueDetectedPopup();
      }
      if (location.pathname.includes(navigation.subscriptions)) {
        openAddCardPopup();
      }
    }
  }, [location.pathname, isDetectedSubscriptionIssue]);

  const styleForIconIssue: CSSObject = useMemo(() => {
    if (isMobile)
      return {
        width: "auto",
        height: "auto",
        position: "absolute",
        bottom: "-0.9rem",
      };
    return {
      width: "auto",
      height: "auto",
      position: "absolute",
      top: "0.1rem",
      right: "0.6rem",
    };
  }, [isMobile]);

  return (
    <div>
      {isDetectedSubscriptionIssue && (
        <CloseButtonWrapper style={{ width: "1.5rem", height: "1.5rem" }}>
          <ButtonClose alertButton style={styleForIconIssue} />
        </CloseButtonWrapper>
      )}
      <Link style={style} to={navigation.subscriptions}>
        <PlanLogo title={subscriptionTitle} />
      </Link>
    </div>
  );
};

export default memo(HeaderSubscription);
