import { FC } from "react";

import Portal from "../portal/portal";
import CustomLoader from "../ui/common/loaders/custom.loader";
import { useAppSelector } from "../../hooks/redux/useRedux";
import { PopupBackground } from "../popups/common/popup.styled";
import getSpinnerStatus from "../../redux/spinner/selectors";

const Loader: FC = () => {
  const isLoad = useAppSelector(getSpinnerStatus);

  if (isLoad) {
    return (
      <Portal>
        <PopupBackground>
          <CustomLoader />
        </PopupBackground>
      </Portal>
    );
  }

  return null;
};

export default Loader;
