import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import notificationsApi from "../../api/notifications.api";
import {
  NotificationId,
  NotificationsList,
} from "../../interfaces/notifications/INotification";
import { IThunkError } from "../../interfaces/redux/IThunkError";

export const getNotifications = createAsyncThunk<NotificationsList>(
  "notifications/list",
  async () => {
    const res = await notificationsApi.list();

    const { notifications } = res.data;

    return notifications;
  }
);

export const markNotificationAsRead = createAsyncThunk<
  NotificationId,
  NotificationId,
  {
    rejectValue: IThunkError;
  }
>("notifications/mark/read", async (payload, { rejectWithValue }) => {
  try {
    await notificationsApi.markAsRead(payload);

    return payload;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

    throw err;
  }
});
