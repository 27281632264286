import { FC, useRef, RefObject, useEffect } from "react";
import classNames from "classnames";

import { FlexContainer } from "../../ui/layout";

import { useAppDispatch } from "../../../hooks/redux/useRedux";
import useOnClickOutside from "../../../hooks/utils/useOnClickOutside";

import getCheckAuth from "../../../redux/checkauth/thunks";

import AccountInfo from "./account/account.info";
import UserSubscription from "./user.subscription";
import AccountNavigation from "./account/account.navigation";

import "./styles.scss";

interface IUserMenu {
  isSettingOpen?: boolean;
  hideSetting: () => void;
  additionalRefs?: RefObject<HTMLDivElement>[];
}

const UserMenu: FC<IUserMenu> = ({
  isSettingOpen,
  hideSetting,
  additionalRefs = [],
}) => {
  const dispatch = useAppDispatch();

  const menuRef = useRef(null);

  useEffect(() => {
    dispatch(getCheckAuth());
  }, []);

  useOnClickOutside(menuRef, hideSetting, additionalRefs);

  const accountSettings = classNames({
    "account-settings": true,
    "account-settings_show": isSettingOpen,
  });

  return (
    <div className={accountSettings} ref={menuRef}>
      <FlexContainer
        direction='column'
        width='auto'
        align='flex-start'
        className='account-settings__container'
      >
        <AccountInfo />

        <UserSubscription hideSetting={hideSetting} />

        <AccountNavigation hideSetting={hideSetting} />
      </FlexContainer>
    </div>
  );
};

export default UserMenu;
