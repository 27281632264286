import { IGetCustomFieldResponse } from "../../interfaces/custom-fields/responses/ICustomFieldsResponse";

export interface ICustomFields {
  customFields: IGetCustomFieldResponse[];
}

const initialState: ICustomFields = {
  customFields: [],
};

export default initialState;
