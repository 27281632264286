import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import recipientsApi from "../../api/recipients.api";
import {
  IConfirmDeletingRecipientsData,
  IConfirmDeletingRecipientsResponse,
  IRecipient,
  IRecipientImport,
} from "../../interfaces/address/IRecipient";

import { closePopup, setPortalPopup } from "../portal-popup/slice";
import messagePopup from "../../constants/popup/constants";
import { IAutomationsEdit } from "../../constants/automation-compain/automation-types";
import { ISort } from "../../components/address-book/address-book.section";
import { RootState } from "../store";

interface IRecipientResponse {
  addresses: IRecipient[];
}

export interface IGetRecipients {
  getCampaignInfo?: boolean;
  sort?: ISort | null;
}

export interface IInfoDate {
  value: Array<IAutomationsEdit>;
  type: string;
  recipient: IRecipient;
}

export interface IUpdateInfo {
  value: Array<IAutomationsEdit>;
  type: string;
}

export const getRecipients = createAsyncThunk<
  IRecipientResponse,
  boolean | undefined
>("recipient/list", async (getCampaignInfo, { getState }) => {
  const state = getState() as RootState;
  const { sortParams } = state.recipients;
  const res = await recipientsApi.list({ getCampaignInfo, sort: sortParams });

  const { addresses } = res.data;

  return {
    addresses,
  };
});

export const getRecipientById = createAsyncThunk<IRecipient, number>(
  "profile/recipient",

  async (id) => {
    const { recipient } = await recipientsApi.getById(id);

    return recipient;
  }
);

export const addRecipient = createAsyncThunk<
  IRecipient | undefined,
  IRecipient,
  { state: RootState }
>(
  "recipient/add",
  async (recipient, { getState, rejectWithValue, dispatch }) => {
    try {
      const res = await recipientsApi.add(recipient);
      const { recipientTags } = getState();
      const tagsList = recipient.tags as number[];
      const validTagsData = recipientTags.tags.filter((tag) =>
        tagsList?.includes(tag.id)
      );

      if (res) {
        dispatch(closePopup());
        const { address_id } = res.data;

        return {
          id: address_id,
          ...recipient,
          name: `${recipient.first_name} ${recipient.last_name}`,
          tags: validTagsData,
        };
      }
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

      throw err;
    }
  }
);

export const updateRecipient = createAsyncThunk<IInfoDate, IRecipient>(
  "profile/updateRecipient",

  async (recipient, { dispatch }) => {
    const res = await recipientsApi.update(recipient as IRecipient);

    if (res && res?.data.type !== "info") {
      dispatch(closePopup());
      await dispatch(getRecipients(true));
    }

    const { value = [], type = "" } = res?.data as IUpdateInfo;
    return { recipient, value, type };
  }
);

export const deleteRecipient = createAsyncThunk<Array<number>, Array<number>>(
  "profile/deleteRecipient",

  async (ids) => {
    await recipientsApi.delete(ids);

    return ids;
  }
);

export const parseRecipientXlsFile = createAsyncThunk<
  IRecipientImport,
  FormData,
  {
    rejectValue: any;
  }
>("profile/parseXls", async (payload, { dispatch }) => {
  try {
    const res = await recipientsApi.parseXls(payload);

    const {
      correct_file,
      new_address,
      recipients,
      total,
      validate_data,
      update_recipients,
      errors_count,
    } = res.data;

    if (correct_file)
      dispatch(
        setPortalPopup({
          text: messagePopup.importSuccess,
          typePopup: "alert",
          title: "Success",
        })
      );

    return {
      correct_file,
      new_address,
      recipients,
      total,
      validate_data,
      update_recipients,
      errors_count,
    };
  } catch (err) {
    throw err;
  }
});

export const getConfirmationDeletingRecipients = createAsyncThunk<
  IConfirmDeletingRecipientsResponse,
  IConfirmDeletingRecipientsData
>("recipient/confirmationDeletingAddress", async (data) => {
  const res = await recipientsApi.confirmationDeletingRecipients(data);

  if (res.data) {
    const { text, type } = res.data;

    return {
      text,
      type,
    };
  }
});
