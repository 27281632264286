import { FC, useEffect, useMemo } from "react";
import classNames from "classnames";

import { Input } from "../../ui/common/inputs/input.common";
import { ButtonAction, Form } from "../../ui/layout";

import usePassword from "../../../hooks/auth/usePassword";
import useValidPassword from "../../../hooks/input/useValidPassword";
import useConfirmation from "../../../hooks/input/useConfirmation";
import useInput from "../../../hooks/input/useInput";

const PasswordEditPopup: FC = () => {
  const newPassword = useInput("");
  const confirmationPassword = useInput("");

  const { editPasswordSubmit } = usePassword();
  const { isValid, message, setMassage } = useValidPassword();
  const { isConfirmed, confirmationMessage, setConfirmationMassage } =
    useConfirmation("Password");

  const validPass = classNames({
    "error-input-none": true,
    "error-input": !!message,
  });

  const confirmedPass = classNames({
    "error-input-none": true,
    "error-input": !!confirmationMessage,
  });

  useEffect(() => {
    setMassage("");
  }, [newPassword.value]);

  useEffect(() => {
    setConfirmationMassage("");
  }, [newPassword.value, confirmationPassword.value]);

  const validNewPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = isValid(newPassword.value);
    const confirmed = isConfirmed(
      newPassword.value,
      confirmationPassword.value
    );
    if (valid && confirmed) {
      editPasswordSubmit(event);
    }
  };

  const messageValidStyle = useMemo(() => {
    return { marginTop: "-.8rem", marginBottom: ".5rem", maxWidth: "17rem" };
  }, []);

  return (
    <Form onSubmit={validNewPassword} style={{ minWidth: "17rem" }}>
      <Input
        placeholder='Old Password'
        type='password'
        name='old_password'
        style={{ marginBottom: "1rem" }}
        required
        showPasswordIcon
      />
      <Input
        {...newPassword}
        placeholder='New Password'
        type='password'
        name='new_password'
        style={{ marginBottom: "1rem" }}
        required
        showPasswordIcon
      />
      <span className={validPass} style={messageValidStyle}>
        {message}
      </span>
      <Input
        {...confirmationPassword}
        placeholder='Confirm Your New Password'
        type='password'
        name='confirm_password'
        style={{ marginBottom: "1rem" }}
        required
        showPasswordIcon
      />
      <span className={confirmedPass} style={messageValidStyle}>
        {confirmationMessage}
      </span>
      <ButtonAction type='submit'>Change</ButtonAction>
    </Form>
  );
};

export default PasswordEditPopup;
