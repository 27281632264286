import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  MouseEvent,
  useMemo,
  useCallback,
  ChangeEvent,
} from "react";

import { FlexContainer, Form, ButtonAction } from "../../ui/layout";
import { Input } from "../../ui/common/inputs/input.common";
import { IOption, IValue } from "../../ui/common/select/select.common";

import useInput from "../../../hooks/input/useInput";
import useSelect from "../../../hooks/input/useSelect";
import { yearsNumber } from "../../../hooks/credit-cards/creditCard.constant";
import useCountries from "../../../hooks/countries/useCountries";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import { getNewCreditCard } from "../../../redux/credit-cards/selectors";
import { resetNewCreditCard } from "../../../redux/credit-cards/slice";

import CustomSelect from "../../custom-select/CustomSelect";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface ICodeCardInfo {
  name: string;
  size: number;
}

interface ICardInfo {
  niceType: string;
  gaps: number[];
  lengths: number[];
  code: ICodeCardInfo;
}

interface ICreditCardForm {
  onCancel?: Dispatch<SetStateAction<boolean>>;
  buttonsPosition?: "row" | "column";
  widthForm?: number;
  widthButton?: number;
  cardInfo: ICardInfo | null;
  createNewCreditCard: (
    event: MouseEvent<HTMLButtonElement>,
    nameOfCard: string,
    cardNumber: string,
    cvvCode: string,
    address: string,
    zipCode: string,
    monthSelectValue: string | IValue,
    yearSelectValue: string | IValue,
    countrySelectValue: string | IValue,
    isDetectedSubscriptionIssue: boolean
  ) => Promise<void>;
  creditCardIcon: () => JSX.Element | null;
  setCreditCardNumber: (cardNumber: number) => void;
  chooseNewCard?: (id: number) => void;
  isDetectedSubscriptionIssue?: boolean;
}

const CreditCardForm: FC<ICreditCardForm> = ({
  onCancel,
  buttonsPosition = "row",
  widthButton = 100,
  widthForm = 100,
  cardInfo,
  createNewCreditCard,
  creditCardIcon,
  setCreditCardNumber,
  chooseNewCard,
  isDetectedSubscriptionIssue = false,
}) => {
  const dispatch = useAppDispatch();

  const newCard = useAppSelector(getNewCreditCard);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const [set, setState] = useState(false);

  const nameOfCard = useInput("");
  const cardNumber = useInput("", 0, /\D/g);
  const cvvCode = useInput("", 0, /\D/g);
  const address = useInput("");
  const zipCode = useInput("");

  const { countriesToList } = useCountries();

  const monthSelectValue = useSelect("");
  const yearSelectValue = useSelect("");
  const countrySelectValue = useSelect("");

  useEffect(() => {
    if (!!newCard?.id && chooseNewCard) {
      chooseNewCard(newCard.id);
      dispatch(resetNewCreditCard());
    }
  }, [newCard]);

  const yearsSelect = useMemo(() => {
    const yearList: IOption[] = [];
    let currentYear = new Date().getFullYear();
    const yearText = (currentYear: number) =>
      currentYear.toString()?.substr(-2);

    for (let i = 0; i <= yearsNumber; i += 1) {
      yearList.push({
        id: i,
        value: yearText(currentYear),
        label: yearText(currentYear),
      });
      currentYear += 1;
    }
    return yearList;
  }, []);

  const monthSelect = useMemo(() => {
    const monthList: IOption[] = [];
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear().toString().slice(-2);

    if (year === yearSelectValue.value) {
      for (let i = month; i <= 12; i += 1) {
        let twoDigitsMonth = i.toString();
        if (i.toString().length === 1) {
          twoDigitsMonth = `0${i}`;
        }
        monthList.push({ id: i, value: twoDigitsMonth, label: twoDigitsMonth });
      }
      return monthList;
    }
    for (let i = 1; i <= 12; i += 1) {
      let twoDigitsMonth = i.toString();
      if (i.toString().length === 1) {
        twoDigitsMonth = `0${i}`;
      }
      monthList.push({ id: i, value: twoDigitsMonth, label: twoDigitsMonth });
    }
    return monthList;
  }, [yearSelectValue]);

  useEffect(() => {
    const year = new Date().getFullYear().toString().slice(-2);
    const month = new Date().getMonth() + 1;
    if (
      year === yearSelectValue.value &&
      month > Number(monthSelectValue.value)
    )
      monthSelectValue.setValue("");
  }, [yearSelectValue]);

  useEffect(() => {
    setCreditCardNumber(+cardNumber.value);
  }, [cardNumber.value]);

  const clearForm = () => {
    nameOfCard.setValue("");
    cardNumber.setValue("");
    cvvCode.setValue("");
    address.setValue("");
    zipCode.setValue("");
    monthSelectValue.setValue("");
    yearSelectValue.setValue("");
    countrySelectValue.setValue("");
    setState(false);
  };

  const formSubmit = async (
    event: MouseEvent<HTMLButtonElement>,
    isDetectedSubscriptionIssue: boolean
  ) => {
    event.preventDefault();
    if (
      !nameOfCard.value ||
      !cardNumber.value ||
      !cvvCode.value ||
      !address.value ||
      !monthSelectValue.value ||
      !yearSelectValue.value ||
      !zipCode.value ||
      !countrySelectValue.value
    ) {
      setState(true);
      return;
    }
    await createNewCreditCard(
      event,
      nameOfCard.value,
      cardNumber.value,
      cvvCode.value,
      address.value,
      zipCode.value,
      monthSelectValue.value,
      yearSelectValue.value,
      countrySelectValue.value,
      isDetectedSubscriptionIssue
    );

    clearForm();
  };

  const onChangeZipCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (
        countrySelectValue.value === "1" ||
        countrySelectValue.value === "2"
      ) {
        return zipCode.onChange(event.target.value.slice(0, 6));
      }
      return zipCode.onChange(event.target.value);
    },
    [countrySelectValue.value]
  );

  return (
    <Form>
      <FlexContainer
        direction={isMobile ? "column" : buttonsPosition}
        align='flex-start'
        justify={buttonsPosition === "row" ? "space-between" : "flex-start"}
      >
        <FlexContainer
          direction='column'
          align='flex-start'
          width={isMobile ? "100%" : `${widthForm}%`}
        >
          <FlexContainer direction={isMobile ? "column" : "row"}>
            <Input
              value={nameOfCard.value}
              onChange={nameOfCard.onChange}
              placeholder='Name On Card'
              style={{
                border: `${
                  !nameOfCard.value && set ? "1px solid red" : "none"
                }`,
              }}
              type='text'
              size={35}
            />
            <Input
              style={{
                marginLeft: isMobile ? "0" : ".61111rem",
                marginTop: isMobile ? ".61111rem" : "0",
                border: `${
                  !cardNumber.value && set ? "1px solid red" : "none"
                }`,
              }}
              value={cardNumber.value}
              onChange={cardNumber.onChange}
              placeholder='Card Number'
              type='text'
              size={cardInfo ? +cardInfo.lengths[0] : 16}
              showCreditCardIcon={!!cardNumber.value && !!creditCardIcon}
              creditCardIcon={creditCardIcon()}
            />
          </FlexContainer>
          <FlexContainer>
            <CustomSelect
              style={{
                marginRight: ".61111rem",
                marginTop: isMobile ? ".68rem" : ".65rem",
                marginBottom: isMobile ? ".68rem" : ".65rem",
                border: `${
                  !monthSelectValue.value && set ? "1px solid red" : "none"
                }`,
              }}
              options={monthSelect}
              select={monthSelectValue}
              placeholder='Month'
              onChange={monthSelectValue.onChange}
            />
            <CustomSelect
              style={{
                marginRight: ".61111rem",
                marginTop: isMobile ? ".68rem" : ".65rem",
                marginBottom: isMobile ? ".68rem" : ".65rem",
                border: `${
                  !yearSelectValue.value && set ? "1px solid red" : "none"
                }`,
              }}
              options={yearsSelect}
              select={yearSelectValue}
              placeholder='Year'
              onChange={yearSelectValue.onChange}
            />
            <Input
              value={cvvCode.value}
              onChange={cvvCode.onChange}
              placeholder={cardInfo ? cardInfo.code.name : "CVV"}
              type='text'
              style={{
                border: `${!cvvCode.value && set ? "1px solid red" : "none"}`,
              }}
              inputStyle={{ height: "100%" }}
              size={cardInfo ? cardInfo.code.size : 4}
            />
          </FlexContainer>
          <FlexContainer direction={isMobile ? "column" : "row"}>
            <Input
              value={address.value}
              onChange={address.onChange}
              placeholder='Street Address'
              style={{
                border: `${!address.value && set ? "1px solid red" : "none"}`,
              }}
              type='text'
              size={35}
            />
            <Input
              style={{
                marginLeft: isMobile ? "0" : ".61111rem",
                marginTop: isMobile ? ".68rem" : "0",
                border: `${!zipCode.value && set ? "1px solid red" : "none"}`,
                flexBasis: "48.5%",
              }}
              value={zipCode.value}
              onChange={onChangeZipCode}
              placeholder='Zip Code'
              type='text'
            />
          </FlexContainer>

          <CustomSelect
            options={countriesToList}
            placeholder='Country'
            style={{
              border: `${
                !countrySelectValue.value && set ? "1px solid red" : "none"
              }`,
            }}
            select={countrySelectValue}
            onChange={countrySelectValue.onChange}
          />
        </FlexContainer>

        <FlexContainer
          direction={isMobile ? "column" : "row"}
          justify='start'
          style={{
            maxWidth: `${widthButton}rem`,
            marginTop: buttonsPosition === "row" && !isMobile ? "0" : "1rem",
          }}
        >
          <ButtonAction
            onClick={(event) => formSubmit(event, isDetectedSubscriptionIssue)}
          >
            ADD CARD
          </ButtonAction>
          {onCancel && (
            <ButtonAction
              style={{
                marginLeft: isMobile ? "0" : ".88888rem",
                marginTop: isMobile ? ".72222rem" : "0",
              }}
              onClick={() => onCancel(false)}
            >
              CANCEL
            </ButtonAction>
          )}
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};

export default CreditCardForm;
