import { userInstance } from "../hooks/instance/useInstance";
import { BasketId } from "../interfaces/basket/IBasket";
import { IBasketSend } from "../interfaces/basket/IBasketSend";
import { IBasketClearResponse } from "../interfaces/basket/responses/IBasketClearResponse";
import { IBasketCountResponse } from "../interfaces/basket/responses/IBasketCountResponse";
import { IBasketGroupedResponse } from "../interfaces/basket/responses/IBasketGroupedResponse";
import { IBasketItemResponse } from "../interfaces/basket/responses/IBasketItemResponse";
import { IBasketSendResponse } from "../interfaces/basket/responses/IBasketSendResponse";
import { IPlaceBasket } from "../interfaces/orders/IPlaceBasket";

const basketApi = {
  clear: async () => {
    return userInstance.post<IBasketClearResponse>("/basket/clear");
  },
  count: async () => {
    return userInstance.get<IBasketCountResponse>("/basket/count");
  },

  item: async () => {
    return userInstance.get<IBasketItemResponse>("/basket/item");
  },
  itemFromAddress: async (id: number) => {
    return userInstance.get(`/basket/itemFromAddress?id=${id}`);
  },
  send: async (payload: IBasketSend) => {
    return userInstance.post<IBasketSendResponse>("/basket/send", {
      ...payload,
    });
  },
  cancel: async (id: BasketId) => {
    return userInstance.post("/basket/cancel", { id });
  },
  update: async (payload: IPlaceBasket) => {
    return userInstance.post("/basket/update", payload);
  },
  remove: async (id: BasketId) => {
    const response = await userInstance.post("/basket/remove", { id });
    return response.data;
  },
  list: async () => {
    return userInstance.get("/basket");
  },
  listGrouped: async (searchPage?: number, limitQuery?: number) => {
    const page = searchPage ? `&page=${searchPage}` : "";
    const limit = limitQuery ? `&limit=${limitQuery}` : `&limit=10`;
    return userInstance.get<IBasketGroupedResponse>(
      `/basket/allGrouped?${page}${limit}`
    );
  },

  listNew: async () => {
    return userInstance.get("/basket/allNew");
  },
  listOrders: async (id: number) => {
    return userInstance.get(`/basket/basketOrders?id=${id}`);
  },
};

export default basketApi;
