import { userInstance } from "../hooks/instance/useInstance";
import { ITags } from "../interfaces/tags/tags";

const tagsApi = {
  list: async () => {
    return userInstance.get<ITags>("/config");
  },
};

export default tagsApi;
