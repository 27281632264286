const messagePopup = {
  wrongLogin:
    "That email address and password combination couldn't be found! Please try again or click the 'Forgot password?' link to reset your password.",
  emptyFields: "Please make sure all fields are filled in correctly",
  emptyDate: "The date is missing! Try to fill in the date field.",
  signUpSuccess: "Activation email was sent to",
  activationSuccess: "Account is activated!",
  pastDate: "The date is incorrect! Try to change the date.",
  invalidCoupon: "Sorry! That coupon code is not valid. Please try again.",
  emptyUploadFile: "You did not select a file! Please to select a file.",
  importSuccess: "The file successfully imported!",
  passwordResetRequestSuccess: "Email with instructions was sent to",
  confirmPasswordReset: "Forgotten Password?",
  addedReturnAddress: "Please add Shipping Address",
  passwordReset: "Password Reset",
  wrongSizeImage: "Image height is too small ! Try to select another image.",
  passwordResetConfirmSuccess:
    "Password successfully updated! Now you can sign in!",
  addressUploadTitle: "ADDRESSES UPLOAD... OOPS!",
  emailsUploadTitle: "EMAILS UPLOAD... OOPS!",
  requestEmailWasSent: "Email with request was successfully sent!",
  addressUploadSubtitle:
    "There were some errors in uploading your recipients. Please fix the following and upload the file again.",
  emailsUploadSubtitle:
    "There were some errors in uploading your emails list. Please fix the following and upload the file again.",
  bulkNotesUploadTitle: "Bulk Upload Issues Detected",
  bulkNotesUploadSubtitle:
    "There were some errors in uploading your notes. Please fix the following and upload the file again.",
  wrongFileType: "Incorrect file. Allow formats: JPEG, GIF, PNG",
  messageSendEmpty: "Please write your message.",
  fontFamilyEmpty: "Please choose writing style.",
  recipientsEmpty: "Please select recipients.",
  feedbackSendSuccess: "Your feedback was successfully sent!",
  logInAsUser: "Success! You're logging in as a user!",
  messageTemplateWrongMessageLength:
    "Sorry! This template contains too many characters for the selected card. To use this template, please select a different card, or choose a different template.",
  zipIncrementingError: "ZIP Code Incrementing Error",
  zipIncrementingMessage:
    "We have detected ZIP codes that are increasing in your Return Addresses or Recipient Addresses. This is a common error. Please confirm this is correct or please go back and update your file.",
  invalidFile: "File is invalid!",
  errorQuantityInserts:
    "Insert selected may not be available for all orders. OK to Continue (Y/N)",
  leavePageConfirmationMessage: "Changes you made may not be saved.",
  toLargerImg:
    "This image is too big, please upload an image that is smaller than 20MB",
  templateCreated: "Message template created successfully!",
  wrongFormatFile:
    "The selected file is invalid. Available formats - jpg, jpeg, png",
  groundShippingDisabled:
    "We are unable to ship via ground to requested location. Please choose another shipping method.",
  deliveryConfirmationWarning:
    "Please choose a Delivery Confirmation option before continuing.",
  createQrCode: "Create a QR Code",
  subTitleTextEmojis:
    "We detected emojis to format message.If you continue emojis will be removed. Continue?",
  titleTextEmojis: "Emojis Detected",
  subTitleTextSpaces:
    "We detected using spaces to format message. This may lead to unexpected results. Continue?",
  titleTextSpaces: "Excessive Spaces Detected",
  titleMissedTags: "Missed Tags Detected",
};

export default messagePopup;
