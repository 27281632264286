import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import TagManager from "react-gtm-module";

import authApi from "../../api/auth.api";
import {
  AuthorizationMethodType,
  IResetPasswordPayload,
  ISignInData,
  ISignUpData,
  IUser,
} from "../../interfaces/auth/IAuth";
import { IThunkError } from "../../interfaces/redux/IThunkError";
import { IConvertEmail } from "../../interfaces/profile/IEmailChange";
import { IAuthProvider } from "../../interfaces/auth/IAuthProvider";
import { closePopup, setPortalPopup } from "../portal-popup/slice";
import messagePopup from "../../constants/popup/constants";
import getCheckAuth from "../checkauth/thunks";
import { getSociialType } from "../../helpers/seo";
import clearLocalStorage from "../../helpers/local-storage";

export const uidStorage = "uid";

export const login = createAsyncThunk<
  IUser,
  void,
  { rejectValue: IThunkError | string }
>("auth/login", async (_, { rejectWithValue }) => {
  const token = localStorage.getItem(uidStorage);

  if (!token) return rejectWithValue("No token");

  try {
    const res = await authApi.user();

    const {
      fname,
      lname,
      credit1,
      credit2,
      attach_invoice,
      email,
      id,
      login,
      last_used_font,
      user_subscription,
      max_discount_subscriptions,
      default_credit_card,
      invoiced,
      expiring_credit2,
      test_mode,
      last_logged_with,
      google_id,
      apple_id,
      facebook_id,
      total_credit_card,
      logged_with,
      problematic_subscription,
      detected_subscription_issue,
      lost_credit,
      group_logo_url,
      prepaid_plan,
      no_delivery_confirmation,
    } = res.data;

    return {
      fname,
      lname,
      email,
      attach_invoice,
      id,
      login,
      last_used_font,
      user_subscription,
      expiring_credit2,
      max_discount_subscriptions,
      default_credit_card,
      invoiced,
      test_mode,
      last_logged_with,
      google_id,
      facebook_id,
      total_credit_card,
      apple_id,
      credit1,
      credit2,
      logged_with,
      problematic_subscription,
      detected_subscription_issue,
      lost_credit,
      group_logo_url,
      prepaid_plan,
      no_delivery_confirmation,
    };
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export const signIn = createAsyncThunk<
  void,
  ISignInData,
  { rejectValue: IThunkError }
>("auth/signin", async (payload, { dispatch }) => {
  const res = await authApi.signin(payload);

  const { uid } = res.data;

  localStorage.setItem(uidStorage, uid);

  dispatch(login());
});

export const signUp = createAsyncThunk<
  void,
  ISignUpData,
  { rejectValue: IThunkError }
>("auth/signup", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await authApi.signup(payload);

    if (res) {
      await dispatch(
        setPortalPopup({
          text: `${messagePopup.signUpSuccess} ${payload?.email}`,
          typePopup: "alert",
          title: "Success",
        })
      );
    }

    return res.data;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  clearLocalStorage();
  await sessionStorage.clear();
  await authApi.logout();
  return null;
});

export const resetPasswordRequest = createAsyncThunk<
  void,
  string,
  { rejectValue: IThunkError }
>(
  "auth/password/reset/request",
  async (email, { rejectWithValue, dispatch }) => {
    try {
      const res = await authApi.resetPasswordRequest(email);

      if (res) {
        dispatch(closePopup());
        dispatch(
          setPortalPopup({
            text: `${messagePopup.passwordResetRequestSuccess} ${email}`,
            typePopup: "alert",
            title: "Success",
          })
        );
      }
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    }
  }
);

export const resetPasswordConfirm = createAsyncThunk<
  void,
  IResetPasswordPayload,
  { rejectValue: IThunkError }
>(
  "auth/password/reset/confirm",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const result = await authApi.resetPasswordConfirm(payload);

      if (result) {
        dispatch(closePopup());
        dispatch(
          setPortalPopup({
            text: messagePopup.passwordResetConfirmSuccess,
            typePopup: "alert",
            title: "Success",
          })
        );
      }
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    }
  }
);

export const activateAccount = createAsyncThunk<
  void,
  string,
  { rejectValue: IThunkError }
>("auth/activation", async (code, { rejectWithValue, dispatch }) => {
  try {
    const res = await authApi.activation(code);
    const authorizationMethod: AuthorizationMethodType = "e-mail";
    if (res) {
      TagManager.dataLayer({
        dataLayer: {
          event: "event-to-ga",
          eventName: "sign_up_success",
          eventParam: authorizationMethod,
        },
      });
      dispatch(
        setPortalPopup({
          text: messagePopup.activationSuccess,
          typePopup: "alert",
          title: "Success",
        })
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export const setPassword = createAsyncThunk(
  "auth/password/set",
  async () => {}
);

export const convertAccount = createAsyncThunk<
  void,
  IConvertEmail,
  { rejectValue: IThunkError }
>(
  "auth/account/convert",
  async (payload: IConvertEmail, { dispatch, rejectWithValue }) => {
    try {
      const res = await authApi.convertAccount(payload);
      if (res) {
        await dispatch(login());
        dispatch(
          setPortalPopup({
            text: res.data.message,
            typePopup: "alert",
            title: "Success",
          })
        );
        await dispatch(getCheckAuth());
      }
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    }
  }
);

export const setTestMode = createAsyncThunk<number, number>(
  "auth/test-mode/set",
  async (testMode: number) => {
    const res = await authApi.testMode(testMode);

    const { test_mode } = res.data;

    return test_mode;
  }
);

export const authProvider = createAsyncThunk<void, IAuthProvider>(
  "auth/signin/provider",
  async (payload, { dispatch }) => {
    const res = await authApi.authProvider(payload);

    const { uid, is_new_user, registration_type } = res.data;

    if (is_new_user) {
      const authorizationType = getSociialType(registration_type);

      TagManager.dataLayer({
        dataLayer: {
          event: "event-to-ga",
          eventName: "sign_up_success",
          eventParam: authorizationType,
        },
      });
    }

    localStorage.setItem(uidStorage, uid);

    await dispatch(login());
  }
);
