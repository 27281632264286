import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import lodash from "lodash";
import {
  addRecipient,
  updateRecipient,
  getRecipients,
  parseRecipientXlsFile,
  getRecipientById,
} from "./thunks";
import { initialState, recipientsAdapter } from "./entity";
import {
  AdvancedFiltersKey,
  IAdvancedFilters,
  ISort,
  SetEditRecipinetIdAction,
} from "./types";
import isValidDate from "../../helpers/calendar/validDate";

const recipientsSlice = createSlice({
  name: "recipients",
  initialState,
  reducers: {
    setEditId: (state, action: PayloadAction<SetEditRecipinetIdAction>) => {
      state.editId = action.payload.id;
    },
    removeEditId: (state) => {
      state.editId = null;
    },
    setInternational: (state, action: PayloadAction<boolean>) => {
      state.isInternational = action.payload;
    },
    clearStatus: (state) => {
      state.isUploaded = false;
    },
    clearUploadRecipient: (state) => {
      state.updateRecipient = [];
    },
    clearNewRecipient: (state) => {
      state.newAddress = 0;
    },
    setRecipientsSortParams: (state, action: PayloadAction<ISort | null>) => {
      state.sortParams = action.payload;
    },
    addAdvancedFilters: (state, action: PayloadAction<IAdvancedFilters>) => {
      state.advancedFilters = lodash.isEmpty(action.payload)
        ? null
        : action.payload;
    },
    removeAdvancedFilter: (
      state,
      action: PayloadAction<AdvancedFiltersKey>
    ) => {
      const updatedFilters = state.advancedFilters;

      if (updatedFilters && action.payload in updatedFilters) {
        delete updatedFilters[action.payload];
      }

      state.advancedFilters = lodash.isEmpty(updatedFilters)
        ? null
        : updatedFilters;
    },
    resetAdvancedFilters: (state) => {
      state.advancedFilters = null;
    },
    setRecipientsId: (state, action: PayloadAction<number[]>) => {
      state.selectedRecipientsId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addRecipient.fulfilled, (state, { payload }) => {
      if (typeof payload?.birthday === "string") {
        payload.birthday = isValidDate(payload?.birthday);
      }
      if (typeof payload?.anniversary === "string") {
        payload.anniversary = isValidDate(payload?.anniversary);
      }
      const { selectAll } = recipientsAdapter.getSelectors();
      const newRecipient = [payload, ...selectAll(state)];

      // @ts-ignore
      recipientsAdapter.setAll(state, newRecipient);
    });
    builder.addCase(addRecipient.rejected, () => {});

    builder.addCase(updateRecipient.fulfilled, (state, { payload }) => {
      const { recipient } = payload;

      recipientsAdapter.updateOne(state, {
        id: recipient?.id!,
        changes: {
          ...payload,
        },
      });
    });
    builder.addCase(updateRecipient.rejected, () => {});

    builder.addCase(getRecipientById.fulfilled, (state, { payload }) => {
      recipientsAdapter.updateOne(state, {
        id: payload?.id!,
        changes: {
          ...payload,
        },
      });
    });

    builder.addCase(getRecipients.fulfilled, (state, { payload }) => {
      const { addresses } = payload;
      recipientsAdapter.setAll(state, addresses);
      state.updateRecipient = [];
      state.newAddress = 0;
      state.isLoadingRecipientList = false;
    });
    builder.addCase(getRecipients.rejected, (state) => {
      state.isLoadingRecipientList = false;
    });
    builder.addCase(getRecipients.pending, (state) => {
      state.isLoadingRecipientList = true;
    });
    builder.addCase(parseRecipientXlsFile.fulfilled, (state, { payload }) => {
      const { recipients, correct_file, update_recipients, new_address } =
        payload;
      state.isXlsUploading = false;

      if (correct_file) recipientsAdapter.setAll(state, recipients.reverse());

      state.updateRecipient = update_recipients;
      state.isUploaded = true;
      state.newAddress = new_address;
    });
    builder.addCase(parseRecipientXlsFile.pending, (state) => {
      state.isXlsUploading = true;
    });
    builder.addCase(parseRecipientXlsFile.rejected, (state) => {
      state.isXlsUploading = false;
    });
  },
});

export const {
  setEditId,
  removeEditId,
  setInternational,
  clearStatus,
  clearUploadRecipient,
  clearNewRecipient,
  setRecipientsSortParams,
  addAdvancedFilters,
  removeAdvancedFilter,
  resetAdvancedFilters,
  setRecipientsId,
} = recipientsSlice.actions;

export default recipientsSlice.reducer;
