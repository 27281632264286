import axios from "axios";

import { uidStorage } from "../../redux/auth/thunks";
import { setSpinner } from "../../redux/spinner/slice";
import { setPortalPopup } from "../../redux/portal-popup/slice";

import typePopups from "../../constants/popup/type-popup";
import { setErrorQuantityInsert } from "../../redux/basket/slice";

const { INVALID_COUPON, PROCEED_ANYWAY } = typePopups;

const baseURL = `${process.env.REACT_APP_API_URL}/api/v1/user`;
const baseURLV2 = `${process.env.REACT_APP_API_URL}/api/v2/user`;
const baseURLCreditCard = process.env.REACT_APP_CREDIT_CARD_API_URL;
const authorizenetSecretKey = process.env.REACT_APP_API_SECRET_KEY;
const xClient = { "x-client": "Handwrytten-App 2.1.1" };

// export const defaultInstance = axios.create({ baseURL })
// axios.defaults.withCredentials = true

export const userInstanceV2 = axios.create({
  baseURL: baseURLV2,
  headers: xClient,
});

export const userInstance = axios.create({
  baseURL,
  headers: xClient,
});

userInstance.defaults.withCredentials = true;
export const uidSecretInstance = axios.create({
  baseURL,
});

export const creditCardInstance = axios.create({
  baseURL: baseURLCreditCard,
});

let numberOfAjaxCAllPending = 0;

const instance = (store: any) => {
  const { dispatch } = store;

  userInstanceV2.interceptors.request.use(
    (config) => {
      const uid = localStorage.getItem(uidStorage);

      numberOfAjaxCAllPending += 1;
      dispatch(setSpinner(true));

      config.headers.Authorization = uid;
      return config;
    },
    (error) => {
      console.log(error);
    }
  );

  userInstanceV2.interceptors.response.use(
    (response) => {
      numberOfAjaxCAllPending -= 1;

      if (numberOfAjaxCAllPending === 0) {
        // hide loader
        dispatch(setSpinner(false));
      }
      return response;
    },
    (error) => {
      if (error?.response?.data?.customCode === 4001) {
        // catch invalid coupon error
        dispatch(
          setPortalPopup({
            form: INVALID_COUPON,
            title: "Invalid Coupon",
            typePopup: "form",
          })
        );
      } else {
        dispatch(
          setPortalPopup({
            text:
              error?.response?.data?.message?.toString() ||
              error.message.toString(),
            typePopup: "alert",
            title: "OOPS!",
          })
        );
      }
      numberOfAjaxCAllPending -= 1;
      if (numberOfAjaxCAllPending === 0) {
        dispatch(setSpinner(false));
      }
    }
  );

  userInstance.interceptors.request.use(
    (config) => {
      const uid = localStorage.getItem(uidStorage);
      config.headers.Authorization = uid;
      if (config.url?.includes("/orders/invoice")) return config;

      if (config.url?.includes("/orders/calcTaxes")) return config;

      numberOfAjaxCAllPending += 1;
      dispatch(setSpinner(true));

      return config;
    },
    (error) => {
      console.log(error);
    }
  );

  userInstance.interceptors.response.use(
    (response) => {
      if (response.config.url?.includes("/orders/calcTaxes")) return response;

      if (response.config.url?.includes("/orders/invoice")) return response;

      numberOfAjaxCAllPending -= 1;

      if (numberOfAjaxCAllPending === 0) {
        // hide loader
        dispatch(setSpinner(false));
      }
      return response;
    },
    async (error) => {
      if (
        error.config.url?.includes("/ai/generateMessage") ||
        error.config.url?.includes("/shipping/rates")
      ) {
        numberOfAjaxCAllPending -= 1;
        dispatch(setSpinner(false));

        throw error;
      }

      if (error?.response?.data?.httpCode === 401) {
        dispatch(
          setPortalPopup({
            text:
              error?.response?.data?.message?.toString() ||
              error.message.toString() ||
              "no auth!",
            typePopup: "noauth",
            title: "OOPS!",
          })
        );
      } else if (
        error?.response?.data?.customCode === 4047 &&
        error?.response?.config
      ) {
        const { method, url, data: payload } = error.response.config;

        dispatch(
          setPortalPopup({
            form: PROCEED_ANYWAY,
            title: "UNMATCHED MERGE FIELD",
            typePopup: "form",
            text: {
              message: error?.response?.data.message,
              method,
              url,
              payload,
            } as any,
          })
        );
      } else if (error?.response?.data?.customCode === 4031) {
        dispatch(
          setPortalPopup({
            text: "Your account is banned!",
            typePopup: "banned",
            title: "OOPS!",
          })
        );
      } else if (error?.response?.data?.customCode === 4002) {
        dispatch(setErrorQuantityInsert(true));
      } else if (error?.response?.data?.customCode === 4001) {
        // catch invalid coupon error
        dispatch(
          setPortalPopup({
            form: INVALID_COUPON,
            title: "Invalid Coupon",
            typePopup: "form",
          })
        );
      } else if (error?.response?.data?.httpCode === 415) {
        // catch invalid file
        dispatch(
          setPortalPopup({
            text:
              error?.response?.data?.message?.toString() ||
              error.message.toString(),
            typePopup: "alert",
            title: "File is invalid!",
          })
        );
      } else {
        if (error.response?.request?.responseType === "blob") {
          error.response.data = JSON.parse(await error.response.data.text());
        }
        dispatch(
          setPortalPopup({
            text:
              error?.response?.data?.message?.toString() ||
              error.message.toString(),
            typePopup: "error",
            title: "OOPS!",
          })
        );
      }
      numberOfAjaxCAllPending -= 1;
      if (numberOfAjaxCAllPending === 0) {
        dispatch(setSpinner(false));
      }
      throw error;
    }
  );

  creditCardInstance.interceptors.request.use(
    (config) => {
      numberOfAjaxCAllPending += 1;
      dispatch(setSpinner(true));

      return config;
    },
    (error) => {
      console.log(error);
    }
  );

  creditCardInstance.interceptors.response.use(
    (response) => {
      numberOfAjaxCAllPending -= 1;

      if (numberOfAjaxCAllPending === 0) {
        // hide loader
        dispatch(setSpinner(false));
      }
      return response;
    },
    () => {
      numberOfAjaxCAllPending -= 1;
      if (numberOfAjaxCAllPending === 0) {
        dispatch(setSpinner(false));
      }
    }
  );

  uidSecretInstance.interceptors.request.use(
    (config) => {
      const uid = localStorage.getItem(uidStorage);

      numberOfAjaxCAllPending += 1;
      dispatch(setSpinner(true));

      config.headers.Authorization = uid;
      config.headers.authorizenetsecret = authorizenetSecretKey;
      return config;
    },
    (error) => {
      console.log(error);
    }
  );

  uidSecretInstance.interceptors.response.use(
    (response) => {
      numberOfAjaxCAllPending -= 1;

      if (numberOfAjaxCAllPending === 0) {
        // hide loader
        dispatch(setSpinner(false));
      }
      return response;
    },
    (error) => {
      numberOfAjaxCAllPending -= 1;
      if (numberOfAjaxCAllPending === 0) {
        dispatch(setSpinner(false));
      }
      console.log(error);
    }
  );
};

export default instance;
