import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";

import {
  getQrCodeList,
  listImages,
  uploadCustomImage,
} from "../../redux/custom-cards/thunks";
import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import {
  getCustomImagesCover,
  getCustomImagesLogo,
  getCustomCard,
  getQrCodes,
} from "../../redux/custom-cards/selectors";
import { IUploadCustomImage } from "../../redux/custom-cards/types";
import usePopup from "../popup/usePopup";
import CONSTANTS from "../../constants/custom-cards/custom-card";
import popupConstants from "../../constants/popup/constants";
import { ConfirmationPopupContext } from "../../context/confirmation-popup.provider";

const { cover_min_width, cover_min_height, back_min_height } =
  CONSTANTS.imageResolutions;

const useCreateCustomImages = (
  cardInfo?: any,
  isFetchQrCode?: boolean,
  ignoreFetch?: boolean
) => {
  const dispatch = useAppDispatch();

  const customImagesLogo = useAppSelector(getCustomImagesLogo);
  const customImagesCover = useAppSelector(getCustomImagesCover);
  const customQrCodes = useAppSelector(getQrCodes);
  const customCard = useAppSelector(getCustomCard);

  const { openConfirmationPopup } = useContext(ConfirmationPopupContext);
  const { openErrorPopup } = usePopup();

  const [fetchImge, setFetchImage] = useState(true);

  useEffect(() => {
    if (!ignoreFetch && customCard && fetchImge) {
      setFetchImage(false);
      const requests = [];
      // if custom card is restricted we must get images only with logo and icon types
      requests.push(
        dispatch(listImages(customCard?.cover_restricted ? "logo,icon" : null))
      );
      if (isFetchQrCode) requests.push(dispatch(getQrCodeList())!);

      Promise.all(requests);
    }
  }, [customCard, fetchImge, isFetchQrCode, ignoreFetch]);

  const qrCodeLocation: string = useMemo(
    () => cardInfo?.qr_code_location,
    [cardInfo]
  );

  const currentCustomImageCover = useMemo(
    () => customImagesCover.find((image) => cardInfo?.cover_id === image.id),
    [customImagesCover, cardInfo]
  );

  const customQrCode = useMemo(
    () => customQrCodes.find((qrCode) => cardInfo?.qr_code_id === qrCode.id),
    [customQrCodes, cardInfo]
  );

  const headerCustomImageCover = useMemo(
    () => customImagesLogo.find((image) => cardInfo?.logo_id === image.id),
    [customImagesLogo, cardInfo]
  );

  const footerCustomImageCover = useMemo(
    () =>
      customImagesLogo.find((image) => cardInfo?.footer_logo_id === image.id),
    [customImagesLogo, cardInfo]
  );

  const footerBackSideFoldedCustomImageLogo = useMemo(
    () => customImagesLogo.find((image) => cardInfo?.back_logo_id === image.id),
    [customImagesLogo, cardInfo]
  );

  const footerBackSideFoldedCustomImageCover = useMemo(
    () =>
      customImagesCover.find((image) => cardInfo?.back_cover_id === image.id),
    [customImagesLogo, cardInfo]
  );

  const acceptImgFile = async (
    acceptImg: FormData | null,
    acceptFileImg: File | null
  ) => {
    if (acceptFileImg) {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();

        if (typeof reader.result === "string") {
          image.src = reader.result;
        }

        image.onload = async () => {
          if (acceptImg) {
            await dispatch(uploadCustomImage(acceptImg));
          }
        };
      };
      await reader.readAsDataURL(acceptFileImg);
    }
  };

  const uploadNewImage = (
    event: ChangeEvent<HTMLInputElement>,
    isCover: boolean,
    setNewImage: (id: number, image_url: string) => void,
    isIcon: boolean
  ) => {
    const formData = new FormData();

    const { files } = event.target;

    if (
      files &&
      files[0] &&
      !files[0].type.match(/image\/(png|jpeg|jpg|JPEG)/)
    ) {
      event.target.files = null;
      return openErrorPopup(popupConstants.wrongFormatFile);
    }

    const isLogoOrIcon = isIcon ? "icon" : "logo";

    if (files && files[0]) {
      formData.append("file", files[0]);
      formData.append("type", isCover ? "cover" : isLogoOrIcon);

      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();

        if (typeof reader.result === "string") {
          image.src = reader.result;
        }

        image.onload = async () => {
          const { height } = image;
          const { width } = image;

          if (
            (isCover &&
              (width >= cover_min_width || height >= cover_min_height)) ||
            (!isCover && height >= back_min_height)
          ) {
            const { meta, payload } = await dispatch(
              uploadCustomImage(formData)
            );

            if (meta.requestStatus === "rejected") {
              event.target.files = null;
            }

            if (meta.requestStatus === "fulfilled" && payload) {
              event.target.files = null;
              const { id, image_url } = payload as IUploadCustomImage;
              setNewImage(+id, image_url);
            }
          } else {
            openConfirmationPopup({
              title: "Warning!",
              subtitle:
                "This image may appear blurry as it is less than the recommended resolution.",
              onConfirm: () => acceptImgFile(formData, files[0]),
              textButtonCancel: "CANCEL",
              textButtonConfirm: "ACCEPT",
            });
          }
        };
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return {
    currentCustomImageCover,
    headerCustomImageCover,
    footerCustomImageCover,
    footerBackSideFoldedCustomImageCover,
    footerBackSideFoldedCustomImageLogo,
    uploadNewImage,
    acceptImgFile,
    customQrCode,
    qrCodeLocation,
  };
};

export default useCreateCustomImages;
