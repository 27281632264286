import { ITag } from "../../interfaces/recipient-tags/ITags";

interface ITagsState {
  tags: Array<ITag>;
  selectedTags: Array<ITag>;
}

const initialState: ITagsState = {
  tags: [],
  selectedTags: [],
};

export interface IAddTag {
  name: string;
}

export default initialState;
