import { FC } from "react";
import { CSSObject } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { FlexContainer } from "../../../../ui/layout";
import BorderBox from "../../../../bordered-box/bordered-box";
import { Typography } from "../../../../ui/typography";

interface IBusinessOptionalSettingsList {
  id: string;
  label: string;
  onAction: (id: string, treeDepth: number) => void;
  treeDepth: number;
  order?: boolean;
  onSelect: () => void;
  styleForSelect?: CSSObject;
  isNotActiveTag?: boolean;
  isTagWithIcon: boolean;
  icon: string | null;
}

const BusinessOptionalSettingsList: FC<IBusinessOptionalSettingsList> = ({
  id,
  label,
  onAction,
  treeDepth,
  order = false,
  onSelect,
  styleForSelect,
  isNotActiveTag,
  isTagWithIcon,
  icon,
}) => {
  return (
    <BorderBox key={id} style={styleForSelect} onAction={onSelect}>
      <FlexContainer
        justify='center'
        style={
          order
            ? { fontSize: ".66666rem", maxWidth: "10rem" }
            : { fontSize: ".66666rem" }
        }
      >
        {icon && (
          <img src={icon} alt='tag icon' style={{ marginRight: "0.5rem" }} />
        )}

        <Typography
          style={{
            color: !isNotActiveTag ? "white" : "",
            whiteSpace: "nowrap",
            maxWidth: "12.5rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </Typography>
      </FlexContainer>
      {onAction && !isTagWithIcon && (
        <div
          style={{
            width: ".813rem",
            height: "auto",
            display: "flex",
          }}
        >
          <AiOutlineClose
            style={{
              cursor: "pointer",
            }}
            onClick={() => onAction(id, treeDepth)}
          />
        </div>
      )}
    </BorderBox>
  );
};

export default BusinessOptionalSettingsList;
