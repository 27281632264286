import { FC } from "react";
import { useHistory } from "react-router-dom";

import { FlexContainer } from "../../ui/layout";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getPrepaidPlan } from "../../../redux/auth/selectors";
import { IPrepaidPlan } from "../../../interfaces/auth/IAuth";
import navigation from "../../../constants/navigation";

interface IAutoReplenish {
  hideSetting: () => void;
}

const AutoReplenish: FC<IAutoReplenish> = ({ hideSetting }) => {
  const prepaidPlan = useAppSelector(getPrepaidPlan);

  const history = useHistory();

  const navigateToAutoReplenish = () => {
    history.push(navigation.paymentOptions);
    hideSetting();
  };

  if (!prepaidPlan) return null;

  return (
    <FlexContainer className='nav__links' justify='space-between'>
      <div>Auto Replenish</div>
      <div className='nav__links_action'>
        <div onClick={navigateToAutoReplenish}>
          {(prepaidPlan as IPrepaidPlan).extendability ? "On" : "Off"}
        </div>
      </div>
    </FlexContainer>
  );
};

export default AutoReplenish;
