const isValidDate = (dateValue: string) => {
  const temp = dateValue?.split("/");
  const year = temp[2];
  const day = temp[1];
  const month = temp[0];
  const correctDate = `${year}-${month}-${day}`;
  return correctDate;
};

export default isValidDate;
