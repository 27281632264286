import styled from "styled-components";

export const Container = styled.div`
  padding: 30px;

  @media (max-width: 599px) {
    padding: 20px;
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
`;

export const Head = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;

  tr {
    padding: 32px 0px;
    text-align: left;
    border-bottom: 1px solid #dddfd2;
    font-weight: 600;
    td {
      padding: 0.61111rem;
      background: #fff;
      font-size: 16px;
    }
  }
`;

export const Body = styled.tbody`
  display: table-row-group;
  vertical-align: middle;

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    font-size: 16px;

    &:nth-child(odd) td {
      background: #fff;
      padding: 20px 0;
      border-bottom: 1px solid #dddfd2;
      padding-left: 10px;
    }

    &:nth-child(even) td {
      border-bottom: 1px solid #dddfd2;
    }

    td {
      padding: 0.61111rem;
      background: #fff;
      text-align: left;
    }
  }
`;

export const Span = styled.span`
  font-weight: 600;
  padding-right: 10px;
`;
