import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import {
  fetchCreditCards,
  fetchCreditCardKeys,
  addCreditCard,
  addNewCreditCard,
} from "./thunks";

const creditCardsSlice = createSlice({
  name: "credit-cards",
  initialState,
  reducers: {
    resetNewCreditCard: (state) => {
      state.newCreditCard = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreditCards.fulfilled, (state, { payload }) => {
      const { credit_cards, discount_credit, discount_credit_type2 } = payload;

      state.creditCards = credit_cards;
      state.discountCredit = parseFloat(discount_credit);
      state.discountCreditType2 = parseFloat(discount_credit_type2);
    });
    builder.addCase(fetchCreditCardKeys.fulfilled, (state, { payload }) => {
      state.creditCardsInfo = payload;
    });

    builder.addCase(addCreditCard.fulfilled, (state, { payload }) => {
      state.creditCardsTokenInfo = payload;
    });
    builder.addCase(addNewCreditCard.fulfilled, (state, { payload }) => {
      state.newCreditCard = payload;
    });
  },
});

export const { resetNewCreditCard } = creditCardsSlice.actions;

export default creditCardsSlice.reducer;
