import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types";

const newMessageSlice = createSlice({
  name: "newMessage",
  initialState,
  reducers: {
    setCardId: (state, { payload }) => {
      state.cardId = payload;
    },
    setCardCover: (state, { payload }) => {
      state.cover = payload;
    },
    setCardName: (state, { payload }) => {
      state.name = payload;
    },
  },
});

export const { setCardId, setCardCover, setCardName } = newMessageSlice.actions;

export default newMessageSlice.reducer;
