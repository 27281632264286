import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import useUrlSearchParams from "./utils/useUrlSearchParams";
import navigation from "../constants/navigation";

const useRedirectCardsFromApp = (redirectTo: string, query: string) => {
  const history = useHistory();
  const locations = useLocation();
  const queryValue = useUrlSearchParams(query);

  useEffect(() => {
    if (queryValue && locations.pathname.includes(navigation.cards)) {
      history.push({
        pathname: `${redirectTo}`,
        search: `?${query}=${queryValue}`,
      });
    }
    if (locations.pathname.includes(navigation.cards)) {
      history.push({
        pathname: `${redirectTo}`,
      });
    }
  }, [query]);
};

export default useRedirectCardsFromApp;
