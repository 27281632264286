import React, { memo, useCallback, useMemo } from "react";

import {
  deleteAddress,
  setDefaultAddress,
  getAddress,
} from "../../../redux/address/thunks";
import { selectAllAddresses } from "../../../redux/address/selectors";

import useAddress from "../../../hooks/address/useAddress";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import { IAddress } from "../../../interfaces/address/IAddress";
import maxAddressInnContainer from "../../../constants/address-book/address-book";
import AddressBookCard from "../address-book/address-book.card";
import SectionBox from "../../ui/common/section-box/box";
import { IEnrollReturnAddress } from "../../../pages/address-book/AddressBook";

const { count } = maxAddressInnContainer;

interface IReturnAddressesList {
  addresses?: Array<IAddress>;
  style?: React.CSSProperties;
  isEnrollMode?: boolean;
}

type IReturnAddressesListProps = IReturnAddressesList & IEnrollReturnAddress;

const ReturnAddressesList = memo<IReturnAddressesListProps>(
  // eslint-disable-next-line react/prop-types
  ({ isEnrollMode, enrollReturnAddress, setEnrollReturnAddress }) => {
    const dispatch = useAppDispatch();

    const addresses = useAppSelector(selectAllAddresses);

    const { togglePopup } = useAddress();

    const getDefaultAddress = useCallback(
      async (id) => {
        await dispatch(setDefaultAddress(id!));
        await dispatch(getAddress());
      },
      [dispatch, getAddress, setDefaultAddress]
    );

    const changeAddressHandler = (id?: number) => {
      if (id) {
        if (isEnrollMode && setEnrollReturnAddress) {
          setEnrollReturnAddress(id);
        } else {
          getDefaultAddress(id);
        }
      }
    };

    const heightContainers = useMemo(
      () => (addresses.length > count ? "30vh" : "max-content"),
      [addresses, count]
    );

    return (
      <div
        className='scroll-container'
        style={{
          maxHeight: heightContainers,
        }}
      >
        <SectionBox className='addresses-list'>
          {addresses.map(
            ({
              address1,
              address2,
              id,
              city,
              state,
              zip,
              default: defaultAddress,
            }) => {
              const _address = `${address1}, ${city}, ${
                address2 ? `${address2},` : ""
              } ${state} ${zip}`;

              const isRadioActive = isEnrollMode
                ? id === enrollReturnAddress
                : defaultAddress!;

              return (
                <AddressBookCard
                  key={id}
                  id={id!}
                  type='radio'
                  radioValue={isRadioActive}
                  label={_address}
                  onDelete={() => dispatch(deleteAddress(id!))}
                  onEdit={() => togglePopup(id!, "Edit Address")}
                  onChange={() => changeAddressHandler(id)}
                  isReturnAddress
                />
              );
            }
          )}
        </SectionBox>
      </div>
    );
  }
);

export default ReturnAddressesList;
