import { FC, useMemo } from "react";

import { FlexContainer } from "../../../ui/layout";
import { Tooltip } from "../../../ui/common/tooltip/tooltip.common";
import useUser from "../../../../hooks/auth/useUser";

const AccountRemainingPrepay: FC = () => {
  const { credit2 } = useUser();

  const prepayCredit = useMemo(() => {
    if (!credit2) return "0.00";
    return credit2 < 0 ? "0.00" : credit2.toFixed(2);
  }, [credit2]);

  return (
    <FlexContainer className='nav__links' justify='space-between'>
      <FlexContainer width='auto'>
        <span>Remaining Prepay</span>
        <Tooltip message='Prepaid credits can be used towards cards and postage.' />
      </FlexContainer>
      <div className='nav__links_text'>
        <div>{`$${prepayCredit}`}</div>
      </div>
    </FlexContainer>
  );
};

export default AccountRemainingPrepay;
