import { FC, useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import NotificationCard from "./notification.card";

import useNotifications from "../../hooks/notifications/useNotifications";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/useRedux";
import { heightNotifications } from "../../redux/notifications/slice";
import { LocationState } from "../../hooks/cards/useCardsByCategory";
import navigation from "../../constants/navigation";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

const NotificationsContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname, state: locationState } = useLocation();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const notificationRef = useRef<HTMLDivElement>(null);

  const { notifications, setNotificationColor, markNotificationRead } =
    useNotifications();

  useEffect(() => {
    dispatch(
      heightNotifications(
        (notificationRef.current as HTMLDivElement).clientHeight
      )
    );
  }, [notificationRef, notifications]);

  const top = useMemo(() => {
    const isCategoryPage =
      pathname.includes("/category") &&
      // @ts-ignore
      !Number.isNaN((locationState as LocationState)?.categoryId) &&
      !pathname.includes("/send");

    const isHomePage = pathname === navigation.home;
    const isPageIncludeFilter = isHomePage || isCategoryPage;

    if (isPageIncludeFilter && isMobile) return "6rem";
    return isMobile ? "2.94444rem" : "4rem";
  }, [pathname, isMobile]);

  if (!notifications) return null;
  return (
    <div
      style={{
        zIndex: isMobile ? 1 : 10,
        top,
      }}
      ref={notificationRef}
    >
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          message={notification.message}
          onCloseClick={() => markNotificationRead(notification.id)}
          show={notification.status === "ACTIVE"}
          bgcolor={setNotificationColor(notification.color)}
        />
      ))}
    </div>
  );
};

export default NotificationsContainer;
