import {
  ICurrent,
  IGetNewSubscription,
  ISubscriptionsType,
} from "../../interfaces/subscriptions/ISubscriptions";
import { IValue } from "../../components/ui/common/select/select.common";

export interface ISubscriptionsUpdate {
  id: string | number;
  credit_card_id: string | IValue;
}

interface ISubscriptionsState {
  subscriptions: ISubscriptionsType | null;
  current: ICurrent | null;
  newCurrent: IGetNewSubscription | null;
  new: IGetNewSubscription | null;
  subscriptionsLoader: boolean;
  warning?: string | null;
  isDetectedSubscriptionIssueLater: boolean;
}

export const initialState: ISubscriptionsState = {
  subscriptions: null,
  current: null,
  newCurrent: null,
  new: null,
  subscriptionsLoader: false,
  warning: null,
  isDetectedSubscriptionIssueLater: false,
};
