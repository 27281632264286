import { FC } from "react";

import { FlexContainer } from "../../../ui/layout";
import { Typography } from "../../../ui/typography";
import TemplateItem from "./template-use.item";

import { useAppSelector } from "../../../../hooks/redux/useRedux";
import useTemplates from "../../../../hooks/templates/useTemplates";
import {
  selectAllTemplates,
  selectTemplatesLoading,
} from "../../../../redux/templates/selectors";

import "./style.scss";

const TemplateUseList: FC = () => {
  const templates = useAppSelector(selectAllTemplates);
  const loader = useAppSelector(selectTemplatesLoading);

  const { activeTemplate, chooseActiveTemplate } = useTemplates();

  if (templates.length) {
    return (
      <div className='template-scroll-container'>
        {templates.map(({ name, id }) => {
          const radioGroup = [
            {
              value: id === activeTemplate?.id,
              name: "template",
              label: name,
              id,
            },
          ];
          return (
            <TemplateItem
              key={id}
              radioGroup={radioGroup}
              radioValue={id === activeTemplate?.id}
              onChooseClick={() => chooseActiveTemplate(id)}
            />
          );
        })}
      </div>
    );
  }
  if (!loader) {
    return (
      <FlexContainer align='center' justify='center' style={{ height: 200 }}>
        <Typography fontSize='.8rem'>
          No templates with such category
        </Typography>
      </FlexContainer>
    );
  }
  return null;
};

export default TemplateUseList;
