// @ts-ignore
const getCursorPoint = (event, element: MutableRefObject) => {
  const point = event.changedTouches ? event.changedTouches[0] : event;

  let x = point.pageX;
  let y = point.pageY;

  if (element && element.current) {
    const elRect = element.current.getBoundingClientRect();

    x -= elRect.left;
    y -= elRect.top;
  }

  return { x, y };
};

// @ts-ignore
const getRelativePoint = (target, ref) => {
  const rect = target.current.getBoundingClientRect();
  const targetRef = ref || target.current.parentNode;

  const refRect = targetRef.getBoundingClientRect();
  return { x: rect.left - refRect.left, y: rect.top - refRect.top };
};

const getCenterPoint = (target: HTMLInputElement) => {
  const bouding = target.getBoundingClientRect();
  return {
    x: bouding.x + bouding.width * 0.5,
    y: bouding.y + bouding.height * 0.5,
  };
};

const isMobileDevice = () => {
  return typeof window.orientation !== "undefined";
};

const addEventListeners = (
  target: HTMLDivElement,
  events: string[] | string,
  call: () => void
) => {
  if (Array.isArray(events)) {
    events.forEach((event) => {
      target.addEventListener(event, call);
    });
  } else {
    target.addEventListener(events, call);
  }
};

const removeEventListeners = (
  target: HTMLDivElement,
  events: string[] | string,
  call: () => void
) => {
  if (Array.isArray(events)) {
    events.forEach((event) => {
      target.removeEventListener(event, call);
    });
  } else {
    target.removeEventListener(events, call);
  }
};

const detectLandscapeImage = (
  imageUrl: string,
  setIsImgLandscape: (value: React.SetStateAction<boolean | null>) => void
) => {
  const img = new Image();
  img.src = imageUrl;
  img.onload = () => {
    setIsImgLandscape(img.width > img.height);
  };
};

export {
  getCursorPoint,
  getRelativePoint,
  getCenterPoint,
  isMobileDevice,
  addEventListeners,
  removeEventListeners,
  detectLandscapeImage,
};
