import radiusIcon from "../../assets/icons/campaigns/radius-icon.png";
import stripsIcon from "../../assets/icons/campaigns/strips-icon.png";
import openCardIcon from "../../assets/icons/campaigns/open-card-icon.png";
import navigation from "../navigation";

export interface ICampaignList {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  icon: any;
  url?: string;
  action?: () => void;
}

const campaignList: ICampaignList[] = [
  {
    id: 1,
    name: "Outbound Prospecting",
    description:
      "Target new contacts from one of the largest, most up-to-date databases in the USA and send outbound campaigns!",
    isActive: true,
    icon: radiusIcon,
    url: navigation.outboundProspecting,
  },
  {
    id: 2,
    name: "Birthdays / Anniversaries",
    description:
      "Send cards to your contacts two weeks before their birthdays and/or anniversaries, automatically!",
    isActive: true,
    icon: openCardIcon,
    url: navigation.automationCards,
  },
  {
    id: 3,
    name: "Multi-Step Campaign",
    description:
      "Create multi-step drip marketing campaigns, keeping your contacts engaged!",
    isActive: true,
    icon: stripsIcon,
    url: navigation.multiStepCampaign,
  },
];

export default campaignList;
