import { createEntityAdapter } from "@reduxjs/toolkit";
import { IMultiStepsState, IStep } from "./types";
import { sortStepsHandler } from "../../helpers/multi-step";

export const multiStepsAdapter = createEntityAdapter<IStep>({
  selectId: (step) => step.id!,
  sortComparer: sortStepsHandler,
});

export const initialState = multiStepsAdapter.getInitialState<IMultiStepsState>(
  {
    campaignsList: null,
    endMultiStepCampaignsList: false,
    activeStepId: null,
    page: 1,
    isLoading: false,
    editMultiStep: null,
    newCampaignData: {
      campaign_name: "",
      return_address_id: null,
      address_ids: [],
    },
    keepState: false,
  }
);
