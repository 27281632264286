import { FC, useEffect, useMemo, useState } from "react";
import { IoIosAdd } from "react-icons/io";

import useRecipientTags from "../../../hooks/recipient-tags/useRecipientTags";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import {
  getRecipientsId,
  selectAllRecipients,
} from "../../../redux/recipients/selectors";
import { ButtonAction, FlexContainer } from "../../ui/layout";
import useCheckbox from "../../../hooks/input/useCheckbox";
import CustomCheckbox from "../../custom-checkbox/custom.checkbox";
import { updateRecipientsTags } from "../../../redux/profile/thunks";
import { closePopup } from "../../../redux/portal-popup/slice";
import { Input } from "../../ui/common/inputs/input.common";
import { ITag } from "../../../interfaces/recipient-tags/ITags";
import { IValueWithLabel } from "../../ui/common/select/multiWithSearch.select";
import { setRecipientsId } from "../../../redux/recipients/slice";
import useInput from "../../../hooks/input/useInput";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const AddTags: FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const selectedRecipientIds = useAppSelector(getRecipientsId);
  const recipients = useAppSelector(selectAllRecipients);

  // tags for checkbox
  const [tagsValue, setTagsValue] = useState<IValueWithLabel[]>([]);
  const [sameTags, setSameTags] = useState<number[]>([]);

  const { tagsToList, createTag, addTagToRecipient } = useRecipientTags(
    true,
    true,
    tagsValue,
    setTagsValue
  );

  const { checked, onChange: onCheckboxChange, setChecked } = useCheckbox([]);
  const { value, setValue, onChange: onInputChange } = useInput("");

  // logic for set initial tags and reset input value after create new tag
  useEffect(() => {
    setValue("");
    if (!tagsValue.length) setTagsValue(tagsToList);
  }, [tagsToList]);

  useEffect(() => {
    const tagsSelectedRecipient: ITag[][] = [];
    recipients.forEach((recipient) => {
      if (selectedRecipientIds.includes(recipient.id!)) {
        const tags = (recipient.tags as ITag[]) || [];
        const recipientTags = tags?.filter((tag) => tag.id > 0);
        tagsSelectedRecipient.push(recipientTags);
      }
    });

    // logic for search the same tags from selected recipients tags
    const commonValues = tagsSelectedRecipient.reduce((acc, tags) => {
      return acc.filter((commonTag) =>
        tags.some((currentTag) => commonTag.id === currentTag.id)
      );
    });

    const sameTags = commonValues.map((value) => value.id);
    setChecked(sameTags);
    setSameTags(sameTags);

    // we need to do this for clear recipients id from redux on close pop up
    return () => {
      dispatch(setRecipientsId([]));
    };
  }, []);

  const btnText = useMemo(() => {
    return `add tag${checked.length > 1 ? "s" : ""} to selected recipient${
      selectedRecipientIds.length > 1 ? "s" : ""
    }`;
  }, [selectedRecipientIds, checked]);

  const onClickBtn = async () => {
    const tagsToUnselect = sameTags.filter((id) => !checked.includes(id));
    await dispatch(
      updateRecipientsTags({
        unselected_tags: tagsToUnselect,
        selected_tags: checked,
        addresses: selectedRecipientIds,
      })
    );
    dispatch(closePopup());
  };

  const createAndApplyTag = async (value: string) => {
    const newTag = await createTag(value);
    if (newTag) {
      addTagToRecipient(newTag.id.toString(), newTag.name);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && value.trim().length) {
      e.preventDefault();
      createAndApplyTag(value.trim());
    }
  };

  const onIconClick = () => {
    if (value.trim().length) {
      createAndApplyTag(value.trim());
    }
  };

  return (
    <FlexContainer
      direction='column'
      style={{
        minWidth: isMobile ? "85%" : "20rem",
        height: isMobile ? "85%" : "55vh",
        paddingTop: isMobile ? "0.5rem" : 0,
      }}
    >
      <Input
        style={{ margin: "0 0 0.6777rem 0" }}
        className='popup-selector gray-input'
        placeholder='Create tag'
        type='text'
        onKeyPress={onKeyPress}
        icon={<IoIosAdd color='#ffffff' size={27} onClick={onIconClick} />}
        onChange={onInputChange}
        value={value}
      />
      <div
        style={{
          margin: "0 .8rem",
        }}
        className='scroll-container'
      >
        {tagsValue.map(({ label, id }) => {
          return (
            <CustomCheckbox
              key={id}
              data={{ label, id: +id }}
              checked={checked}
              onChange={onCheckboxChange!}
              className='name-column'
              style={{
                marginTop: "1rem",
                borderBottom: "1px solid #dddfd2",
                paddingBottom: "1rem",
              }}
            />
          );
        })}
      </div>
      <ButtonAction
        className={isMobile ? "updateBtn-mobile" : "updateBtn"}
        disabled={!checked.length}
        onClick={onClickBtn}
      >
        {btnText}
      </ButtonAction>
    </FlexContainer>
  );
};

export default AddTags;
