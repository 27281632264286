import { FC } from "react";
import { FlexContainer } from "../../layout";

interface ICharactersCounter {
  length: number;
  maxLength: number;
}

const CharactersCounter: FC<ICharactersCounter> = ({ length, maxLength }) => {
  return (
    <FlexContainer className='characters-counter'>
      <span>{`${length} / ${maxLength}`}</span>
    </FlexContainer>
  );
};

export default CharactersCounter;
