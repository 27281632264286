import { FC, memo } from "react";
import { MdClose } from "react-icons/md";
import { IoAlert } from "react-icons/io5";
import { FlexContainer } from "../../layout";
import ButtonCloseStyled from "./btn.styled";

import { IIconButton } from "../../../../interfaces/button/IButton";

const ButtonClose: FC<IIconButton> = ({
  onClick,
  className,
  style,
  alertButton,
  iconSize,
}) => {
  return (
    <ButtonCloseStyled className={className} onClick={onClick} style={style}>
      <FlexContainer justify='center'>
        {!alertButton ? (
          <MdClose color='#fff' size={iconSize || 21} />
        ) : (
          <IoAlert color='#fff' size={iconSize || 18} />
        )}
      </FlexContainer>
    </ButtonCloseStyled>
  );
};

export default memo(ButtonClose);
