import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IFeedback } from "../../interfaces/feedback/IFeedback";
import { IThunkError } from "../../interfaces/redux/IThunkError";

import feedbackApi from "../../api/feedback.api";
import { setPortalPopup } from "../portal-popup/slice";
import popupConstants from "../../constants/popup/constants";

const sendFeedback = createAsyncThunk<
  boolean,
  IFeedback,
  { rejectValue: IThunkError }
>("feedback/send", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const result = await feedbackApi.send(payload);

    if (result) {
      dispatch(
        setPortalPopup({
          title: "Success",
          typePopup: "alert",
          text: popupConstants.feedbackSendSuccess,
        })
      );
      return true;
    }
    return false;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export default sendFeedback;
