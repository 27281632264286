import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./types";

const spinnerSlice = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    setSpinner: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setSpinner } = spinnerSlice.actions;

export default spinnerSlice.reducer;
