import { FC } from "react";
import { MdFavorite } from "react-icons/md";
import { useTheme } from "styled-components";

import { Typography } from "../../ui/typography";
import { FlexContainer } from "../../ui/layout";

interface ICardInfo {
  isFavorite: boolean;
  toggleFavorite: () => void;
  name: string;
}

const CardInfo: FC<ICardInfo> = ({ isFavorite, toggleFavorite, name }) => {
  const { primary, light } = useTheme();

  return (
    <FlexContainer>
      <div>
        <MdFavorite
          size={28}
          cursor='pointer'
          color={isFavorite ? primary : light}
          onClick={toggleFavorite}
        />
      </div>
      <Typography
        fontWeight='400'
        fontSize='0.77777rem'
        style={{
          margin: "0 0.8rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "10rem",
        }}
      >
        {name}
      </Typography>
    </FlexContainer>
  );
};

export default CardInfo;
