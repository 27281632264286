import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./types";
import {
  fetchSubscriptions,
  updateSubscription,
  setNewSubscription,
  cancelSubscription,
} from "./thunks";

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    changeDetectedSubscriptionIssue(state, { payload }) {
      state.isDetectedSubscriptionIssueLater = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptions.fulfilled, (state, { payload }) => {
      state.subscriptions = payload.subscriptions;
      state.current = payload.current;
      state.newCurrent = payload.newSubscription;
      state.subscriptionsLoader = false;
      state.warning = payload.warning || null;
    });
    builder.addCase(fetchSubscriptions.rejected, (state) => {
      state.subscriptionsLoader = false;
    });
    builder.addCase(fetchSubscriptions.pending, (state) => {
      state.subscriptionsLoader = true;
    });
    builder.addCase(updateSubscription.pending, (state) => {
      state.subscriptionsLoader = true;
    });
    builder.addCase(updateSubscription.rejected, (state) => {
      state.subscriptionsLoader = false;
    });
    builder.addCase(updateSubscription.fulfilled, (state) => {
      state.subscriptionsLoader = false;
    });
    builder.addCase(setNewSubscription.pending, (state) => {
      state.subscriptionsLoader = true;
    });
    builder.addCase(setNewSubscription.rejected, (state) => {
      state.subscriptionsLoader = false;
    });
    builder.addCase(setNewSubscription.fulfilled, (state, { payload }) => {
      if (payload) {
        state.new = payload.subscription;
        state.subscriptionsLoader = false;
      }
    });
    builder.addCase(cancelSubscription.pending, (state) => {
      state.subscriptionsLoader = true;
    });
    builder.addCase(cancelSubscription.rejected, (state) => {
      state.subscriptionsLoader = false;
    });
    builder.addCase(cancelSubscription.fulfilled, (state) => {
      state.subscriptionsLoader = false;
    });
  },
});

export const { changeDetectedSubscriptionIssue } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
