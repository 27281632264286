import { ISignature } from "../../interfaces/signatures/ISignature";

interface ISignatureState {
  signatures: Array<ISignature>;
}

const initialState: ISignatureState = {
  signatures: [],
};

export default initialState;
