import { RootState } from "../store";

export const getPortalPopupData = (state: RootState) => state.portalPopups.form;
export const getStatusPortalPopup = (state: RootState) =>
  state.portalPopups.isShowPopup;
export const getTextPortalPopup = (state: RootState) => state.portalPopups.text;
export const getTitlePortalPopup = (state: RootState) =>
  state.portalPopups.title;
export const getTypePortalPopup = (state: RootState) =>
  state.portalPopups.typePopup;
