import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import aiApi from "../../api/ai.api";

const generateMessage = createAsyncThunk<any, string>(
  "ai/generateMessage",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      await aiApi.generateMessage(payload, dispatch);
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    }
  }
);

export default generateMessage;
