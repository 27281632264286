import { useCallback } from "react";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";

import useHubSpotUser from "../hubSpot/useHubSpotUser";
import { IAuthProvider } from "../../interfaces/auth/IAuthProvider";

const useGoogleSignIn = () => {
  const { setAuthProvider } = useHubSpotUser();

  const signIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      if (!tokenResponse.access_token) return;
      const authCandidate: IAuthProvider = {
        access_token: tokenResponse?.access_token,
        client: "web",
        provider: "google",
      };
      setAuthProvider(authCandidate);
    },
    flow: "implicit",
  });

  const signOut = useCallback(async () => {
    return googleLogout();
  }, []);

  return {
    signIn,
    signOut,
  };
};

export default useGoogleSignIn;
