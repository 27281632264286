import styled from "styled-components";
import { ButtonCircle } from "../btn-circle/btn.styled";

const ButtonCloseStyled = styled(ButtonCircle)`
  width: 1.88888rem;
  height: 1.88888rem;

  background-color: ${(props) => props.theme.primary};
`;

export default ButtonCloseStyled;
