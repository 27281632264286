import {
  CustomCardId,
  ICustomCardCreate,
  ImageId,
} from "../interfaces/custom-cards/ICustomCard";
import { ImageType } from "../interfaces/custom-cards/ICustomCardImage";
import { ICustomCardResponse } from "../interfaces/custom-cards/responses/ICustomCardResponse";
import { IListImagesResponse } from "../interfaces/custom-cards/responses/IListImagesResponse";
import { ICustomizedCardResponse } from "../interfaces/custom-cards/responses/ICustomizedCardResponse";
import { IUploadCustomImageResponse } from "../interfaces/custom-cards/responses/IUploadCustomImageResponse";
import { userInstance } from "../hooks/instance/useInstance";
import { IQrCode } from "../interfaces/custom-cards/IQrCode";
import { IFrameTemplatesResponse } from "../interfaces/custom-cards/responses/IFrameTemplateResponse";
import { IQrCodeResponse } from "../interfaces/custom-cards/responses/IQrCodeResponse";

const customCardsApi = {
  createCard: async (payload: ICustomCardCreate) => {
    return userInstance.post("/cards/createCustomCard", { ...payload });
  },
  getCard: async (id: CustomCardId) => {
    return userInstance.get<ICustomCardResponse>(
      `/design/getCustomCard?id=${id}`
    );
  },
  getCustomizedCard: async (id: CustomCardId) => {
    return userInstance.get<ICustomizedCardResponse>(
      `/design/getCustomizedCard?id=${id}`
    );
  },
  deleteImage: async (image_id: ImageId) => {
    return userInstance.post("/cards/deleteCustomLogo", { image_id });
  },
  listImages: async (type: ImageType | string | null) => {
    return userInstance.get<IListImagesResponse>(
      `/cards/listCustomUserImages?type=${type || ""}`
    );
  },
  uploadImage: async (file: FormData) => {
    return userInstance.post<IUploadCustomImageResponse>(
      "/cards/uploadCustomLogo",
      file
    );
  },
  createQrCode: async (payload: IQrCode) => {
    return userInstance.post<any>(`/qrCode`, payload);
  },
  getFrames: async () => {
    return userInstance.get<IFrameTemplatesResponse>(`/qrCode/frames`);
  },
  getQrCodeList: async () => {
    return userInstance.get<IQrCodeResponse>(`/qrCode`);
  },
  deleteQrCodeById: async (id: number) => {
    return userInstance.delete(`/qrCode/${id}`);
  },
};

export default customCardsApi;
