import styled from "styled-components";

const LoaderContainer = styled.div<{ bgcolor?: string }>`
  height: 100vh;
  width: 100vw;
  background-color: ${({ bgcolor }) => bgcolor};

  z-index: 1001;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

LoaderContainer.defaultProps = {
  bgcolor: "rgba(0, 0, 0, 0.1)",
};

export default LoaderContainer;
