import { FC, useContext, useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { useTheme } from "styled-components";

import { FlexContainer } from "../../ui/layout";
import ButtonCircle from "../../ui/buttons/btn-circle/btn-circle.common";

import useInput from "../../../hooks/input/useInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";
import getCustomFields from "../../../redux/custom-fields/selectors";
import { getAdvancedFilters } from "../../../redux/recipients/selectors";
import { removeAdvancedFilter } from "../../../redux/recipients/slice";
import {
  addCustomFields,
  deleteCustomField,
} from "../../../redux/custom-fields/thunks";

import customFieldNameReg from "../../../constants/custom-fields/custom-field-regex";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";
import AddField from "./addField";

const CustomFields: FC = () => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const activeFilters = useAppSelector(getAdvancedFilters);
  const customFieldsData = useAppSelector(getCustomFields);

  const nameInput = useInput("");

  const { btnIcon } = useTheme();

  const [errors, setErrors] = useState<string[]>([]);

  const { openConfirmationPopup } = useContext(ConfirmationPopupContext);

  const removeErrorByName = (error: string) => {
    setErrors((prev) => prev.filter((e) => e !== error));
  };

  const checkIsErrors = (name: string, isValidNameInput: boolean) => {
    const errors = [];

    if (isValidNameInput) {
      errors.push("invalidName");
    }

    if (!name.trim()) {
      errors.push("emptyName");
    }
    setErrors(errors);
  };

  const isInvalidFieldName = (name: string) => {
    const regex = new RegExp(customFieldNameReg);
    return !!name.match(regex);
  };

  const onAddCustomFields = async () => {
    const isInvalidNameInput = isInvalidFieldName(nameInput.value);

    if (isInvalidNameInput || !nameInput.value.trim().length) {
      return checkIsErrors(nameInput.value, isInvalidNameInput);
    }

    await dispatch(addCustomFields({ label: nameInput.value }));

    nameInput.setValue("");
  };

  const onDelete = async (id: number) => {
    const fieldToDelete = customFieldsData.filter((field) => field.id === id);
    const res = await dispatch(deleteCustomField(id));

    if (res && activeFilters && activeFilters["custom field"]) {
      const isNeedToRemove = fieldToDelete.find(
        (field) => field.label === activeFilters["custom field"]
      );
      if (isNeedToRemove) dispatch(removeAdvancedFilter("custom field"));
    }
  };

  const openDeletePopup = (id: number) => {
    openConfirmationPopup({
      subtitle: "Are you sure you want to delete?",
      onConfirm: () => onDelete(id),
    });
  };

  useEffect(() => {
    if (errors.includes("emptyName") && nameInput.value.trim().length) {
      removeErrorByName("emptyName");
    }

    if (
      errors.includes("invalidName") &&
      !isInvalidFieldName(nameInput.value)
    ) {
      removeErrorByName("invalidName");
    }
  }, [nameInput.value, errors]);

  return (
    <FlexContainer
      direction='column'
      width={isMobile ? "100%" : "29rem"}
      style={{
        height: isMobile ? "85%" : "55vh",
      }}
    >
      <FlexContainer
        direction='column'
        style={{ position: "relative", padding: "0", height: "100%" }}
      >
        <AddField
          nameInput={nameInput}
          errors={errors}
          onAddCustomFields={onAddCustomFields}
        />

        {!!customFieldsData?.length && (
          <div
            className='scroll-container'
            style={{ marginTop: "0", height: isMobile ? "" : "40vh" }}
          >
            {customFieldsData.map(({ id, label }) => {
              return (
                <FlexContainer
                  direction='row'
                  key={id}
                  justify='space-between'
                  style={{
                    borderBottom: "1px solid #dddfd2",
                    paddingBottom: ".61111rem",
                    marginTop: ".61111rem",
                  }}
                >
                  <div className='text-ellipsis' style={{ width: "100%" }}>
                    {label}
                  </div>
                  <div style={{ marginRight: "0.5rem" }}>
                    <ButtonCircle
                      onClick={() => openDeletePopup(id)}
                      style={{
                        width: "1.77777rem",
                        height: "1.77777rem",
                        cursor: "pointer",
                      }}
                    >
                      <IoMdTrash color={btnIcon} size={isMobile ? 20 : 15} />
                    </ButtonCircle>
                  </div>
                </FlexContainer>
              );
            })}
          </div>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default CustomFields;
