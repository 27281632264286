import { FC, useMemo } from "react";
import { useTheme } from "styled-components";
import { Typography } from "../../ui/typography";
import { FlexContainer } from "../../ui/layout";

interface ICardPrice {
  price: string | number;
  discount_price: string | number | null;
}

const CardPrice: FC<ICardPrice> = ({ price, discount_price }) => {
  const { primary, darkLight2 } = useTheme();

  const correctShowPrice = useMemo(() => {
    if (typeof price === "number") return price.toFixed(2);
    return price;
  }, [price]);

  const correctDiscountPrice = useMemo(() => {
    if (discount_price && typeof discount_price === "number")
      return discount_price.toFixed(2);
    return discount_price;
  }, [discount_price]);

  return (
    <FlexContainer
      direction='column'
      align='center'
      justify='center'
      width='auto'
    >
      <Typography fontWeight='bold' fontSize='0.77777rem' color={primary}>
        ${correctDiscountPrice || correctShowPrice}
      </Typography>
      {discount_price && (
        <Typography
          fontWeight='bold'
          fontSize='0.77777rem'
          color={darkLight2}
          decoration='line-through'
        >
          ${Number(price).toFixed(2)}
        </Typography>
      )}
    </FlexContainer>
  );
};

export default CardPrice;
