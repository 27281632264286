import { createContext, FC, useState, useMemo } from "react";

export interface IConfirmationPopupContext {
  isNavigationPrevented: boolean;
  setPreventNavigation: (status: boolean) => void;
}

export const PreventNavigationContext =
  createContext<IConfirmationPopupContext>({} as IConfirmationPopupContext);

const PreventNavigationProvider: FC = ({ children }) => {
  const [isNavigationPrevented, setPreventNavigation] =
    useState<boolean>(false);

  const initialValue = useMemo(() => {
    return { isNavigationPrevented, setPreventNavigation };
  }, [isNavigationPrevented, setPreventNavigation]);

  return (
    <PreventNavigationContext.Provider value={initialValue}>
      {children}
    </PreventNavigationContext.Provider>
  );
};

export default PreventNavigationProvider;
