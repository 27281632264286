import { FC } from "react";
import { isIPhone13 } from "react-device-detect";

import { FlexContainer, Navigation, TextLink } from "../../../ui/layout";

import navigation from "../../../../constants/navigation";

import useUser from "../../../../hooks/auth/useUser";
import useAuth from "../../../../hooks/auth/useAuth";

import NavItem from "../nav.item";
import AccountChangeCredentials from "./account.change-credentials";
import AccountDefaultAddress from "./account.default-address";
import AccountConvert from "./account.convert";
import AccountRemainingPrepay from "./account.remaining-prepay";
import AutoReplenish from "../user.auto-replenish";
import AccountRemainingCredits from "./account.remaining-credits";
import Integrations from "../user.integrations";
import CampaignManager from "../user.campaign-manager";

interface IAccountNavigation {
  hideSetting: () => void;
}

const AccountNavigation: FC<IAccountNavigation> = ({ hideSetting }) => {
  const { signout } = useAuth();

  const { user_subscription } = useUser();

  return (
    <Navigation
      direction='column'
      className='scroll-container'
      style={{
        marginTop: user_subscription ? "2rem" : 0,
        paddingBottom: isIPhone13 ? "2rem" : "",
      }}
    >
      {/* {links.map(({ to, value }, index) => ( */}
      <NavItem
        linkTo={navigation.home}
        label='Card Selection'
        hideSetting={hideSetting}
      />

      <CampaignManager hideSetting={hideSetting} />
      <NavItem
        linkTo={navigation.addressBook}
        label='Address Book'
        hideSetting={hideSetting}
      />

      <NavItem
        linkTo={navigation.reporting}
        label='Reporting'
        hideSetting={hideSetting}
      />

      <NavItem
        linkTo={navigation.messageTemplates}
        label='Message Templates'
        hideSetting={hideSetting}
      />

      <NavItem
        linkTo={navigation.pastOrders}
        label='Past Orders'
        hideSetting={hideSetting}
      />

      <AccountConvert />
      <AccountDefaultAddress />

      <NavItem
        linkTo={navigation.paymentOptions}
        label='Payment Options'
        hideSetting={hideSetting}
      />

      <AccountChangeCredentials hideSetting={hideSetting} />
      <AccountRemainingPrepay />

      <AutoReplenish hideSetting={hideSetting} />
      <AccountRemainingCredits />

      <Integrations hideSetting={hideSetting} />

      <FlexContainer className='nav__links' justify='space-between'>
        <TextLink onClick={signout}>Sign Out</TextLink>
      </FlexContainer>
    </Navigation>
  );
};

export default AccountNavigation;
