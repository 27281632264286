import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationId } from "../../interfaces/notifications/INotification";

import { getNotifications, markNotificationAsRead } from "./thunks";
import initialState from "./types";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    removeNotification: (state, action: PayloadAction<NotificationId>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    heightNotifications: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.heightNotification = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.notifications = payload;
    });

    builder.addCase(markNotificationAsRead.fulfilled, (state, { payload }) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== payload
      );
    });
  },
});

export const { removeNotification, heightNotifications } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
