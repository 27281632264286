type LoadAsyncScript = (source: string) => Promise<HTMLScriptElement>;

const loadAsyncScript: LoadAsyncScript = (src) => {
  return new Promise<HTMLScriptElement>((resolve) => {
    const scriptElement = document.createElement("script");

    Object.assign(scriptElement, {
      type: "text/javascript",
      async: true,
      src,
    });

    scriptElement.addEventListener("load", () => resolve(scriptElement));
    document.head.appendChild(scriptElement);
  });
};

export default loadAsyncScript;
