import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import { addTag, deleteTag, getTagList } from "./thunks";

const recipientTagsSlice = createSlice({
  name: "recipientTags",
  initialState,
  reducers: {
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTagList.fulfilled, (state, { payload }) => {
      state.tags = payload as any;
    });
    builder.addCase(deleteTag.fulfilled, (state, { payload }) => {
      state.tags = state.tags!.filter((value) => value?.id !== payload?.id);
    });
    builder.addCase(addTag.fulfilled, (state, { payload }) => {
      state.tags = state.tags?.concat(payload);
    });
  },
});

export const { setSelectedTags } = recipientTagsSlice.actions;

export default recipientTagsSlice.reducer;
