import { RootState } from "../store";

export const getHubSpotUser = (state: RootState) => {
  return state.hubspot.user;
};

export const getHubSpotUse = (state: RootState) => {
  return state.hubspot.use;
};

export const getHSToken = (state: RootState) => {
  return state.hubspot.hsToken;
};
