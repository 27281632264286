import axios from "axios";
import { ICalcTaxesResponse } from "../interfaces/orders/responses/ICalcTaxesResponse";
import { ISingleStepOrderResponse } from "../interfaces/orders/responses/ISingleStepOrderResponse";
import { IPastOrdersListResponse } from "../interfaces/orders/responses/past-orders/IPastOrdersListResponse";
import { OrderId } from "../interfaces/orders/IOrder";
import { IChildsListResponse } from "../interfaces/orders/responses/IChildsListResponse";
import {
  IParseXlsResponse,
  ICoupons,
} from "../interfaces/orders/responses/bulk-orders/IParseXlsResponse";
import { IPlaceBasket } from "../interfaces/orders/IPlaceBasket";
import downloader from "../helpers/file-download";
import { userInstance } from "../hooks/instance/useInstance";

let cancelToken: any;

export const ordersApi = {
  cancelOrder: async (id: number) => {
    return userInstance.post("/orders/cancel", { order_id: id });
  },
  calcTaxes: async (payload: ICoupons) => {
    // if we have pending request we cancel it
    if (typeof cancelToken !== undefined) {
      // @ts-ignore
      await cancelToken?.cancel();
    }

    cancelToken = axios.CancelToken.source();

    return userInstance.post<ICalcTaxesResponse>(
      "/orders/calcTaxes",
      {
        warning: true,
        couponCode: payload.couponCode,
      },
      {
        cancelToken: cancelToken.token,
      }
    );
  },
  cancelCalcTaxes: async () => cancelToken?.cancel(),
  getOrderDetails: async (id: number) => {
    return userInstance.get(`/orders/details?id=${id}`);
  },
  listChilds: async (
    id: OrderId,
    status = "",
    limit?: number,
    page?: number
  ) => {
    return userInstance.get<IChildsListResponse>(
      `/orders/listChilds?id=${id}&status=${status}&limit=${limit || ""}&page=${
        page || ""
      }`
    );
  },
  getInvoicePdf: async (transId: number) => {
    const result = await userInstance.post(
      `/orders/invoice`,
      {
        transaction_id: transId,
      },
      {
        responseType: "blob",
      }
    );
    downloader(result.data, result.headers["file-name"]);
  },
  listPast: async () => {
    return userInstance.get<IPastOrdersListResponse>("/orders/past");
  },
  listGrouped: async (
    searchPage?: number,
    searchQuery?: string | null,
    limitQuery?: number,
    parentQuery?: string
  ) => {
    const page = searchPage ? `&page=${searchPage}` : "";
    const search = searchQuery ? `&search=${searchQuery}` : "";
    const limit = limitQuery ? `&limit=${limitQuery}` : `&limit=10`;
    const parent = parentQuery ? `&parent_id=${parentQuery}` : "";
    return userInstance.get<IPastOrdersListResponse>(
      `/orders/listGrouped?${page}${search}${limit}${parent}`
    );
  },
  payOrder: async () => {
    return userInstance.post("/orders/pay");
  },
  placeBasket: async (payload: IPlaceBasket) => {
    return userInstance.post("/orders/placeBasket", payload);
  },
  placeOrder: async () => {
    return userInstance.post("/orders/place");
  },
  singleStepOrder: async () => {
    return userInstance.post<ISingleStepOrderResponse>(
      "/orders/singleStepOrder"
    );
  },
  parseXls: async (payload: FormData) => {
    return userInstance.post<IParseXlsResponse>(
      "/bulkOrders/parseXls",
      payload
    );
  },
};

export const { cancelCalcTaxes } = ordersApi;
