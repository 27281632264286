const maxWishesCharacters = 50;

const maxNameLength = 30;

const maxAddressLength = 30;

const maxPasswordCharacters = 128;

const maxCustomFieldLength = 20;

const maxRecipientCustomFieldLength = 30;

export {
  maxWishesCharacters,
  maxPasswordCharacters,
  maxNameLength,
  maxAddressLength,
  maxCustomFieldLength,
  maxRecipientCustomFieldLength,
};
