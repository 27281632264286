import { useState } from "react";

const useConfirmation = (name: string) => {
  const [confirmationMessage, setConfirmationMassage] = useState("");

  const isConfirmed = (value: string, valueToCompare: string) => {
    const match = value === valueToCompare;
    if (!match) {
      setConfirmationMassage(`${name} confirmation error`);
    }
    return match;
  };

  return {
    isConfirmed,
    confirmationMessage,
    setConfirmationMassage,
  };
};

export default useConfirmation;
