import React, { FC } from "react";
import classNames from "classnames";

import { useAppSelector } from "../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

import "./style.scss";

export interface ICustomCheckbox {
  label: string;
  id: number;
}

interface ICheckbox {
  data: ICustomCheckbox | ICustomCheckbox[];
  checked: number[];
  onChange: (id: number) => void;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  rounded?: boolean;
  disabled?: boolean;
  heightContainer?: number;
  className?: string;
}

const CustomCheckbox: FC<ICheckbox> = ({
  data,
  checked,
  onChange,
  style,
  rounded,
  disabled = false,
  heightContainer,
  labelStyle,
  className = "",
}) => {
  let dataToRender: ICustomCheckbox[] = [];
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  if (!Array.isArray(data)) {
    dataToRender = [data];
  } else {
    dataToRender = data;
  }

  const checkmarkClass = classNames({
    checkmark: true,
    rounded,
  });

  return (
    <>
      {dataToRender.map(({ label, id }) => (
        <label
          key={id}
          className={`custom-checkbox ${className}`}
          style={{ ...style, cursor: disabled ? "default" : "pointer" }}
        >
          <p style={labelStyle}>{label}</p>
          <input
            type='checkbox'
            name='checkbox'
            checked={checked.includes(id)}
            onChange={() => (disabled ? {} : onChange(id))}
            disabled={disabled}
          />
          <span
            className={checkmarkClass}
            style={{
              // we need it for vertical align on mobile devices
              top: `${isMobile ? `calc(${heightContainer! / 2}px - 50%)` : 0}`,
            }}
          />
        </label>
      ))}
    </>
  );
};

export default CustomCheckbox;
