import { createAsyncThunk } from "@reduxjs/toolkit";

import signaturesApi from "../../api/signatures.api";

const fetchSignatures = createAsyncThunk("profile/signatures", async () => {
  const res = await signaturesApi.list();

  const { signatures } = res.data;

  return signatures;
});

export default fetchSignatures;
