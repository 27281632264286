import { useLayoutEffect, useState } from "react";
import loadBeacon from "./loadBeacon";

const BeaconLoader = () => {
  const [didLoad, setDidLoad] = useState(false);

  useLayoutEffect(() => {
    if (!didLoad) {
      setDidLoad(true);
      loadBeacon();
    }
  }, []);

  return null;
};

export default BeaconLoader;
