import React, { FC } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useTheme } from "styled-components";
import BorderBox from "../../../../bordered-box/bordered-box";

interface ICheckListShowPlusButton {
  countHideItemsBtn: number;
  onAction: (value: boolean) => void;
  show: boolean;
  order: boolean;
}

const CheckListShowPlusButton: FC<ICheckListShowPlusButton> = ({
  onAction,
  countHideItemsBtn,
  show,
  order,
}) => {
  const { medium } = useTheme();

  return (
    <div onClick={() => onAction(!show)}>
      <BorderBox
        style={{
          margin: order ? ".2rem" : "",
          width: "max-content",
          fontSize: ".8rem",
          padding: ".7rem",
          backgroundColor: medium,
          border: "none",
          cursor: "pointer",
        }}
      >
        <AiOutlinePlus />
        {countHideItemsBtn}
      </BorderBox>
    </div>
  );
};

export default CheckListShowPlusButton;
