import { FC } from "react";

import BulkUploadHeaderTable from "./bulk-upload-header.table";
import { Container } from "./bulk-upload.styled";
import BulkUploadTable from "./bulk-upload.table";

import { ValidateDataList } from "../../../interfaces/bulk-upload/IValidateData";

import "./style.scss";

interface IBulkUploadTableContainerProps {
  title: string;
  subtitle: string;
  errors: ValidateDataList;
  onlyWarnings?: boolean;
  onConfirm?: () => void;
}

const BulkUploadTableContainer: FC<IBulkUploadTableContainerProps> = ({
  title,
  subtitle,
  errors,
  onlyWarnings,
  onConfirm,
}) => {
  return (
    <Container style={{ backgroundColor: "white" }}>
      <div>
        <BulkUploadHeaderTable title={title} subtitle={subtitle} />
        <BulkUploadTable
          errors={errors}
          onlyWarnings={onlyWarnings}
          onConfirm={onConfirm}
        />
      </div>
    </Container>
  );
};

export default BulkUploadTableContainer;
