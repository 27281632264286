import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import { buyPrepaidPlan, fetchPrepaidPlans } from "./thunks";

const prepaidPlansSlice = createSlice({
  name: "prepaid_plans",
  initialState,
  reducers: {
    resetSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrepaidPlans.fulfilled, (state, { payload }) => {
      state.prepaidPlans = payload.prepaidPlans;
      state.range = payload.range;
    });
    builder.addCase(buyPrepaidPlan.fulfilled, (state, { payload }) => {
      state.successMessage = payload.message;
    });
  },
});

export const { resetSuccessMessage } = prepaidPlansSlice.actions;

export default prepaidPlansSlice.reducer;
