import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useLayoutEffect,
  useMemo,
} from "react";
import { MdModeEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { useLocation } from "react-router";
import { useTheme } from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import { getConfirmationDeletingRecipients } from "../../../redux/recipients/thunks";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";
import Radio from "../../ui/common/radio/radio.common";
import ButtonCircle from "../../ui/buttons/btn-circle/btn-circle.common";
import { FlexContainer } from "../../ui/layout";

import CustomCheckbox from "../../custom-checkbox/custom.checkbox";
import { Tooltip } from "../../ui/common/tooltip/tooltip.common";
import navigation from "../../../constants/navigation";
import { getActiveAutomationType } from "../../../redux/automation/selectors";
import {
  automationTypes,
  automationTypesConstant,
} from "../../../constants/automation-compain/automation-types";
import tagsErrorMessage from "../../../constants/tags/tags";
import BirthdayLogo from "../../logo/BirthdayLogo";
import MultiStepLogo from "../../logo/MultiStepLogo";
import AnniversaryLogo from "../../logo/AnniversaryLogo";
import {
  getIsMobileWindowDimensions,
  getIsTabletWindowDimensions,
} from "../../../redux/window-dimensions/selectors";

import "../style.scss";
import AddressBookCampaignLogo from "./address-book.campaign-logo";
import { Typography } from "../../ui/typography";
import conjunctionString from "../../../helpers/conjunctionString";
import { ICampaignTagNames } from "../../../interfaces/recipient-tags/ITags";

type AddressBookCardType = "checkbox" | "radio";

interface ICampaignTags extends ICampaignTagNames {
  anniversary: boolean;
  birthday: boolean;
  multiStep: boolean;
}

interface IAddressBookCardProps {
  type: AddressBookCardType;
  id: number;
  label?: string;
  text?: string;
  checked?: Array<number>;
  radioValue?: boolean;
  name?: string;
  businessName?: string;
  square?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onChange?: () => void;
  birthday?: string;
  isAutomations?: boolean;
  anniversary?: string;
  disabled?: boolean;
  setRenderAddressBook?: Dispatch<SetStateAction<boolean>>;
  heightContainer?: number;
  correctDateSentBirthday?: (dateBirthday?: Date | undefined) => string;
  defaultAddress?: boolean;
  index?: number;
  isReturnAddress?: boolean;
  campaignTags?: ICampaignTags;
  style?: any;
  refChild?: any;
  renderMessageError?: boolean;
  message?: string[];
  isValidTags?: boolean;
}

const AddressBookCard: FC<IAddressBookCardProps> = ({
  onDelete,
  onEdit,
  onChange,
  radioValue = false,
  name,
  businessName,
  type,
  birthday,
  isAutomations,
  anniversary,
  label = "",
  id,
  text,
  checked = [],
  square = false,
  disabled = false,
  correctDateSentBirthday,
  setRenderAddressBook,
  heightContainer,
  defaultAddress = false,
  index,
  isReturnAddress,
  campaignTags,
  style,
  refChild,
  message,
  renderMessageError,
  isValidTags,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  let automationId: automationTypesConstant | null = null;

  const activeAutomation = useAppSelector(getActiveAutomationType);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const isTablet = useAppSelector(getIsTabletWindowDimensions);

  if (activeAutomation) automationId = activeAutomation.id;

  const { btnIcon } = useTheme();

  const isAddressBook = location.pathname.includes(navigation.addressBook);

  // check when address book is rendered.
  // Need to get all elements for calc height every container
  useLayoutEffect(() => {
    if (setRenderAddressBook) setRenderAddressBook(true);
  }, []);

  const { openConfirmationPopup } = useContext(ConfirmationPopupContext);

  const radioGroup = [
    {
      value: false,
      name: "order",
      label,
      id,
    },
  ];

  const deleteHandler = async () => {
    onDelete?.();
  };

  const getConfirmationText = async () => {
    const result = await dispatch(
      getConfirmationDeletingRecipients({ address_id: id })
    );
    return getConfirmationDeletingRecipients.fulfilled.match(result)
      ? result?.payload?.text
      : "";
  };

  const openDeletePopup = async () => {
    const confirmationText = await getConfirmationText();
    openConfirmationPopup({
      subtitle: (
        <>
          Are you sure you want to delete?
          {!!confirmationText && (
            <>
              <br /> {confirmationText}{" "}
            </>
          )}
        </>
      ),
      onConfirm: deleteHandler,
    });
  };

  const correctBirthday = useMemo(() => {
    if (!!birthday && (birthday as string).includes("0000")) return "";
    return correctDateSentBirthday && !!birthday
      ? correctDateSentBirthday(
          new Date((birthday as string).concat("T00:00:00-0000"))
        )
      : birthday;
  }, [correctDateSentBirthday, birthday]);

  const correctAnniversary = useMemo(() => {
    if (!!anniversary && (anniversary as string).includes("0000")) return "";
    return correctDateSentBirthday && !!anniversary
      ? correctDateSentBirthday(
          new Date((anniversary as string).concat("T00:00:00-0000"))
        )
      : anniversary;
  }, [correctDateSentBirthday, anniversary]);

  const isValid = useMemo(() => {
    if (automationId === automationTypes.BIRTHDAY) {
      return !correctBirthday && isAutomations;
    }
    if (automationId === automationTypes.ANNIVERSARY) {
      return !correctAnniversary && isAutomations;
    }
  }, [isAutomations, correctBirthday, automationId, correctAnniversary]);

  const isRemovable = useMemo(() => !!onDelete, [onDelete]);

  const removable = useMemo(
    () => !(isAutomations || disabled) && isRemovable,
    [isAutomations, isRemovable, disabled]
  );

  const correctDateTitle = useMemo(() => {
    if (automationId === automationTypes.BIRTHDAY || isAddressBook) {
      return correctBirthday;
    }
    if (automationId === automationTypes.ANNIVERSARY) {
      return correctAnniversary;
    }
  }, [correctBirthday, automationId, correctAnniversary]);

  const iconSize = useMemo(
    () => (isTablet && !isMobile ? "1.3rem" : "1.77777rem"),
    [isTablet, isMobile]
  );

  const mobileIconSize = useMemo(() => (isMobile ? 18 : 15), [isMobile]);

  return (
    <FlexContainer
      ref={refChild}
      className='recipient-list-row row'
      style={{
        padding: isMobile ? "15px 0" : "1rem",
        height: "auto",
        ...style,
      }}
    >
      <FlexContainer>
        <FlexContainer
          id={index ? index.toString() : "0"}
          className='address-book__card containerRef'
          align={isMobile ? "flex-start" : "center"}
          justify='start'
          style={{
            opacity: disabled ? 0.35 : 1,
            maxWidth: isMobile ? "70%" : "",
          }}
        >
          {type === "checkbox" ? (
            <CustomCheckbox
              data={{ label, id }}
              checked={checked}
              onChange={disabled ? () => {} : onChange!}
              disabled={disabled}
              heightContainer={heightContainer}
              labelStyle={{ whiteSpace: "initial" }}
              className='name-column'
            />
          ) : (
            <Radio
              radioGroup={radioGroup}
              onChange={disabled ? () => {} : onChange!}
              value={radioValue}
              defaultTitle={defaultAddress}
              square={square}
              name={name}
              businessName={businessName}
            />
          )}
          <div className='recipient-list__address address-column'>{text}</div>
          {(isAutomations || isAddressBook) && (
            <div
              className='recipient-list__address birthday-column'
              style={!isTablet ? { marginLeft: "4rem" } : {}}
            >
              {correctDateTitle}
            </div>
          )}
          {!isMobile && !isAutomations && !isReturnAddress && campaignTags ? (
            <FlexContainer
              className='campaigns-column'
              direction='row'
              justify='flex-start'
            >
              <FlexContainer
                direction='row'
                justify='space-between'
                style={{ width: isTablet ? "5rem" : "7rem" }}
              >
                <AddressBookCampaignLogo
                  iconSize={iconSize}
                  index={index}
                  message={
                    campaignTags?.anniversary_names?.length
                      ? campaignTags?.anniversary_names.join("<br />")
                      : "Anniversary Campaign"
                  }
                >
                  <AnniversaryLogo isActive={campaignTags?.anniversary} />
                </AddressBookCampaignLogo>

                <AddressBookCampaignLogo
                  iconSize={iconSize}
                  index={index}
                  message={
                    campaignTags?.birthday_names?.length
                      ? campaignTags?.birthday_names.join("<br />")
                      : "Birthday Campaign"
                  }
                >
                  <BirthdayLogo isActive={campaignTags?.birthday} />
                </AddressBookCampaignLogo>

                <AddressBookCampaignLogo
                  iconSize={iconSize}
                  index={index}
                  message={
                    campaignTags?.multi_step_names?.length
                      ? campaignTags?.multi_step_names.join("<br />")
                      : "Multi-step Campaign"
                  }
                >
                  <MultiStepLogo isActive={campaignTags?.multiStep} />
                </AddressBookCampaignLogo>
              </FlexContainer>
            </FlexContainer>
          ) : null}
        </FlexContainer>
        <FlexContainer className='actions-column' direction='row'>
          {onEdit && (
            <div style={{ marginLeft: 10 }}>
              <Tooltip
                message={
                  automationId === automationTypes.BIRTHDAY
                    ? "This recipient doesn't have birthday information"
                    : "This recipient doesn't have anniversary information"
                }
                left
                hide={!isValid}
              >
                <ButtonCircle
                  onClick={onEdit}
                  style={{
                    border: isValid || isValidTags ? "1px solid red" : "none",
                    width: iconSize,
                    height: iconSize,
                  }}
                >
                  <MdModeEdit
                    color={isValid || isValidTags ? "red" : btnIcon}
                    size={isTablet && !isMobile ? 13 : mobileIconSize}
                  />
                </ButtonCircle>
              </Tooltip>
            </div>
          )}

          {removable && (
            <div style={{ marginLeft: 10 }}>
              <ButtonCircle
                onClick={openDeletePopup}
                disabled={disabled}
                style={{ width: iconSize, height: iconSize }}
              >
                <IoMdTrash
                  color={btnIcon}
                  size={isTablet && !isMobile ? 13 : mobileIconSize}
                />
              </ButtonCircle>
            </div>
          )}
        </FlexContainer>
      </FlexContainer>

      {renderMessageError && message && (
        <FlexContainer padding='0 0 0 1.6rem'>
          <Typography fontSize='0.7777rem' color='red'>
            {tagsErrorMessage}
            {conjunctionString(message)}
          </Typography>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default AddressBookCard;
