import { FC } from "react";
import { useTheme } from "styled-components";
import { FaFacebookF, FaGoogle, FaApple } from "react-icons/fa";
import SocialBtn from "./social-btn";

import { FlexContainer } from "../../ui/layout";
import { Title } from "../../ui/typography";
import useFacebookSignIn from "../../../hooks/auth/useFacebookSignIn";
import useAppleSignIn from "../../../hooks/auth/useAppleSignIn";
import useGoogleSignIn from "../../../hooks/auth/useGoogleSignIn";

const iconStyle = {
  marginRight: ".655rem",
  width: "auto",
  height: ".95rem",
};

const SignInSocial: FC = () => {
  const { fbColor, gColor, aColor } = useTheme();

  const { signIn: signInWithGoogle } = useGoogleSignIn();
  const { signIn: signInWithFacebook } = useFacebookSignIn();
  const { signIn: signInWithApple } = useAppleSignIn();

  return (
    <FlexContainer direction='column'>
      <Title className='registration__title' style={{ marginTop: "1.3rem" }}>
        Sign In With
      </Title>
      <FlexContainer justify='space-between' style={{ marginTop: "1rem" }}>
        <SocialBtn
          bgColor={fbColor}
          action={signInWithFacebook}
          title='Facebook'
          btnStyle={{ marginRight: ".8888rem" }}
        >
          <FaFacebookF style={iconStyle} />
        </SocialBtn>

        <SocialBtn
          bgColor={gColor}
          onClick={() => signInWithGoogle()}
          title='Google'
        >
          <FaGoogle style={iconStyle} />
        </SocialBtn>
      </FlexContainer>
      <SocialBtn
        bgColor={aColor}
        action={signInWithApple}
        title='Apple'
        btnStyle={{ marginTop: ".8rem" }}
      >
        <FaApple style={iconStyle} />
      </SocialBtn>
    </FlexContainer>
  );
};

export default SignInSocial;
