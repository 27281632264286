import {
  FC,
  useRef,
  useEffect,
  FormEvent,
  useState,
  useMemo,
  useContext,
} from "react";

import useSelect from "../../../hooks/input/useSelect";
import useInput from "../../../hooks/input/useInput";
import useCountries from "../../../hooks/countries/useCountries";
import useRecipients from "../../../hooks/address/useRecipients";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import useForm from "../../../hooks/forms/useForm";
import useCalendarInput from "../../../hooks/input/useCalendarInput";
import {
  automationTypes,
  IAutomationsEdit,
} from "../../../constants/automation-compain/automation-types";
import useAutoCompletePlaces from "../../../hooks/autocomplete-places/useAutocompletePlaces";
import useAddressLongInputErrors from "../../../hooks/address/useAddressLongInputErrors";
import useSetDataFromGoogleApi from "../../../hooks/autocomplete-places/useSetDataFromGoogleApi";

import {
  getEditRecipientId,
  selectRecipientById,
} from "../../../redux/recipients/selectors";
import RecipientForm from "./recipient.form";
import { IRecipient } from "../../../interfaces/address/IRecipient";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";
import useWarningAtRecipientForm from "../../../hooks/address/useWorningAtRecipientForm";
import trimString from "../../../helpers/trimString";
import {
  ICampaignTagNames,
  ITag,
  ITagValueWithLabel,
} from "../../../interfaces/recipient-tags/ITags";
import { IRecipientCustomField } from "../../../interfaces/custom-fields/IRecipientCustomFields";

export interface ValidateCollection extends HTMLCollection {
  first_name: HTMLInputElement;
  last_name: HTMLInputElement;
  address1: HTMLInputElement;
  city: HTMLInputElement;
  zip: HTMLInputElement;
  state: HTMLInputElement;
  country: HTMLInputElement;
  tag: HTMLInputElement;
  phone: HTMLInputElement;
  email: HTMLInputElement;
}

const RecipientEdit: FC = () => {
  const addressInputRef = useRef<HTMLInputElement>(null);

  const { openConfirmationPopup } = useContext(ConfirmationPopupContext);

  const editId = useAppSelector(getEditRecipientId);
  const [updateValue, setUpdateValue] = useState<IRecipient | null>(null);
  const recipient = useAppSelector((state) =>
    selectRecipientById(state, editId!)
  );

  const {
    findEmptyElements,
    toggleValidateClass,
    checkValidEmail,
    phoneError,
    emailError,
  } = useForm();
  const { update, correctDateSent, setConfirmPopUpText, confirmPopUpText } =
    useRecipients();
  const address = useAutoCompletePlaces(addressInputRef);
  const { checkCorrectText } = useWarningAtRecipientForm();

  const first_name = useInput(recipient?.first_name || "");
  const last_name = useInput(recipient?.last_name || "");
  const business_name = useInput(recipient?.business_name || "");
  const address1 = useInput(recipient?.address1 || "");
  const address2 = useInput(recipient?.address2 || "");
  const city = useInput(recipient?.city || "");
  const zip = useInput(recipient?.zip || "");
  const [tagsValueWithLabel, setTagsValueWithLabel] = useState<
    ITagValueWithLabel[]
  >([]);

  const [recipientCustomFields, setRecipientCustomFields] = useState<
    IRecipientCustomField[]
  >([]);

  const campaignsNames: ICampaignTagNames = useMemo(() => {
    return {
      birthday_names: recipient?.birthday_names,
      anniversary_names: recipient?.anniversary_names,
      multi_step_names: recipient?.multi_step_names,
    };
  }, [recipient]);

  const initialCustomFields = useMemo(
    () => recipient?.custom_fields || [],
    [recipient?.custom_fields]
  );

  const stateCountry = useSelect(recipient?.state || "");
  const country = useSelect(String(recipient?.country_id) || "1");
  const tag = useSelect("");
  const email = useInput(recipient?.email || "");
  const phone = useInput(recipient?.phone || "");

  const { countriesToList, statesToList, countryState } = useCountries(
    country.value as string
  );

  const { lengthInputErrors } = useAddressLongInputErrors({
    firstName: first_name.value,
    lastName: last_name.value,
    businessName: business_name.value,
    address1: address1.value,
    address2: address2.value,
  });

  useSetDataFromGoogleApi(address, city, zip, stateCountry, address1, country);

  useEffect(() => {
    // TODO get some data from backend
    const data = recipient?.tags || [];
    const modifyTags = (data as ITag[]).map((value: ITag) => {
      return {
        label: value.name,
        value: value.id.toString(),
        id: value.id.toString(),
        treeDepth: 0,
        icon: value.icon || "",
        color: value.color || "",
      };
    });
    setTagsValueWithLabel(modifyTags);
  }, [recipient]);

  const correctRecipient = useMemo(() => {
    if (!recipient?.birthday) return undefined;
    const withoutTimeZone = (recipient?.birthday as string).concat(
      "T00:00:00-0000"
    );
    const date = new Date(withoutTimeZone);
    return new Date(date.toISOString().slice(0, -1));
  }, [recipient?.birthday]);

  const correctRecipientAnniversary = useMemo(() => {
    if (!recipient?.anniversary) return undefined;
    const withoutTimeZone = (recipient?.anniversary as string).concat(
      "T00:00:00-0000"
    );
    const date = new Date(withoutTimeZone);
    return new Date(date.toISOString().slice(0, -1));
  }, [recipient?.anniversary]);

  const date = useCalendarInput(correctRecipient);
  const anniversaryDate = useCalendarInput(correctRecipientAnniversary);

  const automationText = useMemo(() => {
    return confirmPopUpText.length > 1 ? "automations" : "automation";
  }, [confirmPopUpText]);

  const correctText = useMemo(() => {
    const birthdayType: string[] = [];
    const anniversaryType: string[] = [];

    confirmPopUpText.map((el: IAutomationsEdit) => {
      if (el.type === automationTypes.BIRTHDAY.toLowerCase()) {
        return birthdayType.push(el.name);
      }
      if (el.type === automationTypes.ANNIVERSARY.toLowerCase()) {
        return anniversaryType.push(el.name);
      }
      return [];
    });

    const birthdayText = birthdayType.length ? (
      <>
        <br />
        <strong>{birthdayType.join(", ")}</strong> - birthday automation
        <br />
      </>
    ) : null;

    const anniversaryText = anniversaryType.length ? (
      <>
        {!birthdayText && <br />}
        <strong>{anniversaryType.join(", ")}</strong> - anniversary automation
        <br />
      </>
    ) : null;

    const automationsName = () => {
      if (anniversaryText && birthdayText) {
        return "birthday and anniversary";
      }
      return anniversaryText && !birthdayText ? "anniversary" : "birthday";
    };

    return (
      <>
        This address is used in
        {birthdayText}
        {anniversaryText} If you remove the date of {automationsName()} then
        this contact will disappear from this {automationText}
      </>
    );
  }, [confirmPopUpText, automationText]);

  useEffect(() => {
    // if we have confirmation text
    if (confirmPopUpText.some((val) => !!val)) {
      openConfirmationPopup({
        subtitle: correctText,
        // @ts-ignore
        onConfirm: (event) => {
          update(event, updateValue!);
          setConfirmPopUpText([]);
        },
        onCancel: () => setConfirmPopUpText([]),
        styleModal: { width: "10%" },
      });
    }
  }, [confirmPopUpText]);

  if (!editId) return null;

  const states = statesToList(country?.value as string);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const htmlFormControlsCollection = event.currentTarget.elements;

    const {
      first_name,
      last_name,
      address1,
      zip,
      city,
      state,
      country,
      phone,
      email,
    } = htmlFormControlsCollection as ValidateCollection;

    const correctState = states!.map((el) => el.label).includes(state?.value);

    if (!correctState && countryState) {
      (
        document.querySelector('select[name="state"]') as HTMLInputElement
      ).value = "";
      stateCountry.setValue("");
    }

    const inputs = [first_name, address1, city, zip, country];
    const inputsWithState = [first_name, address1, zip, city, state, country];
    toggleValidateClass(countryState ? inputsWithState : inputs);

    const emptyElements = findEmptyElements(
      countryState ? inputsWithState : inputs
    );

    // const isPhoneValid = checkValidPhone(phone);
    const isEmailValid = checkValidEmail(email);

    if (emptyElements.length || !isEmailValid) return;

    const birthday = correctDateSent(date.date);
    const anniversary = correctDateSent(anniversaryDate.date);

    const tags =
      tagsValueWithLabel.reduce((acc: number[], tag) => {
        if (+tag.id > 0) {
          acc.push(+tag.id);
        }
        return acc;
      }, []) || [];

    const isUpdatedAnyCustomFields = initialCustomFields.some(
      (initialField, index) =>
        initialField.value !== recipientCustomFields[index].value
    );

    const recipientToUpdate: IRecipient = {
      first_name: trimString(first_name.value),
      last_name: trimString(last_name.value),
      business_name: business_name.value,
      address1: address1.value,
      address2: address2.value,
      city: city.value,
      tags,
      state: countryState ? (state.value as string) : "",
      zip: zip.value,
      country_id: country.value as string,
      birthday: birthday || null,
      anniversary: anniversary || null,
      id: editId,
      check_date: false,
      phone: phone.value,
      email: email.value,
    };
    if (isUpdatedAnyCustomFields)
      recipientToUpdate.custom_fields = recipientCustomFields.map((field) => {
        return {
          id: field.id,
          value: typeof field.value === "string" ? field.value.trim() : null,
        };
      });

    setUpdateValue(recipientToUpdate);

    checkCorrectText(
      () =>
        update(event, {
          ...recipientToUpdate,
          allow_poor: true,
        }),
      first_name.value,
      last_name.value,
      address1.value
    );
  };

  return (
    <RecipientForm
      addressInputRef={addressInputRef}
      campaignsNames={campaignsNames}
      isEditRecipient
      date={date}
      anniversaryDate={anniversaryDate}
      first_name={first_name}
      last_name={last_name}
      recipientCustomFields={recipientCustomFields}
      setRecipientCustomFields={setRecipientCustomFields}
      initialCustomFields={initialCustomFields}
      business_name={business_name}
      email={email}
      phone={phone}
      countriesToList={countriesToList}
      tag={tag}
      tagsValueWithLabel={tagsValueWithLabel}
      setTagsValueWithLabel={setTagsValueWithLabel}
      address1={address1}
      address2={address2}
      city={city}
      zip={zip}
      state={stateCountry}
      country={country}
      countryState={countryState}
      btnText='Update recipient'
      onSubmit={onSubmit}
      states={states}
      isRecipientForm
      lengthInputErrors={lengthInputErrors}
      phoneError={phoneError}
      emailError={emailError}
    />
  );
};

export default RecipientEdit;
