// @ts-nocheck
import React, { FC, memo, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import classNames from "classnames";
import { FlexContainer } from "../../layout";
import { ModalConfirm } from "../../../Modal/Modal";
import { Title } from "../../typography";

import {
  getStatusPortalPopup,
  getTextPortalPopup,
  getTypePortalPopup,
  getTitlePortalPopup,
  getPortalPopupData,
} from "../../../../redux/portal-popup/selectors";
import { closePopup } from "../../../../redux/portal-popup/slice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/redux/useRedux";

import {
  UseTemplatePopup,
  SaveTemplate,
  CreateDefaultAddress,
  RecipientEdit,
  RecipientCreate,
  PasswordEditPopup,
  PasswordResetFormPopup,
  PasswordResetConfirmFormPopup,
  InvalidCouponPopUp,
  EmailChangePopup,
  CustomCardsSuccessPopup,
  ConvertPopup,
  AddressPhysical,
  AddressEdit,
  AddCustomizationCardSavePopup,
  TemplateUseList,
  TemplateItem,
  ReturnAddressesList,
  ReturnAddressesSelectList,
  ChooseCampaignPopup,
  AddTags,
  SelectTags,
  RequestMailingAddressPopup,
  CustomFields,
  ProceedAnyway,
} from "../../../popups";
import SignIn from "../../../../pages/auth/SignIn";
import PortalPopupAlert from "./portal-popup.alert";

import messagePopup from "../../../../constants/popup/constants";
import typePopups from "../../../../constants/popup/type-popup";

import "./style.scss";
import navigation from "../../../../constants/navigation";
import ContainerHeader from "../../../navigation/containerHeader/container.header";
import AiAssistPopup from "../../../popups/ai-assist/ai-assist.popup";
import CreateQRCodePopup from "../../../popups/qr-code/create-qr.popup";
import SelectCardPopup from "../../../popups/select-card/select-card.popup";
import { getIsMobileWindowDimensions } from "../../../../redux/window-dimensions/selectors";
import useAuth from "../../../../hooks/auth/useAuth";

interface IPopup {
  onCloseClick?: () => void;
}

export const modalWindows = [
  typePopups.RECIPIENTS_DEFAULT_ADDRESS,
  typePopups.USE_TEMPLATE,
  typePopups.RECIPIENT_EDIT,
  typePopups.RECIPIENT_CREATE,
  typePopups.ADDRESS_PHYSICAL,
  typePopups.ADDRESS_EDIT,
  typePopups.ADD_TAG,
  typePopups.REQUEST_MAILING_ADDRESS,
  typePopups.CUSTOM_FIELDS,
];

const PortalPopup: FC<IPopup> = ({ onCloseClick }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const { signout } = useAuth();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const form = useAppSelector(getPortalPopupData);
  const isShowPopup = useAppSelector(getStatusPortalPopup);
  const typePopup = useAppSelector(getTypePortalPopup);
  const text = useAppSelector(getTextPortalPopup);
  const title = useAppSelector(getTitlePortalPopup);

  const scrollablePopups = [
    typePopups.RECIPIENTS_DEFAULT_ADDRESS,
    typePopups.RECIPIENT_CREATE,
    typePopups.ADDRESS_EDIT,
    typePopups.RECIPIENT_EDIT,
    typePopups.ADD_TAG,
    typePopups.REQUEST_MAILING_ADDRESS,
    typePopups.CUSTOM_FIELDS,
  ];

  const isScrollable = scrollablePopups.some((popup) => popup === form);

  const isModal = modalWindows.some((popup) => popup === form);

  const isMobileModal = useMemo(() => isModal && isMobile, [isMobile, isModal]);

  const popupClassNames = classNames({
    popup: true,
    "scroll-container": isScrollable,
    "wide-popup":
      form === typePopups.CHOOSE_CAMPAIGN ||
      form === typePopups.CREATE_QR_CODE ||
      form === typePopups.SELECT_CARD,
    "template-popup": form === typePopups.USE_TEMPLATE,
  });

  const mobileModalTitle =
    form === typePopups.USE_TEMPLATE ? "Your Templates" : title;

  const toHome = () => {
    history.push(navigation.home);
    dispatch(closePopup());
  };

  const onClose = async () => {
    if (
      location.pathname.includes(navigation.auth.signup) &&
      typePopup === "noauth"
    )
      return dispatch(closePopup());
    if (typePopup === "banned") {
      signout();
      return;
    }
    if (onCloseClick) {
      onCloseClick();
    }
    if (
      location.pathname.includes(navigation.customizable) &&
      text !== messagePopup.wrongSizeImage &&
      title !== messagePopup.invalidFile &&
      text !== messagePopup.toLargerImg &&
      text !== messagePopup.wrongFormatFile &&
      title !== messagePopup.createQrCode &&
      form !== typePopups.CHOOSE_CAMPAIGN
    ) {
      toHome();
    }
    if (
      location.pathname.includes(navigation.auth.signup) &&
      typePopup !== "error"
    ) {
      history.push(navigation.auth.signin);
    }

    dispatch(closePopup());
  };

  const LazyComponent = useMemo(() => {
    if (typePopup === "form") {
      switch (form) {
        case typePopups.USE_TEMPLATE:
          return UseTemplatePopup;
        case typePopups.SAVE_TEMPLATE:
          return SaveTemplate;
        case typePopups.RECIPIENTS_DEFAULT_ADDRESS:
          return CreateDefaultAddress;
        case typePopups.RECIPIENT_EDIT:
          return RecipientEdit;
        case typePopups.RECIPIENT_CREATE:
          return RecipientCreate;
        case typePopups.ADD_TAG:
          return AddTags;
        case typePopups.SELECT_TAGS:
          return SelectTags;
        case typePopups.PASSWORD_EDIT:
          return PasswordEditPopup;
        case typePopups.PASSWORD_RESET_REQUEST_FORM:
          return PasswordResetFormPopup;
        case typePopups.PASSWORD_RESET_CONFIRM_FORM:
          return PasswordResetConfirmFormPopup;
        case typePopups.INVALID_COUPON:
          return InvalidCouponPopUp;
        case typePopups.CUSTOM_FIELDS:
          return CustomFields;
        case typePopups.EMAIL_CHANGE:
          return EmailChangePopup;
        case typePopups.CUSTOM_CREATE_SUCCESS:
          return CustomCardsSuccessPopup;
        case typePopups.CONVERT_EMAIL:
          return ConvertPopup;
        case typePopups.ADDRESS_PHYSICAL:
          return AddressPhysical;
        case typePopups.ADDRESS_EDIT:
          return AddressEdit;
        case typePopups.ADD_CUSTOMIZATION_CARD:
          return AddCustomizationCardSavePopup;
        case typePopups.TEMPLATE_LIST:
          return TemplateUseList;
        case typePopups.TEMPLATE_ITEM:
          return TemplateItem;
        case typePopups.RETURN_ADDRESS_LIST:
          return ReturnAddressesList;
        case typePopups.RETURN_ADDRESS_SELECT:
          return ReturnAddressesSelectList;
        case typePopups.CHOOSE_CAMPAIGN:
          return ChooseCampaignPopup;
        case typePopups.LOG_IN:
          return SignIn;
        case typePopups.AI_ASSIST:
          return AiAssistPopup;
        case typePopups.CREATE_QR_CODE:
          return CreateQRCodePopup;
        case typePopups.SELECT_CARD:
          return SelectCardPopup;
        case typePopups.PROCEED_ANYWAY:
          return ProceedAnyway;
        case typePopups.REQUEST_MAILING_ADDRESS:
          return RequestMailingAddressPopup;
        default:
          return PortalPopupAlert;
      }
    }
    return PortalPopupAlert;
  }, [form]);

  const popupStyle: React.CSSProperties = useMemo(() => {
    const style = {};

    switch (form) {
      case typePopups.AI_ASSIST:
        style.maxWidth = "max-content";
        return style;
      case typePopups.CREATE_QR_CODE:
        style.maxWidth = "100%";
        return style;
      case typePopups.RECIPIENT_EDIT:
      case typePopups.RECIPIENT_CREATE:
        if (!isMobile) {
          style.width = "40vw";
        }
        return style;
      default:
        style.maxWidth = "31rem";
        return style;
    }
  }, [form]);

  if (isShowPopup) {
    return (
      <ModalConfirm
        open={isShowPopup}
        onClose={onClose}
        isMobileModal={isMobileModal}
      >
        <div className={isMobileModal ? "mobile-modal-wrapper" : ""}>
          {isMobileModal ? (
            <ContainerHeader
              title={mobileModalTitle}
              style={{ marginTop: "0.3rem", marginBottom: "1.2rem" }}
            />
          ) : null}
          <div className={popupClassNames} style={popupStyle}>
            {isMobile || !title ? null : (
              <FlexContainer
                style={{ marginBottom: isMobile ? "0.61111rem" : "2rem" }}
              >
                <Title>{title}</Title>
              </FlexContainer>
            )}

            {typePopup === "form" ? (
              <LazyComponent />
            ) : (
              <LazyComponent onClick={onClose} text={text!} />
            )}
            {isMobileModal ? (
              <div className='mobile-modal-footer'>
                <div className='divider' />
                <button className='back-button' onClick={onClose} type='button'>
                  Cancel and go back
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </ModalConfirm>
    );
  }
  return null;
};

export default memo(PortalPopup);
