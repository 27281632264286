import { createSlice } from "@reduxjs/toolkit";

import { checkToken, getCredits, handleAttachInvoice } from "./thunks";
import initialState from "./types";

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setInvoice: (state, { payload }) => {
      state.attach_invoice = payload;
    },
    resetInfoByToken: (state) => {
      state.infoByToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCredits.fulfilled, (state, { payload }) => {
      state.credits = payload;
    });
    builder.addCase(handleAttachInvoice.fulfilled, (state, { payload }) => {
      state.attach_invoice = payload;
    });
    builder.addCase(checkToken.fulfilled, (state, { payload }) => {
      if (payload) {
        state.infoByToken = payload;
      }
    });
  },
});

export const { setInvoice, resetInfoByToken } = profileSlice.actions;

export default profileSlice.reducer;
