import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./types";
import {
  getCustomizedCard,
  listImages,
  uploadCustomImage,
  createCustomCard,
  getFrames,
  getQrCodeList,
} from "./thunks";
import { ICustomCardImage } from "../../interfaces/custom-cards/ICustomCardImage";

const customCardsSlice = createSlice({
  name: "custom-cards",
  initialState,
  reducers: {
    resetCustomCard: (state) => {
      state.customizedCard = null;
      state.listCover = [];
      state.listLogo = [];
      state.listIcon = [];
      state.listQrCode = [];
    },
    resetFrames: (state) => {
      state.frameTemplates = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listImages.fulfilled, (state, { payload }) => {
      const { images, type } = payload;
      // if we don't have type or have a few types we need to sort images by type
      if (!type || type?.includes(",")) {
        const logo: ICustomCardImage[] = [];
        const cover: ICustomCardImage[] = [];
        const icon: ICustomCardImage[] = [];
        images.forEach((img) => {
          if (img.type === "logo") logo.push(img);
          if (img.type === "cover") cover.push(img);
          if (img.type === "icon") icon.push(img);
        });
        state.listLogo = logo;
        state.listCover = cover;
        state.listIcon = icon;
      }
      if (type === "logo") state.listLogo = images;
      if (type === "cover") state.listCover = images;
      if (type === "icon") state.listIcon = images;
    });
    builder.addCase(getCustomizedCard.fulfilled, (state, { payload }) => {
      state.customizedCard = payload.card;
    });
    builder.addCase(createCustomCard.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFrames.fulfilled, (state, { payload }) => {
      state.frameTemplates = payload;
    });
    builder.addCase(getQrCodeList.fulfilled, (state, { payload }) => {
      state.listQrCode = payload;
    });
    builder.addCase(uploadCustomImage.fulfilled, (state, { payload }) => {
      const { type } = payload;

      if (type === "logo") state.listLogo = [payload, ...state.listLogo];
      if (type === "cover") state.listCover = [payload, ...state.listCover];
      if (type === "icon") state.listIcon = [payload, ...state.listIcon];
    });
  },
});

export const { resetCustomCard, resetFrames } = customCardsSlice.actions;

export default customCardsSlice.reducer;
