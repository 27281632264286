import { userInstance } from "../hooks/instance/useInstance";
import { IShippingRateRequest } from "../interfaces/shipping/IShipping";

const shippingApi = {
  list: async (data: IShippingRateRequest) => {
    try {
      const res = await userInstance.post("/shipping/rates", data);
      return res;
    } catch (err) {
      throw err;
    }
  },
  methodsList: async (isOutbound: boolean) => {
    const query = isOutbound ? "?where[show_in_outbound]=1" : "";
    return userInstance.get(`/shipping/methods${query}`);
  },
};

export default shippingApi;
