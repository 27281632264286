import { useCallback, useState, useEffect, useMemo, MouseEvent } from "react";
import cardValidator from "card-validator";

import {
  getCreditCards,
  selectCreditInfo,
} from "../../redux/credit-cards/selectors";
import {
  deleteCreditCard,
  fetchCreditCards,
  setDefaultCreditCard,
  addCreditCard,
  fetchCreditCardKeys,
  addNewCreditCard,
} from "../../redux/credit-cards/thunks";
import { login } from "../../redux/auth/thunks";
import { useAppDispatch, useAppSelector } from "../redux/useRedux";

import {
  ICreditCard,
  INewCreditCartInfo,
} from "../../interfaces/credit-cards/ICreditCard";
import { IValue } from "../../components/ui/common/select/select.common";
import { CARDS, creditCartType, Card, ICardIcon } from "./creditCard.constant";

const useCreditCards = (skipFetch?: boolean) => {
  const dispatch = useAppDispatch();

  const creditCards = useAppSelector(getCreditCards);
  const creditCardsInfo = useAppSelector(selectCreditInfo);

  const [creditCardsNumber, setCreditCardNumber] = useState<number>(0);

  const { card } = cardValidator.number(creditCardsNumber);

  const creditCardIcon = useCallback(() => {
    const dataNumber: Card = CARDS[card?.type || -1];
    if (!dataNumber) return null;
    return (
      <img
        style={{
          width: "48px",
          height: "48px",
        }}
        alt='credit card logo'
        src={
          (dataNumber?.icon as ICardIcon).default ||
          (dataNumber?.icon as string)
        }
      />
    );
  }, [card]);

  useEffect(() => {
    creditCardIcon();
  }, [card]);

  const defaultCreditCard = useMemo(() => {
    return creditCardsInfo?.creditCards.filter((card) => card.is_default)[0];
  }, [creditCardsInfo]);

  const setNewDefaultCreditCard = useCallback(async (id: number) => {
    await dispatch(setDefaultCreditCard(id));
  }, []);

  useEffect(() => {
    if (skipFetch || !!creditCards.length) return;
    dispatch(fetchCreditCards());
  }, [creditCards.length]);

  const typeCart = useMemo(() => {
    const keyCard: Array<string> = Object.keys(creditCartType);

    // @ts-ignore
    return keyCard.find((key) => creditCartType[key] === card?.niceType);
  }, [card, creditCartType]);

  const getClientInfo = useCallback(async () => {
    const info = await dispatch(fetchCreditCardKeys());
    return info.payload;
  }, []);

  const getTokenInfo = useCallback(
    async (
      clientInfo,
      cardNumber: string,
      cvvCode: string,
      monthSelectValue: string | IValue,
      yearSelectValue: string | IValue,
      zipCode: string
    ) => {
      const dateCard = `${monthSelectValue}${yearSelectValue}`;
      const id = clientInfo?.transaction_key;
      const userName = clientInfo?.login_id;
      const clientKey = clientInfo?.client_key;
      const tokenType = "TOKEN";
      const merchantAuthentication: INewCreditCartInfo = {
        securePaymentContainerRequest: {
          merchantAuthentication: {
            name: userName,
            clientKey,
          },
          data: {
            type: tokenType,
            id,
            token: {
              cardNumber,
              expirationDate: dateCard,
              cardCode: cvvCode,
              zip: zipCode,
            },
          },
        },
      };

      const addCard = await dispatch(addCreditCard(merchantAuthentication));
      const result = await addCard.payload;
      return result;
    },
    []
  );

  const newCardInfo = useCallback(
    async (
      creditCardsTokenInfo,
      zipCode,
      typeCart,
      address,
      nameOfCard,
      countrySelectValue,
      isDetectedSubscriptionIssue
    ) => {
      const newCard = {
        token: creditCardsTokenInfo?.dataValue,
        type: +typeCart,
        descriptor: creditCardsTokenInfo?.dataDescriptor,
        address,
        zip: zipCode,
        name: nameOfCard,
        country_id: countrySelectValue,
        pay_subscription: isDetectedSubscriptionIssue,
      };

      await dispatch(addNewCreditCard(newCard));
    },
    [typeCart]
  );

  const createNewCreditCard = async (
    event: MouseEvent<HTMLButtonElement>,
    nameOfCard: string,
    cardNumber: string,
    cvvCode: string,
    address: string,
    zipCode: string,
    monthSelectValue: string | IValue,
    yearSelectValue: string | IValue,
    countrySelectValue: string | IValue,
    isDetectedSubscriptionIssue: boolean
  ) => {
    event.preventDefault();
    const clientInfo = await getClientInfo();
    const creditCardsTokenInfo = await getTokenInfo(
      clientInfo,
      cardNumber,
      cvvCode,
      monthSelectValue,
      yearSelectValue,
      zipCode
    );

    await newCardInfo(
      creditCardsTokenInfo,
      zipCode,
      typeCart,
      address,
      nameOfCard,
      countrySelectValue,
      isDetectedSubscriptionIssue
    );
    await dispatch(fetchCreditCards());
    await dispatch(login());
  };

  const removeCreditCard = (id: number) => {
    dispatch(deleteCreditCard(id));
  };

  const _mapCreditCards = (creditCards: ICreditCard) => {
    const { card_number, type, id } = creditCards;

    const newCardNumber = `${type}  **** ${card_number.toString().slice(4)}`;

    return {
      label: newCardNumber,
      value: id.toString(),
      id,
    };
  };

  const creditCardsToList = useMemo(() => {
    return creditCards.map(_mapCreditCards);
  }, [creditCards]);

  return {
    creditCardsToList,
    removeCreditCard,
    creditCardsInfo,
    defaultCreditCard,
    setNewDefaultCreditCard,
    createNewCreditCard,
    creditCardIcon,
    setCreditCardNumber,
    cardInfo: card,
  };
};

export default useCreditCards;
