import { IGiftCards } from "../../interfaces/gift-card/IGiftCards";

interface IGiftCardsState {
  giftCards: Array<IGiftCards>;
}

const initialState: IGiftCardsState = {
  giftCards: [],
};

export default initialState;
