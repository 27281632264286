import {
  ChangeEvent,
  FormEvent,
  memo,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { IoIosAdd } from "react-icons/io";

import { Input } from "../../ui/common/inputs/input.common";
import Select from "../../ui/common/select/select.common";
import UploadFile from "../../ui/common/upload/upload.common";
import SelectWithInput from "../../ui/common/select/selectWithInput";
import {
  ButtonAction,
  CharactersCounter,
  FlexContainer,
  Form,
} from "../../ui/layout";
import { Typography } from "../../ui/typography";

import useBulkUpload from "../../../hooks/bulk-upload/useBulkUpload";
import MultiCheckedList from "../../ui/common/select/check-list/checkList";
import { Tooltip } from "../../ui/common/tooltip/tooltip.common";

import {
  ICountry,
  ICountryToList,
} from "../../../interfaces/countries/ICountry";
import { IRecipientCustomField } from "../../../interfaces/custom-fields/IRecipientCustomFields";
import { IGetCustomFieldResponse } from "../../../interfaces/custom-fields/responses/ICustomFieldsResponse";

import PopupCommon from "../common/popup.common";
import TitleSeparator from "../../title-separator/TitleSeparator";
import BulkUploadTableContainer from "../../bulk-upload/table/bulk-upload.container";
import messagePopup from "../../../constants/popup/constants";
import CalendarInput from "../../calendar/CalendarInput";

import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";
import {
  ICampaignTagNames,
  ITagValueWithLabel,
} from "../../../interfaces/recipient-tags/ITags";
import useRecipientTags from "../../../hooks/recipient-tags/useRecipientTags";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";
import { bulkRecipientTemplate } from "../../../constants/url";
import {
  maxAddressLength,
  maxNameLength,
  maxRecipientCustomFieldLength,
} from "../../../constants/characters";
import { IInputInstance } from "../../../hooks/input/useInput";
import detectEmojisInValue from "../../../helpers/detectEmojisInValue";
import usePopup from "../../../hooks/popup/usePopup";

import "./style.scss";

interface IRecipientForm {
  addressInputRef?: RefObject<HTMLInputElement>;
  campaignsNames?: ICampaignTagNames;
  isEditRecipient: boolean;
  first_name: any;
  last_name: any;
  recipientCustomFields?: IRecipientCustomField[];
  setRecipientCustomFields?: React.Dispatch<
    React.SetStateAction<IRecipientCustomField[]>
  >;
  initialCustomFields?: IGetCustomFieldResponse[];
  business_name: any;
  address1: any;
  address2: any;
  email?: IInputInstance;
  phone?: IInputInstance;
  city: any;
  zip: any;
  state?: any;
  states: any;
  country: any;
  tag?: any;
  date?: any;
  anniversaryDate?: any;
  btnText: string;
  countryState?: boolean;
  countries?: Array<ICountry>;
  countriesToList?: ICountryToList[] | null;
  tagsValueWithLabel?: ITagValueWithLabel[];
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  setTagsValueWithLabel?: React.Dispatch<
    React.SetStateAction<ITagValueWithLabel[]>
  >;
  isRecipientForm?: boolean;
  lengthInputErrors?: string[];
  phoneError?: string;
  emailError?: string;
}

const RecipientForm = memo<IRecipientForm>(({ ...rest }) => {
  const {
    addressInputRef,
    campaignsNames,
    isEditRecipient,
    first_name,
    last_name,
    recipientCustomFields,
    setRecipientCustomFields,
    initialCustomFields,
    business_name,
    address1,
    address2,
    email,
    phone,
    city,
    zip,
    state,
    country,
    tag,
    tagsValueWithLabel = [],
    date,
    anniversaryDate,
    btnText,
    countryState,
    countriesToList,
    states,
    onSubmit,
    setTagsValueWithLabel,
    isRecipientForm = false,
    lengthInputErrors,
    phoneError,
    emailError,
  } = rest;

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const { openErrorPopup } = usePopup();

  const {
    uploadRecipientsXlsFile,
    errors,
    cleanErrors,
    onlyWarningsAddress,
    confirmRecipientForm,
  } = useBulkUpload();

  const { openConfirmationPopup } = useContext(ConfirmationPopupContext);

  const formRef = useRef(null);

  const {
    onDeleteTagValueWithLabel,
    onDeleteSelectTagFromList,
    createTag,
    addTagToRecipient,
    tagsToList,
  } = useRecipientTags(
    isRecipientForm,
    true,
    tagsValueWithLabel,
    setTagsValueWithLabel!
  );

  useEffect(() => {
    (document.body.parentNode as HTMLElement).style.overflow = "hidden";
    return () => {
      (document.body.parentNode as HTMLElement).style.overflow = "auto";
    };
  }, []);

  const zipInputStyle = useMemo(() => {
    const style = { marginTop: 0, marginLeft: countryState ? "0.6777rem" : 0 };
    if (isMobile) {
      return {
        ...style,
        marginBottom: "0.6777rem",
        flex: countryState ? 2 : "auto",
      };
    }
    return style;
  }, [isMobile, countryState]);

  const stateInputStyle = useMemo(() => {
    if (isMobile) {
      return {
        marginTop: 0,
        marginBottom: "0.6777rem",
        flex: countryState ? 1 : "auto",
      };
    }
    return { margin: 0 };
  }, [isMobile, countryState]);

  const openDeletePopup = async (id: string, label: string) => {
    openConfirmationPopup({
      subtitle: <>Are you sure you want to delete tag {label}?</>,
      onConfirm: () => {
        onDeleteSelectTagFromList(id);
      },
    });
  };

  const flexContainerStyle = useMemo(() => {
    if (isMobile) {
      return { margin: 0 };
    }
    return { margin: "0.5rem 0" };
  }, [isMobile]);

  const stateZipBlockStyle = useMemo(() => {
    const style = { marginTop: 0 };
    if (!isMobile) {
      return { ...style, flex: countryState ? 2 : 1 };
    }
    return style;
  }, [isMobile, countryState]);
  const IsEmojisInLabels = (labels: string[]) =>
    !!labels.find((label) => detectEmojisInValue(label));

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (
      IsEmojisInLabels([
        first_name.value,
        last_name.value,
        business_name.value,
        address1.value,
        address2.value,
      ])
    ) {
      openErrorPopup("You can not use emojis in recipient information");
    } else {
      onSubmit(event);
    }
  };

  const mobileFormMargins = useMemo(
    () => (isMobile ? { margin: "0 0 0.6777rem" } : { margin: "0.5rem 0" }),
    [isMobile]
  );

  const createAndApplyTag = async (value: string) => {
    const newTag = await createTag(value);
    if (newTag) {
      addTagToRecipient(newTag.id.toString(), newTag.name);
    }
  };

  const currentNameLength = useMemo(
    () => first_name.value.length + last_name.value.length,
    [first_name.value.length, last_name.value.length]
  );

  // function for forbid entering symbols more than maxNameLength in first_name and last_name inputs
  const nameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    firstField: IInputInstance,
    secondField: IInputInstance
  ) => {
    const { value } = event.target;
    const oldValue = firstField.value;

    if (
      value.length < oldValue.length ||
      secondField.value.length + value.length <= maxNameLength
    ) {
      firstField.onChange(value);
    } else if (secondField.value.length + value.length > maxNameLength) {
      const maxAvailableLength =
        maxNameLength - secondField.value.length - oldValue.length;
      const slicedValue = value.slice(0, oldValue.length + maxAvailableLength);

      firstField.onChange(slicedValue);
    }
  };

  useEffect(() => {
    if (!initialCustomFields || !initialCustomFields.length) return;

    const fields = initialCustomFields.map((field) => {
      return { ...field, value: field?.value || null };
    });

    setRecipientCustomFields!(fields);
  }, [initialCustomFields]);

  const onChangeCustomFields = (
    id: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setRecipientCustomFields!((prev) =>
      prev?.map((field) => {
        if (field.id === id) {
          return { ...field, value };
        }
        return field;
      })
    );
  };

  const firstNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    nameChange(event, first_name, last_name);
  };

  const lastNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    nameChange(event, last_name, first_name);
  };

  const longInputErrorsStyle = useCallback(
    (errorName: string) =>
      lengthInputErrors?.includes(errorName) ? { border: "1px solid red" } : {},
    [lengthInputErrors]
  );

  const isLongNameError = useMemo(
    () => lengthInputErrors?.includes("long Recipient Name"),
    [lengthInputErrors]
  );

  return (
    <>
      <Form
        className='recipient-form'
        onSubmit={onFormSubmit}
        style={{ paddingTop: isMobile ? "0.6777rem" : 0 }}
        ref={formRef}
      >
        <FlexContainer direction='column'>
          <FlexContainer
            align='center'
            justify='space-between'
            className='row-input'
          >
            <FlexContainer>
              <Input
                className='gray-input'
                placeholder='First Name'
                type='text'
                name='first_name'
                {...first_name}
                onChange={firstNameChangeHandler}
              />
            </FlexContainer>
            <FlexContainer>
              <Input
                className='popup-selector gray-input'
                placeholder='Last Name (Optional)'
                type='text'
                name='last_name'
                {...last_name}
                style={isMobile ? { marginTop: 0, marginBottom: 0 } : {}}
                onChange={lastNameChangeHandler}
              />
            </FlexContainer>
          </FlexContainer>

          <FlexContainer
            align='center'
            justify='space-between'
            style={{
              fontSize: "0.68rem",
              margin: isMobile && !isLongNameError ? "0.2rem 0" : "",
            }}
          >
            {isLongNameError && (
              <div
                style={{
                  color: "red",
                  paddingRight: "0.5rem",
                }}
              >
                First Name + Last Name must be less than 30 characters
              </div>
            )}
            <FlexContainer
              justify='flex-end'
              width={isLongNameError ? "fit-content" : "100%"}
            >
              <CharactersCounter
                style={{
                  whiteSpace: "nowrap",
                  position: "unset",
                }}
              >
                {currentNameLength} / {maxNameLength} Characters
              </CharactersCounter>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <Input
          className='popup-selector gray-input'
          placeholder='Business Name (Optional)'
          type='text'
          size={maxNameLength}
          showCharactersCounter
          {...business_name}
          style={longInputErrorsStyle("long Business Name")}
        />

        {email && phone ? (
          <FlexContainer
            style={{ gap: isMobile ? 0 : "1rem" }}
            direction={isMobile ? "column" : "row"}
            align='flex-start'
          >
            <FlexContainer direction='column'>
              <Input
                className='popup-selector gray-input'
                placeholder='Email (Optional)'
                type='text'
                name='email'
                {...email}
                style={isMobile && emailError ? { marginBottom: 0 } : {}}
              />
              {emailError && (
                <span
                  className='error-input'
                  style={{ width: "100%", margin: isMobile ? "0.3rem 0" : 0 }}
                >
                  {emailError}
                </span>
              )}
            </FlexContainer>

            <FlexContainer direction='column'>
              <Input
                className='popup-selector gray-input'
                placeholder='Phone (Optional)'
                type='text'
                name='phone'
                {...phone}
                style={isMobile && phoneError ? { marginBottom: 0 } : {}}
              />
              {phoneError && (
                <span
                  className='error-input'
                  style={{ width: "100%", margin: isMobile ? "0.3rem 0" : 0 }}
                >
                  {phoneError}
                </span>
              )}
            </FlexContainer>
          </FlexContainer>
        ) : null}

        <Input
          ref={addressInputRef}
          className='popup-selector gray-input'
          placeholder='Address 1'
          type='text'
          name='address1'
          size={maxAddressLength}
          showCharactersCounter
          {...address1}
          style={longInputErrorsStyle("long Address1")}
        />
        <Input
          className='popup-selector gray-input'
          placeholder='Address 2 (Optional)'
          type='text'
          size={maxAddressLength}
          showCharactersCounter
          {...address2}
          style={longInputErrorsStyle("long Address2")}
        />

        <FlexContainer
          align='center'
          justify='space-between'
          className='row-input'
          style={flexContainerStyle}
        >
          <Input
            placeholder='City'
            type='text'
            name='city'
            {...city}
            className='gray-input'
            style={{
              marginBottom: isMobile ? "0.6777rem" : 0,
            }}
          />
          <FlexContainer direction='row' style={stateZipBlockStyle}>
            {countryState && !!states.length && (
              <Select
                placeholder='State'
                className='gray-input'
                style={stateInputStyle}
                options={states}
                name='state'
                placeholderStyle={{ opacity: 0.35 }}
                {...state}
                noDefault
              />
            )}
            <Input
              placeholder='Zip'
              type='text'
              name='zip'
              {...zip}
              className='gray-input'
              style={zipInputStyle}
            />
          </FlexContainer>
        </FlexContainer>

        <Select
          style={mobileFormMargins}
          options={countriesToList}
          placeholder='Country'
          name='country'
          {...country}
        />
        {isRecipientForm && (
          <SelectWithInput
            {...tag}
            options={tagsToList}
            type='text'
            placeholder='Enter Tag'
            maxLength={100}
            formRef={formRef}
            icon={<IoIosAdd color='#ffffff' size={27} />}
            style={mobileFormMargins}
            optionStyle={{ justifyContent: "space-between" }}
            clearInputOnBlur
            multiple
            clickIconHandler={createTag}
            selectOptionHandler={addTagToRecipient}
            removeOptionHandler={openDeletePopup}
            onEnter={createAndApplyTag}
          />
        )}

        {!!tagsValueWithLabel.length && (
          <FlexContainer>
            <MultiCheckedList
              campaignsNames={campaignsNames}
              valueWithLabel={tagsValueWithLabel.sort((a, b) => +b.id - +a.id)}
              deleteItem={onDeleteTagValueWithLabel}
              isRecipient
            />
          </FlexContainer>
        )}

        {date && (
          <CalendarInput
            date={date}
            placeholder='Birthday (Optional)'
            birthday
            clearDate
            optional
            topPosition
            style={mobileFormMargins}
          />
        )}

        {anniversaryDate && (
          <CalendarInput
            date={anniversaryDate}
            placeholder='Anniversary (Optional)'
            clearDate
            anniversary
            optional
            topPosition
            style={mobileFormMargins}
          />
        )}

        {!!recipientCustomFields?.length && (
          <FlexContainer justify='space-between' wrap='wrap'>
            {recipientCustomFields.map(({ label, value, id }, index) => (
              <div
                style={{
                  width:
                    // input must have width 100% if it is mobile or it is last not even element
                    isMobile ||
                    (!(index % 2) && recipientCustomFields.length - 1 === index)
                      ? "100%"
                      : "48%",
                }}
                key={id}
              >
                <Input
                  className='popup-selector gray-input'
                  placeholder={`${label} (Optional)`}
                  type='text'
                  name={label}
                  value={value?.length ? value : ""}
                  onChange={(e) => onChangeCustomFields(id, e)}
                  size={maxRecipientCustomFieldLength}
                  showCharactersCounter
                  style={{ width: "100%" }}
                  icon={
                    value?.length && label ? (
                      <Tooltip
                        message={label}
                        textStyle={{ textAlign: "center" }}
                        containerStyle={{ cursor: "pointer" }}
                      />
                    ) : null
                  }
                  iconStyle={{
                    backgroundColor: "unset",
                    cursor: "none",
                    right: "3rem",
                  }}
                />
              </div>
            ))}
          </FlexContainer>
        )}

        <ButtonAction
          className={isMobile ? "updateBtn-mobile" : "updateBtn"}
          type='submit'
          disabled={(date && date.notValid) || lengthInputErrors?.length}
        >
          {btnText}
        </ButtonAction>

        {!isEditRecipient && (
          <FlexContainer
            className='upload-file-container'
            direction='column'
            style={{ maxWidth: "max-content" }}
          >
            <TitleSeparator title='OR' />

            <Typography fontSize='0.77777rem'>
              Upload your multiple addresses.&nbsp;
              <a href={bulkRecipientTemplate} target='_blank' rel='noreferrer'>
                Click here
              </a>
              &nbsp;to download a template. Each person must have all fields
              filled out.
            </Typography>

            <FlexContainer margin='1rem 0 0 0'>
              <UploadFile onUploadFile={uploadRecipientsXlsFile} />
            </FlexContainer>
          </FlexContainer>
        )}
      </Form>
      <PopupCommon isOpen={!!errors?.length} onClose={cleanErrors}>
        <BulkUploadTableContainer
          title={messagePopup.addressUploadTitle}
          subtitle={messagePopup.addressUploadSubtitle}
          errors={errors}
          onlyWarnings={onlyWarningsAddress}
          onConfirm={confirmRecipientForm}
        />
      </PopupCommon>
    </>
  );
});

export default RecipientForm;
