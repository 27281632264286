import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import smoothscroll from "smoothscroll-polyfill";
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import App from "./App";
import store from "./redux/store";
import theme from "./theme";

import "./index.scss";
import "./style/index.scss";
import "./style/global.scss";

smoothscroll.polyfill();

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// eslint-disable-next-line  @typescript-eslint/no-unused-expressions
process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://c26974fa1de54c1e9411ec98b826ffa0@o34038.ingest.sentry.io/6492415",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // integrations: [new Integrations.BrowserTracing()],
    integrations: [
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0, // lower the value in production
  });

const app = (
  <Sentry.ErrorBoundary fallback={<div>Error</div>} showDialog>
    <GoogleOAuthProvider clientId={CLIENT_ID as string}>
      <HelmetProvider>
        <BrowserRouter>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    </GoogleOAuthProvider>
  </Sentry.ErrorBoundary>
);
ReactDOM.hydrate(app, document.getElementById("root"));
