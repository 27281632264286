import { FC } from "react";

import { FlexContainer } from "../../ui/layout";
import { Input } from "../../ui/common/inputs/input.common";
import Button from "../../ui/buttons/button";

import { maxCustomFieldLength } from "../../../constants/characters";
import invalidFieldNameError from "../../../constants/custom-fields/custom-field";

export interface IAddField {
  nameInput: any;
  errors: string[];
  onAddCustomFields: () => void;
}

const AddField: FC<IAddField> = ({ nameInput, errors, onAddCustomFields }) => {
  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onAddCustomFields();
    }
  };

  return (
    <FlexContainer direction='column'>
      <Input
        style={{
          marginTop: ".61111rem",
          border: errors.includes("emptyName") ? "1px solid red" : "",
        }}
        className='gray-input'
        {...nameInput}
        placeholder='Add new custom field'
        size={maxCustomFieldLength}
        showCharactersCounter
        onKeyPress={onKeyPress}
        type='text'
      />
      {errors.includes("invalidName") ? (
        <span
          className='error-input'
          style={{ width: "100%", padding: "0.2rem" }}
        >
          {invalidFieldNameError}
        </span>
      ) : null}

      <FlexContainer style={{ margin: "0.5rem" }} width='100%'>
        <Button onClick={onAddCustomFields}>
          <span>CREATE CUSTOM FIELD</span>
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default AddField;
