import {
  IAddCustomFieldResponse,
  IGetCustomFieldsResponse,
} from "../interfaces/custom-fields/responses/ICustomFieldsResponse";
import { userInstance } from "../hooks/instance/useInstance";

const customFieldApi = {
  getCustomFields: async () => {
    return userInstance.get<IGetCustomFieldsResponse>("/profile/fields");
  },
  createCustomField: async (payload: { label: string }) => {
    return userInstance.post<IAddCustomFieldResponse>(
      "/profile/fields",
      payload
    );
  },
  deleteCustomField: async (id: number) => {
    return userInstance.delete(`/profile/fields/${id}`);
  },
};

export default customFieldApi;
