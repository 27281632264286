import { ReactComponent as FacebookIc } from "../../assets/social/facebook-icon.svg";
import { ReactComponent as TwitterIc } from "../../assets/social/x-ic.svg";
import { ReactComponent as LinkedinIc } from "../../assets/social/linkedin-ic.svg";
import { ReactComponent as InstagramIc } from "../../assets/social/instagram-ic.svg";
import { ReactComponent as PinterestIc } from "../../assets/social/pinterest-ic.svg";
import { ReactComponent as YouTubeIc } from "../../assets/social/youtube-brands.svg";

const links = [
  { id: 1, title: "Privacy Policy", path: "privacy-policy" },
  { id: 2, title: "Contact Us", path: "contact-us" },
];

const socialNetworks = [
  {
    id: "facebook",
    href: "https://www.facebook.com/handwrytten",
    icon: <FacebookIc alt='Facebook' title='Facebook' />,
  },
  {
    id: "x",
    href: "https://x.com/handwrytten",
    icon: <TwitterIc alt='X' title='X' />,
  },
  {
    id: "linkedin",
    href: "https://www.linkedin.com/company/handwrytten/",
    icon: <LinkedinIc alt='Linkedin' title='Linkedin' />,
  },
  {
    id: "instagram",
    href: "https://www.instagram.com/handwryttennotes/",
    icon: <InstagramIc alt='Instagram' title='Instagram' />,
  },
  {
    id: "pinterest",
    href: "https://www.pinterest.com/handwrytten/_created/",
    icon: <PinterestIc alt='Pinterest' title='Pinterest' />,
  },
  {
    id: "youTube",
    href: "https://www.youtube.com/c/Handwrytten/",
    icon: <YouTubeIc alt='YouTube' title='YouTube' />,
  },
];

export { links, socialNetworks };
