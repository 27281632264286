import { ICard } from "../../interfaces/cards/ICard";
import { IActiveOrder } from "../../interfaces/orders/IActiveOrder";

interface IOrderState {
  sendDate: number | null;
  card: ICard | null;
  activeOrder: IActiveOrder | null;
  validations: Array<any> | null;
  isLoader: boolean;
}

const initialState: IOrderState = {
  sendDate: null,
  card: null,
  activeOrder: null,
  validations: null,
  isLoader: false,
};

export default initialState;
