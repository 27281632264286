import { FC } from "react";
import { useLocation } from "react-router";
import { useTheme } from "styled-components";
import classNames from "classnames";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import navigation from "../../../constants/navigation";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface ICardButtonsAction {
  isCustomize: boolean;
  isCustom: boolean;
  allowEdit: boolean;
  hubSpotUse: boolean;
  openDeletePopup: () => void;
  isPreview: boolean;
  onSelectCard?: () => void;
  onCustomize: () => void;
  onSendClick: () => void;
  onSendBulkClick: () => void;
}

const CardButtonsAction: FC<ICardButtonsAction> = ({
  isCustomize,
  isCustom,
  allowEdit,
  hubSpotUse,
  openDeletePopup,
  isPreview,
  onSelectCard,
  onCustomize,
  onSendClick,
  onSendBulkClick,
}) => {
  const location = useLocation();

  const { darkLight2, gColor } = useTheme();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const cardButtons = classNames({
    card__buttons: !(
      isMobile && location.pathname.includes(navigation.category)
    ),
    category__buttons:
      isMobile && location.pathname.includes(navigation.category),
    "card__buttons-detail": isPreview && isMobile,
  });

  const cardButtonsCustom = classNames({
    card__buttons: !(
      isMobile && location.pathname.includes(navigation.category)
    ),
    category__buttons:
      isMobile && location.pathname.includes(navigation.category),
    "card__buttons-detail": isPreview && isMobile,
    card__buttons_custom: isCustom,
  });

  if (onSelectCard)
    return (
      <ButtonAction
        onClick={(e) => {
          e.stopPropagation();
          onSelectCard();
        }}
      >
        Select Card
      </ButtonAction>
    );
  if (isCustomize)
    return (
      <ButtonAction
        onClick={onCustomize}
        bgColor={darkLight2}
        border={`2px solid ${darkLight2}`}
        style={{ marginRight: "0" }}
      >
        Customize
      </ButtonAction>
    );
  if (isCustom)
    return (
      <>
        <FlexContainer
          justify='space-between'
          className={cardButtonsCustom}
          margin='0'
        >
          <ButtonAction
            style={{ marginRight: !hubSpotUse ? "" : 0 }}
            onClick={onSendClick}
          >
            Send
          </ButtonAction>
          {!hubSpotUse && (
            <ButtonAction onClick={onSendBulkClick}>Bulk Send</ButtonAction>
          )}
        </FlexContainer>
        <FlexContainer
          justify='space-between'
          className={cardButtons}
          margin='0'
        >
          {allowEdit ? (
            <ButtonAction
              onClick={onCustomize}
              bgColor={darkLight2}
              border={`2px solid ${darkLight2}`}
            >
              Edit
            </ButtonAction>
          ) : null}
          <ButtonAction
            onClick={openDeletePopup}
            bgColor={gColor}
            border={`2px solid ${gColor}`}
            style={{ marginRight: 0 }}
          >
            Delete
          </ButtonAction>
        </FlexContainer>
      </>
    );
  return (
    <>
      <ButtonAction
        style={{ marginRight: !hubSpotUse ? "" : 0 }}
        onClick={onSendClick}
      >
        Send
      </ButtonAction>
      {!hubSpotUse && (
        <ButtonAction onClick={onSendBulkClick}>Bulk Send</ButtonAction>
      )}
    </>
  );
};

export default CardButtonsAction;
