import { FC } from "react";
import plusIcon from "../../../assets/images/plus-black.png";
import { FlexContainer } from "../../ui/layout";

const HeaderClientGroupLogo: FC<{ clientGroupLogo: string }> = ({
  clientGroupLogo,
}) => {
  return (
    <FlexContainer className='header__client-group-logo'>
      <img src={plusIcon} alt='plus black' />
      <img src={clientGroupLogo} alt='client group logo' />
    </FlexContainer>
  );
};

export default HeaderClientGroupLogo;
