import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import {
  addCustomFields,
  deleteCustomField,
  getCustomFieldsList,
} from "./thunks";

const customFieldsSlice = createSlice({
  name: "customFields",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomFieldsList.fulfilled, (state, { payload }) => {
      state.customFields = payload;
    });
    builder.addCase(deleteCustomField.fulfilled, (state, { payload }) => {
      state.customFields = state.customFields.filter(
        (value) => value?.id !== payload?.id
      );
    });
    builder.addCase(addCustomFields.fulfilled, (state, { payload }) => {
      state.customFields.unshift(payload);
    });
  },
});

export default customFieldsSlice.reducer;
