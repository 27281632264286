import { createSlice } from "@reduxjs/toolkit";

import { fetchReports } from "./thunks";
import initialState from "./types";

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      const {
        recent_scans,
        scans,
        statistics,
        total_links,
        total_scans,
        total_unique_scans,
      } = action.payload;

      state.recent_scans = recent_scans;
      state.scans = scans;
      state.statistics = statistics;
      state.total_links = total_links;
      state.total_scans = total_scans;
      state.total_unique_scans = total_unique_scans;
    });
  },
});

export default reportsSlice.reducer;
