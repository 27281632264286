import React, { useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../redux/useRedux";

import {
  ITag,
  ITagValueWithLabel,
} from "../../interfaces/recipient-tags/ITags";
import getTags from "../../redux/recipient-tags/selectors";
import {
  addTag,
  getTagList,
  deleteTag,
} from "../../redux/recipient-tags/thunks";
import { IValueWithLabel } from "../../components/ui/common/select/multiWithSearch.select";

const useRecipientTags = (
  isRecipientForm: boolean,
  include_addresses: boolean,
  selectedTagsList?: ITagValueWithLabel[],
  setTagsValueWithLabel?: React.Dispatch<
    React.SetStateAction<ITagValueWithLabel[]>
  >
) => {
  const dispatch = useAppDispatch();

  const tags = useAppSelector(getTags);

  useEffect(() => {
    if (isRecipientForm) dispatch(getTagList(include_addresses));
  }, []);

  const _maptags = (tag: ITag) => {
    const { name, id, addresses } = tag;

    return {
      label: name,
      value: id.toString(),
      id: id.toString(),
      treeDepth: 0,
      addresses: addresses || undefined,
    };
  };

  const tagsToList: IValueWithLabel[] = useMemo(() => {
    return tags.map(_maptags).sort((a, b) => a.label.localeCompare(b.label));
  }, [tags]);

  const filteredTagsToList: IValueWithLabel[] = useMemo(() => {
    return tagsToList.filter((tag) => tag.addresses?.length);
  }, [tagsToList]);

  const onDeleteTagValueWithLabel = (id: string) => {
    if (setTagsValueWithLabel)
      setTagsValueWithLabel((prev) => {
        return prev.filter((tag) => tag.id !== id);
      });
  };

  const onDeleteSelectTagFromList = async (id: string) => {
    const res = await dispatch(deleteTag(+id));
    if (!res?.payload) return;
    onDeleteTagValueWithLabel(id);
  };

  const createTag = async (value: string) => {
    // check, if the tag include in the tag list
    const existingTag = tagsToList.find(
      (tag) => tag.label.toLowerCase() === value.trim().toLowerCase()
    );

    if (existingTag) return;

    const { payload: createdTag } = await dispatch(
      addTag({
        name: value,
      })
    );

    return createdTag;
  };

  const addTagToRecipient = (id: string, label: string) => {
    if (selectedTagsList && setTagsValueWithLabel) {
      // check, that tag is not selected
      const isTagAlreadyAdded = selectedTagsList.find((tag) => tag.id === id);
      if (isTagAlreadyAdded) return;

      // if tag is not selected - add it to the list
      setTagsValueWithLabel(
        selectedTagsList.concat({
          label,
          value: id,
          id,
          treeDepth: 0,
        })
      );
    }
  };

  return {
    tagsToList,
    filteredTagsToList,
    tags,
    createTag,
    addTagToRecipient,
    onDeleteTagValueWithLabel,
    onDeleteSelectTagFromList,
  };
};

export default useRecipientTags;
