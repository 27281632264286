import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import hubSpotApi from "../../api/hubSpot.api";

import { IThunkError } from "../../interfaces/redux/IThunkError";
import { IHubStopUserRedirect } from "./types";

const fetchHubSpot = createAsyncThunk<
  IHubStopUserRedirect,
  any,
  { rejectValue: IThunkError }
  // @ts-ignore
>("hubspot/linkOAuth", async (payload, { rejectWithValue }) => {
  try {
    const res = await hubSpotApi.getHubSpotUsr(payload);

    const {
      redirect_uri,
      ext_id,
      state,
      delivery_cost,
      country,
      country_id,
      city,
      business_name,
      last_name,
      first_name,
      address_id,
      address1,
      address2,
      zip,
    } = res.data;

    return {
      redirect_uri,
      ext_id,
      state,
      delivery_cost,
      country,
      country_id,
      city,
      business_name,
      last_name,
      first_name,
      address_id,
      address1,
      address2,
      zip,
    };
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export default fetchHubSpot;
