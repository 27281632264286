import { createContext, CSSProperties, FC, useState, useMemo } from "react";

export interface IConfirmationPopup {
  subtitle: string | JSX.Element;
  onConfirm: () => void;
  title?: string;
  onCancel?: () => void;
  textButtonConfirm?: string;
  textButtonCancel?: string;
  style?: CSSProperties;
  styleModal?: CSSProperties;
  subtitleStyle?: CSSProperties;
  styleForTextSub?: CSSProperties;
  showButtons?: boolean;
  positionButtons?:
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "center"
    | "flex-end"
    | "flex-start"
    | "start";
  closeAfterConfirm?: boolean;
}

export interface IConfirmationPopupContext {
  activePopup: IConfirmationPopup | null;
  openConfirmationPopup: (popupData: IConfirmationPopup) => void;
  closeConfirmationPopup: () => void;
}

export const ConfirmationPopupContext =
  createContext<IConfirmationPopupContext>({} as IConfirmationPopupContext);

const ConfirmationPopupProvider: FC = ({ children }) => {
  const [activePopup, setActivePopup] = useState<IConfirmationPopup | null>(
    null
  );

  const openConfirmationPopup = (popupData: IConfirmationPopup) => {
    return setActivePopup(popupData);
  };

  const closeConfirmationPopup = () => setActivePopup(null);

  const initialValue = useMemo(() => {
    return { activePopup, openConfirmationPopup, closeConfirmationPopup };
  }, [activePopup, openConfirmationPopup, closeConfirmationPopup]);

  return (
    <ConfirmationPopupContext.Provider value={initialValue}>
      {children}
    </ConfirmationPopupContext.Provider>
  );
};

export default ConfirmationPopupProvider;
