import { createAsyncThunk } from "@reduxjs/toolkit";

import apiKeysApi from "../../api/apiKeys.api";
import { IAddApiKeys } from "./types";

export const getApiKeys = createAsyncThunk("profile/list", async () => {
  const res = await apiKeysApi.list();
  const { list } = res.data;
  return list;
});

export const deleteApiKeys = createAsyncThunk<any, number | string>(
  "profile/deleteApiKeys",
  async (id) => {
    const result = await apiKeysApi.delete(id);

    if (result) return { id };
  }
);

export const addApiKeys = createAsyncThunk<any, IAddApiKeys>(
  "profile/addApiKeys",
  async (payload) => {
    const res = await apiKeysApi.add(payload);
    const { api_key } = res.data;
    return { api_key };
  }
);
