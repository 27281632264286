import { IFeedback } from "../../interfaces/feedback/IFeedback";

const initialState: IFeedback = {
  email: "",
  name: "",
  message: "",
  isSendMessage: false,
};

export default initialState;
