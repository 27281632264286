import { FC } from "react";
import { ButtonAction, FlexContainer } from "../../ui/layout";

interface IClosePopup {
  onClick?: () => void;
}

const ClosePopup: FC<IClosePopup> = ({ onClick }) => (
  <FlexContainer justify='center' style={{ marginTop: "1rem" }}>
    <ButtonAction width='30%' onClick={onClick}>
      Ok
    </ButtonAction>
  </FlexContainer>
);

export default ClosePopup;
