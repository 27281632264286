import { FC } from "react";

import ButtonClose from "../../ui/buttons/btn-close/btn-close.common";
import { FlexContainer } from "../../ui/layout";
import {
  CloseButtonWrapper,
  PopupBackground,
  PopupContent,
} from "./popup.styled";

import "./style.scss";

interface IPopupCommonProps {
  isOpen: boolean;
  onClose: () => void;
}

const PopupCommon: FC<IPopupCommonProps> = ({ isOpen, children, onClose }) => {
  if (isOpen) {
    return (
      <PopupBackground onClick={onClose}>
        <FlexContainer direction='column' width='max-content'>
          <CloseButtonWrapper>
            <ButtonClose onClick={onClose} />
          </CloseButtonWrapper>
          <PopupContent onClick={(e) => e.stopPropagation()}>
            {children}
          </PopupContent>
        </FlexContainer>
      </PopupBackground>
    );
  }
  return null;
};

export default PopupCommon;
