import React, { FC } from "react";
import { FlexContainer } from "../ui/layout";

import "./style.scss";

interface ITitleSeparator {
  title?: string;
  color?: string;
  style?: React.CSSProperties;
}

const TitleSeparator: FC<ITitleSeparator> = ({
  title = "",
  color = "black",
  style,
}) => {
  const styles = {
    ...style,
    "--color-line": color,
  };

  return (
    <FlexContainer
      align='center'
      justify='center'
      className='title-separator'
      style={styles}
    >
      <span>{title}</span>
    </FlexContainer>
  );
};

export default TitleSeparator;
