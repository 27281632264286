import { RefObject, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
  additionalRefList: RefObject<T>[] = []
) {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      let containsRef = false;
      const refs = [ref, ...additionalRefList];

      refs.some(({ current }) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!current || current.contains(event.target as Node)) {
          containsRef = true;
          return true;
        }
        return false;
      });

      if (containsRef) return;

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handler changes
  }, [ref, additionalRefList, handler]);
}

export default useOnClickOutside;
