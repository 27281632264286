import { useEffect } from "react";

import { useAppDispatch } from "../redux/useRedux";
import { setWindowDimensions } from "../../redux/window-dimensions/slice";
import { IDimensions } from "../../redux/window-dimensions/types";

const getWindowDimensions = (): IDimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  const isMobile: boolean = width < 600;
  const isTablet: boolean = width < 900;
  const isMediumTablet: boolean = width <= 750;
  const isLargeTablet: boolean = width <= 768;
  const isDesktop: boolean = width < 1280;
  const isMediumDesktop: boolean = width < 1150;
  const isSmallDesktop: boolean = width < 1024;
  const isSmallHeight: boolean = height < 820;
  const x1100: boolean = width < 1100 && width > 600;

  return {
    width,
    height,
    isMobile,
    isTablet,
    isDesktop,
    isMediumTablet,
    isLargeTablet,
    isSmallDesktop,
    isSmallHeight,
    isMediumDesktop,
    x1100,
  };
};

const useWindowDimensions = () => {
  const dispatch = useAppDispatch();

  const handleResize = () => {
    dispatch(setWindowDimensions(getWindowDimensions()));
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};

export default useWindowDimensions;
