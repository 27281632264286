import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import fetchGiftCards from "./thunks";

const giftCardsSlice = createSlice({
  name: "giftCards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGiftCards.fulfilled, (state, { payload }) => {
      state.giftCards = payload;
    });
  },
});

export default giftCardsSlice.reducer;
