import styled from "styled-components";

interface IButton {
  btnColor?: string;
  width?: string | number;
  hoverColor?: string;
  hoverBgColor?: string;
}

const Button = styled.button<IButton>`
  width: ${(props) => props.width};
  height: 2.7777rem;

  border: 2px solid ${(props) => props.btnColor || props.theme.primary};
  background-color: ${(props) => props.btnColor || props.theme.primary};
  outline: none;

  cursor: pointer;

  color: ${(props) => props.color || "#fff"};
  text-transform: uppercase;

  transition-duration: 0.3s;
  transition-property: background-color, color;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  pointer-events: ${(props) => props.disabled && "none"};

  opacity: ${(props) => props.disabled && 0.4};

  & :first-child {
    color: ${(props) => props.color || "#fff"};
  }

  &:hover {
    color: ${(props) => props.hoverColor || "black"};
    background-color: ${(props) => props.hoverBgColor || "#fff"};
    & :first-child {
      color: ${(props) => props.hoverColor || "black"};
    }
  }

  span {
    font-size: 0.72222rem;
    line-height: 1rem;
    letter-spacing: 1.37px;
    font-weight: bold;
  }
`;

Button.defaultProps = {
  width: "100%",
};

export default Button;
