import { FC, useEffect, useRef, FormEvent } from "react";

import useInput from "../../../hooks/input/useInput";
import useAddress from "../../../hooks/address/useAddress";
import useSelect from "../../../hooks/input/useSelect";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import useForm from "../../../hooks/forms/useForm";
import { ValidateCollection } from "../recipient/recipient.edit";
import useCountries from "../../../hooks/countries/useCountries";
import useAutoCompletePlaces from "../../../hooks/autocomplete-places/useAutocompletePlaces";

import {
  getEditAddressId,
  selectAddressById,
} from "../../../redux/address/selectors";
import { getAddress, getAddresses } from "../../../redux/address/thunks";

import { IAddress } from "../../../interfaces/address/IAddress";
import RecipientForm from "../recipient/recipient.form";
import { extractAddress } from "../../../helpers/autocomplete/extract-address";
import useWarningAtRecipientForm from "../../../hooks/address/useWorningAtRecipientForm";
import trimString from "../../../helpers/trimString";
import useAddressLongInputErrors from "../../../hooks/address/useAddressLongInputErrors";

const AddressEdit: FC = () => {
  const dispatch = useAppDispatch();

  const addressInputRef = useRef(null);

  const editId = useAppSelector(getEditAddressId);
  const addressEdit = useAppSelector((state) =>
    selectAddressById(state, editId!)
  ) as IAddress;

  const address = useAutoCompletePlaces(addressInputRef);
  const { toggleValidateClass, findEmptyElements } = useForm();
  const { update } = useAddress();
  const { checkCorrectText } = useWarningAtRecipientForm();

  const first_name = useInput(addressEdit?.first_name || "");
  const last_name = useInput(addressEdit?.last_name || "");
  const business_name = useInput(addressEdit?.business_name || "");
  const address1 = useInput(addressEdit?.address1 || "");
  const address2 = useInput(addressEdit?.address2 || "");
  const city = useInput(addressEdit?.city || "");
  const zip = useInput(addressEdit?.zip || "");
  const stateCountry = useSelect(addressEdit?.state || "");

  const country = useSelect(String(addressEdit?.country_id) || "1");

  const { getCountryIdByName, countriesToList, statesToList, countryState } =
    useCountries(country.value as string);

  const { lengthInputErrors } = useAddressLongInputErrors({
    firstName: first_name.value,
    lastName: last_name.value,
    businessName: business_name.value,
    address1: address1.value,
    address2: address2.value,
  });

  useEffect(() => {
    if (!Object.keys(address).length) return;

    const fullAddress = extractAddress(address.street, address.route);
    const countryId = getCountryIdByName(address.country);

    city.setValue(address.city);
    zip.setValue(address.zip);

    stateCountry.setValue(address.state);

    address1.setValue(fullAddress || "");
    country.setValue(String(countryId));
  }, [address]);

  const states = statesToList(country?.value! as string);

  const upsertAddress = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const htmlFormControlsCollection = event.currentTarget.elements;

    const { first_name, last_name, address1, zip, city, state, country } =
      htmlFormControlsCollection as ValidateCollection;
    const correctState = states!.map((el) => el.label).includes(state?.value);

    if (!correctState && countryState) {
      (
        document.querySelector('select[name="state"]') as HTMLInputElement
      ).value = "";
      stateCountry.setValue("");
    }

    const inputs = [first_name, address1, zip, city, country];
    const inputsWithState = [first_name, address1, zip, city, state, country];
    toggleValidateClass(countryState ? inputsWithState : inputs);

    const emptyElements = findEmptyElements(
      countryState ? inputsWithState : inputs
    );

    if (emptyElements.length) return;

    const addressStructure = {
      first_name: trimString(first_name.value),
      last_name: trimString(last_name.value),
      business_name: business_name.value,
      address1: address1.value,
      address2: address2.value,
      city: city.value,
      state: countryState ? (state.value as string) : "",
      country_id: country.value as string,
      zip: zip.value,
      name: `${first_name.value} ${last_name.value}`,
    };

    if (editId) {
      const updateAddress = async () => {
        await update({ ...addressStructure, id: editId, allow_poor: true });
        await dispatch(getAddress());
        await dispatch(getAddresses());
      };

      checkCorrectText(
        () => updateAddress(),
        first_name.value,
        last_name.value,
        address1.value
      );

      return;
    }

    await dispatch(getAddress());
  };

  return (
    <RecipientForm
      addressInputRef={addressInputRef}
      isEditRecipient
      first_name={first_name}
      last_name={last_name}
      business_name={business_name}
      address1={address1}
      address2={address2}
      countriesToList={countriesToList}
      city={city}
      zip={zip}
      state={stateCountry}
      country={country}
      countryState={countryState}
      btnText='UPDATE ADDRESS'
      onSubmit={upsertAddress}
      states={states}
      lengthInputErrors={lengthInputErrors}
    />
  );
};

export default AddressEdit;
