import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { FooterLink } from "../../ui/layout";
import { Overline } from "../../ui/typography";

import { links, socialNetworks } from "../../../constants/footer/footer";

import "./style.scss";

const Footer: FC = () => {
  const history = useHistory();
  const getYear = new Date().getFullYear();

  const redirectTo = (path: string) => {
    history.push(`/${path}`);
  };

  const copyright = useMemo(() => {
    return `© ${getYear} Handwrytten, Inc.`;
  }, [getYear]);

  return (
    <footer>
      <div className='footer'>
        <div className='footer__info'>
          <Overline
            className='copyright'
            fontSize='.6rem'
            lineHeight='2rem'
            color='white'
            margin='0 0 0 0.5rem'
          >
            {copyright}
          </Overline>
          <span className='footer__links'>
            {links.map((link) => (
              <FooterLink key={link.id} onClick={() => redirectTo(link.path)}>
                {link.title}
              </FooterLink>
            ))}
          </span>
        </div>

        <div className='footer__social'>
          {socialNetworks.map(({ id, href, icon }) => (
            <a key={id} href={href} target='_blank' rel='noreferrer'>
              {icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
