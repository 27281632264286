import { FC, useMemo, useState, ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import { setActiveOrder } from "../../../redux/order/slice";
import { closePopup } from "../../../redux/portal-popup/slice";
import { resetAiMessage } from "../../../redux/ai/slice";
import { getAiMessage, getAiStatus } from "../../../redux/ai/selectors";
import { selectCardById } from "../../../redux/order/selectors";
import generateMessage from "../../../redux/ai/thunks";
import {
  getActiveStepId,
  selectStepById,
} from "../../../redux/multi-step/selectors";
import { setActiveStep } from "../../../redux/multi-step/slice";

import AiErrorPopup from "./ai-assist.error";
import { Title } from "../../ui/typography";
import { MessageTextArea } from "../../messages/message/message.styled";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";
import {
  ButtonAction,
  CharactersCounter,
  DashedContainer,
  FlexContainer,
} from "../../ui/layout";

import "./style.scss";

const AiAssistPopup: FC = () => {
  const dispatch = useAppDispatch();

  const [wishesForAi, setWishesForAi] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const card = useAppSelector(selectCardById);
  const aiMessage = useAppSelector(getAiMessage);
  const aiStatus = useAppSelector(getAiStatus);
  const activeStepId = useAppSelector(getActiveStepId);
  const currentStep = useAppSelector((state) =>
    selectStepById(state, activeStepId!)
  );
  const maxLength = useMemo(
    () => currentStep?.card?.characters || card?.characters || 500,
    [card, currentStep?.card]
  );

  const charactersCount = useMemo(
    () => `${message.length} / ${maxLength}`,
    [message.length, maxLength]
  );

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setWishesForAi(e.target.value);

  const handleGenerateMessage = async () => {
    const res = await dispatch(generateMessage(wishesForAi));
    if (res.meta.requestStatus === "rejected") setError(res?.payload?.message);
  };

  useEffect(() => {
    return () => {
      dispatch(resetAiMessage());
    };
  }, []);

  useEffect(() => {
    if (aiMessage) setMessage(aiMessage?.trim());
  }, [aiMessage]);

  const handleResetMessage = () => setMessage("");

  const handleInsertMessage = () => {
    // if we have some active step from multi-step form - use generated message to fill specific step form
    if (activeStepId) {
      dispatch(
        setActiveStep({
          id: activeStepId,
          aiMessage: message,
        })
      );
    } else
      dispatch(
        setActiveOrder({
          message,
        })
      );

    dispatch(closePopup());
  };

  const handleClosePopup = () => setError(null);

  return (
    <div>
      <AiErrorPopup
        isOpen={!!error}
        onClose={handleClosePopup}
        message={error}
      />
      {!isMobile && (
        <FlexContainer style={{ marginTop: "1.5rem", marginBottom: "1rem" }}>
          <Title>AI Assist</Title>
        </FlexContainer>
      )}
      <p style={{ marginTop: isMobile ? "1.5rem" : 0 }}>
        Get an instant AI-generated letter. Just type what to write about:
      </p>
      <FlexContainer
        direction='column'
        justify='start'
        align='flex-start'
        style={{ marginTop: "1.5rem" }}
      >
        <DashedContainer
          className='message-container'
          borderSize='1px'
          borderType='dashed'
          borderColor='#D3CBCB'
          style={{ minWidth: isMobile ? "200px" : "400px" }}
        >
          <MessageTextArea
            placeholder={`Example: Write a short message for my friend joe's birthday.`}
            maxLength={maxLength}
            value={message.length ? message : wishesForAi}
            onChange={handleTextChange}
            style={{
              fontFamily: "aktiv-grotesk, sans-serif",
              fontSize: "18px",
              height: isMobile ? "200px" : "300px",
              lineHeight: "1.52rem",
              minWidth: isMobile ? "200px" : "550px",
            }}
            disabled={!!message.length}
          />
        </DashedContainer>
      </FlexContainer>
      <CharactersCounter
        style={{
          margin: isMobile ? "1rem 0" : ".666rem 0",
          position: "relative",
        }}
      >
        {charactersCount} Characters
      </CharactersCounter>

      {message.length ? (
        <FlexContainer
          direction='row'
          justify='space-between'
          style={{ maxWidth: isMobile ? "100%" : "50%" }}
        >
          <ButtonAction
            style={{ marginRight: "1.2rem" }}
            onClick={handleInsertMessage}
            disabled={aiStatus}
          >
            Insert
          </ButtonAction>
          <ButtonAction onClick={handleResetMessage} disabled={aiStatus}>
            Try again
          </ButtonAction>
        </FlexContainer>
      ) : (
        <ButtonAction
          style={{ maxWidth: isMobile ? "100%" : "40%" }}
          onClick={handleGenerateMessage}
          disabled={!wishesForAi}
        >
          Generate Message
        </ButtonAction>
      )}
    </div>
  );
};

export default AiAssistPopup;
