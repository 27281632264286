import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as bwipjs from "bwip-js";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import QrCodeForm from "./qr-code.form";
import QrCodePreview from "./qr-code.preview";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import useInput from "../../../hooks/input/useInput";

import { createQrCode } from "../../../redux/custom-cards/thunks";

import { getQrCodes } from "../../../redux/custom-cards/selectors";
import TitleSeparator from "../../title-separator/TitleSeparator";
import { IQrCode } from "../../../interfaces/custom-cards/IQrCode";
import useDebounce from "../../../hooks/helpers/useDebounce";
import urlValidationReg from "../../../constants/url/url-validation-regex";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";
import "./style.scss";

export interface IFrameStyleData {
  container: React.CSSProperties;
  text: React.CSSProperties;
  qrCode: React.CSSProperties;
}

const CreateQRCodePopup: FC = () => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const qrCodesList = useAppSelector(getQrCodes);

  const nameInput = useInput("");
  const linkInput = useInput("");
  const webHookInput = useInput("");

  // const logoBackgroundCheckbox = useCheckbox([]);

  const [errors, setErrors] = useState<string[]>([]);

  const [logoImageId, setLogoImageId] = useState<number>(0);
  const [logoImageUrl, setLogoImageUrl] = useState<string>("");

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const currentOrDefaultLink = useMemo(
    () =>
      linkInput.value.length ? linkInput.value : "https://handwrytten.com/",
    [linkInput.value]
  );

  const qrCodeNamesList = useMemo(
    () => qrCodesList.map((qr) => qr.name),
    [qrCodesList]
  );

  const isQrNameAlreadyUsed = useMemo(
    () => qrCodeNamesList.some((name) => name === nameInput.value.trim()),
    [nameInput.value, qrCodeNamesList]
  );

  const debouncedLinkValue = useDebounce(currentOrDefaultLink, 100);

  const isValidUrl = (url: string) => {
    try {
      const regex = new RegExp(urlValidationReg);
      return !!url.match(regex);
    } catch (err) {
      return false;
    }
  };

  const removeErrorByName = (error: string) => {
    setErrors((prev) => prev.filter((e) => e !== error));
  };

  const checkIsErrors = (name: string, link: string, webHook: string) => {
    const errors = [];

    if (!name.trim()) {
      errors.push("emptyName");
    }
    if (isQrNameAlreadyUsed) {
      errors.push("qrNameAlreadyUsed");
    }
    if (!link.trim()) {
      errors.push("emptyLink");
    }

    if (link.trim() && !isValidUrl(link)) {
      errors.push("invalidUrl");
    }
    if (webHook.trim() && !isValidUrl(webHook)) {
      errors.push("invalidWebHookUrl");
    }

    return errors;
  };

  useEffect(() => {
    if (errors.includes("invalidUrl") && isValidUrl(linkInput.value)) {
      removeErrorByName("invalidUrl");
    }
  }, [linkInput.value]);

  useEffect(() => {
    if (
      errors.includes("invalidWebHookUrl") &&
      isValidUrl(webHookInput.value)
    ) {
      removeErrorByName("invalidWebHookUrl");
    }
  }, [webHookInput.value]);

  const handleLogo = (id: number, image_url: string) => {
    setLogoImageId(id);
    setLogoImageUrl(image_url);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = checkIsErrors(
      nameInput.value,
      linkInput.value,
      webHookInput.value
    );

    if (errors.length) {
      scrollContainerRef.current?.scrollTo(0, 0);
      return setErrors(errors);
    }

    const qrCodeData: IQrCode = {
      name: nameInput.value,
      url: linkInput.value,
    };

    if (logoImageId) {
      qrCodeData.icon_id = logoImageId;
    }

    if (webHookInput.value.trim().length) {
      qrCodeData.webhook_url = webHookInput.value;
    }

    await dispatch(createQrCode(qrCodeData));
  };

  useEffect(() => {
    if (!debouncedLinkValue) return;

    setTimeout(() => {
      const canvas = document.createElement("canvas");
      try {
        (bwipjs as any).toCanvas(canvas, {
          bcid: "qrcode",
          text: debouncedLinkValue,
          eclevel: "H",
          width: 101.6,
          height: 101.6,
        });

        const imgCollections = document.getElementsByClassName("canvasToImg");
        const imgArray = Array.from(imgCollections);
        imgArray.forEach((img) => {
          if (img instanceof HTMLImageElement) {
            img.src = canvas.toDataURL("image/png");
          }
        });
      } catch (e) {
        console.log(e);
      }
    }, 0);
  }, [debouncedLinkValue]);

  return (
    <div
      style={{
        paddingTop: isMobile ? "0.6777rem" : 0,
        width: isMobile ? "auto" : "30rem",
        marginTop: isMobile ? "-1rem" : "-2rem",
      }}
    >
      <FlexContainer direction='column' style={{ maxHeight: "76vh" }}>
        <QrCodeForm
          nameInput={nameInput}
          linkInput={linkInput}
          webHookInput={webHookInput}
          logoImageId={logoImageId}
          logoImageUrl={logoImageUrl}
          handleLogo={handleLogo}
          // logoBackgroundCheckbox={logoBackgroundCheckbox}
          errors={errors}
          removeErrorByName={removeErrorByName}
          scrollContainerRef={scrollContainerRef}
        />
        <TitleSeparator
          color='#444'
          style={{ marginBottom: "0" }}
          title='Add a QR Code'
        />
        <FlexContainer
          direction={isMobile ? "column-reverse" : "row"}
          justify='space-between'
          align={isMobile ? "center" : "flex-start"}
          className='qr-preview-section'
          style={{
            marginTop: isMobile ? "0.5rem" : "1rem",
            minHeight: !isMobile ? "9.5rem" : "8rem",
          }}
        >
          <FlexContainer
            direction='column'
            align='start'
            style={{ width: !isMobile ? "40%" : "" }}
          >
            <ButtonAction
              onClick={onSubmit}
              style={{
                width: !isMobile ? "fit-content" : "",
                padding: "0 1rem",
                height: isMobile ? "1.5rem" : "",
                fontSize: isMobile ? "0.5rem" : "",
              }}
              disabled={!!errors.length}
            >
              ADD QR CODE
            </ButtonAction>
          </FlexContainer>

          <QrCodePreview
            url={debouncedLinkValue}
            logoImageUrl={logoImageUrl}
            // isBackground={!!logoBackgroundCheckbox.checked.length}
          />
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

export default CreateQRCodePopup;
