import { IUser } from "../../interfaces/auth/IAuth";

interface AuthState {
  isAuthenticated: boolean;
  user: IUser | null;
  checking: boolean;
  isAuthProviderLoading: boolean;
  loggingAsUser: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  checking: true,
  isAuthProviderLoading: false,
  loggingAsUser: false,
};

export default initialState;
