interface ICardType {
  0: string;
  1: string;
  2: string;
  3: string;
  64: string;
}

export const creditCartType: ICardType = {
  0: "Visa",
  1: "Mastercard",
  2: "Discover",
  3: "American Express",
  64: "Apple Pay",
};

export type Card = {
  icon: string | ICardIcon;
};

export interface ICardIcon {
  default: string;
}

export const CARDS: Record<string, Card> = {
  visa: {
    icon: require("../../assets/card/icons/visa.png"),
  },
  mastercard: {
    icon: require("../../assets/card/icons/mastercard.png"),
  },
  "american-express": {
    icon: require("../../assets/card/icons/amex.png"),
  },
  discover: {
    icon: require("../../assets/card/icons/discover.png"),
  },
};
export const yearsNumber = 10;
