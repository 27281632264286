import {
  FC,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  memo,
  CSSProperties,
} from "react";
import classnames from "classnames";
import Calendar from "react-calendar";
import { MdCancel } from "react-icons/md";
import MaskedTextInput from "react-text-mask";

import { useAppSelector } from "../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

import { FlexContainer } from "../ui/layout";
import useOnClickOutside from "../../hooks/utils/useOnClickOutside";
import { ICalendarData } from "./Calendar";

import {
  holidayDays,
  isValidDateForHolidays,
} from "../../helpers/calendar/holidayDays";
import calendarIcon from "../../assets/icons/icons/calendar-ic.svg";
import "react-calendar/dist/Calendar.css";
import "./style.scss";

interface ICalendar {
  date: ICalendarData;
  placeholder?: string;
  holidays?: string[];
  disableFuture?: boolean;
  disablePast?: boolean;
  editDate?: string | null;
  birthday?: boolean;
  clearDate?: boolean;
  noEndDate?: boolean;
  optional?: boolean;
  currentDateTime?: number | null;
  isValid?: boolean;
  isAutomation?: boolean;
  correctEditDate?: number | null;
  topPosition?: boolean;
  isShippingMethods?: boolean;
  style?: CSSProperties;
  anniversary?: boolean;
  disabled?: boolean;
}

const CalendarInput: FC<ICalendar> = ({
  date: { date, onChange, setValid },
  placeholder = "",
  holidays = [],
  disableFuture,
  disablePast,
  editDate,
  noEndDate,
  birthday,
  clearDate,
  optional,
  currentDateTime,
  isValid,
  isAutomation,
  correctEditDate,
  topPosition,
  isShippingMethods,
  style,
  anniversary,
  disabled,
}) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const [isShow, setShow] = useState<boolean>(false);
  const [correctPlaceholder, setPlaceholder] = useState(
    placeholder || "__/__/____"
  );
  const calendarWindow = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<HTMLInputElement>(null);

  const calendarContainer = classnames({
    calendar: true,
    "calendar-birthday": birthday || anniversary,
  });

  const scrollHandler = () => {
    if (
      isShow &&
      calendarRef &&
      calendarRef.current &&
      calendarWindow &&
      calendarWindow?.current
    ) {
      const inputRect = calendarRef.current.getBoundingClientRect();

      if (
        inputRect.y >= window.innerHeight ||
        inputRect.y <= -inputRect.height
      ) {
        setShow(false);
      }
    }
  };

  const calendarPopup = classnames({
    "react-calendar-container": true,
    "top-position": topPosition,
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, [isShow]);

  const handleClickOutside = () => {
    if (isShow) setShow(false);
    if (!!placeholder && correctPlaceholder === "__/__/____")
      setPlaceholder(placeholder);
  };

  useOnClickOutside(calendarRef, handleClickOutside);

  const handleDate = (time: Date): void => {
    onChange(time);
    setShow(false);
  };

  const handleClearClick = () => {
    onChange(undefined);
    if (!!placeholder && correctPlaceholder === "__/__/____")
      setPlaceholder(placeholder);
  };

  const correctDate = useMemo(() => {
    return new Date(date!).getDate() === date?.getDate();
  }, [date, currentDateTime]);

  const dateEdit = new Date(editDate!);

  const usDateEdit = useMemo(() => {
    return dateEdit?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }, [dateEdit]);

  const calendarDate = useMemo(() => {
    if (noEndDate) return "";
    if (correctDate) return date?.toLocaleDateString("en-US");
    if (editDate) return usDateEdit;
    return placeholder;
  }, [correctDate, usDateEdit, date, editDate, placeholder, noEndDate]);

  const correctDateSent = useMemo(() => {
    const cardDateSend = calendarDate as string;
    if (!cardDateSend) {
      return "";
    }
    const date = new Date(cardDateSend);
    return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
      -2
    )}/${date.getFullYear()}`;
  }, [calendarDate]);

  const correctDateEditSent = useMemo(() => {
    if (!correctEditDate) {
      return "";
    }
    const date = new Date(correctEditDate);
    return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
      -2
    )}/${date.getFullYear()}`;
  }, [correctEditDate]);

  const [dateValue, setDateValue] = useState(correctDateSent || "");
  // @ts-ignore
  const handleChange = (e) => setDateValue(e.target.value);

  const isValidDate = useMemo(() => {
    const temp = dateValue?.split("/");
    const d = new Date(`${temp[0]}/${temp[1]}/${temp[2]}`);

    return (
      d &&
      // @ts-ignore
      d.getMonth() + 1 === Number(temp[0]) &&
      d.getDate() === Number(temp[1]) &&
      d.getFullYear() === Number(temp[2])
    );
  }, [
    dateValue,
    currentDateTime,
    disableFuture,
    disablePast,
    disabled,
    holidays,
    birthday,
    isAutomation,
  ]);

  const validBorder = useMemo(() => {
    if (disabled) return false;
    return (
      (isValid && !noEndDate) ||
      (!isValidDate && dateValue.length === 10) ||
      isValidDateForHolidays({
        dateValue,
        currentDateTime,
        disableFuture,
        disablePast,
        holidays,
        birthday,
        isAutomation,
        correctEditDate,
        correctDateEditSent,
        optional,
        isShippingMethods,
        anniversary,
      }) ||
      (!dateValue && !placeholder)
    );
  }, [isValid, noEndDate, isValidDate, dateValue, placeholder, disabled]);

  useEffect(() => {
    if (correctDateSent && date) {
      setDateValue(correctDateSent);
    } else {
      setDateValue("");
    }
  }, [correctDateSent]);

  useEffect(() => {
    if (isValidDate) onChange(new Date(dateValue));
  }, [dateValue]);

  useEffect(() => {
    setValid(validBorder);
  }, [validBorder]);

  const changePlaceholder = useCallback(() => {
    return setPlaceholder("__/__/____");
  }, []);

  useEffect(() => {
    if (noEndDate) {
      setPlaceholder("");
    }
  }, [noEndDate]);

  return (
    <FlexContainer
      className={calendarContainer}
      ref={calendarRef}
      style={{
        ...style,
        opacity: noEndDate || disabled ? 0.35 : 1,
        borderBottom: validBorder && !noEndDate ? "1px solid red" : "none",
        borderLeft: validBorder && !noEndDate ? "1px solid red" : "none",
        borderTop: validBorder && !noEndDate ? "1px solid red" : "none",
      }}
    >
      <MaskedTextInput
        mask={[/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        className='form-control'
        placeholder={correctPlaceholder}
        guide
        id='my-input-id'
        onBlur={handleChange}
        value={noEndDate ? "" : dateValue}
        onChange={handleChange}
        style={{
          opacity: optional && !correctDate ? 0.5 : 1,
        }}
        onFocus={changePlaceholder}
      />

      {correctDate && clearDate && (
        <div
          className='calendar__clear-date'
          onClick={handleClearClick}
          style={isMobile ? { right: "75px" } : {}}
        >
          <MdCancel size={15} />
        </div>
      )}
      <div
        className='calendar__button'
        onClick={() =>
          setShow((prev) => (noEndDate || disabled ? false : !prev))
        }
      >
        <img src={calendarIcon} alt='calendar' />
      </div>
      {isShow && (
        <div className={calendarPopup} ref={calendarWindow}>
          <Calendar
            value={correctDate ? date : null}
            tileDisabled={({ date, view }) =>
              holidayDays({
                date,
                currentDateTime,
                view,
                isAutomation,
                birthday,
                disablePast,
                disableFuture,
                holidays,
                isShippingMethods,
                anniversary,
              })
            }
            onChange={(time: Date) => {
              handleDate(time);
            }}
          />
        </div>
      )}
    </FlexContainer>
  );
};

export default memo(CalendarInput);
