interface IInitialValues {
  business_name: string | null;
  first_name: string | null;
  last_name: string | null;
}

interface IUserByToken {
  id: number;
  name: string;
}

export interface InfoByToken {
  user: IUserByToken;
  initial_values: IInitialValues;
}

interface IProfileState {
  credits: string;
  attach_invoice: boolean | null;
  infoByToken: InfoByToken | null;
}

const initialState: IProfileState = {
  credits: "",
  attach_invoice: null,
  infoByToken: null,
};

export default initialState;
