import { FC } from "react";

import { Typography } from "../ui/typography";

import "./style.scss";

const DataDeletionInstructions: FC = () => {
  return (
    <div className='data-deletion-instructions__container'>
      <ol>
        <li>
          <Typography>
            Go to <b>Your Facebook Account</b> &rarr;
            <b>Settings & privacy</b> &rarr; <b>Settings</b> .
          </Typography>
        </li>
        <li>
          <Typography>
            Then, go to <b>Apps and Websites</b> and you will see all of your
            Apps activities.
          </Typography>
        </li>
        <li>
          <Typography>
            Select the option box of <b>Handwrytten.</b>
          </Typography>
        </li>
        <li>
          <Typography>
            Click <b>Remove</b> button.
          </Typography>
        </li>
        <li>
          <Typography>
            Congratulation , you are successfully remove your activities.
          </Typography>
        </li>
      </ol>
    </div>
  );
};

export default DataDeletionInstructions;
