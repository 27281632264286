import { FC } from "react";
import { FlexContainer } from "../ui/layout";

interface ICarouselPagination {
  pages: number;
  page: number;
  onHandlePage: (page: number) => void;
}

const CarouselPagination: FC<ICarouselPagination> = ({
  pages,
  page,
  onHandlePage,
}) => (
  <FlexContainer className='pagination'>
    <FlexContainer
      className={`${page === 1 ? "disable" : ""} arrow arrow__previous`}
      onClick={() => onHandlePage(page - 1)}
    />
    <div className='label'>
      <span>{page}</span> of <span>{pages}</span>
    </div>
    <FlexContainer
      className={`${page === pages ? "disable" : ""} arrow`}
      onClick={() => onHandlePage(page + 1)}
    />
  </FlexContainer>
);

export default CarouselPagination;
