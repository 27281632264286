import React, { FC, memo } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Overline } from "../../ui/typography";
import { FlexContainer } from "../../ui/layout";

interface IHeaderUserInfo {
  fname: string | null;
  lname: string | null;
  setSettingAccountOpen: (value: any) => void;
  isHasSubscription: boolean;
  isHasCart: boolean;
  accountRef: React.RefObject<HTMLDivElement> | null;
}

const HeaderUserInfo: FC<IHeaderUserInfo> = ({
  fname,
  lname,
  setSettingAccountOpen,
  isHasSubscription,
  isHasCart,
  accountRef,
}) => {
  return (
    <FlexContainer
      ref={accountRef}
      style={{
        marginRight: isHasSubscription || isHasCart ? "1.1111rem" : "0",
      }}
      className='account__name'
      onClick={() => setSettingAccountOpen((prev: boolean) => !prev)}
    >
      <Overline style={{ marginRight: "0.52222rem" }}>
        {fname} {lname}
      </Overline>
      <MdKeyboardArrowDown size={25} />
    </FlexContainer>
  );
};

export default memo(HeaderUserInfo);
