import React, {
  MouseEvent,
  useEffect,
  useMemo,
  Dispatch,
  memo,
  SetStateAction,
  useRef,
  useState,
  FC,
  useLayoutEffect,
  RefObject,
} from "react";
import { IconType } from "react-icons/lib";
import { MdCancel } from "react-icons/md";

import useOnClickOutside from "../../../../hooks/utils/useOnClickOutside";
import CancelSelectValueButton from "../input-cancel-btn/input-cancel.common";

import { useAppSelector } from "../../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../../redux/window-dimensions/selectors";

import "./style.scss";

export interface IValue {
  value: string;
  src: string;
}

export interface IOption {
  id: number | string;
  value: string | IValue;
  label: string;
  alt?: string;
  font?: string;
  src?: string;
  price?: string | number;
}

interface ISelectWithInput {
  options: IOption[];
  onChange: (
    value: string,
    setShow?: Dispatch<SetStateAction<boolean>>
  ) => void;
  value?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  className?: string;
  containerClass?: string;
  disabled?: boolean;
  name?: string;
  clearSelector?: boolean;
  country?: string;
  formRef?: RefObject<HTMLInputElement>;
  optionStyle?: React.CSSProperties;
  clearInputOnBlur?: boolean;
  maxLength?: number;
  multiple?: boolean;
  icon?: IconType;
  onEnter?: (value: string) => void;
  clickIconHandler?: (value: string) => void;
  removeOptionHandler?: (id: string, label: string) => Promise<void>;
  selectOptionHandler?: (
    id: string | number,
    label: string,
    value: string
  ) => void;
  isDisableInput?: boolean;
}

const SelectWithInput: FC<ISelectWithInput> = ({
  options,
  value,
  style,
  placeholder,
  name,
  country,
  className,
  containerClass,
  disabled,
  clearSelector,
  formRef,
  multiple,
  optionStyle,
  clearInputOnBlur,
  maxLength,
  icon,
  onEnter,
  onChange,
  clickIconHandler = () => {},
  removeOptionHandler,
  selectOptionHandler,
  isDisableInput = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef(null);
  const optionsContainerRef = useRef(null);

  const [isShow, setShow] = useState<boolean>(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [isOpenAbove, setIsOpenAbove] = useState<boolean>(false);

  const openAboveClass = isOpenAbove ? "open-above" : "";

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  function handleClickOutside() {
    if (clearInputOnBlur) setInputValue("");
    if (isShow) setShow(false);
  }

  // hide options and blur input on click outside
  useOnClickOutside(selectRef, handleClickOutside, [optionsContainerRef]);

  const isNotScrollContainer = options.length <= 6;

  const containerMaxHeightText = isNotScrollContainer
    ? "max-content"
    : `calc(${"2.77777rem"} * 5)`;

  const optionsContainerMaxHeight = useMemo(() => {
    return options.find((opt) => !!opt.alt)
      ? "min-content"
      : containerMaxHeightText;
  }, [options]);

  // calculate where to show position of the select options
  useLayoutEffect(() => {
    function positionDropdown() {
      if (selectRef?.current && isShow) {
        // @ts-ignore
        const selectRect = selectRef.current.getBoundingClientRect();
        const footerHeight = 16 * 3.5; // 3.5rem in pixels (height of the footer)
        let spaceBelow = window.innerHeight - selectRect.bottom - footerHeight;

        if (formRef) {
          const formHeight = formRef?.current
            ? formRef.current.offsetHeight
            : 0;

          // calc height from screen top to form, 2 * 16 is form margin
          const heightFromScreenTopToForm =
            (window.innerHeight - formHeight - 2 * 16) / 2;

          spaceBelow =
            formHeight -
            selectRect.bottom +
            heightFromScreenTopToForm -
            1.6 * 16;
        }

        if (optionsContainerRef?.current) {
          // @ts-ignore
          const optionsHeight = optionsContainerRef.current.offsetHeight;
          if (spaceBelow < optionsHeight) {
            setIsOpenAbove(true);
          } else {
            setIsOpenAbove(false);
          }
        }
      }
    }

    if (isShow) {
      positionDropdown();
      window.addEventListener("scroll", positionDropdown);
    }

    return () => {
      window.removeEventListener("scroll", positionDropdown);
    };
  }, [isShow]);

  // set first option as selected value if initial value is not set
  useEffect(() => {
    if (!placeholder && !!options.length && !value) {
      onChange(options[0].label, setShow);
    }
  }, []);

  const correctState = useMemo(() => {
    return options.some((el) => el.label === value);
  }, [options, value]);

  useEffect(() => {
    if (!placeholder && !!options.length && !correctState && !!value) {
      onChange(options[0].label, setShow);
    }
  }, [country]);

  const filterOptions = useMemo(() => {
    // if filter input is empty or not active - return all options
    if (!focus && !inputValue) return options;

    let filterArray: IOption[] = options;

    // if select in the multiple mode - we rely on the inputValue
    if (multiple) {
      if (inputValue) {
        filterArray = options.filter((el) =>
          el.label
            .slice(0, inputValue.length)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        );
      }
      // if select in the common mode - we rely on the value from props
    } else if (value) {
      filterArray = options.filter((el) =>
        el.label
          .slice(0, value.length)
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    }

    return filterArray;
  }, [value, inputValue, options, focus]);

  const styleForCustomSelect = useMemo(() => {
    if (!isShow) return {};

    // if we don't have available options - hide options container
    if (!filterOptions!.length) return { display: "none" };

    const optionsCount = filterOptions!.length < 6 ? filterOptions?.length : 5;

    return {
      height: `calc(${
        filterOptions?.find((opt) => !!opt.alt) ? "6.25rem" : "2.77rem"
      } * ${optionsCount})`,
      minWidth: `${isMobile ? "auto" : "min-content"}`,
    };
  }, [isShow, filterOptions]);

  const handleClearClick = (event: MouseEvent) => {
    event.stopPropagation();
    onChange("", setShow);
  };

  function handleClickInside() {
    if (disabled) return;

    // in common mode we rely on value from props
    const hideCommonCondition = !multiple && !value;

    // in multiple mode we rely on inputValue and available options length
    const hideMultipleCondition = multiple && !inputValue;

    // if click inside while options container visible and filter input is empty - hide options and blur input
    if (isShow && (hideCommonCondition || hideMultipleCondition)) {
      setShow(false);
      inputRef?.current?.blur();
    } else {
      // if click inside while options container hide - show options
      setShow(true);
    }
  }

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && focus) {
      event.preventDefault();
      const convertedInputValue = inputValue.trim();

      // if onEnter function is provided - call it, reset used values and hide options
      if (convertedInputValue && onEnter) {
        onEnter(convertedInputValue);
        setInputValue("");
        inputRef?.current?.blur();
        setShow(false);
      }
    }
  };

  const onIconClick = (e: any) => {
    // icon is part of the input container - so if options hidden and onClick we set focus to the input field
    if (!isShow) {
      inputRef?.current?.focus();
    }

    // if input is empty or clickIconHandler is not provided - do nothing
    if (!inputValue || !clickIconHandler) return;

    // otherwise call icon handler, reset input value and hide options
    e.stopPropagation();
    clickIconHandler(inputValue);
    setInputValue("");
    setShow(false);
  };

  return (
    <div
      className={`custom-select ${isShow ? "show" : ""} ${
        containerClass || ""
      }`}
      style={style}
    >
      <div ref={selectRef} className='select' onClick={handleClickInside}>
        <input
          ref={inputRef}
          type='text'
          style={isMobile ? { top: "1.5rem" } : {}}
          // for select all after adding some tag need to show empty string
          value={multiple ? inputValue : value}
          name={name}
          maxLength={maxLength || 2}
          onKeyDown={keyDownHandler}
          onChange={(event) => {
            onChange(event.target.value);
            setInputValue(event.target.value);
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          list='data'
          placeholder={placeholder}
          disabled={isDisableInput}
          className='input-c input select-input'
        />
        {icon && (
          <div
            style={{
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 50,
              margin: 0,
              cursor: "pointer",
              borderRadius: "50%",
            }}
            className='map-button'
            onClick={onIconClick}
          >
            {icon}
          </div>
        )}

        <CancelSelectValueButton
          active={!!value && !!clearSelector}
          onClick={handleClearClick}
        />
      </div>
      <div
        className={
          className
            ? `options ${isShow ? "ready" : ""} ${className} ${openAboveClass}`
            : `options ${isShow ? "ready" : ""} ${openAboveClass}`
        }
        style={styleForCustomSelect}
      >
        <div
          className='select__container'
          style={{
            maxHeight: optionsContainerMaxHeight,
          }}
          ref={optionsContainerRef}
        >
          {filterOptions?.length
            ? filterOptions?.map(({ id, label, font, value }) => (
                <div
                  key={id}
                  style={
                    font
                      ? {
                          fontFamily: font,
                          fontSize: label === value ? "3rem" : "2rem",
                          lineHeight: "2.5rem",
                        }
                      : optionStyle
                  }
                  className='option'
                  onClick={() => {
                    if (selectOptionHandler) {
                      selectOptionHandler(id, label, value! as string);
                      setInputValue("");
                    }
                    // id === "0" is the logic for add new Item(Tag) on bulk send
                    onChange(
                      id === "0" ? (value as string) : label,
                      // @ts-ignore
                      setShow(false)
                    );
                  }}
                >
                  {label}

                  {removeOptionHandler ? (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        removeOptionHandler(id.toString(), label);
                      }}
                      style={{
                        lineHeight: 0,
                      }}
                    >
                      <MdCancel size={15} />
                    </div>
                  ) : null}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default memo(SelectWithInput);
