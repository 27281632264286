import { useCallback, useState } from "react";
import { HandlerFunction } from "../../components/auto-replenish/auto-replenish";

export interface ICheckbox {
  checked: number[];
  setAll: (ids: number[]) => void;
  deselectAll: () => void;
  onChange: (id: number | number[]) => void;
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
}

const useCheckbox = (initialValue: number[], ref?: HandlerFunction | null) => {
  const [checked, setChecked] = useState<number[]>(initialValue);

  const onChange = (id: number | number[]) => {
    if (Array.isArray(id)) setChecked(id);
    else {
      const isChecked: boolean = checked.includes(id);
      if (isChecked) {
        setChecked(checked.filter((checkedId) => checkedId !== id));
      } else {
        setChecked((prev) => prev.concat(id));
      }
    }
    if (ref) {
      // the second props is a function that is called when you click on a checkbox
      ref?.handlerFunction();
    }
  };

  const setAll = useCallback((ids: Array<number>) => {
    setChecked(ids);
  }, []);

  const deselectAll = () => setChecked([]);

  return {
    checked,
    setAll,
    deselectAll,
    onChange,
    setChecked,
  };
};

export default useCheckbox;
