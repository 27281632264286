import { userInstance } from "../hooks/instance/useInstance";
import { ICardSectionsResponse } from "../interfaces/cards/ICard";
import { IPageAllCategory, IPageCategory } from "../redux/cards/types";

const cardsApi = {
  list: async () => {
    return userInstance.get("/cards/list");
  },
  random: async () => {
    return userInstance.get("/cards/random");
  },
  details: async (id: string | number) => {
    return userInstance.get(`/cards/view?cardId=${id}&lowres=true`);
  },
  sections: async (section: IPageAllCategory) => {
    const search = section?.search ? `&like[name]=${section.search}` : "";
    return userInstance.get<ICardSectionsResponse>(
      `/cards/list/sections?with_detailed_images=true&page=${section.nextPage}&limit=${section.limit}${search}&randomise=1`
    );
  },
  cardsByCategory: async (category: IPageCategory, cursor: string | null) => {
    const cursorQuery = cursor ? `&cursor=${cursor}` : "";
    const search = category?.search ? `&like[name]=${category.search}` : "";
    return userInstance.get(
      `/cards/list?where[category_id]=${
        category.categoryId
      }&with_images=true&with_detailed_images=true&with_all_data=${
        category.categoryId !== 0 ? 0 : 1
      }&page=${category.nextPageCategory}${search}&limit=${
        category.limit
      }&pagination=1${cursorQuery}&randomise=1`
    );
  },
  favoriteCardToggle: async (card_id: number, is_home_page?: boolean) => {
    return userInstance.post(`/cards/favorite`, { card_id, is_home_page });
  },
};

export default cardsApi;
