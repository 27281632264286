import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import useUrlSearchParams from "../utils/useUrlSearchParams";
import navigation from "../../constants/navigation";

const useGetHubSpotToken = () => {
  const history = useHistory();
  const locations = useLocation();
  const token = useUrlSearchParams("token");
  const login = useUrlSearchParams("login");

  const hash = useMemo(() => {
    if (locations.pathname.includes("/oauth/signin") && token) {
      return true;
    }
    return false;
  }, [locations, token]);

  const salesForceToken = useMemo(() => {
    if (locations.pathname.includes("/auth/authorization") && token) {
      return true;
    }
    return false;
  }, [locations, token]);

  useEffect(() => {
    if (salesForceToken) {
      history.push({
        pathname: "/",
        search: `?token=${token}?salesForceToken=${true}`,
      });
    }
    if (hash) {
      history.push({ pathname: "/hubspot", search: `?token=${token}` });
    }
    if (login && locations.pathname.includes(navigation.cards)) {
      history.push({ pathname: "/" });
    }
  }, [hash, login, salesForceToken]);
};

export default useGetHubSpotToken;
