import { createEntityAdapter } from "@reduxjs/toolkit";

import { IRecipient } from "../../interfaces/address/IRecipient";
import { IRecipientState } from "./types";

export const recipientsAdapter = createEntityAdapter<IRecipient>({
  selectId: (recipient) => recipient.id!,
});

export const initialState = recipientsAdapter.getInitialState<IRecipientState>({
  editId: null,
  isXlsUploading: false,
  isUploaded: false,
  isInternational: false,
  isLoadingRecipientList: false,
  updateRecipient: [],
  newAddress: 0,
  sortParams: null,
  advancedFilters: null,
  selectedRecipientsId: [],
});
