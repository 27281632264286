import { ILatLng } from "../../components/campaigns/outbound-prospecting/steps/choose.demographic/demographic-form";
import { IValue } from "../../components/ui/common/select/select.common";
import { TypesOptionalSetting } from "../../constants/outbound-prospecting/outbound-prospecting";
import { IAddress } from "../../pages/add-message/complete-order/CompleteOrder";
import { IDataTab } from "../../pages/customizable/tabs/tabs";
import { INode } from "../../components/ui/common/select/multiWithSearch.select";

export interface IIndustry extends INode {
  id: string;
}

interface IDemographicSearchData {
  searchType: string | IValue;
  address?: string;
  radius?: number | string;
  zip?: number | string;
  city?: string;
  polygon?: string;
  addressState?: string;
  addressCity?: string;
  addressZip?: number | string;
}

export interface IActiveOutboundLeads extends IDemographicSearchData {
  homeOwnership: string | IValue;
  householdIncome: string | IValue;
  lengthResidency: string | IValue;
  selectIndustries: string | IValue;
  salesVolume: string | IValue;
  staffSize: string | IValue;
  type: TypesOptionalSetting;
  netWorth: string | IValue;
  peopleInHousehold: string | IValue;
  childrenInHousehold: string | IValue;
  householdAge: string | IValue;
  maritalStatus: string | IValue;
  homeValue: string | IValue;
  limit: number;
  delivery_confirmation?: number | boolean;
  message?: string;
  industriesTitle?: string[];
  insert?: string | IValue;
  must_deliver_by?: string;
  shipping_address_id?: string;
  shipping_method_id?: number;
  shipping_rate_id?: number;
  wishes?: string;
  font?: string;
  sorder: boolean;
}

export interface IActionStep {
  activeStep: number;
}
export interface IOutboundPrice {
  tax: number;
  card_price: number;
  postage_price: number;
  insert_price: number;
  total: number;
  shipping_price: number;
  delivery_confirmation?: number;
}

export interface IOutboundProcessPrice {
  address_from: number;
  addresses: IAddress[];
  prices: IOutboundPrice;
  quantity: number;
}

export interface IDemographicInfo {
  id?: string;
  city?: string;
  zip?: number | string;
  longitude?: number | null;
  latitude?: number | null;
  address?: string;
  radius?: number | string;
  sorder: boolean;
  "ownRent-d"?: string | IValue;
  "hinc-d"?: string | IValue;
  "worth-d"?: string | IValue;
  "Home-d"?: string | IValue;
  "resLen-d"?: string | IValue;
  marital?: string | IValue;
  hAge?: string | IValue;
  people?: string | IValue;
  kids?: string | IValue;
  sic?: string | IValue;
  sale?: string | IValue;
  staff?: string | IValue;
  "indust-d"?: string | IValue;
  polygon?: string;
  one?: boolean;
  type: TypesOptionalSetting;
  file?: number;
  point?: string;
  country?: string;
}

export interface IOutboundProcess {
  insert_id: number;
  country: string;
  message: string;
  card_id: number;
  shipping_method_id: number;
  shipping_rate_id: number | string;
  shipping_address_id: number | string;
  must_deliver_by: string;
  return_address_id: string;
  records: number;
  delivery_confirmation: number;
}

export interface IOutboundAddToBasket {
  zip?: string | number;
  "ownRent-d"?: string | IValue;
  "hinc-d"?: string | IValue;
  "worth-d"?: string | IValue;
  "Home-d"?: string | IValue;
  "resLen-d"?: string | IValue;
  sale?: string | IValue;
  staff?: string | IValue;
  sic?: string | IValue;
  type: TypesOptionalSetting;
  marital?: string | IValue;
  hAge?: string | IValue;
  people?: string | IValue;
  kids?: string | IValue;
  insert_id?: number | null;
  country: string;
  longitude?: number | null;
  latitude?: number | null;
  card_id: number;
  search_value?: string;
  address?: string;
  shipping_method_id: number;
  shipping_rate_id?: number | string;
  shipping_address_id?: number | string;
  must_deliver_by?: string;
  return_address_id: string;
  records: number;
  font: string;
  sorder: boolean;
  message: string;
  polygon?: string;
  radius: number | null;
  wishes: string;
  check_quantity: boolean;
  check_quantity_inserts: boolean;
  font_size: number;
  auto_font_size: boolean;
  delivery_confirmation: number | boolean;
  signature_id?: string;
  signature2_id?: string;
  city?: string;
  mask?: boolean;
  size_offset: number;
}

export interface IOutboundLeads {
  step: number | null;
  activeOutboundLeads: IActiveOutboundLeads | null;
  count: null | number;
  location: ILatLng | null;
  radiusAddress: number | null;
  searchType: string;
  poligon: ILatLng[] | null;
  procces: IOutboundProcessPrice | null;
  prices: IOutboundPrice | null;
  searchCountry: string;
  searchCity: string | null;
  mask: boolean;
  card_id: number | null;
  typeOptions: IDataTab | null;
  oneBusiness: boolean;
  industries?: IIndustry[] | null;
}

export interface IBoundaryZip {
  boundary: Array<ILatLng> | null;
  zip: string;
}

export const initialState: IOutboundLeads = {
  step: 1,
  searchType: "1",
  activeOutboundLeads: null,
  count: null,
  location: null,
  poligon: null,
  radiusAddress: 0,
  procces: null,
  prices: null,
  searchCountry: "",
  searchCity: null,
  mask: false,
  card_id: null,
  industries: [],
  typeOptions: null,
  oneBusiness: false,
};
