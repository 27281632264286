import React, { FC } from "react";
import { ButtonAction, FlexContainer } from "../../ui/layout";

interface ISocialBtn {
  title?: string;
  bgColor: string;
  action?: () => Promise<void>;
  onClick?: () => void;
  btnStyle?: React.CSSProperties;
  children: JSX.Element;
}

const SocialBtn: FC<ISocialBtn> = ({
  bgColor,
  action,
  title,
  btnStyle = {},
  children,
  onClick,
}) => {
  return (
    <ButtonAction
      bgColor={bgColor}
      border={`2px solid ${bgColor}`}
      style={btnStyle}
      onClick={onClick || action}
    >
      <FlexContainer justify='center'>
        {children}
        {title}
      </FlexContainer>
    </ButtonAction>
  );
};

export default SocialBtn;
