import { FC } from "react";
import { FlexContainer } from "../ui/layout";
import { CloseButtonWrapper } from "../popups/common/popup.styled";
import ButtonClose from "../ui/buttons/btn-close/btn-close.common";

interface IZoomSection {
  zoom: number;
  minZoom: string;
  maxZoom: string;
  onZoom: (zoom: number) => void;
  onRemoveImage?: () => void;
  imageUrl?: string;
  isZoomActive?: boolean;
}

const ZoomSection: FC<IZoomSection> = ({
  imageUrl,
  zoom,
  minZoom,
  maxZoom,
  isZoomActive = true,
  onZoom,
  onRemoveImage,
}) => {
  return (
    <div className={`editor ${imageUrl ? "editor_show" : ""}`}>
      <FlexContainer className='editor__container'>
        <FlexContainer className='editor__image'>
          <FlexContainer
            direction='column'
            justify='center'
            className='editor__image__container'
          >
            <img alt='Customization' src={imageUrl} />
            {onRemoveImage ? (
              <CloseButtonWrapper
                className='close-button'
                style={{
                  width: "1.1rem",
                  height: "1.1rem",
                }}
              >
                <ButtonClose
                  onClick={onRemoveImage}
                  style={{ height: "100%", width: "100%" }}
                  iconSize={15}
                />
              </CloseButtonWrapper>
            ) : null}
          </FlexContainer>
        </FlexContainer>
        {isZoomActive ? (
          <FlexContainer
            className='range'
            direction='column'
            align='center'
            justify='center'
          >
            <span>{`Image Size: ${zoom}`}%</span>
            <input
              className='range__input'
              type='range'
              min={minZoom}
              max={maxZoom}
              value={zoom}
              onChange={(event) => onZoom(+event.target.value)}
            />
          </FlexContainer>
        ) : null}
      </FlexContainer>
    </div>
  );
};

export default ZoomSection;
