import { createAsyncThunk } from "@reduxjs/toolkit";
import fontsApi from "../../api/fonts.api";

export const fetchFonts = createAsyncThunk("fonts/list", async () => {
  const res = await fontsApi.list();

  const { fonts } = res.data;

  return fonts;
});

export const fetchFontsForCustomizer = createAsyncThunk(
  "fonts/getCustomCss",
  async () => {
    const res = await fontsApi.customizer();

    const { fonts } = res.data;

    return fonts;
  }
);
