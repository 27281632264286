import { FC } from "react";

import DataDeletionInstructions from "../../components/data-deletion/data-deletion-instructions";
import ContainerHeader from "../../components/navigation/containerHeader/container.header";
import SectionBox from "../../components/ui/common/section-box/box";
import { Typography } from "../../components/ui/typography";

const DataDeletion: FC = () => {
  return (
    <>
      <ContainerHeader title='Data Deletion Instructions' />
      <SectionBox>
        <Typography fontSize='0.77rem'>
          According to the Facebook Platform rules, we have to provide{" "}
          <b>User Data Deletion Callback URL</b> or{" "}
          <b>Data Deletion Instructions URL</b>. If you want to delete your
          activities for handwrytten.com, you can remove your activities by the
          following instructions.
        </Typography>

        <DataDeletionInstructions />
      </SectionBox>
    </>
  );
};

export default DataDeletion;
