import { FC } from "react";

import { FlexContainer } from "../../../ui/layout";
import { useAppDispatch } from "../../../../hooks/redux/useRedux";
import typePopups from "../../../../constants/popup/type-popup";
import { setPortalPopup } from "../../../../redux/portal-popup/slice";
import usePhysicalAddress from "../../../../hooks/address/usePhisycalAddress";

const { ADDRESS_PHYSICAL } = typePopups;

const AccountDefaultAddress: FC = () => {
  const dispatch = useAppDispatch();

  const { defaultPhysicalAddress } = usePhysicalAddress();

  const openAddressEdit = () => {
    dispatch(
      setPortalPopup({
        title: "Physical Address",
        form: ADDRESS_PHYSICAL,
        typePopup: "form",
      })
    );
  };

  return (
    <FlexContainer direction='column' className='nav__links'>
      <FlexContainer justify='space-between'>
        <div>Physical Address</div>
        <div className='nav__links_action'>
          <div onClick={openAddressEdit}>
            {defaultPhysicalAddress ? "Edit" : "Add"}
          </div>
        </div>
      </FlexContainer>
      {defaultPhysicalAddress && (
        <FlexContainer
          direction='column'
          align='flex-start'
          className='account-settings__address'
        >
          <span>{defaultPhysicalAddress.address}</span>
          <span>
            {defaultPhysicalAddress.zip}, {defaultPhysicalAddress.country}
          </span>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default AccountDefaultAddress;
