export interface IDimensions {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isMediumTablet: boolean;
  isLargeTablet: boolean;
  isSmallDesktop: boolean;
  isSmallHeight: boolean;
  isMediumDesktop: boolean;
  x1100: boolean;
}

interface IWindowDimensions {
  window_dimensions: IDimensions;
}

export const initialState: IWindowDimensions = {
  window_dimensions: {
    width: 0,
    height: 0,
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    isMediumTablet: false,
    isLargeTablet: false,
    isSmallDesktop: false,
    isSmallHeight: false,
    isMediumDesktop: false,
    x1100: false,
  },
};
