import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FlexContainer } from "../../ui/layout";
import { useAppDispatch } from "../../../hooks/redux/useRedux";
import { closePopup } from "../../../redux/portal-popup/slice";
import "./choose-campaign.style.scss";
import ContainerHeader from "../../navigation/containerHeader/container.header";

import OutboundLeadsItemMobile from "../../campaigns/outbound-prospecting/outbound-leads.item.mobile";
import navigation from "../../../constants/navigation";
import campaignList from "../../../constants/campaigns/campaigns";

const ChooseCampaignMobile: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isMobile) {
      history.push(navigation.home);
    }
  }, []);

  const openOutboundProspecting = () => {
    history.push({
      pathname: navigation.outboundProspecting,
      state: { initialStep: 1 },
    });
    dispatch(closePopup());
  };

  const openAutomation = () => {
    history.push({
      pathname: navigation.automationCards,
      state: { initialStep: 1 },
    });
    dispatch(closePopup());
  };

  const openOutboundMultiStep = () => {
    history.push({
      pathname: navigation.multiStepCampaign,
    });
    dispatch(closePopup());
  };

  const outboundsLeadsCampaignActions = [
    {
      outboundsLeadsId: 1,
      action: openOutboundProspecting,
    },
    {
      outboundsLeadsId: 2,
      action: openAutomation,
    },
    {
      outboundsLeadsId: 3,
      action: openOutboundMultiStep,
    },
  ];

  return (
    <FlexContainer
      direction='column'
      style={{ top: "4rem", marginBottom: "12rem" }}
    >
      <ContainerHeader title='Choose a Campaign' style={{ width: "90%" }} />
      <FlexContainer direction='column' className='campaign-list-mobile'>
        {campaignList.map(({ id, name, description, isActive, icon }) => {
          const defaultAction = () => {};
          const action =
            outboundsLeadsCampaignActions.find(
              (action) => action.outboundsLeadsId === id
            )?.action || defaultAction;
          return (
            <OutboundLeadsItemMobile
              key={id}
              name={name}
              description={description}
              isActive={isActive}
              icon={icon}
              id={id}
              action={action}
            />
          );
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ChooseCampaignMobile;
