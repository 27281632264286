import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import fetchInserts from "./thunks";

const insertsSlice = createSlice({
  name: "inserts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInserts.fulfilled, (state, { payload }) => {
      state.inserts = payload;
    });
  },
});

export default insertsSlice.reducer;
