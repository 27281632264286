import { createAsyncThunk } from "@reduxjs/toolkit";

import categoriesApi from "../../api/categories.api";

const fetchCategories = createAsyncThunk("categories/list", async () => {
  const res = await categoriesApi.list();

  return res.data.categories;
});

export default fetchCategories;
