import { userInstance } from "../hooks/instance/useInstance";
import { IAddress } from "../interfaces/address/IAddress";
import { IBillingInfo } from "../interfaces/address/IBillingInfo";
import { IAddressesListResponse } from "../interfaces/address/responses/IAddressesListResponse";

const addressApi = {
  userAddress: async () => {
    return userInstance.get("/profile/address");
  },
  addAddress: async (address: IAddress) => {
    return userInstance.post<{ address: IAddress }>(
      "/profile/createAddress",
      address
    );
  },
  list: async () => {
    return userInstance.get<IAddressesListResponse>("/profile/listAddresses");
  },
  delete: async (address_id: number) => {
    return userInstance.post("/profile/deleteAddress", { address_id });
  },
  update: async (address: IAddress) => {
    return userInstance.put("/profile/updateAddress", address);
  },
  default: async (id: number) => {
    return userInstance.post("/profile/setDefaultAddress", { id });
  },
  updateBilling: async (address: IBillingInfo) => {
    return userInstance.post("/profile/updateBillingInfo", address);
  },
};

export default addressApi;
