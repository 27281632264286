import { userInstance } from "../hooks/instance/useInstance";
import { IGiftCardsResponse } from "../interfaces/gift-card/IGiftCards";

const giftCardsApi = {
  list: async () => {
    return userInstance.get<IGiftCardsResponse>("/giftCards/list");
  },
};

export default giftCardsApi;
