import { FC, memo } from "react";
import { ReactComponent as TestModeIc } from "../../../assets/icons/icons/test-ic.svg";
import { FlexContainer } from "../../ui/layout";

interface IHeaderTestMode {
  isMobile: boolean;
}

const HeaderTestMode: FC<IHeaderTestMode> = ({ isMobile }) => {
  return (
    <FlexContainer
      style={{ marginRight: isMobile ? "0.5rem" : "1rem", width: "auto" }}
    >
      <TestModeIc title='Test mode' />
    </FlexContainer>
  );
};

export default memo(HeaderTestMode);
