import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { IDeleteCustomCard } from "./types";
import designApi from "../../api/design.api";
import { IThunkError } from "../../interfaces/redux/IThunkError";

const deleteCustomCard = createAsyncThunk<
  IDeleteCustomCard | undefined,
  number,
  {
    rejectValue: IThunkError;
  }
>("/design/delete", async (id, { rejectWithValue }) => {
  try {
    const res = await designApi.deleteDesignCard(id);

    if (res) return { id };
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

    throw err;
  }
});

export default deleteCustomCard;
