import { FC } from "react";
import { Link } from "react-router-dom";

import HeaderAuthenticated from "./header.authenticated";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getClientGroupLogo, isAuth } from "../../../redux/auth/selectors";
import logo from "../../../assets/icons/logo/logo1770.svg";
import logoCircle from "../../../assets/images/handwrytten-logo.jpeg";

import HeaderLink from "./header.link";
import HeaderClientGroupLogo from "./header.client-group-logo";
import { FlexContainer } from "../../ui/layout";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

import "./style.scss";

const Header: FC = () => {
  const isAuthenticated = useAppSelector(isAuth);
  const clientGroupLogo = useAppSelector(getClientGroupLogo);

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const uid = localStorage.getItem("uid");

  return (
    <header className='header__container'>
      <FlexContainer width='auto' align={isMobile ? "center" : "start"}>
        <Link to='/'>
          {isMobile ? (
            <img
              src={logoCircle}
              alt='logo'
              className='header__logo header__container__logo'
            />
          ) : (
            <img src={logo} alt='logo' className='header__logo' />
          )}
        </Link>
        {!!clientGroupLogo && (
          <HeaderClientGroupLogo clientGroupLogo={clientGroupLogo} />
        )}
      </FlexContainer>
      {isAuthenticated && !!uid ? <HeaderAuthenticated /> : <HeaderLink />}
    </header>
  );
};

export default Header;
