import { FC } from "react";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../../ui/layout";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";
import { closePopup } from "../../../redux/portal-popup/slice";
import mailbox from "../../../assets/images/mailbox.png";
import OutboundsLeadsHeader from "./outbounds.prospecting.header";

import OutboundLeadsItem from "../../campaigns/outbound-prospecting/outbound-leads.item";
import navigation from "../../../constants/navigation";
import campaignList from "../../../constants/campaigns/campaigns";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

import "./choose-campaign.style.scss";

const ChooseCampaignPopup: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const openOutboundProspecting = () => {
    history.push({
      pathname: navigation.outboundProspecting,
      state: { initialStep: 1 },
    });
    dispatch(closePopup());
  };

  const openOutboundBirthdayCards = () => {
    history.push({
      pathname: navigation.automationCards,
      state: { initialStep: 1 },
    });
    dispatch(closePopup());
  };

  const openOutboundMultiStep = () => {
    history.push({
      pathname: navigation.multiStepCampaign,
    });
    dispatch(closePopup());
  };

  const outboundsLeadsCampaignActions = [
    {
      outboundsLeadsId: 1,
      action: openOutboundProspecting,
    },
    {
      outboundsLeadsId: 2,
      action: openOutboundBirthdayCards,
    },
    {
      outboundsLeadsId: 3,
      action: openOutboundMultiStep,
    },
  ];

  return (
    <>
      {!isMobile && (
        <FlexContainer className='header' direction='row'>
          <img className='header__image' src={mailbox} alt='icon' />
        </FlexContainer>
      )}
      <FlexContainer className='choose-campaign-container' direction='column'>
        <OutboundsLeadsHeader />
        <FlexContainer className='campaign-list' direction='row'>
          {campaignList.map(({ id, name, description, isActive, icon }) => {
            const defaultAction = () => {};
            const action =
              outboundsLeadsCampaignActions.find(
                (action) => action.outboundsLeadsId === id
              )?.action || defaultAction;
            return (
              <OutboundLeadsItem
                key={id}
                name={name}
                description={description}
                isActive={isActive}
                icon={icon}
                id={id}
                action={action}
              />
            );
          })}
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default ChooseCampaignPopup;
