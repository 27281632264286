import { createEntityAdapter } from "@reduxjs/toolkit";
import { IAddress } from "../../interfaces/address/IAddress";
import { IAddressState } from "./types";

export const addressAdapter = createEntityAdapter<IAddress>({
  selectId: (address) => address.id!,
});
export const initialState = addressAdapter.getInitialState<IAddressState>({
  editId: null,
  defaultAddress: null,
  billing_info: null,
  isLoadingAddressList: false,
});
