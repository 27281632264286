import { FontsCustomizerList, FontsList } from "../../interfaces/fonts/IFont";

interface IFontsState {
  fonts: FontsList;
  fontsCustomizer: FontsCustomizerList;
}

const initialState: IFontsState = {
  fonts: [],
  fontsCustomizer: [],
};

export default initialState;
