import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import couponApi from "../../api/coupon.api";
import { IThunkError } from "../../interfaces/redux/IThunkError";

const couponGet = createAsyncThunk<
  string,
  string,
  { rejectValue: IThunkError }
>("/coupon/getCouponCredit", async (code, { rejectWithValue }) => {
  try {
    const res = await couponApi.coupon(code);
    const { discountCredit } = res.data;
    return discountCredit;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    throw err;
  }
});

export default couponGet;
