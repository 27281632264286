import { FC } from "react";

import { FlexContainer } from "../../../ui/layout";
import useUser from "../../../../hooks/auth/useUser";

const AccountRemainingCredits: FC = () => {
  const { credit1 } = useUser();

  if (!credit1) return null;

  return (
    <FlexContainer className='nav__links' justify='space-between'>
      <FlexContainer width='auto'>
        <span>Remaining Credits</span>
      </FlexContainer>
      <div className='nav__links_text'>
        <div>{`$${credit1?.toFixed(2)}`}</div>
      </div>
    </FlexContainer>
  );
};

export default AccountRemainingCredits;
