import { RootState } from "../store";

export const selectPastOrders = (state: RootState) => state.orders.pastOrders;
export const selectCalcTax = (state: RootState) => state.orders.allTax;
export const selectLoaderCalcTax = (state: RootState) =>
  state.orders.loaderCalcTax;
export const selectAddresses = (state: RootState) => state.orders.addresses;
export const selectParentId = (state: RootState) =>
  state.orders.pastOrders.parentId;

export const selectBusenessIncludes = (state: RootState) =>
  state.orders.businessIncludes;

export const selectLoader = (state: RootState) => state.orders.isLoader;
export const selectEditOrder = (state: RootState) => state.orders.editOrder;
export const selectEditOrderId = (state: RootState) => state.orders.editId;
export const loginAtSend = (state: RootState) => state.orders.loginAtSend;
export const selectSearchPastOrder = (state: RootState) =>
  state.orders.searchPastOrder;
export const selectedReturnAddress = (state: RootState) =>
  state.orders.selectedReturnAddress;
