import { createAsyncThunk } from "@reduxjs/toolkit";
import addressApi from "../../api/address.api";

import { IAddress } from "../../interfaces/address/IAddress";
import { IBillingInfo } from "../../interfaces/address/IBillingInfo";

export const addAddress = createAsyncThunk<IAddress, IAddress>(
  "address/create",
  async (payload) => {
    const res = await addressApi.addAddress(payload);

    const { address } = res.data;

    return address;
  }
);

export const updateAddress = createAsyncThunk<IAddress | undefined, IAddress>(
  "address/update",
  async (payload) => {
    const res = await addressApi.update(payload);

    if (res) return payload;
  }
);

export const getAddresses = createAsyncThunk<IAddress[], void>(
  "address/list",
  async () => {
    const res = await addressApi.list();
    const { addresses } = res.data;
    return addresses;
  }
);

export const getAddress = createAsyncThunk("/profile/address", async () => {
  const res = await addressApi.userAddress();

  const { address, billing_info } = res.data;

  return {
    address,
    billing_info,
  };
});

export const deleteAddress = createAsyncThunk<number | undefined, number>(
  "address/delete",
  async (id) => {
    const res = await addressApi.delete(id);

    if (res) return id;
  }
);

export const setDefaultAddress = createAsyncThunk<number, number>(
  "address/default",
  async (id, { dispatch }) => {
    await addressApi.default(id);

    await dispatch(getAddresses());

    return id;
  }
);

export const updateBillingInfo = createAsyncThunk<void, IBillingInfo>(
  "profile/updateBillingInfo",
  async (address, { dispatch }) => {
    await addressApi.updateBilling(address);
    dispatch(getAddress());
  }
);
