import { useMemo, useState, FC, memo, useRef, useEffect } from "react";

import { FlexContainer } from "../../../layout";
import BusinessOptionalSettingsList from "../../../../campaigns/outbound-prospecting/steps/choose.demographic/check-options-list";
import CheckListAdditionalText from "./check-list.additional-text";
import CheckListShowPlusButton from "./check-list.show-plus-button";
import { IValueWithLabel } from "../multiWithSearch.select";
import checkerTwoArrays from "../../../../../helpers/checker-two-arrays";
import { useAppSelector } from "../../../../../hooks/redux/useRedux";
import { getDefaultAutomationStatus } from "../../../../../redux/automation/selectors";
import { getWidthWindowDimensions } from "../../../../../redux/window-dimensions/selectors";
import { ICampaignTagNames } from "../../../../../interfaces/recipient-tags/ITags";
import { Tooltip } from "../../tooltip/tooltip.common";

import "../style.scss";

type DeleteItem = (id: string, treeDepth: number) => void;

type OnSelect = (
  id: number | number[],
  isTagsForSelect?: boolean | undefined,
  isSelected?: boolean | undefined
) => void;

interface IMultiCheckedList {
  campaignsNames?: ICampaignTagNames;
  valueWithLabel: IValueWithLabel[];
  deleteItem?: DeleteItem;
  order?: boolean;
  isRecipient?: boolean;
  onSelect?: OnSelect;
  isTagsForSelect?: boolean;
  checked?: number[];
  tagsViewInScrollContainer?: boolean;
}

const MultiCheckedList: FC<IMultiCheckedList> = ({
  campaignsNames,
  valueWithLabel,
  deleteItem,
  order = false,
  isRecipient,
  onSelect,
  isTagsForSelect,
  checked,
  tagsViewInScrollContainer = false,
}) => {
  const isDefaultAutomationActive = useAppSelector(getDefaultAutomationStatus);
  const width = useAppSelector(getWidthWindowDimensions);

  const maxItemsForIndustries = order ? 3 : 5;

  const [show, setShowMore] = useState<boolean>(false);
  const [maxItems, setMaxItems] = useState(
    isTagsForSelect ? 1 : maxItemsForIndustries
  );
  const [containerWidth, setContainerWidth] = useState(0);
  const [isMaxItemsCalculated, setIsMaxItemsCalculated] = useState(false);
  const [valueWithLabelStatus, setValueWithLabelStatus] = useState<
    IValueWithLabel[]
  >([]);

  const tagRef = useRef<any>(null);

  const calcMaxItemsForTags = () => {
    let width = 0;
    let counter = 0;

    tagRef.current?.childNodes.forEach((children: any) => {
      const gap = parseFloat(
        window.getComputedStyle(tagRef.current, null).getPropertyValue("gap")
      );
      if (counter === 0 && !tagsViewInScrollContainer) {
        width += children.offsetWidth;
      } else {
        width += children.offsetWidth! + gap;
      }
      counter += 1;
    });
    if (width <= containerWidth) {
      setMaxItems((prev) => prev + 1);
    } else {
      setMaxItems((prev) => prev - 1);
      if (width >= containerWidth) setIsMaxItemsCalculated(true);
    }
  };

  useEffect(() => {
    if (
      isTagsForSelect &&
      tagRef.current &&
      !isMaxItemsCalculated &&
      containerWidth > 0
    ) {
      if (maxItems >= valueWithLabel.length) setIsMaxItemsCalculated(true);
      calcMaxItemsForTags();
    }
  }, [tagRef?.current, maxItems, containerWidth, isMaxItemsCalculated]);

  useEffect(() => {
    if (!isTagsForSelect) return;

    setContainerWidth(tagRef.current.offsetWidth);
    setIsMaxItemsCalculated(false);
  }, [width, show]);

  useEffect(() => {
    if (checked) {
      const valueWithLabelStatus: IValueWithLabel[] = [];
      valueWithLabel.forEach((tag) => {
        const isActive = checkerTwoArrays(checked, tag.addresses || []);
        valueWithLabelStatus.push({ ...tag, isActive });
      });
      setValueWithLabelStatus(valueWithLabelStatus);
    } else {
      // when we edit the client, set tags
      setValueWithLabelStatus(valueWithLabel);
    }
  }, [checked, valueWithLabel]);

  const getTooltipMessageById = (id: number, isTagWithIcon: boolean) => {
    if (!isTagWithIcon || !campaignsNames) return "";
    switch (id) {
      case -1:
        return (campaignsNames?.birthday_names as string[]).join("<br />");
      case -2:
        return (campaignsNames?.anniversary_names as string[]).join("<br />");
      case -3:
        return (campaignsNames?.multi_step_names as string[]).join("<br />");
      default:
        return "";
    }
  };

  const onClickTag = (tag_id: string, isActiveTag: boolean) => {
    if (!onSelect || isDefaultAutomationActive) return;

    let checkedAddresses: number[] = [];

    const addressesByTag =
      valueWithLabel.find((value) => value.id === tag_id)!.addresses || [];

    if (!isActiveTag) {
      checkedAddresses = Array.from(new Set(checked!.concat(addressesByTag)));
    } else {
      checkedAddresses = checked!.filter((id) => !addressesByTag.includes(id));
    }

    const tagsWhichContainsTheSameCheckedAddresses: IValueWithLabel[] = [];
    valueWithLabel.forEach((tag) => {
      const isActive = checkerTwoArrays(checkedAddresses, tag.addresses || []);
      tagsWhichContainsTheSameCheckedAddresses.push({ ...tag, isActive });
    });

    setValueWithLabelStatus(tagsWhichContainsTheSameCheckedAddresses);
    onSelect(checkedAddresses);
  };

  const itemsValue: IValueWithLabel[] = useMemo(() => {
    if (valueWithLabelStatus) {
      if (show) return valueWithLabelStatus;
      return valueWithLabelStatus.slice(0, maxItems);
    }
    return [];
  }, [valueWithLabelStatus, show, isMaxItemsCalculated, maxItems]);

  useEffect(() => {
    // if the client delete or add some new tags in valueWithLabel list we should calculate maxItems one more time
    if (itemsValue.length === maxItems && isTagsForSelect && maxItems > 1) {
      setMaxItems(1);
      setIsMaxItemsCalculated(false);
    }
  }, [valueWithLabel]);

  const showPlusButton = useMemo(() => {
    if (valueWithLabel) return !show && valueWithLabel.length > maxItems;
    return false;
  }, [valueWithLabel, show]);

  const countHideItemsBtn = useMemo(() => {
    if (showPlusButton) return valueWithLabel.length - maxItems;
    return 0;
  }, [valueWithLabel, showPlusButton]);

  const containerMargin = useMemo(() => {
    return !itemsValue?.length ? "0.2rem" : "0.3rem 0.3rem 0.5rem 0.3rem";
  }, [itemsValue?.length]);

  const styleContainer = useMemo(() => {
    const containerHeight = show ? "10rem" : "5rem";
    return isRecipient
      ? {
          gap: !isTagsForSelect ? "0.125rem" : "",
          margin: "0.5rem 0",
          position: tagsViewInScrollContainer ? "relative" : "unset",
          height: tagsViewInScrollContainer ? containerHeight : "",
          visibility:
            isTagsForSelect && !isMaxItemsCalculated ? "hidden" : "unset",
          overflow: "visible",
        }
      : { margin: containerMargin, right: order ? "1rem" : "0" };
  }, [
    isRecipient,
    show,
    tagsViewInScrollContainer,
    isTagsForSelect,
    isMaxItemsCalculated,
    order,
    containerMargin,
  ]);

  const renderTags = useMemo(() => {
    return itemsValue?.map(
      ({ id, label, treeDepth, icon, isActive, color }) => {
        // filter, because birthday/anniversary/multi-step have id < 0
        const isTagWithIcon = +id < 0;

        const commonBackground = isActive ? "#ff5037" : "#f5f5f2";

        const tooltipMessage = getTooltipMessageById(+id, isTagWithIcon);

        return (
          <div style={{ padding: tooltipMessage.length ? ".44rem 0" : "" }}>
            <Tooltip
              parseMessageToHtml
              message={tooltipMessage}
              hide={!tooltipMessage.length}
              containerStyle={{ marginLeft: "0" }}
              textStyle={{
                width: "fit-content",
                whiteSpace: "nowrap",
              }}
            >
              <BusinessOptionalSettingsList
                onSelect={() => onClickTag(id, !!isActive)}
                styleForSelect={{
                  border: isRecipient ? "0" : "1px solid #ddddd4",
                  margin: isRecipient ? "0" : ".2rem",
                  width: "max-content",
                  padding: isRecipient ? ".44rem 1rem" : ".44rem",
                  background: isRecipient ? color || commonBackground : "",
                  color: isActive ? "white" : "",
                  cursor:
                    isTagsForSelect && !isDefaultAutomationActive
                      ? "pointer"
                      : "",
                  opacity: isDefaultAutomationActive ? "0.5" : "1",
                }}
                key={id}
                id={id}
                label={label}
                onAction={deleteItem as DeleteItem}
                order={order}
                treeDepth={treeDepth}
                isNotActiveTag={!isActive}
                isTagWithIcon={isTagWithIcon}
                icon={icon || null}
              />
            </Tooltip>
          </div>
        );
      }
    );
  }, [
    isRecipient,
    isTagsForSelect,
    deleteItem,
    order,
    itemsValue,
    isDefaultAutomationActive,
  ]);

  return (
    <FlexContainer
      className='scroll-container'
      // @ts-ignore
      style={styleContainer}
      wrap='wrap'
    >
      <FlexContainer
        wrap={show || !isTagsForSelect ? "wrap" : "nowrap"}
        style={
          isRecipient
            ? {
                gap: "0.625rem",
                position: tagsViewInScrollContainer ? "absolute" : "unset",
                height: tagsViewInScrollContainer ? "75%" : "",
                bottom: tagsViewInScrollContainer ? "10%" : "0",
              }
            : {}
        }
        ref={tagRef}
      >
        {renderTags}
        {showPlusButton && !isTagsForSelect && (
          <CheckListShowPlusButton
            countHideItemsBtn={countHideItemsBtn}
            onAction={setShowMore}
            show={show}
            order={order}
          />
        )}
      </FlexContainer>
      {valueWithLabel?.length > maxItems && (
        <CheckListAdditionalText
          setShowMore={setShowMore}
          isRecipient={!!isRecipient}
          show={show}
          order={order}
          tagsViewInScrollContainer={tagsViewInScrollContainer}
        />
      )}
    </FlexContainer>
  );
};

export default memo(MultiCheckedList);
