import { useState } from "react";
import { validRegEx, validErrors } from "./input.constant";

const useValidPassword = () => {
  const [message, setMassage] = useState("");
  const [valid, setValidPassword] = useState(false);

  const isValid = (event: string) => {
    const errorMessage: Array<string> = ["Password must have"];

    if (!validRegEx.upperCase.test(event)) {
      errorMessage.push(validErrors.upperCase);
    }
    if (!validRegEx.lowerCase.test(event)) {
      errorMessage.push(validErrors.lowerCase);
    }
    if (!validRegEx.numberOne.test(event)) {
      errorMessage.push(validErrors.numberOne);
    }
    if (!validRegEx.special.test(event)) {
      errorMessage.push(validErrors.special);
    }
    if (!validRegEx.length.test(event)) {
      errorMessage.push(validErrors.length);
    }
    const messages = errorMessage.join(",");

    if (messages === "Password must have") {
      setMassage("");
      setValidPassword(true);
      return true;
    }
    setValidPassword(false);
    setMassage(messages);
    return false;
  };

  return {
    message,
    isValid,
    setValidPassword,
    setMassage,
    valid,
  };
};

export default useValidPassword;
