import { FC } from "react";
import { IControlledLogo } from "../../interfaces/logo/ILogo";

const AnniversaryLogo: FC<IControlledLogo> = ({ isActive = false }) => {
  const backgroundColor = isActive ? "#F85E49" : "#E9E9E0";
  const color = isActive ? "#FFFFFF" : "#a7a794";

  return (
    <svg
      width='31px'
      height='31px'
      viewBox='0 0 31 31'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ width: "100%", height: "100%" }}
    >
      <title>173211AD-A90C-4BA1-93AB-3F2E953A52F6</title>
      <defs>
        <polygon id='path-1' points='0 0 19.4913351 0 19.4913351 16 0 16' />
      </defs>
      <g
        id='Design'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='Oval-Copy-6'
          fill={backgroundColor || "#F85E49"}
          cx='15.5'
          cy='15.5'
          r='15.5'
        />
        <g id='Edit-Recipient-Popup' transform='translate(-262.5, -682.000000)'>
          <g id='Group-6' transform='translate(268.215801, 689.271905)'>
            <g id='Group-3'>
              <mask id='mask-2' fill='white'>
                <use xlinkHref='#path-1' />
              </mask>
              <g id='Clip-2' />
              <path
                d='M16.8526501,14.111669 L2.63866787,14.111669 C2.22495172,14.111669 1.88831381,13.7750311 1.88831381,13.361315 L1.88831381,5.81640273 L17.6030042,5.81640273 L17.6030042,13.361315 C17.6030042,13.7750311 17.2663663,14.111669 16.8526501,14.111669 M2.63866787,1.88833097 L16.8526501,1.88833097 C17.2663663,1.88833097 17.6030042,2.22496889 17.6030042,2.63868503 L17.6030042,3.92807176 L1.88831381,3.92807176 L1.88831381,2.63868503 C1.88831381,2.22496889 2.22495172,1.88833097 2.63866787,1.88833097 M16.8526501,0 L2.63866787,0 C1.18362302,0 -1.71666455e-05,1.18381185 -1.71666455e-05,2.63868503 L-1.71666455e-05,13.361315 C-1.71666455e-05,14.8161881 1.18362302,16 2.63866787,16 L16.8526501,16 C18.3076949,16 19.4913351,14.8161881 19.4913351,13.361315 L19.4913351,2.63868503 C19.4913351,1.18381185 18.3076949,0 16.8526501,0'
                id='Fill-1'
                fill={color}
                mask='url(#mask-2)'
              />
            </g>
            <path
              d='M11.0253466,7.26408309 C10.5117205,7.26408309 10.0525128,7.49600446 9.74574482,7.86045234 C9.4388052,7.49600446 8.97959744,7.26408309 8.46597142,7.26408309 C7.54206257,7.26408309 6.79291018,8.01306382 6.79291018,8.93714433 C6.79291018,9.43635037 7.0128149,9.88336981 7.3597528,10.1897944 L9.74437149,12.5745848 L12.1047852,10.2143427 C12.4675164,9.90740312 12.6984078,9.44939702 12.6984078,8.93714433 C12.6984078,8.01306382 11.9492554,7.26408309 11.0253466,7.26408309'
              id='Fill-4'
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AnniversaryLogo;
