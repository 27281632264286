import { FC, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useInput from "../../../hooks/input/useInput";
import useSelect from "../../../hooks/input/useSelect";
import { useAppDispatch } from "../../../hooks/redux/useRedux";
import useAddress from "../../../hooks/address/useAddress";
import useAutoCompletePlaces from "../../../hooks/autocomplete-places/useAutocompletePlaces";
import useCountries from "../../../hooks/countries/useCountries";

import { getAddress, setDefaultAddress } from "../../../redux/address/thunks";
import { ValidateCollection } from "../recipient/recipient.edit";
import useForm from "../../../hooks/forms/useForm";

import { extractAddress } from "../../../helpers/autocomplete/extract-address";
import RecipientForm from "../recipient/recipient.form";
import { selectReturnAddress } from "../../../redux/orders/slice";
import useWarningAtRecipientForm from "../../../hooks/address/useWorningAtRecipientForm";
import trimString from "../../../helpers/trimString";
import navigation from "../../../constants/navigation";
import { setNewCampaignContacts } from "../../../redux/multi-step/slice";

const CreateDefaultAddress: FC = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const addressInputRef = useRef<HTMLInputElement>(null);

  const first_name = useInput("");
  const last_name = useInput("");
  const business_name = useInput("");
  const address1 = useInput("");
  const address2 = useInput("");
  const city = useInput("");
  const zip = useInput("");
  const stateCountry = useSelect("");
  const country = useSelect("1");

  const { checkCorrectText } = useWarningAtRecipientForm();
  const { findEmptyElements, toggleValidateClass } = useForm();
  const { create } = useAddress();
  const address = useAutoCompletePlaces(addressInputRef);

  const { getCountryIdByName, countriesToList, statesToList, countryState } =
    useCountries(country.value as string);

  useEffect(() => {
    if (!Object.keys(address).length) return;

    const fullAddress = extractAddress(address.street, address.route);
    const countryId = getCountryIdByName(address.country);

    city.setValue(address.city);
    zip.setValue(address.zip);

    stateCountry.setValue(address.state);

    address1.setValue(fullAddress || "");
    country.setValue(String(countryId));
  }, [address]);

  const states = statesToList(country?.value! as string);

  const createDefaultAddresses = async (event: any) => {
    event.preventDefault();
    const htmlFormControlsCollection = event.currentTarget.elements;

    const { first_name, last_name, address1, zip, city, state, country } =
      htmlFormControlsCollection as ValidateCollection;
    const correctState = states!.map((el) => el.label).includes(state?.value);

    if (!correctState && countryState) {
      (
        document.querySelector('select[name="state"]') as HTMLInputElement
      ).value = "";
      stateCountry.setValue("");
    }

    const inputs = [first_name, address1, zip, city, country];
    const inputsWithState = [first_name, address1, zip, city, state, country];
    toggleValidateClass(countryState ? inputsWithState : inputs);

    const emptyElements = findEmptyElements(
      countryState ? inputsWithState : inputs
    );

    if (emptyElements.length) return;

    const addressStructure = {
      first_name: trimString(first_name.value),
      last_name: trimString(last_name.value),
      business_name: business_name.value,
      address1: address1.value,
      address2: address2.value,
      city: city.value,
      state: countryState ? (stateCountry.value as string) : "",
      country_id: country.value as string,
      zip: zip.value,
      name: `${trimString(first_name.value)} ${trimString(last_name.value)}`,
      allow_poor: true,
    };

    const createDefaultAddress = async () => {
      const result = await create(addressStructure);
      // set new address as default
      await dispatch(setDefaultAddress(result?.id!));
      // get billing info data
      await dispatch(getAddress());
      // set a new address as selected address
      dispatch(selectReturnAddress(result?.id!));

      if (pathname.includes(navigation.addNewMultiStep)) {
        dispatch(
          setNewCampaignContacts({
            return_address_id: result?.id!,
          })
        );
      }
    };

    checkCorrectText(
      () => createDefaultAddress(),
      first_name.value,
      last_name.value,
      address1.value
    );
  };

  return (
    <RecipientForm
      addressInputRef={addressInputRef}
      isEditRecipient
      first_name={first_name}
      last_name={last_name}
      business_name={business_name}
      address1={address1}
      address2={address2}
      countriesToList={countriesToList}
      city={city}
      zip={zip}
      state={stateCountry}
      country={country}
      countryState={countryState}
      btnText='Add Address'
      states={states}
      onSubmit={(event) => createDefaultAddresses(event)}
    />
  );
};

export default CreateDefaultAddress;
