import { FC, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import { FlexContainer, TextLink } from "../../ui/layout";
import navigation from "../../../constants/navigation";
import { setPortalPopup } from "../../../redux/portal-popup/slice";
import typePopups from "../../../constants/popup/type-popup";
import { useAppDispatch } from "../../../hooks/redux/useRedux";

const { CHOOSE_CAMPAIGN } = typePopups;

interface ICampaignManager {
  hideSetting: () => void;
}

const CampaignManager: FC<ICampaignManager> = ({ hideSetting }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const openChooseCampaignPopup = useCallback(() => {
    if (isMobile) {
      history.push(navigation.chooseCampaign);
    } else {
      dispatch(
        setPortalPopup({
          form: CHOOSE_CAMPAIGN,
          title: "",
          typePopup: "form",
        })
      );
    }
  }, [setPortalPopup, dispatch, isMobile]);

  return (
    <FlexContainer
      className='nav__links'
      direction='row'
      onClick={openChooseCampaignPopup}
    >
      <TextLink color='#fff' onClick={hideSetting}>
        Campaign Manager
      </TextLink>
    </FlexContainer>
  );
};

export default CampaignManager;
