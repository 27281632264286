import { RootState } from "../store";

export const getSubscription = (state: RootState) => {
  return state.subscriptions.subscriptions;
};

export const getCurrentSubscription = (state: RootState) => {
  return state.subscriptions.current;
};

export const getNewSubscription = (state: RootState) => {
  return state.subscriptions.new;
};
export const getNewCurrentSubscription = (state: RootState) => {
  return state.subscriptions.newCurrent;
};
export const subscriptionsLoader = (state: RootState) => {
  return state.subscriptions.subscriptionsLoader;
};

export const getWarning = (state: RootState) => {
  return state.subscriptions.warning;
};

export const getDetectedIssueLater = (state: RootState) =>
  state.subscriptions.isDetectedSubscriptionIssueLater;
