import { RootState } from "../store";

export const isAuth = (state: RootState) => state.auth.isAuthenticated;
export const isTokenChecking = (state: RootState) => state.auth.checking;
export const getUser = (state: RootState) => state.auth.user;
export const getUserId = (state: RootState) => {
  if (state.auth.user) return state.auth.user.id;
  return 0;
};
export const getCredit1User = (state: RootState) =>
  state.auth.user?.credit1 || 0;
export const getCredit2User = (state: RootState) =>
  state.auth.user?.credit2 || 0;

export const getPrepaidPlan = (state: RootState) =>
  state.auth.user?.prepaid_plan || null;
export const lastUserFont = (state: RootState) =>
  state.auth.user?.last_used_font;
export const getTestMode = (state: RootState) => state.auth.user?.test_mode;
export const selectAuthProviderLoading = (state: RootState) =>
  state.auth.isAuthProviderLoading;
export const isLoggingAsUser = (state: RootState) => state.auth.loggingAsUser;
export const getClientGroupLogo = (state: RootState) =>
  state.auth.user?.group_logo_url;

export const isDeliveryConfirmationDisabled = (state: RootState) =>
  state.auth.user?.no_delivery_confirmation;
export const getUserSubscription = (state: RootState) =>
  state.auth.user?.user_subscription;

export const getInitialAttachedInvoice = (state: RootState) =>
  state.auth.user?.attach_invoice;
