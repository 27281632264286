import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { FlexContainer, TextLink } from "../ui/layout";

type AuthType = "signin" | "signup";

const AuthHelp: FC<{
  type: AuthType;
  correctLocation?: boolean;
  close?: () => void;
}> = ({ type, correctLocation, close }) => {
  const signInText = useMemo(() => {
    const text =
      type === "signin" ? `Don't have an account?` : `Already have an account?`;
    const link = type === "signin" ? `Sign Up` : `Sign In`;
    return { text, link };
  }, [type]);

  return (
    <FlexContainer
      align='center'
      justify='center'
      style={{ fontSize: ".61111rem" }}
    >
      <span>{signInText.text}</span>
      <Link
        style={{ marginLeft: ".25rem", fontWeight: 700 }}
        to={`/${type === "signin" ? "signup" : "signin"}`}
        onClick={correctLocation ? close : () => {}}
      >
        <TextLink>{signInText.link}</TextLink>
      </Link>
    </FlexContainer>
  );
};

export default AuthHelp;
