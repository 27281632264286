import { userInstance } from "../hooks/instance/useInstance";

const designApi = {
  deleteDesignCard: async (id: number) => {
    const response = await userInstance.post(`/design/delete`, { id });
    return response.data;
  },
};

export default designApi;
