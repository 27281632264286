import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import subscriptionsApi from "../../api/subscriptions.api";
import {
  INewSubscription,
  ISubscriptionCancel,
} from "../../interfaces/subscriptions/ISubscriptions";
import { IThunkError } from "../../interfaces/redux/IThunkError";
import { ISubscriptionsUpdate } from "./types";
import { login } from "../auth/thunks";
import { setPortalPopup } from "../portal-popup/slice";

export const fetchSubscriptions = createAsyncThunk(
  "subscriptions/list",
  async () => {
    const res = await subscriptionsApi.list();
    const { subscriptions, current, new: newSubscription, warning } = res.data;

    return { subscriptions, current, newSubscription, warning };
  }
);

export const setNewSubscription = createAsyncThunk<
  any,
  INewSubscription,
  { rejectValue: IThunkError }
>("subscriptions/new", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await subscriptionsApi.setNewSubscriptions(payload);
    if (!res) return;
    const { subscription } = res.data;
    await dispatch(fetchSubscriptions());
    dispatch(login());

    return { subscription };
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export const updateSubscription = createAsyncThunk<void, ISubscriptionsUpdate>(
  "subscriptions/updatePaymentMethod",
  async (subscription, { dispatch, rejectWithValue }) => {
    try {
      const res = await subscriptionsApi.updatePaymentMethod(subscription);

      if (!res) return;
      await dispatch(fetchSubscriptions());
      dispatch(
        setPortalPopup({
          typePopup: "alert",
          title: "Success",
          text: "Your subscription is updated.",
        })
      );
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk<any, ISubscriptionCancel>(
  "subscriptions/cancel",
  async (data, { rejectWithValue }) => {
    try {
      const res = await subscriptionsApi.cancelSubscriptions(data);
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
    }
  }
);

export const fetchTaxList = createAsyncThunk(
  "/subscriptions/taxList",
  async () => {
    const res = await subscriptionsApi.getTaxList();
    const taxes = res.data;

    return taxes;
  }
);
