import { userInstance } from "../hooks/instance/useInstance";
import {
  ICountriesResponse,
  IDisabledStatesResponse,
} from "../interfaces/countries/ICountry";
import { IStatesResponse } from "../interfaces/countries/IState";

const countriesApi = {
  list: async () => {
    return userInstance.get<ICountriesResponse>("/countries/list");
  },
  states: async (countryId: number) => {
    return userInstance.get<IStatesResponse>(
      `/countries/listStates?country_id=${countryId}`
    );
  },
  disabledStates: async () => {
    return userInstance.get<IDisabledStatesResponse>(
      "/states/?ground_shipping=0"
    );
  },
};

export default countriesApi;
