import { IReports } from "../../interfaces/reports/IReports";

type IReportsState = IReports;

const initialState: IReportsState = {
  recent_scans: null,
  scans: null,
  statistics: null,
  total_links: null,
  total_scans: null,
  total_unique_scans: null,
};

export default initialState;
