import { createAsyncThunk } from "@reduxjs/toolkit";

import customFieldApi from "../../api/custom-fields.api";

export const getCustomFieldsList = createAsyncThunk(
  "profile/field",
  async () => {
    try {
      const res = await customFieldApi.getCustomFields();
      const { list } = res.data;
      return list;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCustomField = createAsyncThunk<any, number>(
  "profile/field/delete",
  async (id) => {
    try {
      const result = await customFieldApi.deleteCustomField(id);
      if (result) return { id };
    } catch (error) {
      throw error;
    }
  }
);

export const addCustomFields = createAsyncThunk<any, { label: string }>(
  "profile/field/createField",
  async (payload) => {
    try {
      const res = await customFieldApi.createCustomField(payload);
      const { custom_field } = res.data;
      return custom_field;
    } catch (error) {
      throw error;
    }
  }
);
