import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./types";
import {
  getCardsListByCategory,
  getCardsSections,
  toggleFavoriteCard,
} from "./thunks";

import deleteCustomCard from "../design/thunks";

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    togglePreview: (state) => {
      state.isPreview = !state.isPreview;
    },
    setPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.nextPage = payload;
    },
    setPageCategory: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.nextPageCategory = payload;
    },
    setIsListEnd: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      state.isListEnd = payload;
    },
    setInitCardsSection: (state) => {
      state.cards = [];
      state.cursor = null;
      state.nextPage = 1;
    },
    setInitCardsCategory: (state) => {
      state.cardsByCategory = [];
      state.nextPageCategory = 0;
      state.cards = [];
      state.cursor = null;
      state.isListEndCategory = false;
      state.nextPage = 1;
    },
    setRefresh: (state) => {
      state.refresh = !state.refresh;
    },
    setRenderWithoutCustom: (state) => {
      state.cards = state.cards.filter((el) => !el.isCustom);
    },
    setSearchCards: (state, action: PayloadAction<string | null>) => {
      state.searchCards = action.payload;
    },
    setDefaultSearchCards: (state) => {
      state.searchCards = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCardsSections.fulfilled, (state, { payload }) => {
      const { cards } = payload;

      state.cards = state.cards.concat(cards);
      state.nextPage += 1;
      state.isLoading = false;
      state.allPage = payload.pages;
      state.isListEnd =
        +payload.page === payload.pages || +payload.page > payload.pages;
    });
    builder.addCase(getCardsSections.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCardsSections.rejected, (state) => {
      state.isLoading = false;
      state.isListEnd = true;
    });
    builder.addCase(getCardsListByCategory.fulfilled, (state, { payload }) => {
      const newCard = state.cards.map((el) => {
        return {
          ...el,
          ...(el.id === payload?.category ? { cursor: payload.cursor } : {}),
          value:
            el.id === payload?.category
              ? el.value.concat(payload.cards)
              : el.value,
        };
      });
      state.cards = newCard;

      state.cursor = payload.cursor
        ? {
            categoryId: payload.category,
            value: payload.cursor,
          }
        : null;

      if (typeof payload.page === "string" && payload.page === "1") {
        state.cardsByCategory = payload.cards;
      } else {
        state.cardsByCategory = state.cardsByCategory.concat(payload.cards);
      }
      state.nextPageCategory = payload?.isDelete
        ? state.nextPageCategory
        : state.nextPageCategory + 1;
      state.isLoading = false;
      state.isListEndCategory =
        +payload.page === payload.total_pages ||
        +payload.total_pages < +payload.page;
    });
    builder.addCase(getCardsListByCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCustomCard.fulfilled, (state, { payload }) => {
      if (!payload) return;

      const { id: card_id } = payload;
      state.cards = state.cards.map((card) => {
        if (card.isCustom) {
          return {
            ...card,
            value: card.value.filter((cardValue) => cardValue.id !== card_id),
          };
        }
        // favorite cards
        if (card.id === 0) {
          return {
            ...card,
            value: card.value.filter((cardValue) => cardValue.id !== card_id),
          };
        }
        return card;
      });
      state.cardsByCategory = state.cardsByCategory.filter(
        (cardValue) => cardValue.id !== card_id
      );
    });
    builder.addCase(toggleFavoriteCard.fulfilled, (state, { payload }) => {
      const { favorite_cards, is_like, favorite_card, card_id, category_id } =
        payload;

      const favourite = state.cards.find((card) => card.id === 0);

      // when we on route category/:id
      if (favourite) {
        // when we unlike all favorite cards, delete all like from cards by category
        if (
          favourite.value.length === 1 &&
          !is_like &&
          favorite_cards.length === 0
        ) {
          const [, ...newCards] = state.cards;
          state.cards = newCards.map((card) => ({
            ...card,
            value: card.value.map((c) => ({ ...c, isFavorite: false })),
            // test added favorite card
            // total_items: favorite_cards.length,
          }));
        }
        // when we have favorite cards, add likes to cards by category or unlike
        state.cards = state.cards.map((card) => {
          if (card.id === 0) {
            if (is_like)
              return {
                ...card,
                value: [
                  { ...favorite_card, isFavorite: is_like },
                  ...card.value,
                ],
                total_items: favorite_cards.length,
              };

            return {
              ...card,
              value: card.value.filter((c) => c.id !== card_id),
              total_items: favorite_cards.length,
            };
          }
          return {
            ...card,
            value: card.value.map((c) =>
              favorite_cards.includes(c.id)
                ? { ...c, isFavorite: true }
                : { ...c, isFavorite: false }
            ),
          };
        });
      } else {
        // when we havent favorite cards, add favorite section, add likes to cards by category
        const newCards = state.cards.map((card) => {
          return {
            ...card,
            value: card.value.map((c) =>
              favorite_cards.includes(c.id) ? { ...c, isFavorite: true } : c
            ),
          };
        });
        state.cards = [
          {
            id: 0,
            key: "Favorites",
            isCustomize: false,
            isCustom: false,
            value: [{ ...favorite_card, isFavorite: true }],
            total_items: favorite_cards.length,
          },
          ...newCards,
        ];
      }

      // when we on route category/:id
      if (category_id && category_id !== 0) {
        state.cardsByCategory = state.cardsByCategory.map((card) =>
          card.id === card_id ? { ...card, isFavorite: is_like } : card
        );
      } else if (category_id === 0) {
        state.cardsByCategory = state.cardsByCategory.filter(
          (card) => card.id !== card_id
        );
      }
    });
  },
});

export const {
  togglePreview,
  setPage,
  setIsListEnd,
  setInitCardsSection,
  setInitCardsCategory,
  setRefresh,
  setRenderWithoutCustom,
  setSearchCards,
  setDefaultSearchCards,
} = cardsSlice.actions;

export default cardsSlice.reducer;
