import { FC, useContext } from "react";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Typography } from "../../ui/typography";

import { ModalConfirm } from "../../Modal/Modal";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const ConfirmationPopup: FC = () => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const { activePopup, closeConfirmationPopup } = useContext(
    ConfirmationPopupContext
  );

  if (!activePopup) return null;

  const {
    title = "Confirm",
    subtitle,
    onConfirm,
    onCancel,
    textButtonConfirm = "YES",
    textButtonCancel = "NO",
    style,
    subtitleStyle,
    positionButtons = "space-between",
    styleForTextSub,
    showButtons = true,
    closeAfterConfirm = true,
    styleModal,
  } = activePopup;

  const onDecline = () => {
    onCancel?.();
    closeConfirmationPopup();
  };

  const onSubmit = () => {
    onConfirm();
    if (closeAfterConfirm) closeConfirmationPopup();
  };

  return (
    <ModalConfirm open={!!activePopup} onClose={onDecline} style={styleModal}>
      <FlexContainer direction='column' padding='2rem' style={style}>
        <FlexContainer justify='flex-start'>
          <Typography fontSize='1.68888rem' fontWeight='100'>
            {title}
          </Typography>
        </FlexContainer>
        <FlexContainer
          justify='flex-start'
          margin='1.2rem'
          style={styleForTextSub}
        >
          <Typography
            fontSize='1.08888rem'
            fontWeight='100'
            style={subtitleStyle}
          >
            {subtitle}
          </Typography>
        </FlexContainer>
        {showButtons && (
          <FlexContainer justify={positionButtons}>
            <ButtonAction
              width={isMobile ? "30vw" : "15vw"}
              style={{ marginRight: "1.5rem" }}
              onClick={onSubmit}
            >
              {textButtonConfirm}
            </ButtonAction>
            <ButtonAction
              width={isMobile ? "30vw" : "15vw"}
              onClick={onDecline}
            >
              {textButtonCancel}
            </ButtonAction>
          </FlexContainer>
        )}
      </FlexContainer>
    </ModalConfirm>
  );
};

export default ConfirmationPopup;
