import { FC } from "react";
import { Tooltip } from "../../ui/common/tooltip/tooltip.common";

interface IAddressBookCampaignLogo {
  iconSize: string;
  index: number | undefined;
  message: string;
  children: JSX.Element;
}
const AddressBookCampaignLogo: FC<IAddressBookCampaignLogo> = ({
  iconSize,
  index,
  message,
  children,
}) => {
  return (
    <Tooltip
      message={message}
      parseMessageToHtml
      containerStyle={{
        marginLeft: 0,
        width: iconSize,
        height: iconSize,
      }}
      textStyle={{
        width: "fit-content",
        whiteSpace: "nowrap",
      }}
      left={index === 0}
      messageClassName='campaign-tooltip'
    >
      {children}
    </Tooltip>
  );
};

export default AddressBookCampaignLogo;
