export interface PopupState extends IAlertPopup {
  form: string | null;
  isShowPopup: boolean;
}

export interface IAlertPopup {
  text: string;
  typePopup:
    | "alert"
    | "error"
    | "confirm"
    | "success"
    | "form"
    | "banned"
    | "noauth"
    | null;
  title: string;
}

export interface IPopupAction {
  form?: string;
  title: string;
  text?: string;
  typePopup:
    | "alert"
    | "error"
    | "confirm"
    | "success"
    | "form"
    | "banned"
    | "noauth"
    | null;
}

export const initialState: PopupState = {
  form: null,
  isShowPopup: false,
  typePopup: null,
  text: "",
  title: "",
};
