import { FC, useMemo } from "react";
// import parse from "html-react-parser";

import { FlexContainer } from "../../ui/layout";
// import { IFrameTemplate } from "../../../redux/custom-cards/types";
import defaultQrCodeImg from "../../../assets/qr-code/default-qr-code.png";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface IQrPreview {
  url: string;
  logoImageUrl: string;
  // isBackground: boolean;
  // currentFrame?: IFrameTemplate;
  // frameText: string;
  // defaultFrames: IFrameTemplate[];
}

const QrCodePreview: FC<IQrPreview> = ({
  url,
  logoImageUrl,
  // isBackground,
  // currentFrame,
  // frameText,
  // defaultFrames,
}) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const qrCode = useMemo(
    () => (
      <div className='qr-frame-container'>
        <img
          className='canvasToImg'
          src=''
          alt='qr_code'
          style={{ width: "100%" }}
        />
        {logoImageUrl ? (
          <div className='qr-frame-icon-container'>
            <img
              src={logoImageUrl}
              alt='icon'
              style={{
                display: "block",
                width: " 100%",
                // background: isBackground ? "white" : "",
                // padding: isBackground ? "2%" : "",
              }}
            />
          </div>
        ) : null}
      </div>
    ),
    [logoImageUrl]
  );

  // const replaceQrCode = (domNode: any) => {
  //   if (domNode.attribs && domNode.attribs.class === "replace") {
  //     return qrCode;
  //   }
  // };

  return (
    <FlexContainer
      direction='row'
      justify='center'
      style={{
        width: isMobile ? "4.5rem" : "7.5rem",
        paddingBottom: isMobile ? "1rem" : "",
      }}
    >
      {/* {url && defaultFrames.length ? (
        <>
          {currentFrame ? (
            <>
              {parse(currentFrame?.html.replace("Text", frameText), {
                replace: replaceQrCode,
              })}
            </>
          ) : (
            qrCode
          )}
        </>
      ) : (
        <img src={defaultQrCodeImg} alt='qr-code' style={{ width: "100%" }} />
      )} */}
      {url ? (
        qrCode
      ) : (
        <img src={defaultQrCodeImg} alt='qr-code' style={{ width: "100%" }} />
      )}
    </FlexContainer>
  );
};

export default QrCodePreview;
