import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

import { useAppDispatch } from "../redux/useRedux";
import { logout, signIn } from "../../redux/auth/thunks";
import { basketCount } from "../../redux/basket/thunks";
import { setLoggingAsUser } from "../../redux/auth/slice";

import usePopup from "../popup/usePopup";
import useUrlSearchParams from "../utils/useUrlSearchParams";
import navigation from "../../constants/navigation";

const useLogInAsUser = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const locations = useLocation();

  const token = useUrlSearchParams("token");
  const authPathName = useUrlSearchParams("salesForceToken");

  const logInFpomPhp = useMemo(() => {
    if (locations.pathname.includes("/token.php")) {
      return true;
    }
    return false;
  }, []);

  const hash = useMemo(() => {
    if (locations.pathname.includes("/hash=")) {
      return locations.pathname.slice(6);
    }
    return "";
  }, []);

  const { openErrorPopup } = usePopup();

  useEffect(() => {
    return () => {
      window.location.hash = "";
    };
  }, []);

  const setLogInAsUser = async (token: string) => {
    dispatch(setLoggingAsUser(true));
    await dispatch(logout());
    dispatch(setLoggingAsUser(true));

    const { meta, payload } = await dispatch(signIn({ token }));

    if (meta.requestStatus === "fulfilled") {
      window.location.reload();
      await dispatch(basketCount());
      dispatch(setLoggingAsUser(false));
      history.push(navigation.home);

      return;
    }
    return openErrorPopup(payload?.message || "Something went wrong");
  };

  useEffect(() => {
    if (!hash) return;

    setLogInAsUser(hash);
  }, [hash]);

  useEffect(() => {
    if (!token || token === "hubspot_integration") return;
    if (!authPathName) return;
    setLogInAsUser(token);
  }, [token]);

  useEffect(() => {
    if (logInFpomPhp && token) {
      setLogInAsUser(token);
    }
  }, [logInFpomPhp, token]);
};

export default useLogInAsUser;
