import { FC } from "react";

import { MdDoNotDisturbAlt } from "react-icons/md";
import classNames from "classnames";

import { FlexContainer } from "../../ui/layout";
import CustomImageItem from "../../../pages/customizable/images-list/custom-image-item";
import UploadImg from "../../upload-img/upload-img";
import { Input } from "../../ui/common/inputs/input.common";

import { IInputInstance } from "../../../hooks/input/useInput";
import { getCustomImagesIcon } from "../../../redux/custom-cards/selectors";

import { useAppSelector } from "../../../hooks/redux/useRedux";
import useCreateCustomImages from "../../../hooks/custom-card/useCreateCustomImages";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface IQrCodeForm {
  nameInput: IInputInstance;
  linkInput: IInputInstance;
  logoImageId: number;
  logoImageUrl: string;
  handleLogo: (id: number, image_url: string) => void;
  // logoBackgroundCheckbox: {
  //   checked: number[];
  //   setAll: (ids: number[]) => void;
  //   deselectAll: () => void;
  //   onChange: (id: number | number[]) => void;
  //   setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  // };
  errors: string[];
  removeErrorByName: (error: string) => void;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  webHookInput: IInputInstance;
}

const QrCodeForm: FC<IQrCodeForm> = ({
  nameInput,
  linkInput,
  logoImageUrl,
  logoImageId,
  handleLogo,
  // logoBackgroundCheckbox,
  errors,
  removeErrorByName,
  scrollContainerRef,
  webHookInput,
}) => {
  const customImagesIcon = useAppSelector(getCustomImagesIcon);

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const { uploadNewImage } = useCreateCustomImages(null, false, true);

  const noIconClasses = classNames({
    "upload-image-gallery__container": true,
    "upload-image-gallery__img__selected": logoImageId === 0,
  });

  // const logoCheckboxData = { label: "Add Logo Background", id: 1 };

  const resetLogoHandler = () => {
    handleLogo(0, "");
  };

  return (
    <FlexContainer
      direction='column'
      className='qr-form-section scroll-container'
      style={{
        maxHeight: isMobile ? "16rem" : "25rem",
        padding: "0.5rem",
      }}
      ref={scrollContainerRef}
    >
      <Input
        className='gray-input'
        type='text'
        name='name'
        fixedLabel='Name'
        fixedLabelStyle={{ paddingLeft: "1rem", color: "#020F14" }}
        onFocus={() => {
          removeErrorByName("emptyName");
          removeErrorByName("qrNameAlreadyUsed");
        }}
        style={{
          background: "#f5f5f2",
          fontSize: "0.875rem",
          marginTop: isMobile ? "1rem" : "",
          border:
            errors.includes("emptyName") || errors.includes("qrNameAlreadyUsed")
              ? "1px solid red"
              : "",
        }}
        {...nameInput}
      />
      {errors.includes("qrNameAlreadyUsed") ? (
        <span
          className='error-input'
          style={{ width: "100%", padding: "0.2rem" }}
        >
          This name is already in use for another QR code
        </span>
      ) : null}

      <Input
        className='gray-input'
        type='text'
        name='link'
        placeholder='https://'
        fixedLabel='Link'
        fixedLabelStyle={{ paddingLeft: "1rem", color: "#020F14" }}
        onFocus={() => removeErrorByName("emptyLink")}
        style={{
          marginTop: isMobile ? "0.6777rem" : "0.8rem",
          background: "#f5f5f2",
          fontSize: "0.875rem",
          border: errors.includes("emptyLink") ? "1px solid red" : "",
        }}
        {...linkInput}
      />
      {errors.includes("invalidUrl") ? (
        <span
          className='error-input'
          style={{ width: "100%", padding: "0.2rem" }}
        >
          The link must be a valid url
        </span>
      ) : null}

      <Input
        className='gray-input'
        type='text'
        name='webHook'
        placeholder='https:// (Optional)'
        fixedLabel='Webhook'
        fixedLabelStyle={{
          paddingLeft: "1rem",
          color: "#020F14",
          whiteSpace: "nowrap",
        }}
        onFocus={() => removeErrorByName("emptyLink")}
        style={{
          marginTop: isMobile ? "0.6777rem" : "0.8rem",
          background: "#f5f5f2",
          fontSize: "0.875rem",
        }}
        {...webHookInput}
      />

      {errors.includes("invalidWebHookUrl") ? (
        <span
          className='error-input'
          style={{ width: "100%", padding: "0.2rem" }}
        >
          invalid webhook url
        </span>
      ) : null}

      <div style={{ marginTop: "1rem", width: "100%" }}>
        <FlexContainer
          direction={isMobile ? "column" : "row"}
          align={isMobile ? "flex-start" : "center"}
        >
          <div className='upload-image'>
            <UploadImg
              zoom={90}
              imageUrl={logoImageUrl}
              uploadNewImage={uploadNewImage}
              isCover={false}
              isIcon
              textResolution='Add logo'
              handleImage={handleLogo}
              minZoom='0'
              maxZoom='90'
              hideZoom
            />
          </div>
          {/* <CustomCheckbox
            data={logoCheckboxData}
            checked={logoBackgroundCheckbox?.checked!}
            onChange={logoBackgroundCheckbox?.onChange!}
            style={{
              margin: isMobile ? "1rem 0 0 0 " : "0 0 0 1rem",
              fontWeight: 400,
              color: !logoImageId ? "#858585" : "#221d1d",
            }}
            rounded
            disabled={!logoImageId}
          /> */}
        </FlexContainer>

        {customImagesIcon.length ? (
          <FlexContainer margin='1rem 0'>
            <div
              className='upload-image-gallery scroll-container'
              style={{ maxHeight: "5.313rem" }}
            >
              <FlexContainer
                className={noIconClasses}
                direction='column'
                justify='space-around'
                align='center'
                style={{
                  cursor: "pointer",
                  height: "5rem",
                  borderRadius: logoImageId === 0 ? "0.8rem" : "",
                }}
                onClick={() => {
                  resetLogoHandler();
                  // logoBackgroundCheckbox.deselectAll();
                }}
              >
                <MdDoNotDisturbAlt size={20} />
                <span style={{ fontSize: "0.5rem" }}>No Icon</span>
              </FlexContainer>

              {customImagesIcon.map(({ image_url, id, type }) => {
                return (
                  <CustomImageItem
                    image_url={image_url}
                    id={id}
                    type={type}
                    handleImage={handleLogo}
                    customizeId={logoImageId}
                    key={id}
                    onDelete={resetLogoHandler}
                  />
                );
              })}
            </div>
          </FlexContainer>
        ) : null}
      </div>
    </FlexContainer>
  );
};

export default QrCodeForm;
