import { FC } from "react";
import { IoMdClose } from "react-icons/io";

import { FlexContainer } from "../ui/layout";
import { Typography } from "../ui/typography";
import NotificationWrapper from "./notification.styled";

interface INotificationCardProps {
  message?: string;
  show?: boolean;
  bgcolor: string;
  onCloseClick: () => void;
}

const closeButton = {
  padding: 5,
  border: "none",
  background: "none",
  cursor: "pointer",
};

const NotificationCard: FC<INotificationCardProps> = ({
  show,
  message,
  bgcolor,
  onCloseClick,
}) => {
  if (!show) return null;

  return (
    <NotificationWrapper bgcolor={bgcolor}>
      <FlexContainer direction='column' align='flex-start'>
        <Typography fontSize='0.7777rem' style={{ margin: "10px 0" }}>
          {message}
        </Typography>
      </FlexContainer>

      <FlexContainer width='50px' align='center' justify='center'>
        <button type='button' style={closeButton} onClick={onCloseClick}>
          <IoMdClose size={20} />
        </button>
      </FlexContainer>
    </NotificationWrapper>
  );
};

export default NotificationCard;
