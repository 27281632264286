import { IGetNotificationsResponse } from "../interfaces/notifications/responses/IGetNotificatiosResponse";
import { NotificationId } from "../interfaces/notifications/INotification";
import { userInstance } from "../hooks/instance/useInstance";

const notificationsApi = {
  list: async () => {
    return userInstance.get<IGetNotificationsResponse>("/notifications/list");
  },
  markAsRead: async (id: NotificationId) => {
    return userInstance.get(`/notifications/markAsRead?id=${id}`);
  },
};

export default notificationsApi;
