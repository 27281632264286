import React, { FC, useEffect } from "react";
import classNames from "classnames";

import { Input } from "../../ui/common/inputs/input.common";
import { ButtonAction, Form } from "../../ui/layout";

import useInput from "../../../hooks/input/useInput";
import useEmail from "../../../hooks/profile/useEmail";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import useValidPassword from "../../../hooks/input/useValidPassword";
import useConfirmation from "../../../hooks/input/useConfirmation";
import checkAuth from "../../../redux/checkauth/selectors";

const EmailChangePopup: FC = () => {
  const { last: lastTypeLogin } = useAppSelector(checkAuth);

  const { changeEmailSubmit } = useEmail();
  const { isValid, message, setMassage } = useValidPassword();

  const newEmail = useInput("");
  const confirmationEmail = useInput("");
  const password = useInput("");

  const { isConfirmed, confirmationMessage, setConfirmationMassage } =
    useConfirmation("Email");

  useEffect(() => {
    setConfirmationMassage("");
  }, [newEmail.value, confirmationEmail.value]);

  useEffect(() => {
    setMassage("");
  }, [password.value]);

  const validPass = classNames({
    "error-input-none": true,
    "error-input": !!message,
  });

  const confirmedEmail = classNames({
    "error-input-none": true,
    "error-input": !!confirmationMessage,
  });

  const changeEmailUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const confirmed = isConfirmed(newEmail.value, confirmationEmail.value);
    if (confirmed) {
      if (lastTypeLogin === "email") {
        return changeEmailSubmit(event);
      }

      const valid = isValid(password.value);
      if (valid) {
        changeEmailSubmit(event, password.value);
      }
    }
  };

  return (
    <Form onSubmit={changeEmailUser} autoComplete='off'>
      <Input
        {...newEmail}
        isDisableAutocomplete
        placeholder='New email address'
        type='email'
        name='email'
        style={{ marginBottom: "1rem" }}
        required
      />
      <Input
        {...confirmationEmail}
        isDisableAutocomplete
        placeholder='Confirm email address'
        name='email_confirm'
        type='email'
        style={{ marginBottom: "1rem" }}
        required
      />
      <span
        className={confirmedEmail}
        style={{ marginTop: "-.8rem", marginBottom: ".5rem" }}
      >
        {confirmationMessage}
      </span>
      <Input
        isDisableAutocomplete
        placeholder='Enter your password'
        type='password'
        name='password'
        {...password}
        style={{ marginBottom: "1rem" }}
        required
        showPasswordIcon
      />
      <span
        className={validPass}
        style={{ marginTop: "-.8rem", marginBottom: ".5rem" }}
      >
        {message}
      </span>
      <ButtonAction type='submit'>Change</ButtonAction>
    </Form>
  );
};

export default EmailChangePopup;
