import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, IActionStep, IActiveOutboundLeads } from "./types";
import {
  calculateTargets,
  outboundGetIndustry,
  outboundProcess,
  outboundZipBoundary,
} from "./thunks";
import { ILatLng } from "../../components/campaigns/outbound-prospecting/steps/choose.demographic/demographic-form";
import { IDataTab } from "../../pages/customizable/tabs/tabs";

const outboundLeadsSlice = createSlice({
  name: "outboundLeads",
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<IActionStep>) => {
      const { activeStep } = action.payload;
      if (activeStep > 1) {
        state.step = activeStep;
      } else {
        state.step = 1;
      }
    },
    nextOutboundStep: (state) => {
      state.step = Math.min(state.step! + 1, 5);
    },
    setOneBusiness: (state, action: PayloadAction<boolean>) => {
      state.oneBusiness = action.payload;
    },
    setActiveOutboundLeads: (
      state,
      action: PayloadAction<IActiveOutboundLeads>
    ) => {
      state.activeOutboundLeads = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      if (state.activeOutboundLeads)
        state.activeOutboundLeads.limit = action.payload;
    },
    setPoligons: (state, action: PayloadAction<ILatLng[]>) => {
      state.poligon = action.payload;
    },
    setSearchType: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.searchType = payload;
    },
    resetActiveOutboundLeads: (state) => {
      state.activeOutboundLeads = null;
    },
    defaultStep: (state) => {
      state.step = 1;
    },
    setLatlng: (state, action: PayloadAction<ILatLng>) => {
      state.location = action.payload;
    },
    setSearchCountry: (state, action: PayloadAction<string>) => {
      state.searchCountry = action.payload;
    },
    setSearchCity: (state, action: PayloadAction<string>) => {
      state.searchCity = action.payload;
    },
    setLacationRadius: (state, action: PayloadAction<number>) => {
      state.radiusAddress = action.payload;
    },
    defaultCount: (state) => {
      state.count = null;
    },
    setOutboundCardId: (state, action: PayloadAction<number>) => {
      state.card_id = action.payload;
    },
    setOutboundCardIdEmpty: (state) => {
      state.card_id = null;
    },
    setTypeOptionsSetting: (state, action: PayloadAction<IDataTab>) => {
      state.typeOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(outboundGetIndustry.fulfilled, (state, action) => {
      state.industries = action.payload.industries;
    });
    builder.addCase(calculateTargets.fulfilled, (state, action) => {
      const { mask, total_records } = action.payload;
      state.count = total_records;
      state.mask = mask;
    });
    builder.addCase(outboundProcess.fulfilled, (state, { payload }) => {
      const { prices, address_from, addresses, quantity } = payload;
      state.procces = { address_from, addresses, quantity, prices };
      state.prices = prices;
    });
    builder.addCase(outboundZipBoundary.fulfilled, (state, action) => {
      state.poligon = action.payload.boundary;
    });
  },
});

export const {
  setActiveStep,
  setActiveOutboundLeads,
  defaultStep,
  defaultCount,
  setLatlng,
  setPoligons,
  resetActiveOutboundLeads,
  setLacationRadius,
  setSearchType,
  setSearchCountry,
  setSearchCity,
  setLimit,
  setOutboundCardId,
  nextOutboundStep,
  setOutboundCardIdEmpty,
  setTypeOptionsSetting,
  setOneBusiness,
} = outboundLeadsSlice.actions;

export default outboundLeadsSlice.reducer;
