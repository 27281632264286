import { userInstance } from "../hooks/instance/useInstance";

const apiKeysApi = {
  list: async () => {
    return userInstance.get<any>("/profile/apiKey");
  },
  delete: async (id: number | string) => {
    return userInstance.delete<any>(`/profile/apiKey/${id}`);
  },
  add: async (apiKey: any) => {
    return userInstance.post<any>("/profile/apiKey", apiKey);
  },
};

export default apiKeysApi;
