import { RootState } from "../store";

export const getSections = (state: RootState) => state.cards.cards;
export const isPreview = (state: RootState) => state.cards.isPreview;
export const selectIsCardsListEnd = (state: RootState) => state.cards.isListEnd;
export const selectIsCardsListEndCategory = (state: RootState) =>
  state.cards.isListEndCategory;
export const getCardsByCategory = (state: RootState) =>
  state.cards.cardsByCategory;

export const getCurrentPage = (state: RootState) => state.cards.nextPage;
export const getTotalPages = (state: RootState) => state.cards.pages;
export const selectSections = (state: RootState) => state.cards;

export const getCurrentPageCategory = (state: RootState) =>
  state.cards.nextPageCategory;
export const getSearchCards = (state: RootState) => state.cards.searchCards;
