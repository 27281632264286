import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import basketApi from "../../api/basket.api";
import { ordersApi } from "../../api/orders.api";

import { BasketId, BasketRemoveId } from "../../interfaces/basket/IBasket";
import { IBasketSend } from "../../interfaces/basket/IBasketSend";
import { IBasketChildsThunkPayload } from "./types";
import { IPlaceBasket } from "../../interfaces/orders/IPlaceBasket";
import { IThunkError } from "../../interfaces/redux/IThunkError";

export const basketClear = createAsyncThunk<
  any,
  void,
  { rejectValue: IThunkError }
>("basket/clear", async (_, { rejectWithValue }) => {
  try {
    const res = await basketApi.clear();
    const { item } = res.data;
    return {
      item,
    };
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export const basketCount = createAsyncThunk<number, void>(
  "basket/count",
  async () => {
    const res = await basketApi.count();

    const { count } = res.data;

    return count;
  }
);

export const basketChilds = createAsyncThunk<
  IBasketChildsThunkPayload,
  { id: number; nextPage: number }
>("basket/childs", async ({ id, nextPage }) => {
  const res = await ordersApi.listChilds(id, "basket", 100, nextPage);

  const { childs, page } = res.data;

  return {
    childs,
    id,
    page,
  };
});

export const basketItem = createAsyncThunk("basket/item", async () => {
  await basketApi.item();
});

export const basketSend = createAsyncThunk<
  any,
  IBasketSend,
  { rejectValue: IThunkError }
>("basket/send", async (payload) => {
  const res = await basketApi.send(payload);

  const { items, basket_id, price_structure, coupon_name } = res.data;

  return { items, basket_id, price_structure, coupon_name };
});

export const basketCancel = createAsyncThunk<void, BasketId>(
  "basket/cancel",
  async (payload) => {
    await basketApi.cancel(payload);
  }
);

export const basketRemove = createAsyncThunk<BasketId, BasketRemoveId>(
  "basket/delete",
  async ({ id, basket_id }) => {
    await basketApi.remove(basket_id);

    return id;
  }
);

export const basketItemFromAddress = createAsyncThunk<number[], BasketId>(
  "basket/itemFromAddress",
  async (id) => {
    const res = await basketApi.itemFromAddress(id);
    const { address_ids } = res.data;
    return address_ids;
  }
);

export const listBasketGrouped = createAsyncThunk<
  any,
  {
    page?: number;
    limit?: number;
  }
>("basket/list/grouped", async (payload) => {
  const res = await basketApi.listGrouped(payload.page, payload.limit);

  const { items, total_pages, page } = res.data;

  return { items, total_pages, page };
});

export const listBasketNew = createAsyncThunk("basket/list/new", async () => {
  await basketApi.listNew();
});

export const listBasketOrders = createAsyncThunk<void, number>(
  "basket/list/orders",
  async (payload) => {
    await basketApi.listOrders(payload);
  }
);

export const listBaskets = createAsyncThunk("basket/list", async () => {
  await basketApi.list();
});

export const basketUpdate = createAsyncThunk<any, IPlaceBasket>(
  "orders/placeBasket",
  async (payload: IPlaceBasket) => {
    const res = await basketApi.update(payload);
    return res.data;
  }
);
