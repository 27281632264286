import { RootState } from "../store";
import {
  IAutomationState,
  INewAutomation,
  IAutomationType,
  IAutomationSelectCardData,
} from "./types";

export const getNewAutomation = (state: RootState): INewAutomation =>
  state.automations.newCardInfo;

export const getDefaultAutomationStatus = (state: RootState): boolean =>
  state.automations.newCardInfo.is_default;

export const getAutomations = (state: RootState): IAutomationState =>
  state.automations;

export const getAutomationId = (state: RootState): number | null =>
  state.automations.editCardId;

export const getDefaultAutomation = (state: RootState): boolean =>
  state.automations.isDefaultAutomation;

export const getAutomationStep = (state: RootState): number =>
  state.automations.step;

export const getIsDisableStepSwitch = (state: RootState): boolean =>
  state.automations.isDisableStepSwitch;

export const getDisableStepSwitchError = (state: RootState): string =>
  state.automations.disableStepSwitchByError;

export const getActiveAutomationType = (
  state: RootState
): IAutomationType | null => state.automations.automationType;

export const getNotCorrectBirthdayCardList = (state: RootState): boolean =>
  state.automations.notCorrectBirthdayCardList;

export const getInternationalRecipient = (state: RootState): boolean =>
  state.automations.internationalRecipient;

export const getAddressIdsAutomation = (state: RootState): number[] =>
  state.automations.newCardInfo.address_ids;

export const getSelectedCardId = (state: RootState): number | null =>
  state.automations.newCardInfo.card_id;

export const getIsNoEndDate = (state: RootState): boolean =>
  state.automations.newCardInfo.noEndDate;

export const getSelectedCardOnEdit = (
  state: RootState
): IAutomationSelectCardData | null => state.automations.newCardInfo.card;
