import { userInstance } from "../hooks/instance/useInstance";
import {
  IDemographicInfo,
  IOutboundProcess,
  IOutboundAddToBasket,
} from "../redux/outbound-prospecting/types";

const outboundLeadsApi = {
  count: async (payload: IDemographicInfo) => {
    return userInstance.post("/outbound/calcTargets", payload);
  },
  process: async (payload: IOutboundProcess) => {
    return userInstance.post("/outbound/process", payload);
  },
  boundaryZip: async (payload: string) => {
    return userInstance.get(`/outbound/zip_boundary?zip=${payload}`);
  },
  industries: async () => {
    return userInstance.get(`/outbound/industries`);
  },
  outboundAddToBasket: async (payload: IOutboundAddToBasket) => {
    return userInstance.post("/outbound/addToBasket", payload);
  },
};

export default outboundLeadsApi;
