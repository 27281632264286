import React, { FC, useEffect, useState } from "react";
import { ModalConfirm } from "../../Modal/Modal";

import { Typography, Overline } from "../../ui/typography";
import { Input } from "../../ui/common/inputs/input.common";
import Button from "../../ui/buttons/button";
import { FlexContainer } from "../../ui/layout";

import useKeyPress from "../../../hooks/input/useKeypress";
import useFocus from "../../../hooks/input/useFocus";
import { useAppSelector } from "../../../hooks/redux/useRedux";

import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface ISavePopup {
  text: string;
  additionalText: string;
  onConfirm: (newTitle: string) => void;
  onCancel: () => void;
  isOpen: boolean;
  isAutomation?: boolean;
  defaultText?: string;
  placeholder?: string;
  errorMessage?: string;
  removeError?: () => void;
}

const SavePopupWithNameItem: FC<ISavePopup> = ({
  text,
  additionalText,
  onConfirm,
  onCancel,
  isOpen,
  placeholder = "Name your masterpiece",
  isAutomation,
  defaultText = "",
  errorMessage = "",
  removeError = () => {},
}) => {
  const [textName, setTextName] = useState<string>(defaultText || "");

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const handleKeypress = useKeyPress(
    "Enter",
    () => onConfirm(textName),
    !!textName
  );
  const { htmlElRef, setFocus } = useFocus();

  useEffect(() => {
    setFocus();
  }, []);

  return (
    <ModalConfirm open={isOpen} onClose={onCancel}>
      <FlexContainer
        align='flex-start'
        direction='column'
        padding='1.5rem'
        style={{ paddingTop: isMobile ? "0" : "1.5rem", minWidth: "40vw" }}
      >
        {isMobile ? null : (
          <>
            <FlexContainer padding={!isAutomation ? "0 0 1rem 0" : "0"}>
              <Typography
                fontSize={!isAutomation ? "1.88888rem" : "1.5rem"}
                fontWeight='300'
              >
                {text}
              </Typography>
            </FlexContainer>
            <FlexContainer>
              <Typography fontSize='0.8888rem' fontWeight='100'>
                {additionalText}
              </Typography>
            </FlexContainer>
          </>
        )}

        <FlexContainer
          direction='column'
          padding='.5rem 0 1.2rem 0'
          width='100%'
          onClick={removeError}
        >
          <Input
            className='popup__input'
            type='text'
            onChange={(e) => setTextName(e.target.value)}
            ref={htmlElRef!}
            placeholder={isMobile ? placeholder : ""}
            onKeyPress={handleKeypress}
            value={textName}
          />
          {!!errorMessage.length && (
            <span className='error-input'>{errorMessage}</span>
          )}
        </FlexContainer>

        <Button
          width='100%'
          onClick={() => onConfirm(textName)}
          disabled={!textName.trim()}
        >
          <Overline color='#fff'>Save</Overline>
        </Button>
      </FlexContainer>
    </ModalConfirm>
  );
};

export default SavePopupWithNameItem;
