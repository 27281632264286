import { useState } from "react";
import { validatePhoneNumberLength } from "libphonenumber-js/min";
import { isValidPhoneNumber } from "react-phone-number-input";
import phoneErrorMessage from "../../constants/sing-up/phone-error";
import validationRegex from "../../constants/validation";

type Inputs = Array<string>;

const VALIDATE_ERROR_CLASS = "validate__error";

const useForm = () => {
  const [phoneError, setPhoneError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const isEmpty = (value: string) => !value.trim().length;

  const checkEmptyInputs = (inputs: Inputs) => {
    return inputs.some((input) => isEmpty(input.toString()));
  };

  const isInputsEqual = (inputs: Inputs) => {
    return inputs.every((input) => input === inputs[0]);
  };

  const findEmptyElements = (elements: Array<HTMLInputElement> = []) => {
    return elements.flatMap((element) =>
      isEmpty(element.value) ? element.name : []
    );
  };

  const toggleValidateClass = (elements: Array<HTMLInputElement> = []) => {
    elements.forEach((element) =>
      isEmpty(element.value)
        ? element.classList.add(VALIDATE_ERROR_CLASS)
        : element.classList.remove(VALIDATE_ERROR_CLASS)
    );
  };

  const checkValidPhone = (phone: HTMLInputElement) => {
    if (phone.value) {
      const isValidPhone = isValidPhoneNumber(phone.value);

      if (!isValidPhone) {
        const validPhoneLength = validatePhoneNumberLength(phone.value);
        const errorMessage = validPhoneLength
          ? phoneErrorMessage[validPhoneLength]
          : "No valide phone number";

        setPhoneError(errorMessage);
        phone.classList.add(VALIDATE_ERROR_CLASS);
        return false;
      }

      setPhoneError("");
      phone.classList.remove(VALIDATE_ERROR_CLASS);
      return true;
    }

    setPhoneError("");
    phone.classList.remove(VALIDATE_ERROR_CLASS);
    return true;
  };

  const checkValidEmail = (email: HTMLInputElement) => {
    if (email.value) {
      if (!validationRegex.email.test(email.value)) {
        setEmailError("Email is invalid");
        email.classList.add(VALIDATE_ERROR_CLASS);
        return false;
      }

      setEmailError("");
      email.classList.remove(VALIDATE_ERROR_CLASS);
      return true;
    }

    setEmailError("");
    email.classList.remove(VALIDATE_ERROR_CLASS);
    return true;
  };

  return {
    checkEmptyInputs,
    isInputsEqual,
    findEmptyElements,
    toggleValidateClass,
    checkValidPhone,
    checkValidEmail,
    phoneError,
    emailError,
  };
};

export default useForm;
