import styled from "styled-components";

export const PopupBackground = styled.div`
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.1);

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
`;
export const PortalPopupContent = styled.div`
  padding: 0.5rem;
  position: relative;
  top: 0;
  bottom: 0;
  min-width: 22vw;
  margin: 1rem 0.5rem;
  height: fit-content;
  background-color: #ffff;

  @media (max-width: 599px) {
    width: 95vw;
  }
`;

export const MobileModalContent = styled.div`
  height: calc(100vh - 2.94444rem);
  width: 100vw;
  position: relative;
  top: 0;
  bottom: 0;
  min-width: 22vw;
  background-color: #f9f9f8;
`;

export const PopupContent = styled.div`
  overflow-y: auto;
  position: relative;
  top: 0;
  bottom: 0;

  margin: 1rem 0.5rem;

  max-height: 85vh;
  height: fit-content;

  background-color: #fff;

  max-width: max-content;

  @media (max-width: 599px) {
    width: 95vw;
  }
`;
export const CloseContainer = styled.div`
  position: absolute;

  top: 0;
  height: 50px;
  width: 60vw;

  z-index: 101;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 599px) {
    width: 95vw;
    top: 0.61111rem;
    right: 15px;
  }
`;

export const CloseButtonWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  right: -1rem;
  top: -0.4rem;
  display: flex;
  align-items: center;
  z-index: 10;
  justify-content: center;

  @media (max-width: 599px) {
    right: calc(50% - 0.94444rem);
    top: 0.1rem;
    width: auto !important;
    height: auto !important;
  }
`;
export const PopupContentConfirm = styled.div`
  overflow-y: scroll;
  position: fixed;
  top: 0rem;
  bottom: 0;

  max-height: 50vh;
  height: fit-content;

  background-color: #fff;

  width: 40vw;

  @media (max-width: 599px) {
    width: 95vw;
  }
`;

export const CloseContainerConfirm = styled.div`
  position: absolute;

  top: 0;
  height: 50px;
  width: 60vw;

  z-index: 101;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 599px) {
    width: 95vw;
    top: 10px;
    right: 15px;
  }
`;
