import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import sendFeedback from "./thunks";
import initialState from "./types";

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setEmailContactForm: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.email = payload;
    },
    setNameContactForm: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.name = payload;
    },
    setMessageContactForm: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.message = payload;
    },
    setSendMessage: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.isSendMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      sendFeedback.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        const { payload } = action;
        if (payload) {
          state.email = "";
          state.name = "";
          state.message = "";
          state.isSendMessage = true;
        } else {
          state.isSendMessage = false;
        }
      }
    );
  },
});

export const {
  setEmailContactForm,
  setNameContactForm,
  setMessageContactForm,
  setSendMessage,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
