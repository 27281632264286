import { FC } from "react";
import { MdError, MdWarning } from "react-icons/md";

import { FlexContainer } from "../../ui/layout";
import { Title } from "../../ui/typography";

import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface IBulkUploadHeaderTable {
  title: string;
  subtitle: string;
}

const BulkUploadHeaderTable: FC<IBulkUploadHeaderTable> = ({
  title,
  subtitle,
}) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  return (
    <FlexContainer
      direction='column'
      align='start'
      width={isMobile ? "100%" : "85%"}
    >
      <Title className='bulk-upload-table__title'>{title}</Title>
      <span className='bulk-upload-table__subtitle'>{subtitle}</span>
      {!isMobile && (
        <FlexContainer
          align='center'
          justify='center'
          style={{ fontWeight: 300 }}
        >
          <FlexContainer
            style={{
              width: "min-content",
              whiteSpace: "nowrap",
              marginRight: "1rem",
            }}
          >
            <MdError color='red' size={30} /> - Critical Error (To continue, you
            must to fix it)
          </FlexContainer>
          <FlexContainer>
            <MdWarning color='orange' size={30} /> - Warning (You can process
            anyway)
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default BulkUploadHeaderTable;
