import { FC } from "react";
import { useHistory } from "react-router-dom";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Typography } from "../../ui/typography";
import useUser from "../../../hooks/auth/useUser";
import navigation from "../../../constants/navigation";

interface IUserSubscription {
  hideSetting: () => void;
}

const UserSubscription: FC<IUserSubscription> = ({ hideSetting }) => {
  const { max_discount_subscriptions, user_subscription } = useUser();

  const history = useHistory();

  const navigateToSubscription = () => {
    history.push(navigation.subscriptions);
    hideSetting();
  };

  const navigateToPrepaidPlans = () => {
    history.push(navigation.prepaidPlans);
    hideSetting();
  };

  if (user_subscription)
    return (
      <ButtonAction
        className='account-settings__subscribe-button'
        hoverBgColor='transparent'
        hoverColor='#fff'
        onClick={navigateToPrepaidPlans}
        style={{ marginBottom: 0 }}
      >
        PREPAYMENT OPTIONS
      </ButtonAction>
    );

  return (
    <FlexContainer
      direction='column'
      className='account-settings__subscription-panel'
    >
      <Typography className='account-settings__subscription-label'>
        SAVE UP TO {Number(max_discount_subscriptions)}% WITH:
      </Typography>
      <FlexContainer direction='row'>
        <ButtonAction
          className='account-settings__subscribe-button'
          hoverBgColor='transparent'
          hoverColor='#fff'
          onClick={navigateToPrepaidPlans}
        >
          PREPAYMENT OPTIONS
        </ButtonAction>
        <p style={{ fontSize: "0.7rem", margin: "0 0.6rem" }}>or</p>
        <ButtonAction
          className='account-settings__subscribe-button'
          hoverBgColor='transparent'
          hoverColor='#fff'
          onClick={navigateToSubscription}
        >
          SUBSCRIPTION PLANS
        </ButtonAction>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserSubscription;
