import { FC } from "react";
import Lottie from "react-lottie";

import * as loaderJSONData from "../../../../assets/json/loader.json";

const CustomLoader: FC = () => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        // @ts-ignore
        animationData: loaderJSONData.default,
      }}
      height='100px'
      width='100px'
    />
  );
};

export default CustomLoader;
