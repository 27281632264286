const loadBeacon = () => {
  if (typeof document.Beacon === "undefined") {
    const script = document.createElement("script");
    script.id = "beacon";
    script.text = `!function(e,t,n){
        function a(){
          var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",
          n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}
          if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},
          n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}
          (window,document,window.Beacon||function(){});`;

    document.head.appendChild(script);

    window.Beacon("init", "a5f714b1-6a1e-4339-892e-fbd81f5a229c");
  }
};

export default loadBeacon;
