import { FC, useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";

import { Title } from "../../components/ui/typography";
import AuthHelp from "../../components/auth/auth.help";
import SignInForm from "../../components/auth/signin/signin.form";
import TitleSeparator from "../../components/title-separator/TitleSeparator";
import SignInSocial from "../../components/auth/signin/signin.social";
import { FlexContainer, TextLink } from "../../components/ui/layout";

import { closePopup } from "../../redux/portal-popup/slice";

import usePassword from "../../hooks/auth/usePassword";
import { useAppDispatch } from "../../hooks/redux/useRedux";

const SignIn: FC = () => {
  const { openPasswordResetFormPopup } = usePassword();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const correctLocation = useMemo(
    () => location.pathname.includes("/send"),
    [location]
  );
  const close = useCallback(
    () => dispatch(closePopup()),
    [dispatch, closePopup]
  );
  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex, follow' />
      </Helmet>
      <Title className='registration__title'>Sign In to Continue</Title>
      <FlexContainer
        direction='column'
        width={correctLocation ? "" : "16.222rem"}
        className='registration__form'
        align='center'
      >
        <SignInForm />
        <TextLink
          fontSize='.61111rem'
          className='registration__forgot'
          onClick={openPasswordResetFormPopup}
        >
          Forgot Password?
        </TextLink>
        <TitleSeparator
          title='OR'
          color='#444'
          style={{ margin: "1.5rem 0 0 0" }}
        />
        <SignInSocial />
        <TitleSeparator
          color='#444'
          style={{ marginTop: "1.3333rem", marginBottom: "0" }}
        />
        <FlexContainer width='100%' style={{ marginTop: "1.11rem" }}>
          <AuthHelp
            type='signin'
            correctLocation={correctLocation}
            close={close}
          />
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default SignIn;
