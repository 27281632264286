import React, {
  useMemo,
  useState,
  ChangeEvent,
  forwardRef,
  ReactNode,
} from "react";
import { BsEye } from "react-icons/bs";
import { useTheme } from "styled-components";

import { FlexContainer } from "../../layout";
import CancelSelectValueButton from "../input-cancel-btn/input-cancel.common";
import CharactersCounter from "./characters-counter";

import "./style.scss";

export interface IInput {
  placeholder?: string;
  type: string;
  value?: string | number;
  name?: string;
  className?: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void; // ? only for test
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  showPasswordIcon?: boolean;
  required?: boolean;
  disabled?: boolean;
  size?: number;
  isDisableAutocomplete?: boolean;
  creditCardIcon?: JSX.Element | null;
  showCreditCardIcon?: boolean;
  children?: ReactNode;
  clear?: () => void;
  search?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  align?: "left" | "right" | "center";
  additionalData?: any;
  max?: number;
  min?: number;
  inputClass?: string;
  title?: string;
  step?: number;
  styleForClearBtn?: React.CSSProperties;
  pattern?: string;
  fixedLabel?: string;
  fixedLabelStyle?: React.CSSProperties;
  icon?: JSX.Element | null;
  iconStyle?: React.CSSProperties;
  showCharactersCounter?: boolean;
}

export const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      type,
      name,
      className,
      style,
      inputStyle,
      isDisableAutocomplete,
      step,
      placeholder,
      value,
      onChange,
      onFocus,
      onBlur,
      required,
      disabled,
      title,
      min,
      onKeyPress,
      showCreditCardIcon,
      size,
      showPasswordIcon,
      creditCardIcon,
      children,
      clear,
      search,
      align,
      additionalData,
      max,
      inputClass = "",
      styleForClearBtn,
      fixedLabel,
      fixedLabelStyle,
      pattern,
      icon,
      iconStyle,
      showCharactersCounter,
    },
    ref
  ) => {
    const correctNewPasword = useMemo(() => {
      if (
        type === "password" ||
        type === "password-new-confirmation" ||
        type === "password-new"
      ) {
        return "password";
      }
      return type;
    }, [type]);

    const [inputType, setInputType] = useState(correctNewPasword);
    const { primary } = useTheme();

    const classInput: string = useMemo(() => {
      if (!className) return "input-c";
      return `input-c ${className}`;
    }, [className]);

    const onEyeClick = () => {
      if (inputType === "text") return setInputType("password");

      setInputType("text");
    };

    const _OnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (max) {
        e.target.value = Math.min(max, Number(e.target.value)).toString();
      }
      if (additionalData) additionalData(false);
      if (onChange) return onChange(e);
    };

    return (
      <label className={classInput} style={style} title={title}>
        {fixedLabel && <span style={fixedLabelStyle}>{fixedLabel}</span>}
        <input
          ref={ref}
          className={inputClass}
          autoComplete={isDisableAutocomplete ? "new-password" : "on"}
          placeholder={placeholder}
          type={inputType}
          value={value}
          onChange={(e) => _OnChange(e)}
          onFocus={onFocus!}
          onBlur={onBlur!}
          onKeyPress={onKeyPress}
          name={name}
          required={required}
          disabled={disabled}
          maxLength={size}
          style={{
            ...inputStyle,
            textAlign: align || "left",
            paddingRight: showPasswordIcon ? "3rem" : 0,
          }}
          max={max}
          min={min}
          pattern={pattern}
          step={step}
        />
        {icon && (
          <div
            style={{
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 50,
              margin: 0,
              cursor: "pointer",
              borderRadius: "50%",
              ...iconStyle,
            }}
            className='map-button'
          >
            {icon}
          </div>
        )}
        <CancelSelectValueButton
          onClick={clear!}
          active={!!value && !!search}
          style={styleForClearBtn || {}}
        />

        {showCreditCardIcon && (
          <FlexContainer className='show-bt' style={{ marginRight: "1rem" }}>
            {creditCardIcon}
          </FlexContainer>
        )}
        {correctNewPasword === "password" && showPasswordIcon && (
          <div className='show-bt' onClick={onEyeClick}>
            <BsEye
              color={inputType === "text" ? primary : "silver"}
              style={{
                opacity: 0.4,
              }}
            />
          </div>
        )}
        {showCharactersCounter && size ? (
          <CharactersCounter
            length={String(value)?.length || 0}
            maxLength={size}
          />
        ) : null}
        {children}
      </label>
    );
  }
);
