import { setPortalPopup } from "../../redux/portal-popup/slice";
import { useAppDispatch } from "../redux/useRedux";

const usePopup = () => {
  const dispatch = useAppDispatch();

  const openErrorPopup = (text: string) => {
    dispatch(
      setPortalPopup({
        text,
        typePopup: "alert",
        title: "OOPS!",
      })
    );
  };

  const openSuccessPopup = (text: string) => {
    dispatch(
      setPortalPopup({
        text,
        typePopup: "alert",
        title: "Success",
      })
    );
  };

  const openFormPopup = (title: string, form: string) => {
    dispatch(
      setPortalPopup({
        typePopup: "form",
        form,
        title,
      })
    );
  };

  return {
    openErrorPopup,
    openSuccessPopup,
    openFormPopup,
  };
};

export default usePopup;
