import { TransformAddress } from "../../interfaces/autocomplete/types";

const transformAddressComponent: TransformAddress = (acc, curr) => {
  const isPropIncludes = (x: string) => curr.types.includes(x);

  switch (true) {
    case isPropIncludes("street_number"):
      acc.street = curr.long_name;
      break;

    case isPropIncludes("route"):
      acc.route = curr.long_name;
      break;

    case isPropIncludes("locality"):
      acc.city = curr.long_name;
      break;

    case isPropIncludes("administrative_area_level_1"):
      acc.state = curr.short_name;
      break;

    case isPropIncludes("postal_code"):
      acc.zip = curr.long_name;
      break;
    case isPropIncludes("country"):
      acc.country = curr.long_name;
      break;
    default:
      break;
  }

  return acc;
};

export default transformAddressComponent;
