import React, { FC, useCallback, useEffect, useState } from "react";
import { BiEnvelope } from "react-icons/bi";
import classNames from "classnames";

import { Input } from "../../ui/common/inputs/input.common";
import { ButtonAction, FlexContainer, Form } from "../../ui/layout";

import useInput from "../../../hooks/input/useInput";
import useAuth from "../../../hooks/auth/useAuth";
import useCards from "../../../hooks/cards/useCards";
import useValidateForm from "../../../hooks/forms/useValidateForm";

import "../style.scss";

const SignInForm: FC = () => {
  const { signin } = useAuth();
  const { resetList } = useCards();
  const [passwordActivated, setPasswordActivated] = useState(false);

  const email = useInput("");
  const password = useInput("");

  const { errorFields, removeError, checkEmptyFields } = useValidateForm();

  useEffect(() => {
    resetList();
  }, []);

  const errorClassForInput = useCallback(
    (value: string): string => {
      return errorFields.includes(value) ? "error-field" : "";
    },
    [errorFields]
  );

  const passwordConfirmation = classNames({
    "error-field": passwordActivated && !password.value.length,
  });

  const signInUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = checkEmptyFields({
      email: email.value,
      password: password.value,
    });

    if (valid) {
      signin(event, { email: email.value, password: password.value });
    }
  };

  return (
    <Form onSubmit={signInUser}>
      <Input
        {...email}
        placeholder='Email Address'
        type='email'
        className={errorClassForInput("email")}
        onFocus={() => removeError("email")}
      />
      <Input
        style={{ marginTop: ".61111rem" }}
        {...password}
        placeholder='Password'
        type='password'
        showPasswordIcon
        className={passwordConfirmation}
        onFocus={() => removeError("password")}
        onBlur={() => setPasswordActivated(true)}
      />
      <ButtonAction style={{ marginTop: ".61111rem" }} type='submit'>
        <FlexContainer justify='center'>
          <BiEnvelope style={{ marginRight: ".655rem" }} size='1.5rem' />
          Sign In
        </FlexContainer>
      </ButtonAction>
    </Form>
  );
};

export default SignInForm;
