import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDimensions, initialState } from "./types";

const windowDimensionsSlice = createSlice({
  name: "windowDimensions",
  initialState,
  reducers: {
    setWindowDimensions: (state, action: PayloadAction<IDimensions>) => {
      state.window_dimensions = {
        ...state.window_dimensions,
        ...action.payload,
      };
    },
  },
});

export const { setWindowDimensions } = windowDimensionsSlice.actions;

export default windowDimensionsSlice.reducer;
