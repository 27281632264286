import React, { FC, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";
import { AuthorizationMethodType } from "../interfaces/auth/IAuth";
import { AuthProviderType } from "../interfaces/auth/IAuthProvider";
import { ICard } from "../interfaces/cards/ICard";

import mockData from "../mockMetaData.json";

interface ISeo {
  title?: string | null;
  description?: string | null;
  card?: ICard;
  isBulk?: boolean;
}
export const getSociialType = (
  field: AuthProviderType
): AuthorizationMethodType => {
  switch (field) {
    case "google":
      return "social_google";
    case "facebook":
      return "social_facebook";
    case "apple":
      return "social_apple";
    default:
      return "e-mail";
  }
};

export const defaultTitle = "Handwrytten";
export const defaultDescription =
  "Handwrytten is the global leading provider of robotic handwriting services. Using our patent-pending robots, we make sending notes as easy as sending email. All notes are written in pen.Gift cards and other inserts can be included.";

// @ts-ignore
export const SEO: FC<ISeo> = ({ title, description, card, isBulk = false }) => {
  const isPreRendering = navigator.userAgent === "ReactSnap";
  const history = useHistory();

  const sendMetaTitle = useMemo(() => {
    if (card) {
      if (isBulk) {
        return `${card?.category?.name} - Bulk Send ${card?.name}`;
      }
      return `${card?.category?.name} - Send ${card?.name}`;
    }
    if (!title) return defaultTitle;
    return title;
  }, [card, isBulk, title, history.location.pathname]);

  const correctMockDate = useMemo(() => {
    return mockData.find((el) => el.path === history.location.pathname);
  }, [mockData, history.location.pathname]);

  const metaDescription = useMemo(() => {
    if (!description) return defaultDescription;
    return description;
  }, [description, history.location.pathname]);

  const categoryRerender = useMemo(() => {
    return (
      history.location.pathname.includes("category") &&
      !title &&
      !card &&
      !!correctMockDate
    );
  }, [history.location.pathname, title, card, correctMockDate]);

  return (
    <Helmet
      // @ts-ignore
      prioritizeSeoTags
      meta={[
        {
          name: `description`,
          // @ts-ignore
          content:
            isPreRendering || categoryRerender
              ? correctMockDate?.meta_description
              : metaDescription,
        },
      ]}
      // @ts-ignore
      title={
        isPreRendering || categoryRerender
          ? correctMockDate?.title
          : sendMetaTitle
      }
      htmlAttributes={{ lang: "en" }}
    />
  );
};
