import { FC } from "react";

import { ModalConfirm } from "../../Modal/Modal";
import PortalPopupAlert from "../../ui/common/portal-popup/portal-popup.alert";
import { FlexContainer } from "../../ui/layout";
import { Title } from "../../ui/typography";
import { useAppSelector } from "../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

interface IAiErrorPopupInterface {
  isOpen: boolean;
  message: string | null;
  onClose: () => void;
}

const AiErrorPopup: FC<IAiErrorPopupInterface> = ({
  isOpen,
  message = "",
  onClose,
}) => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  return (
    <ModalConfirm open={isOpen} onClose={onClose}>
      <div className='popup'>
        <FlexContainer
          style={{ marginBottom: isMobile ? "0.61111rem" : "2rem" }}
        >
          <Title>OOPS!</Title>
        </FlexContainer>
        <PortalPopupAlert text={message!} onClick={onClose} />
      </div>
    </ModalConfirm>
  );
};

export default AiErrorPopup;
