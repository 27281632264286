import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./types";
import { getApiKeys, deleteApiKeys, addApiKeys } from "./thunks";

const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApiKeys.fulfilled, (state, { payload }) => {
      state.apiKeysList = payload as any;
    });
    builder.addCase(deleteApiKeys.fulfilled, (state, { payload }) => {
      state.apiKeysList = state.apiKeysList!.filter(
        (value) => value?.id !== payload?.id
      );
    });
    builder.addCase(addApiKeys.fulfilled, (state, { payload }) => {
      const { api_key } = payload;
      state.apiKeysList = state.apiKeysList?.concat(api_key);
    });
  },
});

export default apiKeysSlice.reducer;
