import React, { FC, useMemo } from "react";
import { Typography } from "../../../typography";
import { FlexContainer } from "../../../layout";

interface ICheckListAdditionalText {
  isRecipient: boolean;
  show: boolean;
  order: boolean;
  tagsViewInScrollContainer: boolean;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckListAdditionalText: FC<ICheckListAdditionalText> = ({
  tagsViewInScrollContainer,
  order,
  show,
  setShowMore,
  isRecipient,
}) => {
  const showAdditionalText = useMemo(() => {
    return isRecipient
      ? `Show ${show ? "Less" : "More"} Tags`
      : `Show ${show ? "Less" : "More"} Industries Type List`;
  }, [isRecipient, show]);

  return (
    <FlexContainer
      justify={order ? "flex-start" : "flex-end"}
      style={
        tagsViewInScrollContainer
          ? {
              position: "absolute",
              top: show ? "0" : "80%",
              height: "1rem",
            }
          : {}
      }
    >
      <Typography
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          fontSize: ".66rem",
          margin: ".5rem",
          color: "red",
        }}
        onClick={() => setShowMore(!show)}
      >
        {showAdditionalText}
      </Typography>
    </FlexContainer>
  );
};

export default CheckListAdditionalText;
