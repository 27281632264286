import { FC, useEffect } from "react";

import { Input } from "../../ui/common/inputs/input.common";
import { ButtonAction, FlexContainer } from "../../ui/layout";

import useTemplates from "../../../hooks/templates/useTemplates";
import { useAppSelector } from "../../../hooks/redux/useRedux";

import useInput from "../../../hooks/input/useInput";
import useKeyPress from "../../../hooks/input/useKeypress";
import useFocus from "../../../hooks/input/useFocus";

import { selectCreateCandidateTemplate } from "../../../redux/templates/selectors";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const SaveTemplate: FC = () => {
  const template = useAppSelector(selectCreateCandidateTemplate);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const { saveActiveTemplateSubmit } = useTemplates();
  const name = useInput("");

  const handleKeypress = useKeyPress(
    "Enter",
    () => saveActiveTemplateSubmit(name.value, template!),
    !!name.value
  );
  const { htmlElRef, setFocus } = useFocus();

  useEffect(() => {
    setFocus();
  }, []);

  return (
    <FlexContainer
      align='stretch'
      justify='flex-start'
      direction='column'
      style={{
        minWidth: isMobile ? "14rem" : "29rem",
        padding: ".56rem .6rem",
      }}
    >
      <Input
        ref={htmlElRef!}
        placeholder='Name of your new template'
        required
        type='text'
        align='center'
        {...name}
        style={{
          width: "100%",
        }}
        onKeyPress={handleKeypress}
      />

      <ButtonAction
        style={{ marginTop: isMobile ? ".5rem" : "1.5rem" }}
        type='submit'
        onClick={() => saveActiveTemplateSubmit(name.value, template!)}
      >
        Create template
      </ButtonAction>
    </FlexContainer>
  );
};

export default SaveTemplate;
