import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "./types";
import getCardById from "./thunks";

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setSendData: (state, action: PayloadAction<number>) => {
      state.sendDate = action.payload;
    },
    setActiveOrder: (state, action) => {
      state.activeOrder = { ...state.activeOrder, ...action.payload };
    },
    setValidations: (state, action) => {
      state.validations = action.payload;
    },
    setActiveOrderEmpty: (state) => {
      state.activeOrder = null;
    },
    setActiveCardEmpty: (state) => {
      state.card = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCardById.fulfilled, (state, { payload }) => {
      state.card = payload;
      state.isLoader = false;
    });
    builder.addCase(getCardById.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(getCardById.rejected, (state) => {
      state.isLoader = false;
    });
  },
});

export const {
  setSendData,
  setActiveOrder,
  setValidations,
  setActiveCardEmpty,
  setActiveOrderEmpty,
} = orderSlice.actions;

export default orderSlice.reducer;
