import { userInstance } from "../hooks/instance/useInstance";
import { IHolidaysResponse } from "../interfaces/holidays/IHolidays";

const holidaysApi = {
  list: async () => {
    return userInstance.get<IHolidaysResponse>("/orders/getHolidays");
  },
};

export default holidaysApi;
