import { FC } from "react";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Typography } from "../../ui/typography";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import { closePopup } from "../../../redux/portal-popup/slice";
import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";

const InvalidCouponPopUp: FC = () => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  return (
    <FlexContainer
      align={isMobile ? "center" : "flex-start"}
      justify='center'
      direction='column'
    >
      <Typography fontSize='0.88888rem' style={{ margin: "0.3rem" }}>
        Sorry! That coupon code is not valid.
      </Typography>
      <Typography fontSize='0.88888rem' style={{ margin: "0.3rem" }}>
        Please try again
      </Typography>
      <ButtonAction
        width={isMobile ? "100%" : "max-content"}
        onClick={() => dispatch(closePopup())}
        style={{ marginTop: "1rem", padding: "1rem" }}
      >
        TRY AGAIN
      </ButtonAction>
    </FlexContainer>
  );
};

export default InvalidCouponPopUp;
