import { userInstance } from "../hooks/instance/useInstance";
import { IFeedback } from "../interfaces/feedback/IFeedback";

const feedbackApi = {
  send: async (payload: IFeedback) => {
    return userInstance.post("/default/feedBack", payload);
  },
};

export default feedbackApi;
