import React, { memo, FC } from "react";
import Lottie from "react-lottie";

import * as paperPlaneData from "../../assets/json/paper-plane.json";

import "./style.scss";

interface IAirplaneLottie {
  loop?: boolean;
  autoplay?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const AirplaneLottie: FC<IAirplaneLottie> = ({
  loop = false,
  autoplay = true,
  style,
  className,
}) => {
  return (
    <div className={`paper-airplane ${className || ""}`} style={style}>
      <Lottie
        options={{
          loop,
          autoplay,
          // @ts-ignore
          animationData: paperPlaneData.default,
        }}
        height='100%'
        width='100%'
      />
    </div>
  );
};

export default memo(AirplaneLottie);
