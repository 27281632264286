import { FC, useEffect, useMemo, useRef } from "react";
import Masonry from "react-masonry-component";

import Carousel from "../../carousel";
import CardItem from "../../cards/card/card.item";
import CustomSelect from "../../custom-select/CustomSelect";
import { FlexContainer } from "../../ui/layout";
import { Title, Typography } from "../../ui/typography";

import useCategories from "../../../hooks/categories/useCategories";
import useSelect from "../../../hooks/input/useSelect";
import useInfiniteScroll from "../../../hooks/utils/useInfiniteScroll";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import {
  getCardsByCategory,
  getCurrentPageCategory,
  getSearchCards,
  selectIsCardsListEndCategory,
  selectSections,
} from "../../../redux/cards/selectors";
import {
  getCardsListByCategory,
  getCardsSections,
} from "../../../redux/cards/thunks";
import getCategories from "../../../redux/categories/selectors";
import {
  setInitCardsCategory,
  setInitCardsSection,
  setPage,
} from "../../../redux/cards/slice";

import { ICard } from "../../../interfaces/cards/ICard";
import {
  setActiveStep,
  resetActiveStepId,
} from "../../../redux/multi-step/slice";
import { getActiveStepId } from "../../../redux/multi-step/selectors";
import { closePopup } from "../../../redux/portal-popup/slice";
import HomeSearch from "../../home/home.search";
import {
  getIsMobileWindowDimensions,
  getIsTabletWindowDimensions,
  getWidthWindowDimensions,
} from "../../../redux/window-dimensions/selectors";

import "./style.scss";

const SelectCardPopup: FC = () => {
  const dispatch = useAppDispatch();

  const cardsByCategory = useAppSelector(getCardsByCategory);
  const categories = useAppSelector(getCategories);
  const search = useAppSelector(getSearchCards);
  const {
    isLoading,
    cards: cardsSections,
    isListEnd,
    nextPage,
  } = useAppSelector(selectSections);
  const nextPageCategory = useAppSelector(getCurrentPageCategory);
  const isListEndCategory = useAppSelector(selectIsCardsListEndCategory);
  const activeStepId = useAppSelector(getActiveStepId);
  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const width = useAppSelector(getWidthWindowDimensions);
  const isTablet = useAppSelector(getIsTabletWindowDimensions);

  const correctCardsByCategory = useMemo(() => {
    return cardsByCategory.filter(
      (el) => el.category_taxonomy !== "CUSTOMIZED"
    );
  }, [cardsByCategory]);

  const initialCategoryId = String(categories[0]?.id || -1);
  const categorySelect = useSelect(initialCategoryId);
  const {
    categories: { commonCategoriesList },
  } = useCategories();

  const listRef = useRef(null);
  const listEndRef = useRef(null);

  const isAllCategories = categorySelect.value === "-1";

  const currentCategory = categories.find(
    (cat) => cat.id === +categorySelect.value
  );

  const categoryOptions = commonCategoriesList.map(({ name, id }) => ({
    id,
    label: name,
    value: id.toString(),
    alt: !id && id !== 0 ? "FILTER BY CATEGORY" : "",
  }));

  const getCardByCategoryWidth = () => {
    if (isMobile) return "100%";
    if (isTablet) return "45%";
    if (width < 1200) return "33%";
    return "25%";
  };

  const getSectionsList = async (isInitial?: boolean) => {
    const firstRenderList = 2;

    dispatch(setPage(nextPage));

    await dispatch(
      getCardsSections({
        nextPage: isInitial ? 1 : nextPage,
        limit: firstRenderList,
      })
    );
  };

  const getSectionByCategoryId = async (id: number, isInitial?: boolean) => {
    const limit = 10;

    if (isInitial) dispatch(setInitCardsCategory());

    await dispatch(
      getCardsListByCategory({
        categoryId: id,
        nextPageCategory: isInitial ? 1 : nextPageCategory + 1,
        limit,
        search: search || "",
      })
    );
  };

  const onListEnd = () => {
    if (isAllCategories && !search) {
      if (isLoading || isListEnd || !cardsSections.length) return;
      getSectionsList();
    } else {
      if (nextPageCategory === 0 || isListEndCategory || isLoading) return;
      getSectionByCategoryId(+categorySelect.value);
    }
  };

  useEffect(() => {
    if (isAllCategories && !search) {
      getSectionsList(true);
      return;
    }
    if (!search && !!cardsByCategory.length) {
      getSectionByCategoryId(+categorySelect.value, false);
    }
  }, [search, isAllCategories]);

  useEffect(() => {
    if (!categorySelect.value || isAllCategories) return;
    getSectionByCategoryId(+categorySelect.value, true);
  }, [categorySelect.value, isAllCategories]);

  useEffect(() => {
    // reset cards list on unmount
    return () => {
      dispatch(setInitCardsSection());
    };
  }, []);

  const onSelectCard = (card: ICard) => {
    dispatch(
      setActiveStep({
        id: activeStepId,
        card_id: card.id,
        card,
      })
    );
    dispatch(resetActiveStepId());
    dispatch(closePopup());
  };

  useInfiniteScroll(listRef, listEndRef, onListEnd);

  return (
    <FlexContainer direction='column' className='select-card-container'>
      <FlexContainer
        direction={isMobile ? "column" : "row"}
        className='select-card-container__header'
        style={{ width: "100%", paddingRight: 0 }}
      >
        <Title className='popup-header-title'>Select a Card</Title>
        <FlexContainer
          direction={isMobile ? "column" : "row"}
          style={{ alignItems: "center", marginBottom: 0 }}
          className={!isMobile ? "category-select" : "category-select-block"}
        >
          {!isMobile ? (
            <Typography fontSize='0.8rem' fontWeight='600'>
              Category:
            </Typography>
          ) : null}
          <CustomSelect
            containerClass='selector-item'
            style={{
              width: "30%",
            }}
            options={categoryOptions}
            select={categorySelect}
            onChange={categorySelect.onChange}
          />

          <HomeSearch
            style={{
              width: "30%",
            }}
            isOutbound
            category={+categorySelect.value}
          />
        </FlexContainer>
      </FlexContainer>

      <div className='cards-list  scroll-container'>
        <div
          className='sections-container'
          style={!isAllCategories ? { marginTop: 0 } : {}}
        >
          {!cardsSections.length && !!currentCategory ? (
            <Masonry disableImagesLoaded={false} updateOnEachImageLoad={false}>
              {correctCardsByCategory.map((card) => {
                const {
                  id,
                  name,
                  price,
                  discount_price,
                  cover,
                  isFavorite,
                  orientation,
                  allow_edit,
                  detailed_images,
                } = card;
                const backImage = detailed_images?.back?.image || "";
                const insideImage = detailed_images?.inside?.image || "";
                return (
                  <CardItem
                    masonry
                    key={id}
                    id={id}
                    widthCard={getCardByCategoryWidth()}
                    categoryId={+categorySelect.value}
                    name={name}
                    price={price}
                    discount_price={discount_price}
                    cover={cover!}
                    backImage={backImage}
                    isFavorite={isFavorite}
                    insideImage={insideImage}
                    isPreview={false}
                    orientation={orientation}
                    allowEdit={!!allow_edit}
                    onSelectCard={() => onSelectCard(card)}
                    isVisibleByDefault
                  />
                );
              })}
            </Masonry>
          ) : (
            cardsSections.map((section) => {
              const correctCards = section.value.filter(
                (el) => el.category_taxonomy !== "CUSTOMIZED"
              );
              return (
                <Carousel
                  key={section.id}
                  title={section.key}
                  cards={correctCards}
                  categoryId={section.id}
                  totalItems={section?.total_items!}
                  onSelectCard={(_, card) => onSelectCard(card)}
                  isAllCategories
                  isSelectCardPopup
                />
              );
            })
          )}
          <div ref={listEndRef} style={{ height: 50 }} />
        </div>
      </div>
    </FlexContainer>
  );
};

export default SelectCardPopup;
