import { configureStore, combineReducers } from "@reduxjs/toolkit";

import auth from "./auth/slice";
import cards from "./cards/slice";
import portalPopups from "./portal-popup/slice";
import categories from "./categories/slice";
import profile from "./profile/slice";
import recipients from "./recipients/slice";
import recipientTags from "./recipient-tags/slice";
import customFields from "./custom-fields/slice";
import address from "./address/slice";
import countries from "./countries/slice";
import creditCards from "./credit-cards/slice";
import fonts from "./fonts/slice";
import templates from "./templates/slice";
import customCards from "./custom-cards/slice";
import basket from "./basket/slice";
import orders from "./orders/slice";
import preview from "./preview/slice";
import giftCards from "./gift-cards/slice";
import inserts from "./inserts/slice";
import spinner from "./spinner/slice";
import signatures from "./signatures/slice";
import order from "./order/slice";
import subscriptions from "./subscriptions/slice";
import newMessage from "./new-message/slice";
import coupon from "./coupon/slice";
import holidays from "./holidays/slice";
import checkAuth from "./checkauth/slice";
import shipping from "./shipping/slice";
import feedback from "./feedback/slice";
import notifications from "./notifications/slice";
import hubspot from "./hubspot/slice";
import automations from "./automation/slice";
import outboundLeads from "./outbound-prospecting/slice";
import tags from "./tags/slice";
import apiKeys from "./api-keys/slice";
import ai from "./ai/slice";
import multiSteps from "./multi-step/slice";
import prepaidPlans from "./prepaid-plans/slice";
import reports from "./reports/slice";
import windowDimensions from "./window-dimensions/slice";

const combinedReducer = combineReducers({
  auth,
  cards,
  categories,
  profile,
  address,
  recipients,
  recipientTags,
  automations,
  countries,
  creditCards,
  shipping,
  fonts,
  templates,
  customCards,
  basket,
  orders,
  preview,
  giftCards,
  inserts,
  spinner,
  signatures,
  order,
  subscriptions,
  newMessage,
  coupon,
  holidays,
  checkAuth,
  portalPopups,
  feedback,
  hubspot,
  notifications,
  outboundLeads,
  tags,
  apiKeys,
  ai,
  multiSteps,
  prepaidPlans,
  reports,
  windowDimensions,
  customFields,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout/fulfilled") {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
