import {
  IPrepaidPlan,
  IRange,
} from "../../interfaces/subscriptions/IPrepaidPlans";

interface IPrepaidPlansState {
  prepaidPlans: IPrepaidPlan[];
  range: IRange;
  successMessage: string | null;
}

const initialState: IPrepaidPlansState = {
  prepaidPlans: [],
  range: {
    from: null,
    to: null,
  },
  successMessage: null,
};
export default initialState;
