import { useCallback, useEffect, useMemo } from "react";

import { getCountries } from "../../redux/countries/selectors";
import { fetchCountries } from "../../redux/countries/thunks";
import { useAppDispatch, useAppSelector } from "../redux/useRedux";

import { ICountry, ICountryToList } from "../../interfaces/countries/ICountry";
import { IState } from "../../interfaces/countries/IState";

const useCountries = (selectCountry?: string) => {
  const dispatch = useAppDispatch();

  const countries = useAppSelector(getCountries);

  useEffect(() => {
    if (countries.length) return;

    dispatch(fetchCountries());
  }, [countries.length]);

  const _mapCountries = (country: ICountry) => {
    const { name, id } = country;

    return {
      label: name,
      value: id.toString(),
      id,
    };
  };

  const _mapStates = (state: IState, index: number) => {
    const { short_name } = state;

    return {
      label: short_name,
      value: short_name,
      id: index,
    };
  };

  const countriesToList: ICountryToList[] = useMemo(() => {
    return countries.map(_mapCountries);
  }, [countries]);

  const countryState = useMemo(() => {
    return !!countries.filter(
      (country) => country.id.toString() === selectCountry
    )[0]?.states.length;
  }, [selectCountry, countries]);

  const statesToList = useCallback(
    (countryId?: string) => {
      if (!countryId) return;
      const country = countries.find(({ id }) => id === Number(countryId));

      if (country?.states.length) {
        return country.states.map(_mapStates);
      }

      return [];
    },
    [countries]
  );

  const getCountryIdByName = (countryName: string) => {
    return countriesToList.find((country) => country.label === countryName)?.id;
  };

  return {
    countriesToList,
    statesToList,
    countryState,
    getCountryIdByName,
  };
};

export default useCountries;
