import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { closePopup } from "../portal-popup/slice";
import automatonsApi from "../../api/automatons.api";
import {
  IAddAutomation,
  IBirthdayCardsData,
  IPageAllAutomation,
} from "./types";
import {
  IDefaultAutomation,
  IAutomationId,
  IAutomationResponse,
  // IAutomationByIdResponse,
  IUpdateAutomationResponse,
} from "../../interfaces/automation/automation";
import { automationTypesConstant } from "../../constants/automation-compain/automation-types";

export const addAutomation = createAsyncThunk<
  IAutomationResponse | undefined,
  IAddAutomation
>("automation", async (card, { rejectWithValue, dispatch }) => {
  try {
    const res = await automatonsApi.add(card);

    if (res) {
      dispatch(closePopup());

      return {
        item: res.data.item,
      };
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

    throw err;
  }
});

export const updateAutomation = createAsyncThunk<
  IUpdateAutomationResponse | undefined,
  IAddAutomation
>("automation", async (card, { rejectWithValue, dispatch }) => {
  try {
    const res = await automatonsApi.update(card);

    if (res) {
      dispatch(closePopup());

      return {
        id: res.data.id,
      };
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

    throw err;
  }
});

export const getAutomationList = createAsyncThunk<
  IBirthdayCardsData,
  IPageAllAutomation
>("automation/list", async (props: IPageAllAutomation) => {
  const res = await automatonsApi.list(props);

  const { rows, total_pages, page } = res.data;

  return { cards: rows, pages: total_pages, page };
});

export const getAutomation = createAsyncThunk<any, IAutomationId>(
  "automation/card",
  async (card_id) => {
    const res = await automatonsApi.card(card_id);
    const { details } = res.data;
    return { details };
  }
);

export const deleteAutomation = createAsyncThunk(
  "automation/delete",
  async (id: IAutomationId) => {
    await automatonsApi.delete(id);

    return {
      id,
    };
  }
);

export const checkDefaultAutomation = createAsyncThunk<
  IDefaultAutomation,
  automationTypesConstant
>(
  "automation/checkDefaultAutomation",
  async (type: automationTypesConstant) => {
    const res = await automatonsApi.checkDefaultAutomation(type);

    return {
      exist: !!res?.data?.exist,
    };
  }
);
