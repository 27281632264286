import { userInstance } from "../hooks/instance/useInstance";
import {
  IEnrollPayload,
  IMultiCampaignPayload,
  IPaginationPayload,
} from "../redux/multi-step/types";

const multiStepApi = {
  getAllCampaigns: async (payload: IPaginationPayload) => {
    const page = payload.page ? `&page=${payload.page}` : "";
    const search = payload.searchQuery ? `&search=${payload.searchQuery}` : "";
    const limit = payload.limit ? `&limit=${payload.limit}` : `&limit=6`;
    return userInstance.get(`/multiCampaign?${page}${limit}${search}`);
  },
  removeCampaign: async (id: number) => {
    const response = await userInstance.delete(`/multiCampaign/${id}`);
    return response;
  },
  createCampaign: async (payload: IMultiCampaignPayload) => {
    const response = await userInstance.post(`/multiCampaign`, payload);
    return response;
  },
  getCampaignById: async (id: number) => {
    const response = await userInstance.get(`/multiCampaign/${id}`);
    return response;
  },
  updateCampaign: async (payload: IMultiCampaignPayload) => {
    const response = await userInstance.put(
      `/multiCampaign/${payload.id}`,
      payload
    );
    return response;
  },
  updateCampaignAddresses: async (payload: IEnrollPayload) => {
    const { id, return_address_id, address_ids } = payload;
    const response = await userInstance.put(`/multiCampaign/${id}/addresses`, {
      return_address_id,
      address_ids,
    });
    return response;
  },
};

export default multiStepApi;
