import { RootState } from "../store";
import { recipientsAdapter } from "./entity";

export const getEditRecipientId = (state: RootState) => state.recipients.editId;
export const selectRecipients = (state: RootState) => state.recipients;
export const selectUpdateRecipients = (state: RootState) =>
  state.recipients.updateRecipient;
export const recipientLoader = (state: RootState) =>
  state.recipients.isLoadingRecipientList;

export const newRecipientAddressLength = (state: RootState) =>
  state.recipients.newAddress;

export const getRecipientsSortParams = (state: RootState) =>
  state.recipients.sortParams;

export const getAdvancedFilters = (state: RootState) =>
  state.recipients.advancedFilters;

export const getRecipientsId = (state: RootState) =>
  state.recipients.selectedRecipientsId;

export const {
  selectAll: selectAllRecipients,
  selectById: selectRecipientById,
  selectIds: selectRecipientsIds,
} = recipientsAdapter.getSelectors((state: RootState) => state.recipients);
