import { IHolidays } from "../../interfaces/holidays/IHolidays";

interface IHolidaysState {
  holidays: Array<IHolidays>;
}

const initialState: IHolidaysState = {
  holidays: [],
};

export default initialState;
