import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { modalWindows } from "../../components/ui/common/portal-popup/portal-popup.common";
import navigation from "../../constants/navigation";
import messagePopup from "../../constants/popup/constants";
import { ConfirmationPopupContext } from "../../context/confirmation-popup.provider";
import { PreventNavigationContext } from "../../context/preventNavigation.context";
import { setErrorQuantityInsert } from "../../redux/basket/slice";
import { getAutomationStep } from "../../redux/automation/selectors";
import { setActiveAutomationStep } from "../../redux/automation/slice";
import {
  setActiveCardEmpty,
  setActiveOrderEmpty,
} from "../../redux/order/slice";
import {
  setDefaultEditOrder,
  setDefaultEditOrderId,
} from "../../redux/orders/slice";
import { getActiveStep } from "../../redux/outbound-prospecting/selectors";
import {
  setActiveStep,
  setOutboundCardIdEmpty,
} from "../../redux/outbound-prospecting/slice";
import { getPortalPopupData } from "../../redux/portal-popup/selectors";
import { closePopup } from "../../redux/portal-popup/slice";

import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import {
  resetEditCampaign,
  resetNewCampaignData,
} from "../../redux/multi-step/slice";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

const useNavigation = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const activePortalPopup = useAppSelector(getPortalPopupData);
  const automationStep = useAppSelector(getAutomationStep);
  const outboundStep = useAppSelector(getActiveStep);

  const [previousPagePathname, setPreviousPagePathname] = useState<
    string | null
  >(null);

  const {
    activePopup: activeConfirmationPopup,
    closeConfirmationPopup,
    openConfirmationPopup,
  } = useContext(ConfirmationPopupContext);

  const { isNavigationPrevented, setPreventNavigation } = useContext(
    PreventNavigationContext
  );

  const isModal = modalWindows.some((modal) => modal === activePortalPopup);

  const isPopupPreventNavigation = useMemo(() => {
    // if is mobile version and active popup is modal or confirmation popup - navigation back must be blocked
    return isMobile && (isModal || !!activeConfirmationPopup);
  }, [isMobile, isModal, activeConfirmationPopup]);

  const shouldBlockNavigation = useMemo(
    () =>
      isPopupPreventNavigation || isNavigationPrevented || automationStep > 1,
    [isPopupPreventNavigation, isNavigationPrevented, automationStep]
  );

  const setDefaultOrder = () => {
    dispatch(setActiveOrderEmpty());
    dispatch(setDefaultEditOrder());
    dispatch(setDefaultEditOrderId());
    dispatch(setErrorQuantityInsert(false));
  };

  const clearStateHandler = (currentPage: string, path: string) => {
    if (currentPage === navigation.completeOrder) {
      if (!path.includes("send")) {
        setDefaultOrder();
      }
    }
    if (currentPage.includes("send")) {
      setDefaultOrder();
    }
    if (currentPage.includes(navigation.bulkSend)) {
      dispatch(setErrorQuantityInsert(false));
    }
    if (
      currentPage.includes(navigation.addNewAutomationCards) &&
      !path.includes(navigation.addNewAutomationCards)
    ) {
      dispatch(setActiveOrderEmpty());
      dispatch(setActiveCardEmpty());
      dispatch(setErrorQuantityInsert(false));
    }
    if (
      currentPage.includes(navigation.editAutomations) &&
      !path.includes(navigation.editAutomations)
    ) {
      dispatch(setActiveOrderEmpty());
      dispatch(setActiveCardEmpty());
      dispatch(setErrorQuantityInsert(false));
    }
    if (
      currentPage.includes(navigation.outboundProspecting) &&
      !path.includes(navigation.outboundProspecting)
    ) {
      dispatch(setActiveOrderEmpty());
      dispatch(setActiveCardEmpty());
      dispatch(setOutboundCardIdEmpty());
      dispatch(setErrorQuantityInsert(false));
    }
    if (currentPage.includes(navigation.addressBook)) {
      dispatch(resetEditCampaign());
      dispatch(resetNewCampaignData());
    }
  };

  const getIsBackAction = (previousPage: string | null, nextPage: string) => {
    if (previousPage === nextPage) return true;

    if (location.pathname === navigation.addNewAutomationCards) {
      return nextPage === navigation.addNewAutomationCards;
    }

    if (location.pathname.includes(navigation.editAutomations)) {
      return nextPage.includes(navigation.editAutomations);
    }

    if (location.pathname === navigation.outboundProspecting) {
      return nextPage === navigation.outboundProspecting;
    }
  };

  const onLeave = useCallback(
    (path: string) => {
      // if path equal previous page it means that history action is goBack
      const isGoBackAction = getIsBackAction(previousPagePathname, path);
      history.block(() => undefined);
      setPreventNavigation(false);

      // clear page state when leave
      clearStateHandler(location.pathname, path);

      // special actions for the addNewBirthdayCards page
      if (
        location.pathname === navigation.addNewAutomationCards &&
        isGoBackAction
      ) {
        if (automationStep > 1) {
          return dispatch(
            setActiveAutomationStep({ activeStep: automationStep - 1 })
          );
        }
      }
      if (
        location.pathname.includes(navigation.editAutomations) &&
        isGoBackAction
      ) {
        if (automationStep > 1) {
          return dispatch(
            setActiveAutomationStep({ activeStep: automationStep - 1 })
          );
        }
      }

      // special actions for the outboundProspecting page
      if (
        location.pathname === navigation.outboundProspecting &&
        isGoBackAction
      ) {
        if (outboundStep! > 1) {
          return dispatch(setActiveStep({ activeStep: outboundStep! - 1 }));
        }
      }

      if (isGoBackAction) {
        return history.goBack();
      }

      history.push(path);
    },
    [
      location.pathname,
      automationStep,
      previousPagePathname,
      setActiveStep,
      outboundStep,
      automationStep,
      getIsBackAction,
    ]
  );

  useEffect(() => {
    return () => {
      // save the previous page when we leave the current one
      setPreviousPagePathname(location.pathname);
    };
  }, [location.pathname]);

  useEffect(() => {
    const unblock = history.block((location: any) => {
      // check if any modal was active at the time of the navigation attempt
      const modalWasActive = isPopupPreventNavigation;

      // close modal
      dispatch(closePopup());

      // close confirmation popup
      closeConfirmationPopup();
      if (isNavigationPrevented && !modalWasActive) {
        openConfirmationPopup({
          subtitle: messagePopup.leavePageConfirmationMessage,
          onConfirm: () => onLeave(location.pathname),
        });
      }

      return shouldBlockNavigation ? false : undefined;
    });

    return () => {
      unblock();
    };
  }, [
    shouldBlockNavigation,
    isNavigationPrevented,
    isPopupPreventNavigation,
    location.pathname,
    previousPagePathname,
    automationStep,
    outboundStep,
  ]);
};

export default useNavigation;
