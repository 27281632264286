import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PastOrdersList } from "../../interfaces/orders/IPastOrder";
import { basketAdapter, initialState } from "./entity";

import {
  basketChilds,
  basketCount,
  basketRemove,
  listBasketGrouped,
  basketItemFromAddress,
  basketClear,
  basketSend,
} from "./thunks";

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    clearCount: (state) => {
      state.count = 0;
    },
    updateCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    clearBasketItem: (state) => {
      state.items = [];
    },
    clearAll: (state) => {
      basketAdapter.setAll(state, []);
    },
    setSpecialRequests: (state, action: PayloadAction<any>) => {
      const nerRequests = Object.assign(
        state.specialRequestNotes,
        action.payload
      );
      state.specialRequestNotes = nerRequests;
    },
    clearSendBasket: (state) => {
      state.sendSuccess = false;
      state.basketSendOrder = null;
      state.basket_id = null;
      state.priceStructure = null;
      state.couponName = null;
    },
    setRecipientsAddress: (state, action: PayloadAction<number[] | null>) => {
      const { payload } = action;
      state.recipientAddress = payload;
    },
    setErrorQuantityInsert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.errorQuantityInserts = !!payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listBasketGrouped.fulfilled, (state, { payload }) => {
      const { page, items, total_pages } = payload;
      const includesBusiness = (items as PastOrdersList).some((item) => {
        return item.outbound_info?.type === "business";
      });
      if (includesBusiness) {
        state.businessIncludes = true;
      }
      state.isLoading = false;
      state.items = state.items.concat(items);
      state.page = page + 1;
      state.totalPage = total_pages;
      state.endPageBasket = page >= total_pages;
    });
    builder.addCase(listBasketGrouped.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listBasketGrouped.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(basketClear.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(basketClear.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(basketClear.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(basketSend.fulfilled, (state, { payload }) => {
      const { items, basket_id, price_structure, coupon_name } = payload;
      state.basketSendOrder = items;
      state.couponName = coupon_name;
      state.basket_id = basket_id;
      state.priceStructure = price_structure;
      state.sendSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(basketSend.rejected, (state) => {
      state.sendSuccess = false;
      state.isLoading = false;
    });
    builder.addCase(basketSend.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(basketChilds.fulfilled, (state, { payload }) => {
      const { childs, id, page } = payload;
      const { items } = state;

      const newOrders = items.map((order) => {
        return order.id === id
          ? {
              ...order,
              childs: page > 1 ? order.childs.concat(childs) : childs,
              order,
              page,
            }
          : { ...order };
      });

      state.items = newOrders;
    });
    builder.addCase(basketCount.fulfilled, (state, { payload }) => {
      state.count = payload;
    });
    builder.addCase(basketItemFromAddress.fulfilled, (state, { payload }) => {
      state.recipientAddress = payload;
    });
    builder.addCase(basketRemove.fulfilled, (state, { payload }) => {
      state.items = state.items.filter((o) => o.id !== payload);
      state.isLoading = false;
    });
    builder.addCase(basketRemove.rejected, (state) => {
      state.isLoading = true;
    });
    builder.addCase(basketRemove.pending, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  clearCount,
  clearAll,
  clearSendBasket,
  setRecipientsAddress,
  setSpecialRequests,
  clearBasketItem,
  updateCount,
  setErrorQuantityInsert,
} = basketSlice.actions;

export default basketSlice.reducer;
