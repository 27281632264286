interface ISavedData {
  [key: string]: string;
}

// readNotifications - need to be saved so when user unauthorized - notifications that already read will not appear
const exceptions = ["readNotifications"];

const clearLocalStorage = () => {
  const savedData: ISavedData = {};

  // data that we need to save anyway
  exceptions.forEach((exception) => {
    const data = localStorage.getItem(exception);

    if (data) {
      savedData[exception] = data;
    }
  });

  // clear add data from localStorage
  localStorage.clear();

  // write saved data back to localStorage
  Object.keys(savedData).forEach((key) => {
    localStorage.setItem(key, savedData[key]);
  });
};

export default clearLocalStorage;
