import { FC } from "react";
import { Link } from "react-router-dom";

import { FlexContainer, TextLink } from "../../ui/layout";
import NewLabel from "../../new-label/new-label";

interface INavItem {
  linkTo: string;
  label: string;
  hideSetting: () => void;
  isNewItem?: boolean;
}

const NavItem: FC<INavItem> = ({ linkTo, label, isNewItem, hideSetting }) => {
  return (
    <FlexContainer className='nav__links'>
      {isNewItem ? <NewLabel /> : null}
      <Link to={linkTo}>
        <TextLink color='#fff' onClick={hideSetting}>
          {label}
        </TextLink>
      </Link>
    </FlexContainer>
  );
};

export default NavItem;
