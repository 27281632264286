import { useEffect } from "react";

import { UseSetDataFromGoogleApi } from "../../interfaces/autocomplete/types";
import { extractAddress } from "../../helpers/autocomplete/extract-address";
import useCountries from "../countries/useCountries";

const useSetDataFromGoogleApi: UseSetDataFromGoogleApi = (
  address,
  cityInput,
  zipInput,
  stateInput,
  address1Input,
  countrySelect
) => {
  const { getCountryIdByName } = useCountries(countrySelect.value as string);

  useEffect(() => {
    if (!Object.keys(address).length) return;

    const fullAddress = extractAddress(address.street, address.route);

    const countryId = getCountryIdByName(address.country);

    cityInput.setValue(address.city);
    zipInput.setValue(address.zip);

    stateInput.setValue(address.state);

    address1Input.setValue(fullAddress || "");
    countrySelect.setValue(String(countryId));
  }, [address]);
};

export default useSetDataFromGoogleApi;
