import styled from "styled-components";

interface IPreviewContainer2Props {
  height?: string;
  width: string;
  padding: string;
  fontFamily?: string;
  backgroundImage?: string;
  isCustom?: boolean;
  isOutboundLeads?: boolean;
}

interface IPreviewContainerProps {
  fontFamily: string;
  fontSize: string;
  lineHeight: string;
  backgroundImage?: string;
  isCustom?: boolean;
  isOutboundLeads?: boolean;
}

interface IPreviewContentProps {
  lineHeight: number;
  width: string;
  height: string;
}

interface IPreviewSignatureContianerProps {
  maxWidth: string;
}

interface IPreviewTextProps {
  color: string;
}

const PreviewText = styled.div<IPreviewTextProps>`
  white-space: pre-wrap;
  word-wrap: break-word;

  color: ${({ color }) => color || "#0040ac"};
`;

const TextArea = styled.textarea`
  border: none;
  resize: none;
`;

export const PreviewContainer = styled.div<IPreviewContainerProps>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};

  width: 100%;

  //
  // changed background-position from center to bottom right and
  // background-size from contain to cover to display the #1380 card correctly
  //
  background-position: bottom right;
  background-size: cover;

  background-repeat: no-repeat;

  font-family: ${({ fontFamily }) => fontFamily};

  white-space: break-spaces;
  //white-space: pre-wrap;
  //white-space: pre-line;

  // word-wrap: break-word;
`;

export const PreviewContainerTest = styled.div<IPreviewContainer2Props>`
  width: ${({ width }) => width};

  //
  // changed background-position from center to bottom right and background-size from contain to cover to display the #1380 card correctly
  //
  background-position: bottom right;
  background-size: cover;

  padding: ${({ padding }) => padding};

  background-repeat: no-repeat;

  overflow: hidden;
`;

export const PreviewContent = styled.div<IPreviewContentProps>`
  line-height: ${({ lineHeight }) => lineHeight};
  width: ${({ width }) => width};

  margin: 0 auto;
`;

export const PreviewSignatureContainer = styled.div<IPreviewSignatureContianerProps>`
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: 100%;
  margin-top: 0.5em;
`;

export const PreviewSignatureImage = styled.img`
  display: block;
  max-width: 100%;
`;

export const WishesContainer = styled.div`
  max-width: 45%;
  margin: 0 0 0 auto;
`;

export const PreviewWishesContainer = styled.div``;

export const PreviewMessage = styled(PreviewText)``;
export const PreviewWishes = styled(PreviewText)``;

export const SignatureNameContainer = styled.div`
  width: 150px;
  height: 30px;

  margin: 16px 10px;

  span {
    user-select: none;
    font-size: 12px;
  }
`;

export const HighlightTextarea = styled.div`
  width: 100%;
  height: 200px;
  padding: 1rem;
  font-size: 1.125rem;
  line-height: 1.4rem;
  font-family: aktiv-grotesk, sans-serif;
  overflow: auto;

  .public-DraftEditorPlaceholder-inner {
    font-family: aktiv-grotesk, sans-serif;
    line-height: 1.4rem;
    color: #858585;
    margin-bottom: -1.5rem;
  }
`;

export const HighlightWishesTextArea = styled.div`
  max-height: 90px;
  width: 218px;
  position: relative;
  margin: 16px;
  &::placeholder {
    line-height: 1.4rem;
  }
  .DraftEditor-root {
    min-height: 4rem;
  }

  .DraftEditor-editorContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .public-DraftEditorPlaceholder-inner {
    font-family: aktiv-grotesk, sans-serif;
    line-height: 1.4rem;
    color: #858585;
    margin-bottom: -1.5rem;
  }
`;

export const WishesTextArea = styled(TextArea)`
  max-height: 90px;
  width: 218px;
  position: relative;
  margin: 16px;
  &::placeholder {
    line-height: 1.4rem;
  }
`;
export const MessageTextArea = styled(TextArea)`
  width: 100%;
  height: 200px;
  padding: 1rem;
`;

PreviewContainer.defaultProps = {
  fontFamily: "inherit",
};

PreviewText.defaultProps = {
  color: "#0040ac",
};
