import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrderEditDetail } from "../../components/orders/order/order.item";
import { PastOrdersList } from "../../interfaces/orders/IPastOrder";

import {
  listChilds,
  listGroupedOrders,
  calcTaxes,
  parseOrdersXlsFile,
  placeBasket,
  getOrderDetails,
  listGroupedOrdersSearch,
  cancelOrder,
} from "./thunks";
import { initialState, IAllTaxes } from "./types";

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setDefaultOrdersPage: (state) => {
      state.pastOrders.page = 1;
      state.pastOrders.endPagePastOrders = false;
    },
    setParentId: (state, action: PayloadAction<string | null>) => {
      state.pastOrders.parentId = action.payload;
    },
    clearParentId: (state) => {
      state.pastOrders.parentId = null;
    },
    setDefaultEditOrder: (state) => {
      state.editOrder = null;
    },
    selectReturnAddress: (state, action: PayloadAction<number | null>) => {
      state.selectedReturnAddress = action.payload;
    },
    setDefaultEditOrderId: (state) => {
      state.editId = null;
    },
    setLoginAtSend: (state, action: PayloadAction<boolean>) => {
      state.loginAtSend = action.payload;
    },
    setDefaultEditAddresses: (state) => {
      state.addresses = [];
    },
    setEditCardId: (state, action: PayloadAction<number | null>) => {
      state.editId = action.payload;
    },
    setSearchPastOrder: (state, action: PayloadAction<string | null>) => {
      state.searchPastOrder = action.payload;
    },
    setDefaultSearchPastOrder: (state) => {
      state.searchPastOrder = null;
    },
    setDefaultPastOrder: (state) => {
      state.pastOrders.orders = [];
    },
    setDefaultTaxes: (state) => {
      state.allTax = initialState.allTax;
    },
    setCalcTaxCanceled: (state) => {
      state.calcTaxCanceled = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listGroupedOrders.fulfilled, (state, { payload }) => {
      const includesBusiness = (payload.orders as PastOrdersList).some(
        (order) => {
          return order.outbound_info?.type === "business";
        }
      );
      if (includesBusiness) {
        state.businessIncludes = true;
      }
      state.pastOrders.orders = state.pastOrders.orders.concat(payload.orders);
      state.pastOrders.page = payload.page + 1;
      state.pastOrders.isLoading = false;
      state.pastOrders.endPagePastOrders =
        payload.total_pages === state.pastOrders.page - 1 ||
        payload.total_pages < state.pastOrders.page;
    });

    builder.addCase(listGroupedOrders.pending, (state) => {
      state.pastOrders.isLoading = true;
    });
    builder.addCase(listGroupedOrders.rejected, (state, { payload }) => {
      state.pastOrders.isLoading = false;
      state.pastOrders.error = payload as string;
    });

    builder.addCase(listGroupedOrdersSearch.fulfilled, (state, { payload }) => {
      const includesBusiness = (payload.orders as PastOrdersList).some(
        (order) => {
          return order.outbound_info?.type === "business";
        }
      );
      if (includesBusiness) {
        state.businessIncludes = true;
      }
      state.pastOrders.orders = payload?.orders!;
      state.pastOrders.page = (payload?.page ?? 0) + 1;
      state.pastOrders.isLoading = false;
      state.pastOrders.endPagePastOrders =
        payload?.total_pages === state.pastOrders.page - 1;
    });
    builder.addCase(listGroupedOrdersSearch.pending, (state) => {
      state.pastOrders.isLoading = true;
    });
    builder.addCase(listGroupedOrdersSearch.rejected, (state) => {
      state.pastOrders.isLoading = false;
      state.pastOrders.orders = [];
    });
    builder.addCase(calcTaxes.pending, (state) => {
      state.loaderCalcTax = true;
    });
    builder.addCase(calcTaxes.fulfilled, (state, { payload }) => {
      state.allTax = payload as IAllTaxes;
      state.loaderCalcTax = false;
      state.calcTaxCanceled = false;
    });
    builder.addCase(calcTaxes.rejected, (state) => {
      if (state.calcTaxCanceled) {
        // if previous request was canceled loader should stay active because a new request is pending
        state.loaderCalcTax = true;
      } else {
        state.loaderCalcTax = false;
      }
    });
    builder.addCase(parseOrdersXlsFile.pending, (state) => {
      state.pastOrders.isLoading = true;
    });
    builder.addCase(parseOrdersXlsFile.rejected, (state) => {
      state.pastOrders.isLoading = false;
    });

    builder.addCase(parseOrdersXlsFile.fulfilled, (state, { payload }) => {
      state.addresses = payload.data ? payload.data : [];
      state.pastOrders.isLoading = false;
    });

    builder.addCase(placeBasket.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(placeBasket.fulfilled, (state) => {
      state.isLoader = false;
    });
    builder.addCase(placeBasket.rejected, (state) => {
      state.isLoader = false;
    });

    builder.addCase(getOrderDetails.fulfilled, (state, { payload }) => {
      state.editOrder = payload as IOrderEditDetail;
      state.selectedReturnAddress = payload.address_from?.address_id || null;
    });
    builder.addCase(listChilds.fulfilled, (state, { payload }) => {
      const { childs, id } = payload;
      const { orders } = state.pastOrders;

      const newOrders = orders.map((order) =>
        order.id === id ? { ...order, childs } : { ...order }
      );

      state.pastOrders.orders = newOrders;
    });
    builder.addCase(cancelOrder.fulfilled, (state, { payload }) => {
      state.pastOrders.orders = state.pastOrders.orders.filter(
        (o) => o.id !== payload
      );
      state.isLoader = false;
    });
    builder.addCase(cancelOrder.rejected, (state) => {
      state.isLoader = false;
    });
    builder.addCase(cancelOrder.pending, (state) => {
      state.isLoader = true;
    });
  },
});

export const {
  setSearchPastOrder,
  setDefaultOrdersPage,
  setDefaultEditOrder,
  setDefaultEditOrderId,
  setDefaultEditAddresses,
  setDefaultSearchPastOrder,
  setDefaultPastOrder,
  setDefaultTaxes,
  setCalcTaxCanceled,
  setEditCardId,
  setLoginAtSend,
  setParentId,
  clearParentId,
  selectReturnAddress,
} = ordersSlice.actions;

export default ordersSlice.reducer;
