type ExtractAddress = (street: string, route: string) => string | null;
type ExtractFullAddress = (
  street: string,
  route: string,
  city: string,
  state?: string,
  zip?: string
) => string | null;

export const extractAddress: ExtractAddress = (street, route) => {
  if (street && route) {
    return street.concat(` ${route}`);
  }

  return null;
};
export const extractFullAddress: ExtractFullAddress = (
  street,
  route,
  state,
  city,
  zip
) => {
  if (street && route && zip && state && city) {
    return street.concat(` ${route}, ${state}, ${city}, ${zip}`);
  }
  if (street && route && city) {
    return street.concat(` ${route}, ${state}, ${city}`);
  }
  if (street && route && zip && state) {
    return street.concat(` ${route}, ${state}`);
  }

  return null;
};
