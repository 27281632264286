import { createAsyncThunk } from "@reduxjs/toolkit";
import orderApi from "../../api/order.api";
import { ICard } from "../../interfaces/cards/ICard";

const getCardById = createAsyncThunk<ICard, number>(
  "card/view",
  async (card_id) => {
    const res = await orderApi.getCardById(card_id);
    const { card } = res.data;
    return card;
  }
);

export default getCardById;
