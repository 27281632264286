import { FC } from "react";
import AirplaneLottie from "../lottie/airplane.lottie";

import SectionBox from "../ui/common/section-box/box";
import { FlexContainer, ButtonAction } from "../ui/layout";

import useUser from "../../hooks/auth/useUser";

import { useAppSelector } from "../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

import "./style.scss";

interface ICongrats {
  btnAction: () => void;
  btnText: string;
  btnActionChangeUser: () => void;
}

const HubSpotComponent: FC<ICongrats> = ({
  btnText,
  btnAction,
  btnActionChangeUser,
}) => {
  const { email, name } = useUser();
  const message =
    "Handwrytten will be able to post your card orders to HubSpot.";

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  return (
    <FlexContainer
      justify='center'
      align='center'
      style={{ height: "100%" }}
      className='hubspot'
    >
      <SectionBox style={{ top: !isMobile ? "17rem" : "7rem" }}>
        <FlexContainer
          direction='column'
          justify='center'
          style={{ position: "static" }}
        >
          <p className='hubspot__notification'>
            Do you want to proceed as <b>{name}</b>
            {email ? ` (${email})` : ""}
          </p>
          <p className='hubspot__notification'>{message}</p>
          <FlexContainer
            justify='center'
            direction={isMobile ? "column" : "row"}
          >
            <ButtonAction
              onClick={btnAction}
              className='hubspot__btn'
              style={{ marginRight: !isMobile ? "2rem" : "" }}
            >
              {btnText}
            </ButtonAction>
            <ButtonAction
              onClick={btnActionChangeUser}
              className='hubspot__btn'
            >
              Change user
            </ButtonAction>
          </FlexContainer>
          <AirplaneLottie className='hubspot__paper-airplane' />
        </FlexContainer>
      </SectionBox>
    </FlexContainer>
  );
};

export default HubSpotComponent;
