import { useEffect, useState, useMemo } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

import transformAddressComponent from "../../helpers/autocomplete/transform-address";
import {
  Address,
  ExtractAddress,
  UseAutoCompletePlaces,
} from "../../interfaces/autocomplete/types";

const autocompleteFields = ["address_components", "geometry"];

const useAutoCompletePlaces: UseAutoCompletePlaces = (
  ref,
  mapSearch,
  searchType,
  searchAddress
) => {
  const [address, setAddress] = useState<Address>({} as Address);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
    libraries: ["places"],
    language: "en",
  });

  const options = useMemo(() => {
    if (
      (!!searchType && searchType === "2") ||
      (!!searchType && searchType === "1" && searchAddress === "zip")
    ) {
      return {
        types: ["postal_code"],
        componentRestrictions: { country: "us" },
      };
    }
    if (
      (!!searchType && searchType === "3") ||
      (!!searchType && searchType === "1" && searchAddress === "city")
    ) {
      return {
        types: ["(cities)"],
        componentRestrictions: { country: "us" },
      };
    }
    if (!!searchType && searchType === "1") {
      return {
        types: ["address"],
        componentRestrictions: { country: "us" },
      };
    }

    return {
      componentRestrictions: { country: "us" },
    };
  }, [searchType]);

  const extractAddress: ExtractAddress = (place) => {
    const isAddress = Array.isArray(place.address_components);

    if (!isAddress) return;

    const address = place.address_components?.reduce(
      transformAddressComponent,
      {} as Address
    );

    return address;
  };

  const onChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace();

    const address = extractAddress(place);

    if (!address) return;

    setAddress(address);
  };

  const loadAutocomplete = () => {
    if (!ref.current) return;
    const autocomplete = mapSearch
      ? new window.google.maps.places.Autocomplete(ref?.current!, options)
      : new window.google.maps.places.Autocomplete(ref?.current!);

    autocomplete.setFields(autocompleteFields);

    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    const load = async () => {
      if (isLoaded) {
        loadAutocomplete();
      }
    };

    load();
  }, [ref.current, isLoaded]);

  return address;
};

export default useAutoCompletePlaces;
