import { FC, useEffect } from "react";
import { useHistory } from "react-router";
import HubSpotComponent from "../../components/hubSpot/HubSpotComponent";
import useHubSpotUser from "../../hooks/hubSpot/useHubSpotUser";

import { uidStorage } from "../../redux/auth/thunks";
import useAuth from "../../hooks/auth/useAuth";
import useHubSpot from "../../hooks/auth/useHubSpot";
import navigation from "../../constants/navigation";

const HubSpot: FC = () => {
  const uid = localStorage.getItem(uidStorage);
  const history = useHistory();
  const { setHubSpotUser, token } = useHubSpotUser();
  const { signout } = useAuth();
  useHubSpot();

  useEffect(() => {
    if (!uid) {
      history.push({
        pathname: navigation.auth.signin,
        search: `?token=${token}`,
      });
    }
  }, []);

  return (
    <HubSpotComponent
      btnAction={() => setHubSpotUser()}
      btnText='Proceed'
      btnActionChangeUser={() => {
        signout();
      }}
    />
  );
};

export default HubSpot;
