export const headerItems = [
  { label: "Row", width: "10%" },
  { label: "Type", width: "10%" },
  { label: "Field", width: "15%" },
  { label: "Issue" },
];
export const PLAIN_TEXT = "active-grotesk";

export default {
  headerItems,
  PLAIN_TEXT,
};
