import { FC, useEffect, useState } from "react";

import useSelect from "../../../../hooks/input/useSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/redux/useRedux";
import useTemplatesCategories from "../../../../hooks/templates/useTemplatesCategories";

import { IOption } from "../../../ui/common/select/select.common";
import { Title } from "../../../ui/typography";
import { FlexContainer } from "../../../ui/layout";

import CustomSelect from "../../../custom-select/CustomSelect";
import TemplateUseList from "./template-use.list";
import {
  fetchListTemplates,
  fetchTemplateCategories,
} from "../../../../redux/templates/thunks";
import { getIsMobileWindowDimensions } from "../../../../redux/window-dimensions/selectors";

const UseTemplatePopup: FC = () => {
  const dispatch = useAppDispatch();

  const [categories, setCategories] = useState<Array<IOption>>([]);

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const templateSelect = useSelect("All");
  const { categoriesSelect } = useTemplatesCategories();

  useEffect(() => {
    const getTemplateCategories = async () => {
      const result = await dispatch(fetchTemplateCategories());

      if (fetchTemplateCategories.fulfilled.match(result)) {
        const categoriesOptions = categoriesSelect(result.payload);

        setCategories(categoriesOptions);
      }
    };

    getTemplateCategories();
  }, []);

  useEffect(() => {
    dispatch(
      fetchListTemplates(
        Number.isNaN(+templateSelect.value) ? null : +templateSelect.value
      )
    );
  }, [templateSelect.value]);

  return (
    <div
      style={{
        height: isMobile ? "85%" : "55vh",
        margin: "0 .8rem",
        paddingTop: isMobile ? "0.5rem" : 0,
      }}
    >
      <FlexContainer
        justify='space-between'
        style={{
          width: isMobile ? "100%" : "27rem",
        }}
      >
        {isMobile ? null : (
          <Title style={{ minWidth: "fit-content", fontSize: "1.5rem" }}>
            Your Templates
          </Title>
        )}
        <CustomSelect
          style={{ maxWidth: isMobile ? "100%" : "50%" }}
          options={categories}
          select={templateSelect}
          placeholder='All'
          onChange={templateSelect.onChange}
        />
      </FlexContainer>
      <TemplateUseList />
    </div>
  );
};

export default UseTemplatePopup;
