import React, { useContext, useCallback } from "react";

import { ConfirmationPopupContext } from "../../context/confirmation-popup.provider";
import { useAppSelector } from "../redux/useRedux";
import { getIsMobileWindowDimensions } from "../../redux/window-dimensions/selectors";

const useWarningAtRecipientForm = () => {
  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const { openConfirmationPopup, closeConfirmationPopup } = useContext(
    ConfirmationPopupContext
  );

  const textTitle =
    "Name(s) and/or address(es) do not appear to be formatted in title case. Names and addresses in all upper or lower case will appear as such on the envelope.";

  const openUpperOrLoweCaseWarningConfirmationPopup = (
    nextStep: () => void
  ) => {
    openConfirmationPopup({
      // need change title
      title: "Incorrect Address Formatting Detected",
      textButtonCancel: "GO BACK",
      textButtonConfirm: "YES",
      subtitle: (
        <>
          {textTitle}
          <br /> Continue?
        </>
      ),
      onConfirm: () => nextStep(),
      onCancel: closeConfirmationPopup,
      style: { maxWidth: isMobile ? "100%" : "50vw" },
    });
  };

  const checkCorrectTextCase = (...texts: string[]) => {
    const textWithLowerCase = texts.some(
      (text: string) => text === text.toLowerCase()
    );
    const textWithUpperCase = texts.some(
      (text: string) => text === text.toUpperCase()
    );
    return textWithLowerCase || textWithUpperCase;
  };

  const checkCorrectText = useCallback(
    (nextStap: () => void, name: string, lastName: string, address: string) => {
      const uncorrectText = lastName
        ? checkCorrectTextCase(name, lastName, address)
        : checkCorrectTextCase(name, address);
      if (uncorrectText) {
        return openUpperOrLoweCaseWarningConfirmationPopup(nextStap);
      }
      return nextStap();
    },
    [openUpperOrLoweCaseWarningConfirmationPopup, checkCorrectTextCase]
  );

  return { checkCorrectText };
};

export default useWarningAtRecipientForm;
