import { FC, useEffect, useState } from "react";
import classNames from "classnames";

import useInput from "../../../hooks/input/useInput";
import { Input } from "../../ui/common/inputs/input.common";
import { Typography } from "../../ui/typography";
import { ButtonAction, Form } from "../../ui/layout";

import usePassword from "../../../hooks/auth/usePassword";
import useValidPassword from "../../../hooks/input/useValidPassword";
import useConfirmation from "../../../hooks/input/useConfirmation";
import useValidateForm from "../../../hooks/forms/useValidateForm";

const PasswordResetConfirmFormPopup: FC = () => {
  const { confirmPasswordResetSubmit } = usePassword();
  const { isValid, message, setMassage } = useValidPassword();

  const { isConfirmed } = useConfirmation("Password");

  const [passwordConfirmed, setPasswordConfirmed] = useState(true);

  const { errorFields, removeError, checkEmptyFields } = useValidateForm();

  const newPassword = useInput("");
  const confirmPassword = useInput("");

  const validPass = classNames({
    "error-input-none": true,
    "error-input": !!message,
  });

  useEffect(() => {
    setMassage("");
  }, [newPassword.value]);

  const validNewPassword = (event: React.FormEvent<HTMLFormElement>) => {
    const requiredData = {
      password: newPassword.value,
      password_confirmation: confirmPassword.value,
    };

    event.preventDefault();
    const valid = isValid(newPassword.value);

    if (valid) {
      const confirmed = isConfirmed(newPassword.value, confirmPassword.value);
      setPasswordConfirmed(confirmed);
      checkEmptyFields(requiredData);
      if (valid && confirmed) confirmPasswordResetSubmit(event);
    }
  };

  return (
    <>
      <Typography fontSize='0.88888rem'>
        Never fear! Just type in a new one and we&apos;ll update it right away!
      </Typography>
      <Form onSubmit={validNewPassword}>
        <Input
          placeholder='New Password'
          type='password'
          name='password'
          showPasswordIcon
          style={{ marginBottom: "1rem" }}
          required
          onChange={newPassword.onChange}
        />
        <span className={validPass}>{message}</span>
        <Input
          placeholder='Confirm New Password'
          type='password'
          name='password_confirm'
          showPasswordIcon
          style={{ marginBottom: "1rem" }}
          required
          onChange={confirmPassword.onChange}
          className={
            errorFields.includes("password_confirmation") || !passwordConfirmed
              ? "error-field"
              : ""
          }
          onFocus={() => {
            removeError("password_confirmation");
            setPasswordConfirmed(true);
          }}
        />
        <ButtonAction type='submit'>Go Ahead</ButtonAction>
      </Form>
    </>
  );
};

export default PasswordResetConfirmFormPopup;
