import { KeyboardEvent } from "react";

const useKeyPress = (key: string, onConfirm: () => void, disabled: boolean) => {
  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === key && disabled) {
      onConfirm();
    }
  };

  return handleKeypress as (e: KeyboardEvent<HTMLDivElement>) => void;
};

export default useKeyPress;
