import { RootState } from "../store";

export const getCustomCard = (state: RootState) =>
  state.customCards.customizedCard;

export const getCustomImagesLogo = (state: RootState) =>
  state.customCards.listLogo;

export const getCustomImagesCover = (state: RootState) =>
  state.customCards.listCover;

export const getCustomImagesIcon = (state: RootState) =>
  state.customCards.listIcon;

export const getCover = (state: RootState) =>
  state.customCards.customizedCard?.cover || null;

export const getCoverFullBleed = (state: RootState) =>
  state.customCards.customizedCard?.cover_full_bleed || null;

export const getBackCoverFullBleed = (state: RootState) =>
  state.customCards.customizedCard?.back_cover_full_bleed || null;

export const getCustomCardInfo = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo;

export const getFrameTemplates = (state: RootState) =>
  state.customCards.frameTemplates;

export const getQrCodes = (state: RootState) => state.customCards.listQrCode;

export const getCoverId = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.cover_id;

export const getFooterContent = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo;

export const getFooterLogoId = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.footer_logo_id;

export const getFooterContentHeaderFontId = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.footer_font_id;

export const getFooterContentHeaderFontSize = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.footer_font_size;

export const getFooterContentHeaderText = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.footer_text;

export const getFooterContentHeaderAlign = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.footer_align;

export const getFooterContentHeaderFontColor = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.footer_font_color;

export const getHeaderLogoId = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.logo_id;

export const getHeaderContentHeaderFontId = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.header_font_id;

export const getHeaderContentHeaderFontSize = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.header_font_size;

export const getHeaderContentHeaderText = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.header_text;

export const getHeaderContentHeaderAlign = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.header_align;

export const getHeaderContentHeaderFontColor = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.header_font_color;

export const getCoverSizePercent = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.cover_size_percent;

export const getLogoSizePercent = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.header_logo_size_percent;

export const getFooterLogoSizePercent = (state: RootState) =>
  state.customCards.customizedCard?.customCardInfo?.footer_logo_size_percent;

export const getSaveCardLoader = (state: RootState) =>
  state.customCards.isLoading;

export const getIsFullBleedCard = (state: RootState) =>
  state.customCards.customizedCard?.full_bleed;

export const getCustomizedCardHeight = (state: RootState) =>
  state.customCards.customizedCard?.closed_height || 0;

export const getCustomizedCardWidth = (state: RootState) =>
  state.customCards.customizedCard?.closed_width || 0;

export const getCustomizedCardMarginLeft = (state: RootState) =>
  state.customCards.customizedCard?.margin_left || 0;

export const getCustomizedCardMarginRight = (state: RootState) =>
  state.customCards.customizedCard?.margin_right || 0;

export const getCustomizedCardOrientation = (state: RootState) =>
  state.customCards.customizedCard?.orientation;
