import { createAsyncThunk } from "@reduxjs/toolkit";

import checkAuthApi from "../../api/checkauth.api";

const getCheckAuth = createAsyncThunk("checkauth/auth", async () => {
  const res = await checkAuthApi.checkAuth();
  return res.data;
});

export default getCheckAuth;
