import { FC } from "react";

import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Typography } from "../../ui/typography";
import { ICampaignList } from "../../../constants/campaigns/campaigns";

const OutboundLeadsItem: FC<ICampaignList> = ({
  name,
  description,
  isActive,
  icon,
  action,
}) => {
  return (
    <FlexContainer className='campaign-item' direction='column'>
      <FlexContainer className='campaign-item__title-block' direction='row'>
        <img className='campaign-item__icon' src={icon} alt='icon' />
        <Typography className='campaign-item__title'>{name}</Typography>
      </FlexContainer>
      <Typography className='campaign-item__text'>{description}</Typography>
      {isActive ? (
        <ButtonAction onClick={action!}>Select</ButtonAction>
      ) : (
        <ButtonAction
          className='disabled-button'
          style={{ opacity: 1 }}
          disabled
        >
          COMING IN 2023!
        </ButtonAction>
      )}
    </FlexContainer>
  );
};

export default OutboundLeadsItem;
