import { FC } from "react";

import { FlexContainer } from "../../../ui/layout";
import Radio from "../../../ui/common/radio/radio.common";

interface ITemplateSaveItemProps {
  radioValue: boolean;
  radioGroup: { value: boolean; name: string; label: string; id: number }[];
  onChooseClick: () => void;
}

const TemplateItem: FC<ITemplateSaveItemProps> = ({
  radioValue,
  radioGroup,
  onChooseClick,
}) => (
  <FlexContainer
    margin='0 auto'
    width='100%'
    padding='.6rem 0'
    style={{
      marginBottom: "-1px",
      overflow: "hidden",
      backgroundColor: "white",
      borderTop: "1px solid #DDDFD2",
      borderBottom: "1px solid #DDDFD2",
    }}
    justify='space-between'
  >
    <FlexContainer direction='row'>
      <Radio
        radioGroup={radioGroup}
        onChange={onChooseClick}
        value={radioValue}
        square
        style={{
          paddingLeft: "2rem",
          lineHeight: "2rem",
          fontSize: "1rem",
          fontWeight: 500,
        }}
      />
    </FlexContainer>
  </FlexContainer>
);

export default TemplateItem;
