import { FC, useState } from "react";

import { Title, Typography } from "../../ui/typography";
import { ButtonAction, FlexContainer } from "../../ui/layout";
import { Input } from "../../ui/common/inputs/input.common";
import UploadFile from "../../ui/common/upload/upload.common";
import PopupCommon from "../common/popup.common";
import BulkUploadTableContainer from "../../bulk-upload/table/bulk-upload.container";

import useInput from "../../../hooks/input/useInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/useRedux";

import { getIsMobileWindowDimensions } from "../../../redux/window-dimensions/selectors";
import { requestMailingAddress } from "../../../redux/profile/thunks";
import { setPortalPopup } from "../../../redux/portal-popup/slice";
import validationRegex from "../../../constants/validation";
import messagePopup from "../../../constants/popup/constants";
import { bulkRequestMailingTemplate } from "../../../constants/url";

import { ValidateDataList } from "../../../interfaces/bulk-upload/IValidateData";
import { IBulkRequestMailingAddressResponse } from "../../../interfaces/profile/IRequestMailingAddress";

import "./style.scss";

const RequestMailingAddressPopup: FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(getIsMobileWindowDimensions);
  const emailInput = useInput("");

  const [emailsFormData, setEmailsFormData] = useState<FormData | null>(null);
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
  const [onlyWarnings, setOnlyWarnings] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidateDataList>([]);

  const showSuccessPopup = (isBulk?: boolean) => {
    dispatch(
      setPortalPopup({
        text: isBulk
          ? messagePopup.importSuccess
          : messagePopup.requestEmailWasSent,
        typePopup: "alert",
        title: "Success",
      })
    );
  };

  const uploadFileHandler = async (data: FormData) => {
    setEmailsFormData(data);
  };

  const clearFileHandler = () => {
    setEmailsFormData(null);
  };

  const rejectUploadHandler = (errors: ValidateDataList) => setErrors(errors);

  const requestSingleAddressHandler = async () => {
    if (!validationRegex.email.test(emailInput.value)) {
      setEmailInvalid(true);
    } else {
      const { meta } = await dispatch(
        requestMailingAddress({ email: emailInput.value })
      );

      if (meta.requestStatus === "fulfilled") {
        showSuccessPopup();
      }
    }
  };

  const requestBulkAddressHandler = async () => {
    if (emailsFormData) {
      const { meta, payload } = await dispatch(
        requestMailingAddress(emailsFormData)
      );

      const responsePayload = payload as IBulkRequestMailingAddressResponse;

      if (meta.requestStatus === "fulfilled") {
        if (!responsePayload.correct_file && responsePayload.errors_count) {
          const hasCritical = !!responsePayload?.errors_count?.critical;
          const hasWarnings = !!responsePayload?.errors_count?.warnings;

          setOnlyWarnings(!hasCritical && hasWarnings);
          return rejectUploadHandler(
            responsePayload?.validate_data as ValidateDataList
          );
        }

        showSuccessPopup(true);
      }
    }
  };

  const forceRequestBulkAddressesHandler = async () => {
    if (emailsFormData) {
      setErrors([]);
      emailsFormData.append("no_check_warning", "true");

      const { meta } = await dispatch(requestMailingAddress(emailsFormData));
      if (meta.requestStatus === "fulfilled") {
        showSuccessPopup(true);
      }
    }
  };

  return (
    <FlexContainer
      className='request-mailing-address-popup'
      direction='column'
      align='flex-start'
    >
      <Title className='request-mailing-address-popup__title'>
        Request Mailing Address
      </Title>
      <Typography className='request-mailing-address-popup__description'>
        We will send an email requesting their address and they will be added to
        your account. We will notify you when they do.
      </Typography>
      <FlexContainer
        className='request-mailing-address-popup__input-container'
        direction={isMobile ? "column" : "row"}
      >
        <Input
          type='text'
          placeholder='Email Address'
          {...emailInput}
          onFocus={() => setEmailInvalid(false)}
          style={{
            border: emailInvalid ? "1px solid red" : "none",
          }}
        />
        {emailInvalid ? (
          <span
            className='error-input'
            style={{
              width: "100%",
              padding: "0.2rem",
              position: "absolute",
              bottom: isMobile ? "42%" : "-50%",
            }}
          >
            Email is invalid
          </span>
        ) : null}
        <ButtonAction
          onClick={requestSingleAddressHandler}
          disabled={!emailInput.value.length}
        >
          Send Request
        </ButtonAction>
      </FlexContainer>
      <div className='line' />
      <Title className='request-mailing-address-popup__title'>
        Bulk Upload
      </Title>
      <Typography className='request-mailing-address-popup__description'>
        Got a whole list of email addresses? Upload a spreadsheet below.
      </Typography>
      <FlexContainer
        className='request-mailing-address-popup__input-container'
        direction={isMobile ? "column" : "row"}
      >
        <UploadFile
          placeholder='Please select a file'
          clear={clearFileHandler}
          onUploadFile={uploadFileHandler}
          cancelButtonStyle={{
            right: isMobile ? "1rem" : "11rem",
            top: isMobile ? "0.5rem" : "0.3rem",
          }}
          hideButton
        />
        <ButtonAction
          onClick={requestBulkAddressHandler}
          disabled={!emailsFormData}
        >
          Send Request
        </ButtonAction>
        <PopupCommon isOpen={!!errors?.length} onClose={() => setErrors([])}>
          <BulkUploadTableContainer
            title={messagePopup.emailsUploadTitle}
            subtitle={messagePopup.emailsUploadSubtitle}
            errors={errors}
            onlyWarnings={onlyWarnings}
            onConfirm={forceRequestBulkAddressesHandler}
          />
        </PopupCommon>
      </FlexContainer>
      <a
        className='request-mailing-address-popup__download-link'
        href={bulkRequestMailingTemplate}
        target='_blank'
        rel='noreferrer'
      >
        Download a Template
      </a>
    </FlexContainer>
  );
};

export default RequestMailingAddressPopup;
