import { FC, CSSProperties, ReactNode } from "react";

import { FlexContainer } from "../../ui/layout";
import { Title } from "../../ui/typography";

import "./style.scss";

interface IContainerHeader {
  title: string;
  withoutLine?: boolean;
  style?: CSSProperties;
  icon?: ReactNode;
}

const ContainerHeader: FC<IContainerHeader> = ({
  title,
  withoutLine,
  style,
  icon,
}) => {
  return (
    <FlexContainer justify='space-between' style={style}>
      <Title>{title}</Title>
      {!withoutLine && <div className='line' />}
      {icon || null}
    </FlexContainer>
  );
};

export default ContainerHeader;
