import { createAsyncThunk } from "@reduxjs/toolkit";

import holidaysApi from "../../api/holidays.api";

const fetchHolidays = createAsyncThunk("holidays", async () => {
  const res = await holidaysApi.list();

  const { holidays } = res.data;

  return holidays;
});

export default fetchHolidays;
