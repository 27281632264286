import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import { fetchFonts, fetchFontsForCustomizer } from "./thunks";

const fontsSlice = createSlice({
  name: "fonts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFonts.fulfilled, (state, { payload }) => {
      state.fonts = payload;
    });
    builder.addCase(fetchFontsForCustomizer.fulfilled, (state, { payload }) => {
      state.fontsCustomizer = payload;
    });
  },
});

export default fontsSlice.reducer;
