export const validErrors = {
  upperCase: "at least one upper case English",
  lowerCase: "at least one lower case English letter",
  numberOne: "at least one digit",
  special: "at least one special character",
  length: "minimum eight in length ",
};

interface IValidate {
  upperCase: RegExp;
  lowerCase: RegExp;
  numberOne: RegExp;
  special: RegExp;
  length: RegExp;
}

export const validRegEx: IValidate = {
  upperCase: /^(?=.*?[A-Z])/,
  lowerCase: /^(?=.*?[a-z])/,
  numberOne: /^(?=.*?[0-9])/,
  special: /^(?=.*?[#?!@$%^&*-_/])/,
  length: /^(.{8,})/,
};
