import styled from "styled-components";
import { IRadio } from "../../../../interfaces/inputs/IRadio";
import checkbox from "../../../../style/checkbox";

export const RadioStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.77777rem;
`;

export const LabelStyled = styled.label`
  position: relative;
  padding-left: 1.61111rem;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 0.77777rem;
  line-height: 1.55555rem;
  user-select: none;

  display: flex;
  flex: 1 1 30%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

export const InputStyled = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const top = {
  top: `calc(50% - ${checkbox.$checkbox_square})`,
};

export const CheckmarkStyled = styled.span<IRadio>`
  position: absolute;
  top: ${top.top};
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: ${(props) => (props.square ? "#EBEBE2" : "#DDDDD4")};
  border-radius: ${(props) => (props.square ? "0" : "50%")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: ${(props) =>
      props.square ? checkbox.$checkbox_circle : checkbox.$checkbox_square};
    height: ${(props) =>
      props.square ? checkbox.$checkbox_circle : checkbox.$checkbox_square};
    border-radius: ${(props) => (props.square ? "0" : "50%")};
    background: transparent;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.active:after {
    background: #ff5037;
  }
`;
