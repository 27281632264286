import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, IPopupAction } from "./types";

const portalPopupsSlice = createSlice({
  name: "portalPopups",
  initialState,
  reducers: {
    setPortalPopup: (state, action: PayloadAction<IPopupAction>) => {
      const { form, typePopup, title, text } = action.payload;
      state.form = form!;
      state.text = text!;
      state.typePopup = typePopup;
      state.title = title;
      state.isShowPopup = true;
    },
    closePopup: () => initialState,
  },
});

export const { setPortalPopup, closePopup } = portalPopupsSlice.actions;

export default portalPopupsSlice.reducer;
