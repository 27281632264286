import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import templatesApi from "../../api/templates.api";
import { IThunkError } from "../../interfaces/redux/IThunkError";
import {
  ITemplateCreatePayload,
  ITemplateUpdatePayload,
  ITemplate,
  TemplateId,
  TemplatesList,
} from "../../interfaces/templates/ITemplate";
import { ITemplateCategory } from "../../interfaces/templates/ITemplateCategory";
import { ITemplateCandidate } from "./types";
import { removeTemplateFromEdit } from "./slice";
import { closePopup, setPortalPopup } from "../portal-popup/slice";
import messagePopup from "../../constants/popup/constants";

export const fetchListTemplates = createAsyncThunk<
  TemplatesList,
  number | null
>("templates/list", async (id = null) => {
  const res = await templatesApi.list(id);
  const { templates } = res.data;

  return templates;
});

export const createTemplate = createAsyncThunk<
  ITemplateCandidate | undefined,
  ITemplateCreatePayload
>("templates/create", async (payload, { dispatch }) => {
  const { name, id, message, wishes, signature, signature2 } = payload;

  const res = await templatesApi.create({
    name,
    message,
    signature_id: signature?.id!,
    signature2_id: signature2?.id!,
    wishes,
  });

  if (!res) return;

  dispatch(closePopup());
  dispatch(removeTemplateFromEdit(id!));
  dispatch(
    setPortalPopup({
      text: messagePopup.templateCreated,
      typePopup: "alert",
      title: "Success",
    })
  );
  dispatch(fetchListTemplates(null));

  const { template_id } = res.data;

  return {
    new_id: template_id,
    old_id: id,
    template: {
      name,
      message,
      signature,
      signature2,
      wishes,
    },
  };
});

export const deleteTemplate = createAsyncThunk<number | undefined, TemplateId>(
  "templates/delete",
  async (id) => {
    const res = await templatesApi.delete(id);

    if (res) return id;
  }
);

export const updateTemplate = createAsyncThunk<
  any,
  ITemplateUpdatePayload,
  {
    rejectValue: IThunkError;
  }
>("templates/update", async (payload, { rejectWithValue, dispatch }) => {
  const { signature2, signature, id, message, wishes, name } = payload;

  try {
    const res = await templatesApi.update({
      id,
      signature2_id: signature2?.id! || null,
      signature_id: signature?.id! || null,
      message,
      wishes,
      name,
    });

    if (res) {
      dispatch(removeTemplateFromEdit(id!));
      const { template } = res.data;

      const updatedTemplateWithSignatures = {
        ...template,
        signature,
        signature2,
      };

      dispatch(fetchListTemplates(null));

      return updatedTemplateWithSignatures;
    }
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);

    throw err;
  }
});

export const viewTemplate = createAsyncThunk<ITemplate, TemplateId>(
  "templates/view",
  async (id) => {
    const res = await templatesApi.view(id);

    const { template } = res.data;

    return template;
  }
);

export const fetchTemplateCategories = createAsyncThunk<
  Array<ITemplateCategory>,
  void
>("templates/categories", async () => {
  const res = await templatesApi.categories();

  const { categories } = res.data;

  return categories;
});
