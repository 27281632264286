import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../interfaces/auth/IAuth";

import { login, signIn, logout, setTestMode, authProvider } from "./thunks";
import initialState from "./types";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNewEmail: (state, { payload }) => {
      if (state.user) state.user.email = payload;
    },
    // update by socket
    setCreditUser: (state, { payload }) => {
      if (state.user) {
        const { credit1, credit2 } = payload;
        state.user.credit1 = credit1;
        state.user.credit2 = credit2;
      }
    },
    setLoggingAsUser: (state, { payload }) => {
      state.loggingAsUser = payload;
    },
    setLastFontFamily: (state, { payload }) => {
      (state.user as IUser).last_used_font = payload as string;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state) => {
      state.isAuthProviderLoading = false;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.isAuthProviderLoading = false;
    });
    builder.addCase(signIn.pending, (state) => {
      state.isAuthProviderLoading = true;
    });

    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.checking = false;
      state.isAuthenticated = true;

      state.user = payload!;
      state.user.credit1 = payload.credit1!;
    });
    builder.addCase(login.rejected, (state) => {
      state.checking = false;
      state.isAuthenticated = false;
    });

    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.isAuthenticated = false;
      state.user = payload;
    });
    builder.addCase(setTestMode.fulfilled, (state, { payload }) => {
      if (state.user) {
        state.user.test_mode = payload;
      }
    });

    builder.addCase(authProvider.fulfilled, (state) => {
      state.isAuthProviderLoading = false;
    });
    builder.addCase(authProvider.pending, (state) => {
      state.isAuthProviderLoading = true;
    });
    builder.addCase(authProvider.rejected, (state) => {
      state.isAuthProviderLoading = false;
    });
  },
});

export const {
  setNewEmail,
  setCreditUser,
  setLoggingAsUser,
  setLastFontFamily,
} = authSlice.actions;

export default authSlice.reducer;
