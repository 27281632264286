import { useState, memo, useMemo, FC, CSSProperties } from "react";
import classNames from "classnames";
import parse from "html-react-parser";

import { FlexContainer } from "../../layout";

import "./style.scss";

interface ITooltip {
  children?: JSX.Element;
  message: string | number;
  parseMessageToHtml?: boolean;
  inOutbound?: boolean;
  isAutomation?: boolean;
  outboundPaginations?: boolean;
  hide?: boolean;
  left?: boolean;
  bottom?: boolean;
  containerStyle?: CSSProperties;
  messageClassName?: string;
  textStyle?: CSSProperties;
  iconSymbol?: string;
  isForTransaction?: boolean;
}

export const Tooltip: FC<ITooltip> = ({
  message,
  inOutbound,
  isAutomation,
  children,
  hide = false,
  outboundPaginations = false,
  left = false,
  bottom = false,
  containerStyle,
  messageClassName = "",
  textStyle,
  iconSymbol,
  isForTransaction = false,
  parseMessageToHtml = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  const tooltip = classNames({
    tooltip: true,
    tooltip_show: isOpen,
    "left-position": left,
    "bottom-position": bottom,
  });

  const tooltipOrder = classNames({
    tooltipOrder: true,
    tooltipOrder_show: isOpen,
  });

  const whiteStyle = useMemo(() => {
    const defaultStyle = {
      marginLeft: "0",
      backgroundColor: "#dddfd2",
    };
    if (isAutomation) {
      return {
        ...defaultStyle,
        width: "1rem",
        height: "1rem",
        margin: "0 0.5rem",
      };
    }
    if (inOutbound) {
      return {
        ...defaultStyle,
        width: "1.88888rem",
        height: "1.88888rem",
      };
    }

    if (children && isForTransaction) {
      return {
        width: "inherit",
        height: "inherit",
        backgroundColor: "transparent",
        margin: "0.61111rem 0 0",
      };
    }

    if (children) {
      return {
        width: "min-content",
        backgroundColor: "transparent",
      };
    }

    return {};
  }, [inOutbound, isAutomation, isForTransaction]);

  return hide ? (
    children || null
  ) : (
    <FlexContainer
      align='center'
      justify='center'
      className={outboundPaginations ? tooltipOrder : tooltip}
      onPointerEnter={open}
      onPointerLeave={close}
      width={inOutbound ? "1.8888rem" : "1rem"}
      style={{ ...whiteStyle, ...containerStyle }}
    >
      {children || (
        <FlexContainer
          align='center'
          justify='center'
          style={{ color: "white" }}
          width={inOutbound ? "1.8888rem" : "1rem"}
        >
          {iconSymbol || "?"}
        </FlexContainer>
      )}
      <div
        className={`${
          outboundPaginations ? "tooltipOrder__text" : "tooltip__text"
        } ${messageClassName}`}
        style={textStyle}
      >
        {parseMessageToHtml ? parse(message.toString()) : message}
      </div>
    </FlexContainer>
  );
};

export default memo(Tooltip);
