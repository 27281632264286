import {
  userInstance,
  creditCardInstance,
  uidSecretInstance,
} from "../hooks/instance/useInstance";

import {
  ICreditCardsListOnlyResponse,
  ICreditCardsListResponse,
  INewCreditCartInfo,
} from "../interfaces/credit-cards/ICreditCard";

const creditCardsApi = {
  getKeyForNewCard: async () => {
    return uidSecretInstance.get("/profile/getAuthorizeNetInfo");
  },
  add: async (merchantAuthentication: any) => {
    return creditCardInstance.post(
      "/xml/v1/request.api",
      merchantAuthentication
    );
  },
  addCreditCard: async (newCreditCard: INewCreditCartInfo) => {
    return userInstance.post("/creditCards/addNew", newCreditCard);
  },
  delete: async (credit_card_id: number) => {
    const response = await userInstance.post("/creditCards/delete", {
      credit_card_id,
    });
    return response.data;
  },
  list: async () => {
    return userInstance.get<ICreditCardsListResponse>("/creditCards/list");
  },
  listOnly: async () => {
    return userInstance.get<ICreditCardsListOnlyResponse>(
      "/creditCards/listOnly"
    );
  },
  setDefault: async (credit_card_id: number) => {
    return userInstance.post("/creditCards/setDefault", {
      credit_card_id,
    });
  },
};

export default creditCardsApi;
