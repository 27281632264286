import { ICard, ICursor, ISection } from "../../interfaces/cards/ICard";

export interface IToggleFavoriteAction {
  cardId: number;
  categoryId: number;
  is_home_page?: boolean;
}

export interface IDeleteCustomCard {
  card_id: number;
}

export interface IToggleFavoriteResponse {
  favorite_cards: number[];
  is_like: boolean;
  favorite_card: ICard;
  is_home_page?: boolean;
  card_id: number;
  category_id: number;
}

export interface CardsByCategoryRequest {
  categoryId: number;
  page: number;
}

interface CardsState {
  cards: Array<ISection>;
  isPreview: boolean;
  nextPage: number;
  pages: number | null;
  allPage: number | null;
  isListEnd: boolean;
  cardsByCategory: Array<ICard>;
  isLoading: boolean;
  refresh: boolean;
  nextPageCategory: number;
  isListEndCategory: boolean;
  searchCards: string | null;
  cursor: ICursor | null;
  // favorite
}
export interface IPageCategory {
  categoryId: number;
  nextPageCategory: number;
  limit: number;
  isDelete?: boolean;
  search?: string;
}
export interface IPageAllCategory {
  nextPage: number;
  limit: number;
  search?: string;
}

export interface ISearchAllCategory {
  search: string;
  limit: number;
}

export const initialState: CardsState = {
  isPreview: false,
  cards: [],
  nextPage: 1,
  pages: null,
  allPage: null,
  isListEnd: false,
  cardsByCategory: [],
  isLoading: false,
  refresh: false,
  nextPageCategory: 1,
  isListEndCategory: false,
  searchCards: "",
  cursor: null,
};
