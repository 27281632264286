interface IStepEntity {
  [name: string]: any;
}

interface IStepArrayItem {
  id: number;
  [name: string]: any;
}

type IStepsArray = IStepArrayItem[];

const sortStepsHandler = (a: IStepEntity, b: IStepEntity) => {
  if (a.sortOrder === b.sortOrder) return -1;
  return a.sortOrder - b.sortOrder;
};

const stepsEntitiesToArray = (stepsEntities: IStepEntity): IStepsArray => {
  const result: any[] = [];
  const keys = Object.keys(stepsEntities);

  keys.forEach((key) => {
    result.push(stepsEntities[key]);
  });

  return result;
};

const getNextSteps = (entities: IStepEntity, startWith: number) => {
  const stepsArray = stepsEntitiesToArray(entities);
  const sortedSteps = stepsArray.sort(sortStepsHandler);

  const indexBySortOrder = sortedSteps.findIndex(
    (step) => step.sortOrder === startWith
  );

  const nextSteps = sortedSteps.slice(indexBySortOrder + 1);

  return nextSteps;
};

export { sortStepsHandler, getNextSteps };
