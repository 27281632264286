import { ICountry, IDisabledState } from "../../interfaces/countries/ICountry";

interface ICountriesState {
  countries: Array<ICountry>;
  disabledStates: Array<IDisabledState>;
}

const initialState: ICountriesState = {
  countries: [],
  disabledStates: [],
};

export default initialState;
