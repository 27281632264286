import { FC } from "react";

import { Input } from "../../ui/common/inputs/input.common";
import { ButtonAction, Form } from "../../ui/layout";

import useEmail from "../../../hooks/profile/useEmail";

const ConvertPopup: FC = () => {
  const { changeEmailSubmit } = useEmail();

  return (
    <Form onSubmit={changeEmailSubmit} autoComplete='off'>
      <Input
        isDisableAutocomplete
        placeholder='New email address'
        type='email'
        name='email'
        style={{ margin: "1rem 0" }}
        required
      />
      <Input
        isDisableAutocomplete
        placeholder='Confirm email address'
        name='email_confirm'
        type='email'
        style={{ margin: "1rem 0" }}
        required
      />
      <Input
        isDisableAutocomplete
        placeholder='Enter your password'
        type='password'
        name='password'
        style={{ margin: "1rem 0" }}
        required
        showPasswordIcon
      />
      <ButtonAction type='submit'>Change</ButtonAction>
    </Form>
  );
};

export default ConvertPopup;
