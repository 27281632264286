const navigation = {
  home: "/",
  redirect: "/",
  auth: {
    signin: "/signin",
    signup: "/signup",
  },
  send: "/category/:category/:cardName-:cardId/send",
  completeOrder: "/complete-order",
  bulkSend: "/bulk-send",
  myBasket: "/my-basket",
  paymentOptions: "/payment-options",
  subscriptions: "/subscriptions",
  prepaidPlans: "/prepaid-plans",
  thanksForSubscribing: "/thanks-for-subscribing",
  thanksForPrepaid: "/thanks-for-prepaid",
  addressBook: "/address-book",
  requestMailingAddress: "/request-mailing-address",
  thanksForProvidedAddress: "/thanks-for-provided-address",
  reporting: "/reporting",
  messageTemplates: "/message-templates",
  customizable: "/customizable",
  pastOrders: "/past-orders",
  onItsWay: "/on-its-way",
  contactUs: "/contact-us",
  outboundProspecting: "/outbound-prospecting",
  automationCards: "/automation-cards",
  addNewAutomationCards: "/add-new-automations",
  editAutomations: "/edit-automations",
  hubspot: "/hubspot",
  error: "/error",
  category: "/category",
  privacyPolicy: "/privacy-policy",
  dataDeletion: "/data-deletion",
  cards: "/cards",
  chooseCampaign: "/choose-campaign-mobile",
  oauth: "/oauth",
  automationId: "/:automation_id",
  cardId: "/:card_id",
  integrations: "/integrations",
  multiStepCampaign: "/multi-step-campaigns",
  addNewMultiStep: "/add-multi-step-campaign",
  editMultiStep: "/multi-step-campaign",
  notFound: "/not-found",
};

export default navigation;
