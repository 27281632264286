const correctPathName = (path?: string) => {
  if (!path) return "handwrytten";
  return path
    ?.replace(/ /g, "-")
    .replace(/&/g, "")
    .replace(/'/g, "")
    .toLocaleLowerCase()
    .replace("/", "");
};

export default correctPathName;
