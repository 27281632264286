import { automationTypesConstant } from "../constants/automation-compain/automation-types";
import { userInstance } from "../hooks/instance/useInstance";

import {
  IDefaultAutomation,
  IAutomationId,
  IAutomationResponse,
  IAutomationListResponse,
  IAutomationByIdResponse,
  IUpdateAutomationResponse,
} from "../interfaces/automation/automation";
import { IAddAutomation, IPageAllAutomation } from "../redux/automation/types";

const automatonsApi = {
  card: async (card_id: IAutomationId) => {
    return userInstance.get<IAutomationByIdResponse>(`/automation/${card_id}`);
  },
  list: async (props: IPageAllAutomation) => {
    return userInstance.get<IAutomationListResponse>(
      `/automation?page=${props.nextPage}&limit=${props.limit}`
    );
  },
  delete: async (card_id: IAutomationId) => {
    return userInstance.delete(`/automation/${card_id}`);
  },
  add: async (card: IAddAutomation) => {
    return userInstance.post<IAutomationResponse>("/automation", card);
  },
  update: async (card: IAddAutomation) => {
    return userInstance.put<IUpdateAutomationResponse>(
      `/automation/${card.id}`,
      card
    );
  },
  checkDefaultAutomation: async (type: automationTypesConstant) => {
    return userInstance.get<IDefaultAutomation>(
      `/automation/checkDefaultCampaign?type=${type.toLocaleLowerCase()}`
    );
  },
};

export default automatonsApi;
