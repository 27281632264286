import { IOption } from "../../components/ui/common/select/select.common";

interface ITags {
  tags: IOption[];
}

const initialState: ITags = {
  tags: [],
};

export default initialState;
