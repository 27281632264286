import { createSlice } from "@reduxjs/toolkit";

import { getShippingRatesList, getShippingMethodsList } from "./thunks";
import { initialState, IShippingMethod, IShippingRate } from "./types";

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShippingRatesList.fulfilled, (state, { payload }) => {
      state.ratesList = payload as IShippingRate[];
      state.ratesError = null;
    });
    builder.addCase(
      getShippingRatesList.rejected,
      (state, { payload }: any) => {
        state.ratesError = payload?.message || "Something went wrong";
      }
    );
    builder.addCase(getShippingMethodsList.fulfilled, (state, { payload }) => {
      state.methodsList = payload as IShippingMethod[];
    });
  },
});

export default shippingSlice.reducer;
