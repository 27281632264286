import { createSlice } from "@reduxjs/toolkit";
import getTags from "./thunk";
import initialState from "./types";

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
    });
  },
});

export default tagsSlice.reducer;
