import { userInstance } from "../hooks/instance/useInstance";
import {
  ITemplateCreatePayload,
  ITemplateCreateResponse,
  ITemplateUpdatePayload,
  ITemplateListResponse,
  ITemplateUpdateResponse,
  ITemplateViewResponse,
  TemplateId,
} from "../interfaces/templates/ITemplate";
import { ITemplateCategoriesResponse } from "../interfaces/templates/responses/ITemplateCategoriesResponse";

const templatesApi = {
  create: async (template: ITemplateCreatePayload) => {
    return userInstance.post<ITemplateCreateResponse>("/templates/create", {
      ...template,
    });
  },
  delete: async (template_id: TemplateId) => {
    return userInstance.post("/templates/delete", { template_id });
  },
  update: async (template: ITemplateUpdatePayload) => {
    const { id } = template;

    return userInstance.post<ITemplateUpdateResponse>("/templates/update", {
      ...template,
      template_id: id,
    });
  },
  list: async (id: number | null) => {
    const seID = id !== null ? `category_id=${id}` : "";
    return userInstance.get<ITemplateListResponse>(`/templates/list?${seID}`);
  },
  view: async (id: TemplateId) => {
    return userInstance.get<ITemplateViewResponse>(
      `/templates/view?template_id=${id}`
    );
  },
  categories: async () => {
    return userInstance.get<ITemplateCategoriesResponse>(
      "/templateCategories/list"
    );
  },
};

export default templatesApi;
