import { CSSProperties, FC, memo, ReactNode } from "react";

import "./style.scss";

export const TitleBar: FC<{
  title: string;
  children: ReactNode;
  style: CSSProperties;
}> = ({ title, children, style }) => {
  return (
    <div className='title-bar-line' style={style}>
      <h1>{title}</h1>
      <div className='line' />
      {children}
    </div>
  );
};

export default memo(TitleBar);
