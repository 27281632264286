import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import fetchSignatures from "./thunks";

const signaturesSlice = createSlice({
  name: "signatures",
  initialState,
  reducers: {
    clearSignatures: (state) => {
      state.signatures = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSignatures.fulfilled, (state, { payload }) => {
      state.signatures = payload;
    });
  },
});

export const { clearSignatures } = signaturesSlice.actions;

export default signaturesSlice.reducer;
