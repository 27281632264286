import React, {
  MouseEvent,
  useEffect,
  useMemo,
  Dispatch,
  memo,
  SetStateAction,
  useRef,
  useState,
  FC,
  useCallback,
  useLayoutEffect,
} from "react";
import classNames from "classnames";
import useOnClickOutside from "../../../../hooks/utils/useOnClickOutside";
import CancelSelectValueButton from "../input-cancel-btn/input-cancel.common";

import SelectStyled from "./select.styled";
import { ICurrent } from "../../../../interfaces/subscriptions/ISubscriptions";
import { useAppSelector } from "../../../../hooks/redux/useRedux";
import { getIsMobileWindowDimensions } from "../../../../redux/window-dimensions/selectors";

import "./style.scss";

export interface IValue {
  value: string;
  src: string;
}

export interface IOption {
  id: number;
  value: string | IValue;
  label: (() => JSX.Element) | string;
  alt?: string;
  font?: string;
  src?: string;
  price?: string | number;
  key?: string;
}

interface ISelect {
  options: IOption[];
  value?: string | IValue;
  style?: React.CSSProperties;
  placeholder?: string;
  className?: string;
  containerClass?: string;
  disabled?: boolean;
  clearSelector?: boolean;
  additionalInfo?: ICurrent | null;
  selectPlanId?: boolean;
  onChange: (
    value: string | IValue,
    setShow: Dispatch<SetStateAction<boolean>>
  ) => void;
  fixedPlaceholder?: string;
  icon?: any;
  isFontsForCustomCard?: boolean;
  name?: string;
  styleForCancelButton?: React.CSSProperties;
  noDefault?: boolean;
  placeholderStyle?: React.CSSProperties;
}

const Select: FC<ISelect> = ({
  options,
  value,
  style,
  name,
  placeholder,
  onChange,
  className,
  containerClass,
  disabled,
  clearSelector,
  additionalInfo,
  selectPlanId,
  fixedPlaceholder,
  icon,
  isFontsForCustomCard,
  styleForCancelButton,
  noDefault,
  placeholderStyle,
}) => {
  const ref = useRef(null);
  const refSelect = useRef(null);
  const optionsContainerRef = useRef(null);

  const [isShow, setShow] = useState<boolean>(false);
  const [customSelect, setCustomSelect] = useState<JSX.Element | null>(null);

  const [isOpenAbove, setIsOpenAbove] = useState<boolean>(false);

  const isMobile = useAppSelector(getIsMobileWindowDimensions);

  const optionsClasses = classNames({
    "options-credit-card": !!className,
    options: !className,
    ready: isShow,
    "open-above": isOpenAbove,
  });

  useLayoutEffect(() => {
    function positionDropdown() {
      if (refSelect?.current && isShow) {
        // @ts-ignore
        const selectRect = refSelect.current.getBoundingClientRect();
        const footerHeight = 16 * 3.5; // 3.5rem in pixels (height of the footer)
        const spaceBelow =
          window.innerHeight - selectRect.bottom - footerHeight;

        if (optionsContainerRef?.current) {
          // @ts-ignore
          const optionsHeight = optionsContainerRef.current.offsetHeight;
          if (spaceBelow < optionsHeight) {
            setIsOpenAbove(true);
          } else {
            setIsOpenAbove(false);
          }
        }
      }
    }

    if (isShow) {
      positionDropdown();
      window.addEventListener("scroll", positionDropdown);
    }

    return () => {
      window.removeEventListener("scroll", positionDropdown);
    };
  }, [isShow]);

  const handleClearClick = (event: MouseEvent) => {
    event.stopPropagation();
    onChange("", setShow);
  };

  useEffect(() => {
    if (!placeholder && options.length && !noDefault) {
      onChange(value || options[0].value, setShow);
    }
  }, []);

  function handleClickOutside() {
    if (isShow) setShow(false);
  }

  function handleClickInside() {
    if (disabled) return;
    setShow(!isShow);
  }

  useOnClickOutside(ref, handleClickOutside, [refSelect]);

  const valueSelect: string = useMemo(() => {
    if (!value) return "";
    return typeof value === "object" ? value.value : value;
  }, [value]);

  const fontStyle = useCallback(
    (font: string, label: string) => {
      const correctSize = isFontsForCustomCard && isMobile;
      return {
        fontFamily: correctSize ? (label as string) : font,
        fontSize: correctSize ? "1rem" : "1.5rem",
        lineHeight: correctSize ? "2rem" : "2.5rem",
        minHeight: correctSize ? "1rem" : "1.7777rem",
      };
    },
    [isMobile, isFontsForCustomCard]
  );

  const optionsWithPlaceholder: IOption[] = useMemo(() => {
    if (placeholder)
      return [
        {
          id: -1,
          value: "",
          label: placeholder,
          key: placeholder,
        },
        ...options,
      ];
    return options;
  }, [placeholder, options]);

  useEffect(() => {
    const option = optionsWithPlaceholder.find(
      (op) => op.value === valueSelect
    );
    if (option) {
      if (typeof option.alt === "function") {
        if (
          additionalInfo &&
          +option.id === +(additionalInfo?.subscription_id ?? 0)
        ) {
          setCustomSelect(
            <span>
              {option.value}
              <span style={{ color: "#FF5037", fontWeight: "bold" }}>
                {` (CURRENT)`}
              </span>
            </span>
          );
        } else {
          setCustomSelect(<span>{option.value}</span>);
        }
      }
    }
  }, [valueSelect]);

  const styleForSelect = useMemo(() => {
    const isValueFont = options.find((opt) => opt.font === valueSelect);
    if (isValueFont)
      return {
        fontFamily: isFontsForCustomCard
          ? (isValueFont.label as string)
          : valueSelect,
        fontSize: isFontsForCustomCard && isMobile ? "1rem" : "1.5rem",
        lineHeight: "2.5rem",
      };

    // if select has placeholder and has no default value
    if (!value && placeholder && placeholderStyle) {
      return placeholderStyle;
    }

    return {};
  }, [
    value,
    placeholder,
    valueSelect,
    options,
    isMobile,
    isFontsForCustomCard,
    noDefault,
  ]);

  const isNotScrollContainer = options.length <= 6;

  const containerMaxHeightForText = isNotScrollContainer
    ? "max-content"
    : `calc(${"2.77777rem"} * 5)`;

  const optionsContainerMaxHeight = useMemo(() => {
    return options.find((opt) => !!opt.alt)
      ? "min-content"
      : containerMaxHeightForText;
  }, [options]);

  const styleForCustomSelect = useMemo(() => {
    if (!isShow) return {};

    return {
      height: optionsContainerMaxHeight,
      maxHeight: options.find((opt) => !!opt.alt) ? "12rem" : "auto",
      minWidth: `${isMobile ? "auto" : "min-content"}`,
    };
  }, [isShow, options, optionsContainerMaxHeight]);

  return (
    <div
      className={`custom-select ${containerClass || ""} ${
        isShow ? "show" : ""
      }`}
      style={style}
    >
      <div ref={refSelect} className='select' onClick={handleClickInside}>
        {!selectPlanId && (
          <div className='select select__custom'>{customSelect}</div>
        )}
        {fixedPlaceholder ? (
          <div className='fixed-placeholder-select'>
            <span className='fixed-placeholder-select__placeholder'>
              {fixedPlaceholder}
            </span>
            <span>
              {optionsWithPlaceholder.find((v) => v.value === value)?.label}
            </span>
          </div>
        ) : (
          <>
            <SelectStyled
              value={valueSelect}
              style={
                styleForCancelButton
                  ? { width: "65%", fontSize: isMobile ? "smaller" : "small" }
                  : styleForSelect
              }
              onChange={() => {}}
              name={name}
            >
              {optionsWithPlaceholder.map(({ id, value, label, alt, key }) => (
                <option
                  style={{ color: "red" }}
                  key={key || id}
                  value={typeof value === "object" ? value.value : value}
                >
                  {alt || label}
                </option>
              ))}
            </SelectStyled>
            {icon}
          </>
        )}
        <CancelSelectValueButton
          active={!!value && !!clearSelector}
          onClick={handleClearClick}
          style={styleForCancelButton}
        />
      </div>
      <div className={optionsClasses} ref={ref} style={styleForCustomSelect}>
        <div
          className='select__container'
          style={{
            maxHeight: optionsContainerMaxHeight,
          }}
          ref={optionsContainerRef}
        >
          {options.map(({ id, value, label, font, src }) => {
            return (
              <div
                key={id}
                style={font ? fontStyle(font, label as string) : {}}
                className='option'
                onClick={() => {
                  onChange(src ? { value: `${value}`, src } : value, setShow);
                }}
              >
                {typeof label === "function" ? label() : label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(Select);
