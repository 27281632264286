import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  deleteAddress,
  getAddresses,
  addAddress,
  updateAddress,
  getAddress,
} from "./thunks";
import { addressAdapter, initialState } from "./entity";

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setEditId: (state, action: PayloadAction<number | null>) => {
      state.editId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAddress.fulfilled, (state, { payload }) => {
      addressAdapter.addOne(state, payload);
    });

    builder.addCase(getAddresses.fulfilled, (state, { payload }) => {
      addressAdapter.setAll(state, payload);
    });
    builder.addCase(getAddress.fulfilled, (state, { payload }) => {
      state.defaultAddress = payload;
      state.billing_info = payload.billing_info;
    });
    builder.addCase(deleteAddress.fulfilled, (state, { payload }) => {
      if (payload) addressAdapter.removeOne(state, payload);
    });
    builder.addCase(updateAddress.fulfilled, (state, { payload }) => {
      if (payload) {
        const { id } = payload;
        addressAdapter.updateOne(state, { id: id!, changes: { ...payload } });
      }
    });
  },
});

export const { setEditId } = addressSlice.actions;

export default addressSlice.reducer;
