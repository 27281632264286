import styled from "styled-components";

export const ButtonCircle = styled.button`
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

export const ButtonCircleStyled = styled(ButtonCircle)`
  width: 1.77777rem;
  height: 1.77777rem;
  background-color: #e9e9e0;
`;
