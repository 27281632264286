import { CSSProperties, FC, useContext } from "react";
import { AiFillDelete } from "react-icons/ai";

import classNames from "classnames";
import {
  deleteCustomImage,
  deleteQrCodeById,
} from "../../../redux/custom-cards/thunks";
import { useAppDispatch } from "../../../hooks/redux/useRedux";
import { ImageType } from "../../../interfaces/custom-cards/ICustomCardImage";
import { ConfirmationPopupContext } from "../../../context/confirmation-popup.provider";

interface ICustomImageLogo {
  image_url: string;
  id: string;
  type: ImageType | undefined;
  handleImage: (id: number, image_url: string) => void;
  customizeId: number;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
  onDelete?: () => void;
}

const CustomImageItem: FC<ICustomImageLogo> = ({
  image_url,
  id,
  type,
  handleImage,
  customizeId,
  containerStyle,
  imgStyle,
  onDelete,
}) => {
  const dispatch = useAppDispatch();

  const { openConfirmationPopup } = useContext(ConfirmationPopupContext);

  const ci = classNames({
    "upload-image-gallery__img": !(+customizeId === +id),
    "upload-image-gallery__img__selected": +customizeId === +id,
  });

  const deleteCustomMedia = (id: number | string, type?: ImageType) => {
    if (type === "qrCode") return dispatch(deleteQrCodeById(+id));
    dispatch(deleteCustomImage({ id: +id, type: type! }));
    if (onDelete) {
      onDelete();
    }
  };

  const openConfirmPopup = () => {
    openConfirmationPopup({
      subtitle: "Are you sure you want to delete?",
      onConfirm: () => deleteCustomMedia(id, type),
    });
  };

  return (
    <div className='upload-image-gallery__container' style={containerStyle}>
      <img
        className={ci}
        src={image_url}
        alt={type}
        onClick={() => handleImage(+id, image_url)}
        style={imgStyle}
      />
      <AiFillDelete
        color='red'
        className='upload-image-gallery__delete-image'
        onClick={openConfirmPopup}
        size='1em'
      />
    </div>
  );
};

export default CustomImageItem;
