import {
  Dispatch,
  SetStateAction,
  RefObject,
  useCallback,
  useState,
} from "react";

import { addTemplate, chooseTemplate } from "../../redux/templates/slice";
import { closePopup } from "../../redux/portal-popup/slice";
import {
  createTemplate,
  deleteTemplate,
  updateTemplate,
} from "../../redux/templates/thunks";
import { useAppDispatch, useAppSelector } from "../redux/useRedux";
import {
  selectCreateCandidateTemplate,
  selectTemplateById,
  selectTemplatesState,
} from "../../redux/templates/selectors";
import { setActiveOrder } from "../../redux/order/slice";
import { selectOrder } from "../../redux/order/selectors";

import {
  ITemplateCreatePayload,
  ITemplateUpdatePayload,
  ITemplate,
  TemplateId,
} from "../../interfaces/templates/ITemplate";
import { IValue } from "../../components/ui/common/select/select.common";
import usePopup from "../popup/usePopup";
import useForm from "../forms/useForm";

import popupConstants from "../../constants/popup/constants";
import typePopups from "../../constants/popup/type-popup";

const { SAVE_TEMPLATE } = typePopups;

interface ITextareaField {
  setValue: (value: string) => void;
  value: string;
}

export interface ISelectHighlight {
  anchor: number;
  focus: number;
}

interface ISignatureSelect {
  setValue: (value: IValue) => void;
}

const useTemplates = (ref?: RefObject<HTMLTextAreaElement>) => {
  const dispatch = useAppDispatch();

  const [select, setSelection] = useState(null);

  const { activeTemplateId } = useAppSelector(selectTemplatesState);
  const activeTemplate = useAppSelector((state) =>
    selectTemplateById(state, activeTemplateId!)
  );
  const templateWithCreateStatus = useAppSelector(
    selectCreateCandidateTemplate
  );

  const { card } = useAppSelector(selectOrder);

  const { openFormPopup, openErrorPopup } = usePopup();
  const { checkEmptyInputs } = useForm();

  const add = async (template: ITemplate) => {
    if (templateWithCreateStatus) return;

    dispatch(addTemplate({ status: "create", template }));
  };

  const remove = (id: TemplateId) => dispatch(deleteTemplate(id));

  const addTagToMessage = useCallback(
    (
      val: string | IValue,
      message: ITextareaField,
      setShow?: Dispatch<SetStateAction<boolean>>
    ) => {
      const value = val as string;
      if (setShow) setShow(false);
      // @ts-ignore
      const includesHighlight = ref?.current?.props?.highlight;
      if (ref?.current && !select) {
        const currentCursorStart = ref!.current.selectionStart;
        const currentCursorEnd = ref!.current.selectionEnd;
        const result =
          message.value.slice(0, currentCursorStart).concat(value) +
          message.value.slice(currentCursorEnd);
        message.setValue(result);
      }
      if (
        ref?.current &&
        (select as ISelectHighlight | null) &&
        !includesHighlight
      ) {
        const currentCursorStart = ref!.current.selectionStart;
        const currentCursorEnd = ref!.current.selectionEnd;
        const result =
          message.value.slice(0, currentCursorStart).concat(value) +
          message.value.slice(currentCursorEnd);
        message.setValue(result);
      }
      if ((select as ISelectHighlight | null) && includesHighlight) {
        const { anchor, focus } = select!;
        const currentCursorStart = anchor;
        const currentCursorEnd = focus;
        const result =
          message.value.slice(0, currentCursorStart).concat(value) +
          message.value.slice(currentCursorEnd);
        message.setValue(result);
      }
    },
    [ref, select]
  );

  const create = async (template: ITemplateCreatePayload) => {
    const { name, message } = template;

    if (!name.trim().length || !message.trim().length)
      return openErrorPopup("Name and message required");

    await dispatch(createTemplate(template));
  };

  const update = async (template: ITemplateUpdatePayload) => {
    await dispatch(updateTemplate(template));
  };

  const injectActiveTemplate = (
    template: ITemplate,
    messageField: ITextareaField,
    wishesField: ITextareaField,
    signatureSelect: ISignatureSelect
  ) => {
    const { message, signature, signature2, wishes } = template;

    if (message.length > card?.characters!) {
      dispatch(chooseTemplate(0));
      return openErrorPopup(popupConstants.messageTemplateWrongMessageLength);
    }

    // messageField.setValue(message ?? '')
    wishesField.setValue(wishes ?? "");

    const signatureId = signature?.id || signature2?.id || "";

    signatureSelect.setValue({
      src: signature?.preview || signature2?.preview || "",
      value: String(signatureId),
    });

    dispatch(
      setActiveOrder({
        signature_id: signature?.id || 0,
        signature2_id: signature2?.id || 0,
        message,
        wishes,
      })
    );
  };

  const chooseActiveTemplate = useCallback(
    (id: TemplateId) => {
      dispatch(chooseTemplate(id));

      dispatch(closePopup());
    },
    [dispatch]
  );

  const openSaveTemplatePopup = useCallback((template: ITemplate) => {
    add(template);

    return openFormPopup("", SAVE_TEMPLATE);
  }, []);

  const saveActiveTemplateSubmit = useCallback(
    async (name: string, template: ITemplate) => {
      const isNameEmpty = checkEmptyInputs([name]);

      if (isNameEmpty) return;

      return dispatch(createTemplate({ ...template, name }));
    },
    []
  );

  return {
    remove,
    add,
    create,
    setSelection,
    update,
    addTagToMessage,
    injectActiveTemplate,
    chooseActiveTemplate,
    openSaveTemplatePopup,
    saveActiveTemplateSubmit,
    activeTemplate,
    select,
  };
};

export default useTemplates;
