import { createSlice } from "@reduxjs/toolkit";

import initialState from "./types";
import fetchHolidays from "./thunks";

const holidaysSlice = createSlice({
  name: "holidays",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHolidays.fulfilled, (state, { payload }) => {
      state.holidays = payload;
    });
  },
});

export default holidaysSlice.reducer;
