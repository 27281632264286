import { FC, memo } from "react";

import { IButtonProps } from "../../../../interfaces/button/IButton";
import { FlexContainer } from "../../layout";
import { ButtonCircleStyled } from "./btn.styled";

const ButtonCircle: FC<IButtonProps> = ({
  children,
  onClick,
  style,
  disabled = false,
}) => {
  return (
    <ButtonCircleStyled
      onClick={disabled ? () => {} : onClick}
      style={style}
      disabled={disabled}
    >
      <FlexContainer justify='center'>{children}</FlexContainer>
    </ButtonCircleStyled>
  );
};

export default memo(ButtonCircle);
