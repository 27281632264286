import { useCallback, useEffect, FormEvent } from "react";
import { useHistory } from "react-router";

import {
  resetPasswordConfirm,
  resetPasswordRequest,
} from "../../redux/auth/thunks";
import { setPortalPopup } from "../../redux/portal-popup/slice";
import { changePassword } from "../../redux/profile/thunks";
import { useAppDispatch } from "../redux/useRedux";

import messagePopup from "../../constants/popup/constants";
import typePopups from "../../constants/popup/type-popup";

import useForm from "../forms/useForm";
import useUrlSearchParams from "../utils/useUrlSearchParams";
import navigation from "../../constants/navigation";

const { PASSWORD_RESET_REQUEST_FORM, PASSWORD_RESET_CONFIRM_FORM } = typePopups;

interface FormElementsPasswordReset extends HTMLCollection {
  password: HTMLInputElement;
  password_confirm: HTMLInputElement;
}

interface FormElementsPasswordEdit extends HTMLCollection {
  old_password: HTMLInputElement;
  new_password: HTMLInputElement;
  confirm_password: HTMLInputElement;
}

const usePassword = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const hash = useUrlSearchParams("hash");
  const { isInputsEqual } = useForm();

  const openConfirmPasswordResetPortalPopup = () => {
    dispatch(
      setPortalPopup({
        typePopup: "form",
        title: messagePopup.confirmPasswordReset,
        form: PASSWORD_RESET_CONFIRM_FORM,
      })
    );
  };

  const openPasswordResetFormPopup = () => {
    dispatch(
      setPortalPopup({
        typePopup: "form",
        title: messagePopup.passwordReset,
        form: PASSWORD_RESET_REQUEST_FORM,
      })
    );
  };

  const requestPasswordResetSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const email = (event.currentTarget[0] as HTMLInputElement).value;

      await dispatch(resetPasswordRequest(email));
    },
    []
  );

  const confirmPasswordResetSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!hash) return;

      const { password, password_confirm } = event.currentTarget
        .elements as FormElementsPasswordReset;

      await dispatch(
        resetPasswordConfirm({
          pass: password.value,
          pass_confirm: password_confirm.value,
          hash,
        })
      );

      history.push(navigation.auth.signin);
    },
    []
  );

  const editPasswordSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { old_password, new_password, confirm_password } = event
        .currentTarget.elements as FormElementsPasswordEdit;
      const isPasswordsEqual = isInputsEqual([
        new_password.value,
        confirm_password.value,
      ]);

      if (!isPasswordsEqual) return;

      await dispatch(
        changePassword({
          old_password: old_password.value,
          new_password: new_password.value,
        })
      );
    },
    []
  );

  useEffect(() => {
    if (hash) return openConfirmPasswordResetPortalPopup();
  }, [hash]);

  return {
    openPasswordResetFormPopup,
    requestPasswordResetSubmit,
    confirmPasswordResetSubmit,
    editPasswordSubmit,
  };
};

export default usePassword;
