import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import prepaidPlansApi from "../../api/prepaid-plans.api";
import { IThunkError } from "../../interfaces/redux/IThunkError";
import {
  IPrepaidPlanPayload,
  IUpdarePrepaidPlanPayload,
} from "../../interfaces/subscriptions/IPrepaidPlans";
import { login } from "../auth/thunks";

export const fetchPrepaidPlans = createAsyncThunk(
  "/prepaidPlans/list",
  async () => {
    const res = await prepaidPlansApi.list();

    return res.data;
  }
);

export const buyPrepaidPlan = createAsyncThunk<
  any,
  IPrepaidPlanPayload,
  { rejectValue: IThunkError }
>("prepaidPlans/buy", async (payload, { rejectWithValue }) => {
  try {
    const res = await prepaidPlansApi.buy(payload);
    if (!res) return;
    return res.data;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});

export const updatePrepaidPlan = createAsyncThunk<
  any,
  IUpdarePrepaidPlanPayload,
  { rejectValue: IThunkError }
>("prepaidPlans", async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await prepaidPlansApi.update(payload);
    if (!res) return;
    dispatch(login());
    return res.data;
  } catch (err) {
    if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data);
  }
});
